import { useState, useEffect } from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import FormField from 'forms/FormField'
import FormGroup from 'forms/FormsGroup'
import Title from 'components/Title'
import Container from 'components/Container'
import BackgroundImage from 'components/BackgroundImage'
import PlaceholderCover from 'assets/placeholders/inf-mobile-profile-cover.jpg'
import FormSwitch from 'forms/FormSwitch'
import { ReactComponent as IconCamera } from 'assets/icons/camera.svg'
import Button from 'components/Button'
import FilePicker from 'utils/filePicker'
import { useClient } from 'urql'
import {
  CreateHeroBannerDocument,
  CreateHeroBannerMutation,
  DeleteOptionalBannerPicDocument,
  GetHeroBannerByIdQuery,
  GetLastHeroOrderDocument,
  GetLastHeroOrderQuery,
  UpdateHeroBannerDocument,
  UploadHeroBannerPictureDocument,
  UploadHeroBannerPictureMutation,
  useGetHeroBannerByIdQuery,
} from 'generated/graphql'
import { toInteger } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { LoadingSpinner } from 'components/Loading'
import ErrorMessage from 'components/ErrorMessage'
import combineQuery from 'graphql-combine-query'
import { useToast } from 'components/ToastMessage'
import { updatedDiff } from 'deep-object-diff'
import { mapValues, pick } from 'lodash/fp'

function HeroDetails() {
  const { heroID } = useParams<{ heroID: string }>()

  return (
    <Container topAndBottom>
      {heroID ? <HeroDetailsWithValidData id={+heroID} /> : <NewHeroForm />}
    </Container>
  )
}

const HeroDetailsWithValidData = ({ id }: { id: number }) => {
  const [{ data, fetching, error }] = useGetHeroBannerByIdQuery({
    variables: {
      id,
    },
  })

  if (fetching) {
    return <LoadingSpinner $color="primary" $size="md" $type="logo" />
  }
  if (error) {
    return <ErrorMessage>{error.message}</ErrorMessage>
  }
  return (
    <Container topAndBottom>
      <NewHeroForm initialValues={data?.HeroBanner_by_pk} />
    </Container>
  )
}

const imageValidation = yup.object({
  imageBase64: yup.string(),
  imageType: yup.string(),
  imageUrl: yup.string(),
})

const NewHeroForm = ({
  initialValues,
}: {
  initialValues?: GetHeroBannerByIdQuery['HeroBanner_by_pk']
}) => {
  const { handleSubmit, register, setValue, formState, getValues } = useForm({
    defaultValues: {
      title: initialValues?.title || '',
      subtitle: initialValues?.subtitle || '',
      active: initialValues?.active || false,
      mainImage: {
        imageBase64: '',
        imageType: '',
        imageUrl: initialValues?.WUI_File_Lg?.url || '',
      },
      mdImage: {
        imageBase64: '',
        imageType: '',
        imageUrl: initialValues?.WUI_File_Md?.url || '',
      },
      smImage: {
        imageBase64: '',
        imageType: '',
        imageUrl: initialValues?.WUI_File_Sm?.url || '',
      },
    },
    resolver: yupResolver(
      yup.object({
        title: yup.string().required(),
        subtitle: yup.string().required(),
        mainImage: imageValidation.required(),
        mdImage: imageValidation,
        smImage: imageValidation,
        active: yup.boolean(),
      }),
    ),
  })
  const [showImages, setShowImages] = useState(false)
  const [mainImageState, setMainImage] = useState(
    initialValues?.WUI_File_Lg?.url || '',
  )
  const [mdImageState, setMdImage] = useState(
    initialValues?.WUI_File_Md?.url || '',
  )
  const [smImageState, setSmImage] = useState(
    initialValues?.WUI_File_Sm?.url || '',
  )
  const [uploadingImages, setUploadingImages] = useState<null | {
    current: number
    total: number
  }>(null)
  const { errors, isDirty, isSubmitting } = formState
  const client = useClient()
  const history = useHistory()
  const toast = useToast()

  useEffect(() => {
    if (initialValues?.WUI_File_Md || initialValues?.WUI_File_Sm) {
      setShowImages(true)
    }
  }, [initialValues])

  const getImageID = (key: string) => {
    switch (key) {
      case 'imageLg':
        return (
          (initialValues?.WUI_File_Lg &&
            getValues('mainImage').imageBase64 &&
            initialValues.WUI_File_Lg.id) ||
          -1
        )
      case 'imageMd':
        return (
          (initialValues?.WUI_File_Md &&
            getValues('mdImage').imageBase64 &&
            initialValues.WUI_File_Md.id) ||
          -1
        )
      case 'imageSm':
        return (
          (initialValues?.WUI_File_Sm &&
            getValues('smImage').imageBase64 &&
            initialValues.WUI_File_Sm.id) ||
          -1
        )
      default:
        return -1
    }
  }

  const addMainPicture = async (target: string) => {
    const { base64String, mime } = await FilePicker('image/*')
    switch (target) {
      case 'mainImage':
        setValue(
          'mainImage',
          {
            imageBase64: base64String,
            imageType: mime,
            imageUrl: '',
          },
          { shouldDirty: true },
        )
        setMainImage(`data:${mime};base64,${base64String}`)
        break
      case 'mdImage':
        setValue(
          'mdImage',
          {
            imageBase64: base64String,
            imageType: mime,
            imageUrl: '',
          },
          { shouldDirty: true },
        )
        setMdImage(`data:${mime};base64,${base64String}`)
        break
      case 'smImage':
        setValue(
          'smImage',
          {
            imageBase64: base64String,
            imageType: mime,
            imageUrl: '',
          },
          { shouldDirty: true },
        )
        setSmImage(`data:${mime};base64,${base64String}`)
        break
    }
  }

  return (
    <form
      onSubmit={handleSubmit(
        async ({ title, subtitle, active, mainImage, mdImage, smImage }) => {
          const images = [
            { key: 'imageLg', ...mainImage },
            { key: 'imageMd', ...mdImage },
            { key: 'imageSm', ...smImage },
          ].filter((image) => image.imageBase64 && image.imageType)
          //Todo: Improve the combineQuery in the future
          let mutation: any = combineQuery('UltimateHeroBannerMutation')
          let createHeroResponse
          let picturesErrors: string[] = []
          let heroID: number
          if (!initialValues?.id) {
            const getLastOrderResponse = await client
              .query<GetLastHeroOrderQuery>(GetLastHeroOrderDocument)
              .toPromise()

            const lastOrder =
              toInteger(
                getLastOrderResponse.data?.HeroBanner_aggregate?.aggregate?.max
                  ?.order,
              ) + 1 || 0
            createHeroResponse = await client
              .mutation<CreateHeroBannerMutation>(CreateHeroBannerDocument, {
                title,
                subtitle,
                active,
                order: lastOrder,
              })
              .toPromise()
            heroID = createHeroResponse.data?.insert_HeroBanner_one?.id
          } else {
            heroID = initialValues.id
            const heroValueKeys = ['title', 'subtitle', 'active']
            const heroChanges = updatedDiff(
              pick(heroValueKeys, initialValues),
              pick(heroValueKeys, { title, subtitle, active }),
            )

            if (Object.keys(heroChanges).length > 0) {
              //Note: we can improve this query by implementing the images here too
              mutation = mutation.add(
                UpdateHeroBannerDocument,
                {
                  id: heroID,
                  set: (mapValues as any).convert({ cap: false })(
                    (value: any) => {
                      return value
                    },
                    heroChanges,
                  ),
                },
                heroChanges,
              )
            }
          }

          const imagesToDelete = [
            initialValues?.WUI_File_Md &&
            !mdImage.imageUrl &&
            !mdImage.imageBase64
              ? {
                  key: 'imageMd',
                  ...initialValues.WUI_File_Md,
                }
              : null,
            initialValues?.WUI_File_Sm &&
            !smImage.imageUrl &&
            !smImage.imageBase64
              ? {
                  key: 'imageSm',
                  ...initialValues.WUI_File_Sm,
                }
              : null,
          ].filter(Boolean)

          for (let i = 0; i < images.length; i++) {
            const { imageBase64, imageType, key } = images[i]
            setUploadingImages({
              current: i + 1,
              total: images.length,
            })
            const pictureResponse = await client
              .mutation<UploadHeroBannerPictureMutation>(
                UploadHeroBannerPictureDocument,
                {
                  heroID,
                  imageBase64,
                  imageType,
                  key,
                  oldImageID: getImageID(key),
                },
              )
              .toPromise()
            if (pictureResponse.error?.message) {
              picturesErrors.push(pictureResponse.error.message)
            }
          }
          setUploadingImages(null)
          if (imagesToDelete.length > 0) {
            mutation = mutation.addN(
              DeleteOptionalBannerPicDocument,
              imagesToDelete
                .map((image) => {
                  if (image?.key) {
                    const { key, id } = image
                    return {
                      id: heroID,
                      set: { [key]: null },
                      imageID: id,
                    }
                  } else {
                    return null
                  }
                })
                .filter(Boolean),
            )
          }
          if ('document' in mutation) {
            const { document, variables } = mutation as any
            const response = await client
              .mutation(document, variables)
              .toPromise()
            if (response.error || picturesErrors.length > 0) {
              toast.notify({
                type: 'failure',
                message: `There was an error updating the banner`,
              })
            } else {
              toast.notify({
                type: 'success',
                message: 'The banner has been successfuly updated.',
              })
              if (!initialValues?.id) history.push(`/hero/${heroID}`)
            }
          } else if (createHeroResponse || images.length > 0) {
            if (picturesErrors.length > 0 || createHeroResponse?.error) {
              toast.notify({
                type: 'failure',
                message: `There was an error ${
                  initialValues?.id ? 'Creating' : 'Updating'
                } this banner`,
              })
            } else {
              toast.notify({
                type: 'success',
                message: `Hero ${!initialValues?.id ? 'Created' : 'Updated'}`,
              })
              if (!initialValues?.id) history.push(`/hero/${heroID}`)
            }
          }
        },
      )}
      className="max-w-xl mx-auto"
    >
      {uploadingImages && (
        <div>{`Uploading ${uploadingImages.current}/${uploadingImages.total}...`}</div>
      )}
      <div className="flex">
        <Title title={`${initialValues ? 'Update' : 'Create a'} hero banner`} />
        <div className="ml-auto">
          <FormSwitch
            onChange={(state) => setValue('active', state)}
            defaultValue={getValues('active')}
          />
        </div>
      </div>
      <FormGroup>
        <FormField
          placeholder="Hero Title"
          {...register('title')}
          error={errors.title?.message}
        />
        <FormField
          placeholder="Hero Subtitle"
          {...register('subtitle')}
          error={errors.subtitle?.message}
        />
        <div>
          <div className="aspect-w-16 aspect-h-9 relative h-20">
            <BackgroundImage
              image={mainImageState || PlaceholderCover}
              className="rounded-lg"
            >
              <div className="inline-flex p-2 z-0">
                <div className="p-1">
                  <button
                    type="button"
                    className="bg-black rounded-full bg-opacity-60 p-2"
                    onClick={() => {
                      addMainPicture('mainImage')
                    }}
                  >
                    <IconCamera height="17" className="fill-white" />
                  </button>
                </div>
              </div>
            </BackgroundImage>
          </div>
          <div className="text-action-fail text-sm pt-1">
            {errors.mainImage?.imageBase64?.message}
          </div>
        </div>
        {/* TODO: Add button to delete individual images */}
        <Button
          $type={'secondary'}
          onClick={() => {
            if (showImages) {
              setValue(
                'mdImage',
                {
                  imageUrl: '',
                  imageBase64: '',
                  imageType: '',
                },
                { shouldDirty: true },
              )
              setMdImage('')
              setValue(
                'smImage',
                {
                  imageUrl: '',
                  imageBase64: '',
                  imageType: '',
                },
                { shouldDirty: true },
              )
              setSmImage('')
            }
            setShowImages(!showImages)
          }}
          type="button"
        >{`${
          !showImages ? 'Add Custom Images' : 'Delete Custom Images'
        }`}</Button>

        {/*  Custo Images */}
        {/*  Medium size image */}
        <div className="flex flex-col gap-4">
          {showImages && (
            <>
              <div className="font-bold">Medium Size Image</div>
              <div className="aspect-w-16 aspect-h-9 relative h-20 max-w-md">
                <BackgroundImage
                  image={mdImageState || PlaceholderCover}
                  className="rounded-lg"
                >
                  <div className="inline-flex p-2 z-0 ">
                    <div className="p-1">
                      <button
                        //                  type="button"
                        type="button"
                        className="bg-black rounded-full bg-opacity-60 p-2"
                        onClick={() => {
                          addMainPicture('mdImage')
                        }}
                      >
                        <IconCamera height="17" className="fill-white" />
                      </button>
                    </div>
                  </div>
                </BackgroundImage>
              </div>
              {/*  Small size image */}
              <div className="font-bold">Small Size Image</div>
              <div className="aspect-w-16 aspect-h-9 relative h-20 max-w-sm">
                <BackgroundImage
                  image={smImageState || PlaceholderCover}
                  className="rounded-lg"
                >
                  <div className="inline-flex p-2 z-0 ">
                    <div className="p-1">
                      <button
                        type="button"
                        className="bg-black rounded-full bg-opacity-60 p-2"
                        onClick={() => {
                          addMainPicture('smImage')
                        }}
                      >
                        <IconCamera height="17" className="fill-white" />
                      </button>
                    </div>
                  </div>
                </BackgroundImage>
              </div>
            </>
          )}
        </div>
      </FormGroup>
      <Button $fluid disabled={!isDirty} loading={isSubmitting}>
        Save
      </Button>
    </form>
  )
}

export default HeroDetails
