import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  json: any;
  jsonb: any;
  numeric: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};


export type BankAccountOutput = {
  __typename?: 'BankAccountOutput';
  account: Scalars['String'];
  account_holder_name: Scalars['String'];
  account_holder_type: Scalars['String'];
  bank_name: Scalars['String'];
  country: Scalars['String'];
  currency: Scalars['String'];
  fingerprint: Scalars['String'];
  id: Scalars['String'];
  last4: Scalars['String'];
  metadata: Scalars['json'];
  object: Scalars['String'];
  routing_number: Scalars['String'];
  status: Scalars['String'];
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

export type CompanyAddress = {
  city: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  primary: Scalars['String'];
  secondary?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  zipCode: Scalars['String'];
};

export type CountrySpecsOutput = {
  __typename?: 'CountrySpecsOutput';
  company: Scalars['jsonb'];
  individual: Scalars['jsonb'];
};

export type CountrySupportedOutput = {
  __typename?: 'CountrySupportedOutput';
  data: Scalars['jsonb'];
};

export type CreateCompanyInfoOutput = {
  __typename?: 'CreateCompanyInfoOutput';
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateIndividualAccountOutput = {
  __typename?: 'CreateIndividualAccountOutput';
  success?: Maybe<Scalars['Boolean']>;
};

export type DataOutput = {
  __typename?: 'DataOutput';
  received_value: Scalars['String'];
};

export type DefaultBankAccountOutput = {
  __typename?: 'DefaultBankAccountOutput';
  id: Scalars['String'];
};

/** columns and relationships of "Demographic" */
export type Demographic = {
  __typename?: 'Demographic';
  value: Scalars['String'];
};

/** aggregated selection of "Demographic" */
export type Demographic_Aggregate = {
  __typename?: 'Demographic_aggregate';
  aggregate?: Maybe<Demographic_Aggregate_Fields>;
  nodes: Array<Demographic>;
};

/** aggregate fields of "Demographic" */
export type Demographic_Aggregate_Fields = {
  __typename?: 'Demographic_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Demographic_Max_Fields>;
  min?: Maybe<Demographic_Min_Fields>;
};


/** aggregate fields of "Demographic" */
export type Demographic_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Demographic_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "Demographic". All fields are combined with a logical 'AND'. */
export type Demographic_Bool_Exp = {
  _and?: Maybe<Array<Demographic_Bool_Exp>>;
  _not?: Maybe<Demographic_Bool_Exp>;
  _or?: Maybe<Array<Demographic_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Demographic" */
export enum Demographic_Constraint {
  /** unique or primary key constraint */
  SellerSocialMediaItemPkey = 'SellerSocialMediaItem_pkey'
}

export enum Demographic_Enum {
  AudienceAges = 'audience_ages',
  AudienceGender = 'audience_gender',
  AudienceSize = 'audience_size',
  AverageEngagement = 'average_engagement',
  AverageLikesPerPost = 'average_likes_per_post',
  InfluencerCategory = 'influencer_category',
  Location = 'location'
}

/** Boolean expression to compare columns of type "Demographic_enum". All fields are combined with logical 'AND'. */
export type Demographic_Enum_Comparison_Exp = {
  _eq?: Maybe<Demographic_Enum>;
  _in?: Maybe<Array<Demographic_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Demographic_Enum>;
  _nin?: Maybe<Array<Demographic_Enum>>;
};

/** input type for inserting data into table "Demographic" */
export type Demographic_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Demographic_Max_Fields = {
  __typename?: 'Demographic_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Demographic_Min_Fields = {
  __typename?: 'Demographic_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Demographic" */
export type Demographic_Mutation_Response = {
  __typename?: 'Demographic_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Demographic>;
};

/** input type for inserting object relation for remote table "Demographic" */
export type Demographic_Obj_Rel_Insert_Input = {
  data: Demographic_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Demographic_On_Conflict>;
};

/** on conflict condition type for table "Demographic" */
export type Demographic_On_Conflict = {
  constraint: Demographic_Constraint;
  update_columns?: Array<Demographic_Update_Column>;
  where?: Maybe<Demographic_Bool_Exp>;
};

/** Ordering options when selecting data from "Demographic". */
export type Demographic_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: Demographic */
export type Demographic_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "Demographic" */
export enum Demographic_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "Demographic" */
export type Demographic_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "Demographic" */
export enum Demographic_Update_Column {
  /** column name */
  Value = 'value'
}

export type FileOutput = {
  __typename?: 'FileOutput';
  fileId: Scalars['bigint'];
  fileType: Scalars['String'];
  imageUrl: Scalars['String'];
};

/** columns and relationships of "HeroBanner" */
export type HeroBanner = {
  __typename?: 'HeroBanner';
  /** An object relationship */
  WUI_File_Lg?: Maybe<Wui_File>;
  /** An object relationship */
  WUI_File_Md?: Maybe<Wui_File>;
  /** An object relationship */
  WUI_File_Sm?: Maybe<Wui_File>;
  active?: Maybe<Scalars['Boolean']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  imageLg?: Maybe<Scalars['bigint']>;
  imageMd?: Maybe<Scalars['bigint']>;
  imageSm?: Maybe<Scalars['bigint']>;
  order: Scalars['Int'];
  subtitle: Scalars['String'];
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "HeroBanner" */
export type HeroBanner_Aggregate = {
  __typename?: 'HeroBanner_aggregate';
  aggregate?: Maybe<HeroBanner_Aggregate_Fields>;
  nodes: Array<HeroBanner>;
};

/** aggregate fields of "HeroBanner" */
export type HeroBanner_Aggregate_Fields = {
  __typename?: 'HeroBanner_aggregate_fields';
  avg?: Maybe<HeroBanner_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<HeroBanner_Max_Fields>;
  min?: Maybe<HeroBanner_Min_Fields>;
  stddev?: Maybe<HeroBanner_Stddev_Fields>;
  stddev_pop?: Maybe<HeroBanner_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<HeroBanner_Stddev_Samp_Fields>;
  sum?: Maybe<HeroBanner_Sum_Fields>;
  var_pop?: Maybe<HeroBanner_Var_Pop_Fields>;
  var_samp?: Maybe<HeroBanner_Var_Samp_Fields>;
  variance?: Maybe<HeroBanner_Variance_Fields>;
};


/** aggregate fields of "HeroBanner" */
export type HeroBanner_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<HeroBanner_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type HeroBanner_Avg_Fields = {
  __typename?: 'HeroBanner_avg_fields';
  id?: Maybe<Scalars['Float']>;
  imageLg?: Maybe<Scalars['Float']>;
  imageMd?: Maybe<Scalars['Float']>;
  imageSm?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "HeroBanner". All fields are combined with a logical 'AND'. */
export type HeroBanner_Bool_Exp = {
  WUI_File_Lg?: Maybe<Wui_File_Bool_Exp>;
  WUI_File_Md?: Maybe<Wui_File_Bool_Exp>;
  WUI_File_Sm?: Maybe<Wui_File_Bool_Exp>;
  _and?: Maybe<Array<HeroBanner_Bool_Exp>>;
  _not?: Maybe<HeroBanner_Bool_Exp>;
  _or?: Maybe<Array<HeroBanner_Bool_Exp>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  imageLg?: Maybe<Bigint_Comparison_Exp>;
  imageMd?: Maybe<Bigint_Comparison_Exp>;
  imageSm?: Maybe<Bigint_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  subtitle?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "HeroBanner" */
export enum HeroBanner_Constraint {
  /** unique or primary key constraint */
  HeroBannerPkey = 'HeroBanner_pkey'
}

/** input type for incrementing numeric columns in table "HeroBanner" */
export type HeroBanner_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  imageLg?: Maybe<Scalars['bigint']>;
  imageMd?: Maybe<Scalars['bigint']>;
  imageSm?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "HeroBanner" */
export type HeroBanner_Insert_Input = {
  WUI_File_Lg?: Maybe<Wui_File_Obj_Rel_Insert_Input>;
  WUI_File_Md?: Maybe<Wui_File_Obj_Rel_Insert_Input>;
  WUI_File_Sm?: Maybe<Wui_File_Obj_Rel_Insert_Input>;
  active?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  imageLg?: Maybe<Scalars['bigint']>;
  imageMd?: Maybe<Scalars['bigint']>;
  imageSm?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type HeroBanner_Max_Fields = {
  __typename?: 'HeroBanner_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  imageLg?: Maybe<Scalars['bigint']>;
  imageMd?: Maybe<Scalars['bigint']>;
  imageSm?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type HeroBanner_Min_Fields = {
  __typename?: 'HeroBanner_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  imageLg?: Maybe<Scalars['bigint']>;
  imageMd?: Maybe<Scalars['bigint']>;
  imageSm?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "HeroBanner" */
export type HeroBanner_Mutation_Response = {
  __typename?: 'HeroBanner_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<HeroBanner>;
};

/** on conflict condition type for table "HeroBanner" */
export type HeroBanner_On_Conflict = {
  constraint: HeroBanner_Constraint;
  update_columns?: Array<HeroBanner_Update_Column>;
  where?: Maybe<HeroBanner_Bool_Exp>;
};

/** Ordering options when selecting data from "HeroBanner". */
export type HeroBanner_Order_By = {
  WUI_File_Lg?: Maybe<Wui_File_Order_By>;
  WUI_File_Md?: Maybe<Wui_File_Order_By>;
  WUI_File_Sm?: Maybe<Wui_File_Order_By>;
  active?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  imageLg?: Maybe<Order_By>;
  imageMd?: Maybe<Order_By>;
  imageSm?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  subtitle?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: HeroBanner */
export type HeroBanner_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "HeroBanner" */
export enum HeroBanner_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ImageLg = 'imageLg',
  /** column name */
  ImageMd = 'imageMd',
  /** column name */
  ImageSm = 'imageSm',
  /** column name */
  Order = 'order',
  /** column name */
  Subtitle = 'subtitle',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "HeroBanner" */
export type HeroBanner_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  imageLg?: Maybe<Scalars['bigint']>;
  imageMd?: Maybe<Scalars['bigint']>;
  imageSm?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type HeroBanner_Stddev_Fields = {
  __typename?: 'HeroBanner_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  imageLg?: Maybe<Scalars['Float']>;
  imageMd?: Maybe<Scalars['Float']>;
  imageSm?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type HeroBanner_Stddev_Pop_Fields = {
  __typename?: 'HeroBanner_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  imageLg?: Maybe<Scalars['Float']>;
  imageMd?: Maybe<Scalars['Float']>;
  imageSm?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type HeroBanner_Stddev_Samp_Fields = {
  __typename?: 'HeroBanner_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  imageLg?: Maybe<Scalars['Float']>;
  imageMd?: Maybe<Scalars['Float']>;
  imageSm?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type HeroBanner_Sum_Fields = {
  __typename?: 'HeroBanner_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  imageLg?: Maybe<Scalars['bigint']>;
  imageMd?: Maybe<Scalars['bigint']>;
  imageSm?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
};

/** update columns of table "HeroBanner" */
export enum HeroBanner_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ImageLg = 'imageLg',
  /** column name */
  ImageMd = 'imageMd',
  /** column name */
  ImageSm = 'imageSm',
  /** column name */
  Order = 'order',
  /** column name */
  Subtitle = 'subtitle',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type HeroBanner_Var_Pop_Fields = {
  __typename?: 'HeroBanner_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  imageLg?: Maybe<Scalars['Float']>;
  imageMd?: Maybe<Scalars['Float']>;
  imageSm?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type HeroBanner_Var_Samp_Fields = {
  __typename?: 'HeroBanner_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  imageLg?: Maybe<Scalars['Float']>;
  imageMd?: Maybe<Scalars['Float']>;
  imageSm?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type HeroBanner_Variance_Fields = {
  __typename?: 'HeroBanner_variance_fields';
  id?: Maybe<Scalars['Float']>;
  imageLg?: Maybe<Scalars['Float']>;
  imageMd?: Maybe<Scalars['Float']>;
  imageSm?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

export type IndividualAddress = {
  city: Scalars['String'];
  country: Scalars['String'];
  primary: Scalars['String'];
  secondary?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  zipCode: Scalars['String'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

export type LegalInformationOutput = {
  __typename?: 'LegalInformationOutput';
  address?: Maybe<Scalars['jsonb']>;
  company?: Maybe<Scalars['jsonb']>;
  individual?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['String']>;
};

export type LoginAdminOutput = {
  __typename?: 'LoginAdminOutput';
  country?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['bigint'];
  lastName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  username: Scalars['String'];
};

export type LoginOutput = {
  __typename?: 'LoginOutput';
  accepted_terms: Scalars['Boolean'];
  country?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  hasCompletedLegalInformation: Scalars['Boolean'];
  id: Scalars['bigint'];
  isSeller: Scalars['Boolean'];
  lastName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  theme_color?: Maybe<Scalars['String']>;
  username: Scalars['String'];
};

export type OrderDetailsOutput = {
  __typename?: 'OrderDetailsOutput';
  id: Scalars['bigint'];
};

export type OrderOutput = {
  __typename?: 'OrderOutput';
  buyer_id: Scalars['bigint'];
  created_by: Scalars['bigint'];
  description: Scalars['String'];
  id: Scalars['bigint'];
  items: Scalars['jsonb'];
  metadata: Scalars['jsonb'];
  package_type: Scalars['String'];
  price: Scalars['Int'];
  seller_id: Scalars['bigint'];
  status: Scalars['String'];
  title: Scalars['String'];
};

export type PaymentIntentOutput = {
  __typename?: 'PaymentIntentOutput';
  clientSecret: Scalars['String'];
  intentID: Scalars['String'];
};

export type PaymentMethodCardOutput = {
  __typename?: 'PaymentMethodCardOutput';
  allow_3d_secure: Scalars['Boolean'];
  brand: Scalars['String'];
  country_code: Scalars['String'];
  exp_month: Scalars['Int'];
  exp_year: Scalars['Int'];
  external_id: Scalars['String'];
  fingerprint: Scalars['String'];
  funding: Scalars['String'];
  id: Scalars['bigint'];
  last4: Scalars['String'];
};

export type PortfolioFileOutput = {
  __typename?: 'PortfolioFileOutput';
  fileId: Scalars['bigint'];
  imageUrl: Scalars['String'];
  portfolioId: Scalars['bigint'];
};

/** columns and relationships of "ProjectDemographic" */
export type ProjectDemographic = {
  __typename?: 'ProjectDemographic';
  /** An object relationship */
  Demographic: Demographic;
  /** An object relationship */
  WUI_SellerProject: Wui_SellerProject;
  /** An object relationship */
  WUI_SocialMediaSource: Wui_SocialMediaSource;
  demographic: Demographic_Enum;
  id: Scalars['bigint'];
  project_id: Scalars['bigint'];
  source: Wui_SocialMediaSource_Enum;
  value: Scalars['String'];
};

/** aggregated selection of "ProjectDemographic" */
export type ProjectDemographic_Aggregate = {
  __typename?: 'ProjectDemographic_aggregate';
  aggregate?: Maybe<ProjectDemographic_Aggregate_Fields>;
  nodes: Array<ProjectDemographic>;
};

/** aggregate fields of "ProjectDemographic" */
export type ProjectDemographic_Aggregate_Fields = {
  __typename?: 'ProjectDemographic_aggregate_fields';
  avg?: Maybe<ProjectDemographic_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ProjectDemographic_Max_Fields>;
  min?: Maybe<ProjectDemographic_Min_Fields>;
  stddev?: Maybe<ProjectDemographic_Stddev_Fields>;
  stddev_pop?: Maybe<ProjectDemographic_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ProjectDemographic_Stddev_Samp_Fields>;
  sum?: Maybe<ProjectDemographic_Sum_Fields>;
  var_pop?: Maybe<ProjectDemographic_Var_Pop_Fields>;
  var_samp?: Maybe<ProjectDemographic_Var_Samp_Fields>;
  variance?: Maybe<ProjectDemographic_Variance_Fields>;
};


/** aggregate fields of "ProjectDemographic" */
export type ProjectDemographic_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ProjectDemographic_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ProjectDemographic" */
export type ProjectDemographic_Aggregate_Order_By = {
  avg?: Maybe<ProjectDemographic_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ProjectDemographic_Max_Order_By>;
  min?: Maybe<ProjectDemographic_Min_Order_By>;
  stddev?: Maybe<ProjectDemographic_Stddev_Order_By>;
  stddev_pop?: Maybe<ProjectDemographic_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ProjectDemographic_Stddev_Samp_Order_By>;
  sum?: Maybe<ProjectDemographic_Sum_Order_By>;
  var_pop?: Maybe<ProjectDemographic_Var_Pop_Order_By>;
  var_samp?: Maybe<ProjectDemographic_Var_Samp_Order_By>;
  variance?: Maybe<ProjectDemographic_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ProjectDemographic" */
export type ProjectDemographic_Arr_Rel_Insert_Input = {
  data: Array<ProjectDemographic_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<ProjectDemographic_On_Conflict>;
};

/** aggregate avg on columns */
export type ProjectDemographic_Avg_Fields = {
  __typename?: 'ProjectDemographic_avg_fields';
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ProjectDemographic" */
export type ProjectDemographic_Avg_Order_By = {
  id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ProjectDemographic". All fields are combined with a logical 'AND'. */
export type ProjectDemographic_Bool_Exp = {
  Demographic?: Maybe<Demographic_Bool_Exp>;
  WUI_SellerProject?: Maybe<Wui_SellerProject_Bool_Exp>;
  WUI_SocialMediaSource?: Maybe<Wui_SocialMediaSource_Bool_Exp>;
  _and?: Maybe<Array<ProjectDemographic_Bool_Exp>>;
  _not?: Maybe<ProjectDemographic_Bool_Exp>;
  _or?: Maybe<Array<ProjectDemographic_Bool_Exp>>;
  demographic?: Maybe<Demographic_Enum_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  project_id?: Maybe<Bigint_Comparison_Exp>;
  source?: Maybe<Wui_SocialMediaSource_Enum_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ProjectDemographic" */
export enum ProjectDemographic_Constraint {
  /** unique or primary key constraint */
  ProjectDemographicPkey = 'ProjectDemographic_pkey',
  /** unique or primary key constraint */
  ProjectDemographicProjectIdDemographicSourceKey = 'ProjectDemographic_project_id_demographic_source_key'
}

/** input type for incrementing numeric columns in table "ProjectDemographic" */
export type ProjectDemographic_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  project_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "ProjectDemographic" */
export type ProjectDemographic_Insert_Input = {
  Demographic?: Maybe<Demographic_Obj_Rel_Insert_Input>;
  WUI_SellerProject?: Maybe<Wui_SellerProject_Obj_Rel_Insert_Input>;
  WUI_SocialMediaSource?: Maybe<Wui_SocialMediaSource_Obj_Rel_Insert_Input>;
  demographic?: Maybe<Demographic_Enum>;
  id?: Maybe<Scalars['bigint']>;
  project_id?: Maybe<Scalars['bigint']>;
  source?: Maybe<Wui_SocialMediaSource_Enum>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ProjectDemographic_Max_Fields = {
  __typename?: 'ProjectDemographic_max_fields';
  id?: Maybe<Scalars['bigint']>;
  project_id?: Maybe<Scalars['bigint']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "ProjectDemographic" */
export type ProjectDemographic_Max_Order_By = {
  id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ProjectDemographic_Min_Fields = {
  __typename?: 'ProjectDemographic_min_fields';
  id?: Maybe<Scalars['bigint']>;
  project_id?: Maybe<Scalars['bigint']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "ProjectDemographic" */
export type ProjectDemographic_Min_Order_By = {
  id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** response of any mutation on the table "ProjectDemographic" */
export type ProjectDemographic_Mutation_Response = {
  __typename?: 'ProjectDemographic_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProjectDemographic>;
};

/** on conflict condition type for table "ProjectDemographic" */
export type ProjectDemographic_On_Conflict = {
  constraint: ProjectDemographic_Constraint;
  update_columns?: Array<ProjectDemographic_Update_Column>;
  where?: Maybe<ProjectDemographic_Bool_Exp>;
};

/** Ordering options when selecting data from "ProjectDemographic". */
export type ProjectDemographic_Order_By = {
  Demographic?: Maybe<Demographic_Order_By>;
  WUI_SellerProject?: Maybe<Wui_SellerProject_Order_By>;
  WUI_SocialMediaSource?: Maybe<Wui_SocialMediaSource_Order_By>;
  demographic?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ProjectDemographic */
export type ProjectDemographic_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "ProjectDemographic" */
export enum ProjectDemographic_Select_Column {
  /** column name */
  Demographic = 'demographic',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Source = 'source',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ProjectDemographic" */
export type ProjectDemographic_Set_Input = {
  demographic?: Maybe<Demographic_Enum>;
  id?: Maybe<Scalars['bigint']>;
  project_id?: Maybe<Scalars['bigint']>;
  source?: Maybe<Wui_SocialMediaSource_Enum>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ProjectDemographic_Stddev_Fields = {
  __typename?: 'ProjectDemographic_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ProjectDemographic" */
export type ProjectDemographic_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ProjectDemographic_Stddev_Pop_Fields = {
  __typename?: 'ProjectDemographic_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ProjectDemographic" */
export type ProjectDemographic_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ProjectDemographic_Stddev_Samp_Fields = {
  __typename?: 'ProjectDemographic_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ProjectDemographic" */
export type ProjectDemographic_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type ProjectDemographic_Sum_Fields = {
  __typename?: 'ProjectDemographic_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  project_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "ProjectDemographic" */
export type ProjectDemographic_Sum_Order_By = {
  id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
};

/** update columns of table "ProjectDemographic" */
export enum ProjectDemographic_Update_Column {
  /** column name */
  Demographic = 'demographic',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Source = 'source',
  /** column name */
  Value = 'value'
}

/** aggregate var_pop on columns */
export type ProjectDemographic_Var_Pop_Fields = {
  __typename?: 'ProjectDemographic_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ProjectDemographic" */
export type ProjectDemographic_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ProjectDemographic_Var_Samp_Fields = {
  __typename?: 'ProjectDemographic_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ProjectDemographic" */
export type ProjectDemographic_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ProjectDemographic_Variance_Fields = {
  __typename?: 'ProjectDemographic_variance_fields';
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ProjectDemographic" */
export type ProjectDemographic_Variance_Order_By = {
  id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
};

export type ProjectFileOutput = {
  __typename?: 'ProjectFileOutput';
  fileId: Scalars['bigint'];
  imageUrl: Scalars['String'];
  projectId: Scalars['bigint'];
};

export type RemoveBankAccountOutput = {
  __typename?: 'RemoveBankAccountOutput';
  id: Scalars['String'];
};

export type RemovePaymentMethodCardOutput = {
  __typename?: 'RemovePaymentMethodCardOutput';
  id: Scalars['bigint'];
};

export type SearchInfluencersFilters = {
  tbd?: Maybe<Scalars['Boolean']>;
};

export type SearchInfluencersOutput = {
  __typename?: 'SearchInfluencersOutput';
  first_name: Scalars['String'];
  from_price: Scalars['Int'];
  highlights: Array<Maybe<Scalars['jsonb']>>;
  is_seller: Scalars['Boolean'];
  last_name: Scalars['String'];
  picture?: Maybe<Scalars['String']>;
  review_avg: Scalars['Float'];
  social_media: Array<Maybe<Scalars['String']>>;
  user_id: Scalars['bigint'];
  username: Scalars['String'];
};

export type SessionEventOutput = {
  __typename?: 'SessionEventOutput';
  Success?: Maybe<Scalars['Boolean']>;
};

export type SignupOutput = {
  __typename?: 'SignupOutput';
  accepted_terms: Scalars['Boolean'];
  country?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  hasCompletedLegalInformation: Scalars['Boolean'];
  id: Scalars['bigint'];
  isSeller: Scalars['Boolean'];
  lastName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  username: Scalars['String'];
};

/** columns and relationships of "SocialMediaDemographic" */
export type SocialMediaDemographic = {
  __typename?: 'SocialMediaDemographic';
  /** An object relationship */
  Demographic: Demographic;
  /** An object relationship */
  WUI_SocialMediaSource: Wui_SocialMediaSource;
  /** An object relationship */
  WUI_User: Wui_User;
  demographic: Demographic_Enum;
  id: Scalars['bigint'];
  max?: Maybe<Scalars['numeric']>;
  min?: Maybe<Scalars['numeric']>;
  source: Wui_SocialMediaSource_Enum;
  user_id: Scalars['bigint'];
  value: Scalars['String'];
};

/** aggregated selection of "SocialMediaDemographic" */
export type SocialMediaDemographic_Aggregate = {
  __typename?: 'SocialMediaDemographic_aggregate';
  aggregate?: Maybe<SocialMediaDemographic_Aggregate_Fields>;
  nodes: Array<SocialMediaDemographic>;
};

/** aggregate fields of "SocialMediaDemographic" */
export type SocialMediaDemographic_Aggregate_Fields = {
  __typename?: 'SocialMediaDemographic_aggregate_fields';
  avg?: Maybe<SocialMediaDemographic_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<SocialMediaDemographic_Max_Fields>;
  min?: Maybe<SocialMediaDemographic_Min_Fields>;
  stddev?: Maybe<SocialMediaDemographic_Stddev_Fields>;
  stddev_pop?: Maybe<SocialMediaDemographic_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SocialMediaDemographic_Stddev_Samp_Fields>;
  sum?: Maybe<SocialMediaDemographic_Sum_Fields>;
  var_pop?: Maybe<SocialMediaDemographic_Var_Pop_Fields>;
  var_samp?: Maybe<SocialMediaDemographic_Var_Samp_Fields>;
  variance?: Maybe<SocialMediaDemographic_Variance_Fields>;
};


/** aggregate fields of "SocialMediaDemographic" */
export type SocialMediaDemographic_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<SocialMediaDemographic_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "SocialMediaDemographic" */
export type SocialMediaDemographic_Aggregate_Order_By = {
  avg?: Maybe<SocialMediaDemographic_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<SocialMediaDemographic_Max_Order_By>;
  min?: Maybe<SocialMediaDemographic_Min_Order_By>;
  stddev?: Maybe<SocialMediaDemographic_Stddev_Order_By>;
  stddev_pop?: Maybe<SocialMediaDemographic_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<SocialMediaDemographic_Stddev_Samp_Order_By>;
  sum?: Maybe<SocialMediaDemographic_Sum_Order_By>;
  var_pop?: Maybe<SocialMediaDemographic_Var_Pop_Order_By>;
  var_samp?: Maybe<SocialMediaDemographic_Var_Samp_Order_By>;
  variance?: Maybe<SocialMediaDemographic_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "SocialMediaDemographic" */
export type SocialMediaDemographic_Arr_Rel_Insert_Input = {
  data: Array<SocialMediaDemographic_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<SocialMediaDemographic_On_Conflict>;
};

/** aggregate avg on columns */
export type SocialMediaDemographic_Avg_Fields = {
  __typename?: 'SocialMediaDemographic_avg_fields';
  id?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "SocialMediaDemographic" */
export type SocialMediaDemographic_Avg_Order_By = {
  id?: Maybe<Order_By>;
  max?: Maybe<Order_By>;
  min?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "SocialMediaDemographic". All fields are combined with a logical 'AND'. */
export type SocialMediaDemographic_Bool_Exp = {
  Demographic?: Maybe<Demographic_Bool_Exp>;
  WUI_SocialMediaSource?: Maybe<Wui_SocialMediaSource_Bool_Exp>;
  WUI_User?: Maybe<Wui_User_Bool_Exp>;
  _and?: Maybe<Array<SocialMediaDemographic_Bool_Exp>>;
  _not?: Maybe<SocialMediaDemographic_Bool_Exp>;
  _or?: Maybe<Array<SocialMediaDemographic_Bool_Exp>>;
  demographic?: Maybe<Demographic_Enum_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  max?: Maybe<Numeric_Comparison_Exp>;
  min?: Maybe<Numeric_Comparison_Exp>;
  source?: Maybe<Wui_SocialMediaSource_Enum_Comparison_Exp>;
  user_id?: Maybe<Bigint_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "SocialMediaDemographic" */
export enum SocialMediaDemographic_Constraint {
  /** unique or primary key constraint */
  SellerSocialMediaAudiencePkey = 'SellerSocialMediaAudience_pkey',
  /** unique or primary key constraint */
  SellerSocialMediaDataUserIdSourceSocialMediaItemKey = 'SellerSocialMediaData_user_id_source_social_media_item_key'
}

/** input type for incrementing numeric columns in table "SocialMediaDemographic" */
export type SocialMediaDemographic_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  max?: Maybe<Scalars['numeric']>;
  min?: Maybe<Scalars['numeric']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "SocialMediaDemographic" */
export type SocialMediaDemographic_Insert_Input = {
  Demographic?: Maybe<Demographic_Obj_Rel_Insert_Input>;
  WUI_SocialMediaSource?: Maybe<Wui_SocialMediaSource_Obj_Rel_Insert_Input>;
  WUI_User?: Maybe<Wui_User_Obj_Rel_Insert_Input>;
  demographic?: Maybe<Demographic_Enum>;
  id?: Maybe<Scalars['bigint']>;
  max?: Maybe<Scalars['numeric']>;
  min?: Maybe<Scalars['numeric']>;
  source?: Maybe<Wui_SocialMediaSource_Enum>;
  user_id?: Maybe<Scalars['bigint']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type SocialMediaDemographic_Max_Fields = {
  __typename?: 'SocialMediaDemographic_max_fields';
  id?: Maybe<Scalars['bigint']>;
  max?: Maybe<Scalars['numeric']>;
  min?: Maybe<Scalars['numeric']>;
  user_id?: Maybe<Scalars['bigint']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "SocialMediaDemographic" */
export type SocialMediaDemographic_Max_Order_By = {
  id?: Maybe<Order_By>;
  max?: Maybe<Order_By>;
  min?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type SocialMediaDemographic_Min_Fields = {
  __typename?: 'SocialMediaDemographic_min_fields';
  id?: Maybe<Scalars['bigint']>;
  max?: Maybe<Scalars['numeric']>;
  min?: Maybe<Scalars['numeric']>;
  user_id?: Maybe<Scalars['bigint']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "SocialMediaDemographic" */
export type SocialMediaDemographic_Min_Order_By = {
  id?: Maybe<Order_By>;
  max?: Maybe<Order_By>;
  min?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** response of any mutation on the table "SocialMediaDemographic" */
export type SocialMediaDemographic_Mutation_Response = {
  __typename?: 'SocialMediaDemographic_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SocialMediaDemographic>;
};

/** on conflict condition type for table "SocialMediaDemographic" */
export type SocialMediaDemographic_On_Conflict = {
  constraint: SocialMediaDemographic_Constraint;
  update_columns?: Array<SocialMediaDemographic_Update_Column>;
  where?: Maybe<SocialMediaDemographic_Bool_Exp>;
};

/** Ordering options when selecting data from "SocialMediaDemographic". */
export type SocialMediaDemographic_Order_By = {
  Demographic?: Maybe<Demographic_Order_By>;
  WUI_SocialMediaSource?: Maybe<Wui_SocialMediaSource_Order_By>;
  WUI_User?: Maybe<Wui_User_Order_By>;
  demographic?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  max?: Maybe<Order_By>;
  min?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: SocialMediaDemographic */
export type SocialMediaDemographic_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "SocialMediaDemographic" */
export enum SocialMediaDemographic_Select_Column {
  /** column name */
  Demographic = 'demographic',
  /** column name */
  Id = 'id',
  /** column name */
  Max = 'max',
  /** column name */
  Min = 'min',
  /** column name */
  Source = 'source',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "SocialMediaDemographic" */
export type SocialMediaDemographic_Set_Input = {
  demographic?: Maybe<Demographic_Enum>;
  id?: Maybe<Scalars['bigint']>;
  max?: Maybe<Scalars['numeric']>;
  min?: Maybe<Scalars['numeric']>;
  source?: Maybe<Wui_SocialMediaSource_Enum>;
  user_id?: Maybe<Scalars['bigint']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type SocialMediaDemographic_Stddev_Fields = {
  __typename?: 'SocialMediaDemographic_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "SocialMediaDemographic" */
export type SocialMediaDemographic_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  max?: Maybe<Order_By>;
  min?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type SocialMediaDemographic_Stddev_Pop_Fields = {
  __typename?: 'SocialMediaDemographic_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "SocialMediaDemographic" */
export type SocialMediaDemographic_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  max?: Maybe<Order_By>;
  min?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type SocialMediaDemographic_Stddev_Samp_Fields = {
  __typename?: 'SocialMediaDemographic_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "SocialMediaDemographic" */
export type SocialMediaDemographic_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  max?: Maybe<Order_By>;
  min?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type SocialMediaDemographic_Sum_Fields = {
  __typename?: 'SocialMediaDemographic_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  max?: Maybe<Scalars['numeric']>;
  min?: Maybe<Scalars['numeric']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "SocialMediaDemographic" */
export type SocialMediaDemographic_Sum_Order_By = {
  id?: Maybe<Order_By>;
  max?: Maybe<Order_By>;
  min?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** update columns of table "SocialMediaDemographic" */
export enum SocialMediaDemographic_Update_Column {
  /** column name */
  Demographic = 'demographic',
  /** column name */
  Id = 'id',
  /** column name */
  Max = 'max',
  /** column name */
  Min = 'min',
  /** column name */
  Source = 'source',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

/** aggregate var_pop on columns */
export type SocialMediaDemographic_Var_Pop_Fields = {
  __typename?: 'SocialMediaDemographic_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "SocialMediaDemographic" */
export type SocialMediaDemographic_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  max?: Maybe<Order_By>;
  min?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type SocialMediaDemographic_Var_Samp_Fields = {
  __typename?: 'SocialMediaDemographic_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "SocialMediaDemographic" */
export type SocialMediaDemographic_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  max?: Maybe<Order_By>;
  min?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type SocialMediaDemographic_Variance_Fields = {
  __typename?: 'SocialMediaDemographic_variance_fields';
  id?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "SocialMediaDemographic" */
export type SocialMediaDemographic_Variance_Order_By = {
  id?: Maybe<Order_By>;
  max?: Maybe<Order_By>;
  min?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** columns and relationships of "SocialMediaLink" */
export type SocialMediaLink = {
  __typename?: 'SocialMediaLink';
  /** An object relationship */
  WUI_User: Wui_User;
  enabled: Scalars['Boolean'];
  id: Scalars['bigint'];
  primary: Scalars['Boolean'];
  title: Scalars['String'];
  url: Scalars['String'];
  user_id: Scalars['bigint'];
};

/** aggregated selection of "SocialMediaLink" */
export type SocialMediaLink_Aggregate = {
  __typename?: 'SocialMediaLink_aggregate';
  aggregate?: Maybe<SocialMediaLink_Aggregate_Fields>;
  nodes: Array<SocialMediaLink>;
};

/** aggregate fields of "SocialMediaLink" */
export type SocialMediaLink_Aggregate_Fields = {
  __typename?: 'SocialMediaLink_aggregate_fields';
  avg?: Maybe<SocialMediaLink_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<SocialMediaLink_Max_Fields>;
  min?: Maybe<SocialMediaLink_Min_Fields>;
  stddev?: Maybe<SocialMediaLink_Stddev_Fields>;
  stddev_pop?: Maybe<SocialMediaLink_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SocialMediaLink_Stddev_Samp_Fields>;
  sum?: Maybe<SocialMediaLink_Sum_Fields>;
  var_pop?: Maybe<SocialMediaLink_Var_Pop_Fields>;
  var_samp?: Maybe<SocialMediaLink_Var_Samp_Fields>;
  variance?: Maybe<SocialMediaLink_Variance_Fields>;
};


/** aggregate fields of "SocialMediaLink" */
export type SocialMediaLink_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<SocialMediaLink_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type SocialMediaLink_Avg_Fields = {
  __typename?: 'SocialMediaLink_avg_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "SocialMediaLink". All fields are combined with a logical 'AND'. */
export type SocialMediaLink_Bool_Exp = {
  WUI_User?: Maybe<Wui_User_Bool_Exp>;
  _and?: Maybe<Array<SocialMediaLink_Bool_Exp>>;
  _not?: Maybe<SocialMediaLink_Bool_Exp>;
  _or?: Maybe<Array<SocialMediaLink_Bool_Exp>>;
  enabled?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  primary?: Maybe<Boolean_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  url?: Maybe<String_Comparison_Exp>;
  user_id?: Maybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "SocialMediaLink" */
export enum SocialMediaLink_Constraint {
  /** unique or primary key constraint */
  SocialMediaLinkPkey = 'SocialMediaLink_pkey'
}

/** input type for incrementing numeric columns in table "SocialMediaLink" */
export type SocialMediaLink_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "SocialMediaLink" */
export type SocialMediaLink_Insert_Input = {
  WUI_User?: Maybe<Wui_User_Obj_Rel_Insert_Input>;
  enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  primary?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type SocialMediaLink_Max_Fields = {
  __typename?: 'SocialMediaLink_max_fields';
  id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type SocialMediaLink_Min_Fields = {
  __typename?: 'SocialMediaLink_min_fields';
  id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "SocialMediaLink" */
export type SocialMediaLink_Mutation_Response = {
  __typename?: 'SocialMediaLink_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SocialMediaLink>;
};

/** input type for inserting object relation for remote table "SocialMediaLink" */
export type SocialMediaLink_Obj_Rel_Insert_Input = {
  data: SocialMediaLink_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<SocialMediaLink_On_Conflict>;
};

/** on conflict condition type for table "SocialMediaLink" */
export type SocialMediaLink_On_Conflict = {
  constraint: SocialMediaLink_Constraint;
  update_columns?: Array<SocialMediaLink_Update_Column>;
  where?: Maybe<SocialMediaLink_Bool_Exp>;
};

/** Ordering options when selecting data from "SocialMediaLink". */
export type SocialMediaLink_Order_By = {
  WUI_User?: Maybe<Wui_User_Order_By>;
  enabled?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  primary?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: SocialMediaLink */
export type SocialMediaLink_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "SocialMediaLink" */
export enum SocialMediaLink_Select_Column {
  /** column name */
  Enabled = 'enabled',
  /** column name */
  Id = 'id',
  /** column name */
  Primary = 'primary',
  /** column name */
  Title = 'title',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "SocialMediaLink" */
export type SocialMediaLink_Set_Input = {
  enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  primary?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type SocialMediaLink_Stddev_Fields = {
  __typename?: 'SocialMediaLink_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type SocialMediaLink_Stddev_Pop_Fields = {
  __typename?: 'SocialMediaLink_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type SocialMediaLink_Stddev_Samp_Fields = {
  __typename?: 'SocialMediaLink_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type SocialMediaLink_Sum_Fields = {
  __typename?: 'SocialMediaLink_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "SocialMediaLink" */
export enum SocialMediaLink_Update_Column {
  /** column name */
  Enabled = 'enabled',
  /** column name */
  Id = 'id',
  /** column name */
  Primary = 'primary',
  /** column name */
  Title = 'title',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type SocialMediaLink_Var_Pop_Fields = {
  __typename?: 'SocialMediaLink_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type SocialMediaLink_Var_Samp_Fields = {
  __typename?: 'SocialMediaLink_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type SocialMediaLink_Variance_Fields = {
  __typename?: 'SocialMediaLink_variance_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};

export type UpdateOrderOutput = {
  __typename?: 'UpdateOrderOutput';
  success?: Maybe<Scalars['Boolean']>;
};

/** columns and relationships of "WUI_AuthProvider" */
export type Wui_AuthProvider = {
  __typename?: 'WUI_AuthProvider';
  /** An object relationship */
  WUI_User: Wui_User;
  created_at: Scalars['timestamptz'];
  external_id: Scalars['String'];
  id: Scalars['bigint'];
  refresh_token?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  user_id: Scalars['bigint'];
  username?: Maybe<Scalars['String']>;
};

/** aggregated selection of "WUI_AuthProvider" */
export type Wui_AuthProvider_Aggregate = {
  __typename?: 'WUI_AuthProvider_aggregate';
  aggregate?: Maybe<Wui_AuthProvider_Aggregate_Fields>;
  nodes: Array<Wui_AuthProvider>;
};

/** aggregate fields of "WUI_AuthProvider" */
export type Wui_AuthProvider_Aggregate_Fields = {
  __typename?: 'WUI_AuthProvider_aggregate_fields';
  avg?: Maybe<Wui_AuthProvider_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_AuthProvider_Max_Fields>;
  min?: Maybe<Wui_AuthProvider_Min_Fields>;
  stddev?: Maybe<Wui_AuthProvider_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_AuthProvider_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_AuthProvider_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_AuthProvider_Sum_Fields>;
  var_pop?: Maybe<Wui_AuthProvider_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_AuthProvider_Var_Samp_Fields>;
  variance?: Maybe<Wui_AuthProvider_Variance_Fields>;
};


/** aggregate fields of "WUI_AuthProvider" */
export type Wui_AuthProvider_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wui_AuthProvider_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Wui_AuthProvider_Avg_Fields = {
  __typename?: 'WUI_AuthProvider_avg_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "WUI_AuthProvider". All fields are combined with a logical 'AND'. */
export type Wui_AuthProvider_Bool_Exp = {
  WUI_User?: Maybe<Wui_User_Bool_Exp>;
  _and?: Maybe<Array<Wui_AuthProvider_Bool_Exp>>;
  _not?: Maybe<Wui_AuthProvider_Bool_Exp>;
  _or?: Maybe<Array<Wui_AuthProvider_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  external_id?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  refresh_token?: Maybe<String_Comparison_Exp>;
  token?: Maybe<String_Comparison_Exp>;
  user_id?: Maybe<Bigint_Comparison_Exp>;
  username?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_AuthProvider" */
export enum Wui_AuthProvider_Constraint {
  /** unique or primary key constraint */
  WuiAuthProviderExternalIdKey = 'WUI_AuthProvider_external_id_key',
  /** unique or primary key constraint */
  WuiAuthProviderUserIdKey = 'WUI_AuthProvider_user_id_key',
  /** unique or primary key constraint */
  WuiAuthSocialMediaPkey = 'WUI_AuthSocialMedia_pkey'
}

/** input type for incrementing numeric columns in table "WUI_AuthProvider" */
export type Wui_AuthProvider_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_AuthProvider" */
export type Wui_AuthProvider_Insert_Input = {
  WUI_User?: Maybe<Wui_User_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  refresh_token?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
  username?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Wui_AuthProvider_Max_Fields = {
  __typename?: 'WUI_AuthProvider_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  refresh_token?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
  username?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Wui_AuthProvider_Min_Fields = {
  __typename?: 'WUI_AuthProvider_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  refresh_token?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
  username?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "WUI_AuthProvider" */
export type Wui_AuthProvider_Mutation_Response = {
  __typename?: 'WUI_AuthProvider_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_AuthProvider>;
};

/** input type for inserting object relation for remote table "WUI_AuthProvider" */
export type Wui_AuthProvider_Obj_Rel_Insert_Input = {
  data: Wui_AuthProvider_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Wui_AuthProvider_On_Conflict>;
};

/** on conflict condition type for table "WUI_AuthProvider" */
export type Wui_AuthProvider_On_Conflict = {
  constraint: Wui_AuthProvider_Constraint;
  update_columns?: Array<Wui_AuthProvider_Update_Column>;
  where?: Maybe<Wui_AuthProvider_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_AuthProvider". */
export type Wui_AuthProvider_Order_By = {
  WUI_User?: Maybe<Wui_User_Order_By>;
  created_at?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  refresh_token?: Maybe<Order_By>;
  token?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  username?: Maybe<Order_By>;
};

/** primary key columns input for table: WUI_AuthProvider */
export type Wui_AuthProvider_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "WUI_AuthProvider" */
export enum Wui_AuthProvider_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  RefreshToken = 'refresh_token',
  /** column name */
  Token = 'token',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Username = 'username'
}

/** input type for updating data in table "WUI_AuthProvider" */
export type Wui_AuthProvider_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  refresh_token?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
  username?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Wui_AuthProvider_Stddev_Fields = {
  __typename?: 'WUI_AuthProvider_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Wui_AuthProvider_Stddev_Pop_Fields = {
  __typename?: 'WUI_AuthProvider_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Wui_AuthProvider_Stddev_Samp_Fields = {
  __typename?: 'WUI_AuthProvider_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Wui_AuthProvider_Sum_Fields = {
  __typename?: 'WUI_AuthProvider_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "WUI_AuthProvider" */
export enum Wui_AuthProvider_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  RefreshToken = 'refresh_token',
  /** column name */
  Token = 'token',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Username = 'username'
}

/** aggregate var_pop on columns */
export type Wui_AuthProvider_Var_Pop_Fields = {
  __typename?: 'WUI_AuthProvider_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Wui_AuthProvider_Var_Samp_Fields = {
  __typename?: 'WUI_AuthProvider_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Wui_AuthProvider_Variance_Fields = {
  __typename?: 'WUI_AuthProvider_variance_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "WUI_ChatRoom" */
export type Wui_ChatRoom = {
  __typename?: 'WUI_ChatRoom';
  /** An array relationship */
  WUI_ChatRoomMessages: Array<Wui_ChatRoomMessage>;
  /** An aggregate relationship */
  WUI_ChatRoomMessages_aggregate: Wui_ChatRoomMessage_Aggregate;
  /** An array relationship */
  WUI_ChatRoomOrders: Array<Wui_ChatRoomOrder>;
  /** An aggregate relationship */
  WUI_ChatRoomOrders_aggregate: Wui_ChatRoomOrder_Aggregate;
  /** An object relationship */
  WUI_ChatRoomType: Wui_ChatRoomType;
  /** An array relationship */
  WUI_ChatRoomUsers: Array<Wui_ChatRoomUser>;
  /** An aggregate relationship */
  WUI_ChatRoomUsers_aggregate: Wui_ChatRoomUser_Aggregate;
  id: Scalars['bigint'];
  type: Wui_ChatRoomType_Enum;
};


/** columns and relationships of "WUI_ChatRoom" */
export type Wui_ChatRoomWui_ChatRoomMessagesArgs = {
  distinct_on?: Maybe<Array<Wui_ChatRoomMessage_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_ChatRoomMessage_Order_By>>;
  where?: Maybe<Wui_ChatRoomMessage_Bool_Exp>;
};


/** columns and relationships of "WUI_ChatRoom" */
export type Wui_ChatRoomWui_ChatRoomMessages_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_ChatRoomMessage_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_ChatRoomMessage_Order_By>>;
  where?: Maybe<Wui_ChatRoomMessage_Bool_Exp>;
};


/** columns and relationships of "WUI_ChatRoom" */
export type Wui_ChatRoomWui_ChatRoomOrdersArgs = {
  distinct_on?: Maybe<Array<Wui_ChatRoomOrder_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_ChatRoomOrder_Order_By>>;
  where?: Maybe<Wui_ChatRoomOrder_Bool_Exp>;
};


/** columns and relationships of "WUI_ChatRoom" */
export type Wui_ChatRoomWui_ChatRoomOrders_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_ChatRoomOrder_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_ChatRoomOrder_Order_By>>;
  where?: Maybe<Wui_ChatRoomOrder_Bool_Exp>;
};


/** columns and relationships of "WUI_ChatRoom" */
export type Wui_ChatRoomWui_ChatRoomUsersArgs = {
  distinct_on?: Maybe<Array<Wui_ChatRoomUser_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_ChatRoomUser_Order_By>>;
  where?: Maybe<Wui_ChatRoomUser_Bool_Exp>;
};


/** columns and relationships of "WUI_ChatRoom" */
export type Wui_ChatRoomWui_ChatRoomUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_ChatRoomUser_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_ChatRoomUser_Order_By>>;
  where?: Maybe<Wui_ChatRoomUser_Bool_Exp>;
};

/** columns and relationships of "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessage = {
  __typename?: 'WUI_ChatRoomMessage';
  /** An object relationship */
  WUI_ChatRoom: Wui_ChatRoom;
  /** An object relationship */
  WUI_User: Wui_User;
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['jsonb']>;
  id: Scalars['bigint'];
  message: Scalars['String'];
  room_id: Scalars['bigint'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id: Scalars['bigint'];
};


/** columns and relationships of "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessageDataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessage_Aggregate = {
  __typename?: 'WUI_ChatRoomMessage_aggregate';
  aggregate?: Maybe<Wui_ChatRoomMessage_Aggregate_Fields>;
  nodes: Array<Wui_ChatRoomMessage>;
};

/** aggregate fields of "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessage_Aggregate_Fields = {
  __typename?: 'WUI_ChatRoomMessage_aggregate_fields';
  avg?: Maybe<Wui_ChatRoomMessage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_ChatRoomMessage_Max_Fields>;
  min?: Maybe<Wui_ChatRoomMessage_Min_Fields>;
  stddev?: Maybe<Wui_ChatRoomMessage_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_ChatRoomMessage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_ChatRoomMessage_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_ChatRoomMessage_Sum_Fields>;
  var_pop?: Maybe<Wui_ChatRoomMessage_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_ChatRoomMessage_Var_Samp_Fields>;
  variance?: Maybe<Wui_ChatRoomMessage_Variance_Fields>;
};


/** aggregate fields of "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessage_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wui_ChatRoomMessage_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessage_Aggregate_Order_By = {
  avg?: Maybe<Wui_ChatRoomMessage_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Wui_ChatRoomMessage_Max_Order_By>;
  min?: Maybe<Wui_ChatRoomMessage_Min_Order_By>;
  stddev?: Maybe<Wui_ChatRoomMessage_Stddev_Order_By>;
  stddev_pop?: Maybe<Wui_ChatRoomMessage_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Wui_ChatRoomMessage_Stddev_Samp_Order_By>;
  sum?: Maybe<Wui_ChatRoomMessage_Sum_Order_By>;
  var_pop?: Maybe<Wui_ChatRoomMessage_Var_Pop_Order_By>;
  var_samp?: Maybe<Wui_ChatRoomMessage_Var_Samp_Order_By>;
  variance?: Maybe<Wui_ChatRoomMessage_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Wui_ChatRoomMessage_Append_Input = {
  data?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessage_Arr_Rel_Insert_Input = {
  data: Array<Wui_ChatRoomMessage_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Wui_ChatRoomMessage_On_Conflict>;
};

/** aggregate avg on columns */
export type Wui_ChatRoomMessage_Avg_Fields = {
  __typename?: 'WUI_ChatRoomMessage_avg_fields';
  id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessage_Avg_Order_By = {
  id?: Maybe<Order_By>;
  room_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "WUI_ChatRoomMessage". All fields are combined with a logical 'AND'. */
export type Wui_ChatRoomMessage_Bool_Exp = {
  WUI_ChatRoom?: Maybe<Wui_ChatRoom_Bool_Exp>;
  WUI_User?: Maybe<Wui_User_Bool_Exp>;
  _and?: Maybe<Array<Wui_ChatRoomMessage_Bool_Exp>>;
  _not?: Maybe<Wui_ChatRoomMessage_Bool_Exp>;
  _or?: Maybe<Array<Wui_ChatRoomMessage_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  data?: Maybe<Jsonb_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  message?: Maybe<String_Comparison_Exp>;
  room_id?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user_id?: Maybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_ChatRoomMessage" */
export enum Wui_ChatRoomMessage_Constraint {
  /** unique or primary key constraint */
  WuiChatRoomMessagePkey = 'WUI_ChatRoomMessage_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Wui_ChatRoomMessage_Delete_At_Path_Input = {
  data?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Wui_ChatRoomMessage_Delete_Elem_Input = {
  data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Wui_ChatRoomMessage_Delete_Key_Input = {
  data?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessage_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  room_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessage_Insert_Input = {
  WUI_ChatRoom?: Maybe<Wui_ChatRoom_Obj_Rel_Insert_Input>;
  WUI_User?: Maybe<Wui_User_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['String']>;
  room_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Wui_ChatRoomMessage_Max_Fields = {
  __typename?: 'WUI_ChatRoomMessage_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['String']>;
  room_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessage_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  room_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Wui_ChatRoomMessage_Min_Fields = {
  __typename?: 'WUI_ChatRoomMessage_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['String']>;
  room_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessage_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  room_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessage_Mutation_Response = {
  __typename?: 'WUI_ChatRoomMessage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_ChatRoomMessage>;
};

/** on conflict condition type for table "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessage_On_Conflict = {
  constraint: Wui_ChatRoomMessage_Constraint;
  update_columns?: Array<Wui_ChatRoomMessage_Update_Column>;
  where?: Maybe<Wui_ChatRoomMessage_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_ChatRoomMessage". */
export type Wui_ChatRoomMessage_Order_By = {
  WUI_ChatRoom?: Maybe<Wui_ChatRoom_Order_By>;
  WUI_User?: Maybe<Wui_User_Order_By>;
  created_at?: Maybe<Order_By>;
  data?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  room_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: WUI_ChatRoomMessage */
export type Wui_ChatRoomMessage_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Wui_ChatRoomMessage_Prepend_Input = {
  data?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "WUI_ChatRoomMessage" */
export enum Wui_ChatRoomMessage_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  RoomId = 'room_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessage_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['String']>;
  room_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Wui_ChatRoomMessage_Stddev_Fields = {
  __typename?: 'WUI_ChatRoomMessage_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessage_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  room_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Wui_ChatRoomMessage_Stddev_Pop_Fields = {
  __typename?: 'WUI_ChatRoomMessage_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessage_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  room_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Wui_ChatRoomMessage_Stddev_Samp_Fields = {
  __typename?: 'WUI_ChatRoomMessage_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessage_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  room_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Wui_ChatRoomMessage_Sum_Fields = {
  __typename?: 'WUI_ChatRoomMessage_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  room_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessage_Sum_Order_By = {
  id?: Maybe<Order_By>;
  room_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** update columns of table "WUI_ChatRoomMessage" */
export enum Wui_ChatRoomMessage_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  RoomId = 'room_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Wui_ChatRoomMessage_Var_Pop_Fields = {
  __typename?: 'WUI_ChatRoomMessage_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessage_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  room_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Wui_ChatRoomMessage_Var_Samp_Fields = {
  __typename?: 'WUI_ChatRoomMessage_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessage_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  room_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Wui_ChatRoomMessage_Variance_Fields = {
  __typename?: 'WUI_ChatRoomMessage_variance_fields';
  id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "WUI_ChatRoomMessage" */
export type Wui_ChatRoomMessage_Variance_Order_By = {
  id?: Maybe<Order_By>;
  room_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** columns and relationships of "WUI_ChatRoomOrder" */
export type Wui_ChatRoomOrder = {
  __typename?: 'WUI_ChatRoomOrder';
  /** An object relationship */
  WUI_ChatRoom: Wui_ChatRoom;
  /** An object relationship */
  WUI_Order: Wui_Order;
  id: Scalars['bigint'];
  order_id: Scalars['bigint'];
  room_id: Scalars['bigint'];
};

/** aggregated selection of "WUI_ChatRoomOrder" */
export type Wui_ChatRoomOrder_Aggregate = {
  __typename?: 'WUI_ChatRoomOrder_aggregate';
  aggregate?: Maybe<Wui_ChatRoomOrder_Aggregate_Fields>;
  nodes: Array<Wui_ChatRoomOrder>;
};

/** aggregate fields of "WUI_ChatRoomOrder" */
export type Wui_ChatRoomOrder_Aggregate_Fields = {
  __typename?: 'WUI_ChatRoomOrder_aggregate_fields';
  avg?: Maybe<Wui_ChatRoomOrder_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_ChatRoomOrder_Max_Fields>;
  min?: Maybe<Wui_ChatRoomOrder_Min_Fields>;
  stddev?: Maybe<Wui_ChatRoomOrder_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_ChatRoomOrder_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_ChatRoomOrder_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_ChatRoomOrder_Sum_Fields>;
  var_pop?: Maybe<Wui_ChatRoomOrder_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_ChatRoomOrder_Var_Samp_Fields>;
  variance?: Maybe<Wui_ChatRoomOrder_Variance_Fields>;
};


/** aggregate fields of "WUI_ChatRoomOrder" */
export type Wui_ChatRoomOrder_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wui_ChatRoomOrder_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "WUI_ChatRoomOrder" */
export type Wui_ChatRoomOrder_Aggregate_Order_By = {
  avg?: Maybe<Wui_ChatRoomOrder_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Wui_ChatRoomOrder_Max_Order_By>;
  min?: Maybe<Wui_ChatRoomOrder_Min_Order_By>;
  stddev?: Maybe<Wui_ChatRoomOrder_Stddev_Order_By>;
  stddev_pop?: Maybe<Wui_ChatRoomOrder_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Wui_ChatRoomOrder_Stddev_Samp_Order_By>;
  sum?: Maybe<Wui_ChatRoomOrder_Sum_Order_By>;
  var_pop?: Maybe<Wui_ChatRoomOrder_Var_Pop_Order_By>;
  var_samp?: Maybe<Wui_ChatRoomOrder_Var_Samp_Order_By>;
  variance?: Maybe<Wui_ChatRoomOrder_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "WUI_ChatRoomOrder" */
export type Wui_ChatRoomOrder_Arr_Rel_Insert_Input = {
  data: Array<Wui_ChatRoomOrder_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Wui_ChatRoomOrder_On_Conflict>;
};

/** aggregate avg on columns */
export type Wui_ChatRoomOrder_Avg_Fields = {
  __typename?: 'WUI_ChatRoomOrder_avg_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "WUI_ChatRoomOrder" */
export type Wui_ChatRoomOrder_Avg_Order_By = {
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  room_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "WUI_ChatRoomOrder". All fields are combined with a logical 'AND'. */
export type Wui_ChatRoomOrder_Bool_Exp = {
  WUI_ChatRoom?: Maybe<Wui_ChatRoom_Bool_Exp>;
  WUI_Order?: Maybe<Wui_Order_Bool_Exp>;
  _and?: Maybe<Array<Wui_ChatRoomOrder_Bool_Exp>>;
  _not?: Maybe<Wui_ChatRoomOrder_Bool_Exp>;
  _or?: Maybe<Array<Wui_ChatRoomOrder_Bool_Exp>>;
  id?: Maybe<Bigint_Comparison_Exp>;
  order_id?: Maybe<Bigint_Comparison_Exp>;
  room_id?: Maybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_ChatRoomOrder" */
export enum Wui_ChatRoomOrder_Constraint {
  /** unique or primary key constraint */
  WuiChatRoomOrderPkey = 'WUI_ChatRoomOrder_pkey'
}

/** input type for incrementing numeric columns in table "WUI_ChatRoomOrder" */
export type Wui_ChatRoomOrder_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  order_id?: Maybe<Scalars['bigint']>;
  room_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_ChatRoomOrder" */
export type Wui_ChatRoomOrder_Insert_Input = {
  WUI_ChatRoom?: Maybe<Wui_ChatRoom_Obj_Rel_Insert_Input>;
  WUI_Order?: Maybe<Wui_Order_Obj_Rel_Insert_Input>;
  id?: Maybe<Scalars['bigint']>;
  order_id?: Maybe<Scalars['bigint']>;
  room_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Wui_ChatRoomOrder_Max_Fields = {
  __typename?: 'WUI_ChatRoomOrder_max_fields';
  id?: Maybe<Scalars['bigint']>;
  order_id?: Maybe<Scalars['bigint']>;
  room_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "WUI_ChatRoomOrder" */
export type Wui_ChatRoomOrder_Max_Order_By = {
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  room_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Wui_ChatRoomOrder_Min_Fields = {
  __typename?: 'WUI_ChatRoomOrder_min_fields';
  id?: Maybe<Scalars['bigint']>;
  order_id?: Maybe<Scalars['bigint']>;
  room_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "WUI_ChatRoomOrder" */
export type Wui_ChatRoomOrder_Min_Order_By = {
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  room_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "WUI_ChatRoomOrder" */
export type Wui_ChatRoomOrder_Mutation_Response = {
  __typename?: 'WUI_ChatRoomOrder_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_ChatRoomOrder>;
};

/** on conflict condition type for table "WUI_ChatRoomOrder" */
export type Wui_ChatRoomOrder_On_Conflict = {
  constraint: Wui_ChatRoomOrder_Constraint;
  update_columns?: Array<Wui_ChatRoomOrder_Update_Column>;
  where?: Maybe<Wui_ChatRoomOrder_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_ChatRoomOrder". */
export type Wui_ChatRoomOrder_Order_By = {
  WUI_ChatRoom?: Maybe<Wui_ChatRoom_Order_By>;
  WUI_Order?: Maybe<Wui_Order_Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  room_id?: Maybe<Order_By>;
};

/** primary key columns input for table: WUI_ChatRoomOrder */
export type Wui_ChatRoomOrder_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "WUI_ChatRoomOrder" */
export enum Wui_ChatRoomOrder_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  RoomId = 'room_id'
}

/** input type for updating data in table "WUI_ChatRoomOrder" */
export type Wui_ChatRoomOrder_Set_Input = {
  id?: Maybe<Scalars['bigint']>;
  order_id?: Maybe<Scalars['bigint']>;
  room_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Wui_ChatRoomOrder_Stddev_Fields = {
  __typename?: 'WUI_ChatRoomOrder_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "WUI_ChatRoomOrder" */
export type Wui_ChatRoomOrder_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  room_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Wui_ChatRoomOrder_Stddev_Pop_Fields = {
  __typename?: 'WUI_ChatRoomOrder_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "WUI_ChatRoomOrder" */
export type Wui_ChatRoomOrder_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  room_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Wui_ChatRoomOrder_Stddev_Samp_Fields = {
  __typename?: 'WUI_ChatRoomOrder_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "WUI_ChatRoomOrder" */
export type Wui_ChatRoomOrder_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  room_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Wui_ChatRoomOrder_Sum_Fields = {
  __typename?: 'WUI_ChatRoomOrder_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  order_id?: Maybe<Scalars['bigint']>;
  room_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "WUI_ChatRoomOrder" */
export type Wui_ChatRoomOrder_Sum_Order_By = {
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  room_id?: Maybe<Order_By>;
};

/** update columns of table "WUI_ChatRoomOrder" */
export enum Wui_ChatRoomOrder_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  RoomId = 'room_id'
}

/** aggregate var_pop on columns */
export type Wui_ChatRoomOrder_Var_Pop_Fields = {
  __typename?: 'WUI_ChatRoomOrder_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "WUI_ChatRoomOrder" */
export type Wui_ChatRoomOrder_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  room_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Wui_ChatRoomOrder_Var_Samp_Fields = {
  __typename?: 'WUI_ChatRoomOrder_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "WUI_ChatRoomOrder" */
export type Wui_ChatRoomOrder_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  room_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Wui_ChatRoomOrder_Variance_Fields = {
  __typename?: 'WUI_ChatRoomOrder_variance_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "WUI_ChatRoomOrder" */
export type Wui_ChatRoomOrder_Variance_Order_By = {
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  room_id?: Maybe<Order_By>;
};

/** columns and relationships of "WUI_ChatRoomType" */
export type Wui_ChatRoomType = {
  __typename?: 'WUI_ChatRoomType';
  value: Scalars['String'];
};

/** aggregated selection of "WUI_ChatRoomType" */
export type Wui_ChatRoomType_Aggregate = {
  __typename?: 'WUI_ChatRoomType_aggregate';
  aggregate?: Maybe<Wui_ChatRoomType_Aggregate_Fields>;
  nodes: Array<Wui_ChatRoomType>;
};

/** aggregate fields of "WUI_ChatRoomType" */
export type Wui_ChatRoomType_Aggregate_Fields = {
  __typename?: 'WUI_ChatRoomType_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Wui_ChatRoomType_Max_Fields>;
  min?: Maybe<Wui_ChatRoomType_Min_Fields>;
};


/** aggregate fields of "WUI_ChatRoomType" */
export type Wui_ChatRoomType_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wui_ChatRoomType_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "WUI_ChatRoomType". All fields are combined with a logical 'AND'. */
export type Wui_ChatRoomType_Bool_Exp = {
  _and?: Maybe<Array<Wui_ChatRoomType_Bool_Exp>>;
  _not?: Maybe<Wui_ChatRoomType_Bool_Exp>;
  _or?: Maybe<Array<Wui_ChatRoomType_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_ChatRoomType" */
export enum Wui_ChatRoomType_Constraint {
  /** unique or primary key constraint */
  WuiChatRoomTypePkey = 'WUI_ChatRoomType_pkey'
}

export enum Wui_ChatRoomType_Enum {
  Conversation = 'conversation',
  Order = 'order'
}

/** Boolean expression to compare columns of type "WUI_ChatRoomType_enum". All fields are combined with logical 'AND'. */
export type Wui_ChatRoomType_Enum_Comparison_Exp = {
  _eq?: Maybe<Wui_ChatRoomType_Enum>;
  _in?: Maybe<Array<Wui_ChatRoomType_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Wui_ChatRoomType_Enum>;
  _nin?: Maybe<Array<Wui_ChatRoomType_Enum>>;
};

/** input type for inserting data into table "WUI_ChatRoomType" */
export type Wui_ChatRoomType_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Wui_ChatRoomType_Max_Fields = {
  __typename?: 'WUI_ChatRoomType_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Wui_ChatRoomType_Min_Fields = {
  __typename?: 'WUI_ChatRoomType_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "WUI_ChatRoomType" */
export type Wui_ChatRoomType_Mutation_Response = {
  __typename?: 'WUI_ChatRoomType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_ChatRoomType>;
};

/** input type for inserting object relation for remote table "WUI_ChatRoomType" */
export type Wui_ChatRoomType_Obj_Rel_Insert_Input = {
  data: Wui_ChatRoomType_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Wui_ChatRoomType_On_Conflict>;
};

/** on conflict condition type for table "WUI_ChatRoomType" */
export type Wui_ChatRoomType_On_Conflict = {
  constraint: Wui_ChatRoomType_Constraint;
  update_columns?: Array<Wui_ChatRoomType_Update_Column>;
  where?: Maybe<Wui_ChatRoomType_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_ChatRoomType". */
export type Wui_ChatRoomType_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: WUI_ChatRoomType */
export type Wui_ChatRoomType_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "WUI_ChatRoomType" */
export enum Wui_ChatRoomType_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "WUI_ChatRoomType" */
export type Wui_ChatRoomType_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "WUI_ChatRoomType" */
export enum Wui_ChatRoomType_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "WUI_ChatRoomUser" */
export type Wui_ChatRoomUser = {
  __typename?: 'WUI_ChatRoomUser';
  /** An object relationship */
  WUI_ChatRoom: Wui_ChatRoom;
  /** An object relationship */
  WUI_User?: Maybe<Wui_User>;
  id: Scalars['bigint'];
  last_seen?: Maybe<Scalars['timestamp']>;
  room_id: Scalars['bigint'];
  seen?: Maybe<Scalars['Boolean']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "WUI_ChatRoomUser" */
export type Wui_ChatRoomUser_Aggregate = {
  __typename?: 'WUI_ChatRoomUser_aggregate';
  aggregate?: Maybe<Wui_ChatRoomUser_Aggregate_Fields>;
  nodes: Array<Wui_ChatRoomUser>;
};

/** aggregate fields of "WUI_ChatRoomUser" */
export type Wui_ChatRoomUser_Aggregate_Fields = {
  __typename?: 'WUI_ChatRoomUser_aggregate_fields';
  avg?: Maybe<Wui_ChatRoomUser_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_ChatRoomUser_Max_Fields>;
  min?: Maybe<Wui_ChatRoomUser_Min_Fields>;
  stddev?: Maybe<Wui_ChatRoomUser_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_ChatRoomUser_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_ChatRoomUser_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_ChatRoomUser_Sum_Fields>;
  var_pop?: Maybe<Wui_ChatRoomUser_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_ChatRoomUser_Var_Samp_Fields>;
  variance?: Maybe<Wui_ChatRoomUser_Variance_Fields>;
};


/** aggregate fields of "WUI_ChatRoomUser" */
export type Wui_ChatRoomUser_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wui_ChatRoomUser_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "WUI_ChatRoomUser" */
export type Wui_ChatRoomUser_Aggregate_Order_By = {
  avg?: Maybe<Wui_ChatRoomUser_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Wui_ChatRoomUser_Max_Order_By>;
  min?: Maybe<Wui_ChatRoomUser_Min_Order_By>;
  stddev?: Maybe<Wui_ChatRoomUser_Stddev_Order_By>;
  stddev_pop?: Maybe<Wui_ChatRoomUser_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Wui_ChatRoomUser_Stddev_Samp_Order_By>;
  sum?: Maybe<Wui_ChatRoomUser_Sum_Order_By>;
  var_pop?: Maybe<Wui_ChatRoomUser_Var_Pop_Order_By>;
  var_samp?: Maybe<Wui_ChatRoomUser_Var_Samp_Order_By>;
  variance?: Maybe<Wui_ChatRoomUser_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "WUI_ChatRoomUser" */
export type Wui_ChatRoomUser_Arr_Rel_Insert_Input = {
  data: Array<Wui_ChatRoomUser_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Wui_ChatRoomUser_On_Conflict>;
};

/** aggregate avg on columns */
export type Wui_ChatRoomUser_Avg_Fields = {
  __typename?: 'WUI_ChatRoomUser_avg_fields';
  id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "WUI_ChatRoomUser" */
export type Wui_ChatRoomUser_Avg_Order_By = {
  id?: Maybe<Order_By>;
  room_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "WUI_ChatRoomUser". All fields are combined with a logical 'AND'. */
export type Wui_ChatRoomUser_Bool_Exp = {
  WUI_ChatRoom?: Maybe<Wui_ChatRoom_Bool_Exp>;
  WUI_User?: Maybe<Wui_User_Bool_Exp>;
  _and?: Maybe<Array<Wui_ChatRoomUser_Bool_Exp>>;
  _not?: Maybe<Wui_ChatRoomUser_Bool_Exp>;
  _or?: Maybe<Array<Wui_ChatRoomUser_Bool_Exp>>;
  id?: Maybe<Bigint_Comparison_Exp>;
  last_seen?: Maybe<Timestamp_Comparison_Exp>;
  room_id?: Maybe<Bigint_Comparison_Exp>;
  seen?: Maybe<Boolean_Comparison_Exp>;
  user_id?: Maybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_ChatRoomUser" */
export enum Wui_ChatRoomUser_Constraint {
  /** unique or primary key constraint */
  WuiChatRoomUserPkey = 'WUI_ChatRoomUser_pkey',
  /** unique or primary key constraint */
  WuiChatRoomUserRoomIdUserIdKey = 'WUI_ChatRoomUser_room_id_user_id_key'
}

/** input type for incrementing numeric columns in table "WUI_ChatRoomUser" */
export type Wui_ChatRoomUser_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  room_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_ChatRoomUser" */
export type Wui_ChatRoomUser_Insert_Input = {
  WUI_ChatRoom?: Maybe<Wui_ChatRoom_Obj_Rel_Insert_Input>;
  WUI_User?: Maybe<Wui_User_Obj_Rel_Insert_Input>;
  id?: Maybe<Scalars['bigint']>;
  last_seen?: Maybe<Scalars['timestamp']>;
  room_id?: Maybe<Scalars['bigint']>;
  seen?: Maybe<Scalars['Boolean']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Wui_ChatRoomUser_Max_Fields = {
  __typename?: 'WUI_ChatRoomUser_max_fields';
  id?: Maybe<Scalars['bigint']>;
  last_seen?: Maybe<Scalars['timestamp']>;
  room_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "WUI_ChatRoomUser" */
export type Wui_ChatRoomUser_Max_Order_By = {
  id?: Maybe<Order_By>;
  last_seen?: Maybe<Order_By>;
  room_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Wui_ChatRoomUser_Min_Fields = {
  __typename?: 'WUI_ChatRoomUser_min_fields';
  id?: Maybe<Scalars['bigint']>;
  last_seen?: Maybe<Scalars['timestamp']>;
  room_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "WUI_ChatRoomUser" */
export type Wui_ChatRoomUser_Min_Order_By = {
  id?: Maybe<Order_By>;
  last_seen?: Maybe<Order_By>;
  room_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "WUI_ChatRoomUser" */
export type Wui_ChatRoomUser_Mutation_Response = {
  __typename?: 'WUI_ChatRoomUser_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_ChatRoomUser>;
};

/** on conflict condition type for table "WUI_ChatRoomUser" */
export type Wui_ChatRoomUser_On_Conflict = {
  constraint: Wui_ChatRoomUser_Constraint;
  update_columns?: Array<Wui_ChatRoomUser_Update_Column>;
  where?: Maybe<Wui_ChatRoomUser_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_ChatRoomUser". */
export type Wui_ChatRoomUser_Order_By = {
  WUI_ChatRoom?: Maybe<Wui_ChatRoom_Order_By>;
  WUI_User?: Maybe<Wui_User_Order_By>;
  id?: Maybe<Order_By>;
  last_seen?: Maybe<Order_By>;
  room_id?: Maybe<Order_By>;
  seen?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: WUI_ChatRoomUser */
export type Wui_ChatRoomUser_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "WUI_ChatRoomUser" */
export enum Wui_ChatRoomUser_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LastSeen = 'last_seen',
  /** column name */
  RoomId = 'room_id',
  /** column name */
  Seen = 'seen',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "WUI_ChatRoomUser" */
export type Wui_ChatRoomUser_Set_Input = {
  id?: Maybe<Scalars['bigint']>;
  last_seen?: Maybe<Scalars['timestamp']>;
  room_id?: Maybe<Scalars['bigint']>;
  seen?: Maybe<Scalars['Boolean']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Wui_ChatRoomUser_Stddev_Fields = {
  __typename?: 'WUI_ChatRoomUser_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "WUI_ChatRoomUser" */
export type Wui_ChatRoomUser_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  room_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Wui_ChatRoomUser_Stddev_Pop_Fields = {
  __typename?: 'WUI_ChatRoomUser_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "WUI_ChatRoomUser" */
export type Wui_ChatRoomUser_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  room_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Wui_ChatRoomUser_Stddev_Samp_Fields = {
  __typename?: 'WUI_ChatRoomUser_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "WUI_ChatRoomUser" */
export type Wui_ChatRoomUser_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  room_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Wui_ChatRoomUser_Sum_Fields = {
  __typename?: 'WUI_ChatRoomUser_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  room_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "WUI_ChatRoomUser" */
export type Wui_ChatRoomUser_Sum_Order_By = {
  id?: Maybe<Order_By>;
  room_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** update columns of table "WUI_ChatRoomUser" */
export enum Wui_ChatRoomUser_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LastSeen = 'last_seen',
  /** column name */
  RoomId = 'room_id',
  /** column name */
  Seen = 'seen',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Wui_ChatRoomUser_Var_Pop_Fields = {
  __typename?: 'WUI_ChatRoomUser_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "WUI_ChatRoomUser" */
export type Wui_ChatRoomUser_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  room_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Wui_ChatRoomUser_Var_Samp_Fields = {
  __typename?: 'WUI_ChatRoomUser_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "WUI_ChatRoomUser" */
export type Wui_ChatRoomUser_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  room_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Wui_ChatRoomUser_Variance_Fields = {
  __typename?: 'WUI_ChatRoomUser_variance_fields';
  id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "WUI_ChatRoomUser" */
export type Wui_ChatRoomUser_Variance_Order_By = {
  id?: Maybe<Order_By>;
  room_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregated selection of "WUI_ChatRoom" */
export type Wui_ChatRoom_Aggregate = {
  __typename?: 'WUI_ChatRoom_aggregate';
  aggregate?: Maybe<Wui_ChatRoom_Aggregate_Fields>;
  nodes: Array<Wui_ChatRoom>;
};

/** aggregate fields of "WUI_ChatRoom" */
export type Wui_ChatRoom_Aggregate_Fields = {
  __typename?: 'WUI_ChatRoom_aggregate_fields';
  avg?: Maybe<Wui_ChatRoom_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_ChatRoom_Max_Fields>;
  min?: Maybe<Wui_ChatRoom_Min_Fields>;
  stddev?: Maybe<Wui_ChatRoom_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_ChatRoom_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_ChatRoom_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_ChatRoom_Sum_Fields>;
  var_pop?: Maybe<Wui_ChatRoom_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_ChatRoom_Var_Samp_Fields>;
  variance?: Maybe<Wui_ChatRoom_Variance_Fields>;
};


/** aggregate fields of "WUI_ChatRoom" */
export type Wui_ChatRoom_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wui_ChatRoom_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Wui_ChatRoom_Avg_Fields = {
  __typename?: 'WUI_ChatRoom_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "WUI_ChatRoom". All fields are combined with a logical 'AND'. */
export type Wui_ChatRoom_Bool_Exp = {
  WUI_ChatRoomMessages?: Maybe<Wui_ChatRoomMessage_Bool_Exp>;
  WUI_ChatRoomOrders?: Maybe<Wui_ChatRoomOrder_Bool_Exp>;
  WUI_ChatRoomType?: Maybe<Wui_ChatRoomType_Bool_Exp>;
  WUI_ChatRoomUsers?: Maybe<Wui_ChatRoomUser_Bool_Exp>;
  _and?: Maybe<Array<Wui_ChatRoom_Bool_Exp>>;
  _not?: Maybe<Wui_ChatRoom_Bool_Exp>;
  _or?: Maybe<Array<Wui_ChatRoom_Bool_Exp>>;
  id?: Maybe<Bigint_Comparison_Exp>;
  type?: Maybe<Wui_ChatRoomType_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_ChatRoom" */
export enum Wui_ChatRoom_Constraint {
  /** unique or primary key constraint */
  WuiChatRoomPkey = 'WUI_Chat_Room_pkey'
}

/** input type for incrementing numeric columns in table "WUI_ChatRoom" */
export type Wui_ChatRoom_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_ChatRoom" */
export type Wui_ChatRoom_Insert_Input = {
  WUI_ChatRoomMessages?: Maybe<Wui_ChatRoomMessage_Arr_Rel_Insert_Input>;
  WUI_ChatRoomOrders?: Maybe<Wui_ChatRoomOrder_Arr_Rel_Insert_Input>;
  WUI_ChatRoomType?: Maybe<Wui_ChatRoomType_Obj_Rel_Insert_Input>;
  WUI_ChatRoomUsers?: Maybe<Wui_ChatRoomUser_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Wui_ChatRoomType_Enum>;
};

/** aggregate max on columns */
export type Wui_ChatRoom_Max_Fields = {
  __typename?: 'WUI_ChatRoom_max_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Wui_ChatRoom_Min_Fields = {
  __typename?: 'WUI_ChatRoom_min_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "WUI_ChatRoom" */
export type Wui_ChatRoom_Mutation_Response = {
  __typename?: 'WUI_ChatRoom_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_ChatRoom>;
};

/** input type for inserting object relation for remote table "WUI_ChatRoom" */
export type Wui_ChatRoom_Obj_Rel_Insert_Input = {
  data: Wui_ChatRoom_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Wui_ChatRoom_On_Conflict>;
};

/** on conflict condition type for table "WUI_ChatRoom" */
export type Wui_ChatRoom_On_Conflict = {
  constraint: Wui_ChatRoom_Constraint;
  update_columns?: Array<Wui_ChatRoom_Update_Column>;
  where?: Maybe<Wui_ChatRoom_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_ChatRoom". */
export type Wui_ChatRoom_Order_By = {
  WUI_ChatRoomMessages_aggregate?: Maybe<Wui_ChatRoomMessage_Aggregate_Order_By>;
  WUI_ChatRoomOrders_aggregate?: Maybe<Wui_ChatRoomOrder_Aggregate_Order_By>;
  WUI_ChatRoomType?: Maybe<Wui_ChatRoomType_Order_By>;
  WUI_ChatRoomUsers_aggregate?: Maybe<Wui_ChatRoomUser_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** primary key columns input for table: WUI_ChatRoom */
export type Wui_ChatRoom_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "WUI_ChatRoom" */
export enum Wui_ChatRoom_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "WUI_ChatRoom" */
export type Wui_ChatRoom_Set_Input = {
  id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Wui_ChatRoomType_Enum>;
};

/** aggregate stddev on columns */
export type Wui_ChatRoom_Stddev_Fields = {
  __typename?: 'WUI_ChatRoom_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Wui_ChatRoom_Stddev_Pop_Fields = {
  __typename?: 'WUI_ChatRoom_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Wui_ChatRoom_Stddev_Samp_Fields = {
  __typename?: 'WUI_ChatRoom_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Wui_ChatRoom_Sum_Fields = {
  __typename?: 'WUI_ChatRoom_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "WUI_ChatRoom" */
export enum Wui_ChatRoom_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type'
}

/** aggregate var_pop on columns */
export type Wui_ChatRoom_Var_Pop_Fields = {
  __typename?: 'WUI_ChatRoom_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Wui_ChatRoom_Var_Samp_Fields = {
  __typename?: 'WUI_ChatRoom_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Wui_ChatRoom_Variance_Fields = {
  __typename?: 'WUI_ChatRoom_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "WUI_File" */
export type Wui_File = {
  __typename?: 'WUI_File';
  created_at: Scalars['timestamptz'];
  file_type: Wui_FileType_Enum;
  id: Scalars['bigint'];
  url: Scalars['String'];
};

/** columns and relationships of "WUI_FileType" */
export type Wui_FileType = {
  __typename?: 'WUI_FileType';
  value: Scalars['String'];
};

/** aggregated selection of "WUI_FileType" */
export type Wui_FileType_Aggregate = {
  __typename?: 'WUI_FileType_aggregate';
  aggregate?: Maybe<Wui_FileType_Aggregate_Fields>;
  nodes: Array<Wui_FileType>;
};

/** aggregate fields of "WUI_FileType" */
export type Wui_FileType_Aggregate_Fields = {
  __typename?: 'WUI_FileType_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Wui_FileType_Max_Fields>;
  min?: Maybe<Wui_FileType_Min_Fields>;
};


/** aggregate fields of "WUI_FileType" */
export type Wui_FileType_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wui_FileType_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "WUI_FileType". All fields are combined with a logical 'AND'. */
export type Wui_FileType_Bool_Exp = {
  _and?: Maybe<Array<Wui_FileType_Bool_Exp>>;
  _not?: Maybe<Wui_FileType_Bool_Exp>;
  _or?: Maybe<Array<Wui_FileType_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_FileType" */
export enum Wui_FileType_Constraint {
  /** unique or primary key constraint */
  WuiFileTypeEnumPkey = 'WUI_File_Type_Enum_pkey'
}

export enum Wui_FileType_Enum {
  Document = 'document',
  Image = 'image',
  Video = 'video'
}

/** Boolean expression to compare columns of type "WUI_FileType_enum". All fields are combined with logical 'AND'. */
export type Wui_FileType_Enum_Comparison_Exp = {
  _eq?: Maybe<Wui_FileType_Enum>;
  _in?: Maybe<Array<Wui_FileType_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Wui_FileType_Enum>;
  _nin?: Maybe<Array<Wui_FileType_Enum>>;
};

/** input type for inserting data into table "WUI_FileType" */
export type Wui_FileType_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Wui_FileType_Max_Fields = {
  __typename?: 'WUI_FileType_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Wui_FileType_Min_Fields = {
  __typename?: 'WUI_FileType_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "WUI_FileType" */
export type Wui_FileType_Mutation_Response = {
  __typename?: 'WUI_FileType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_FileType>;
};

/** on conflict condition type for table "WUI_FileType" */
export type Wui_FileType_On_Conflict = {
  constraint: Wui_FileType_Constraint;
  update_columns?: Array<Wui_FileType_Update_Column>;
  where?: Maybe<Wui_FileType_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_FileType". */
export type Wui_FileType_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: WUI_FileType */
export type Wui_FileType_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "WUI_FileType" */
export enum Wui_FileType_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "WUI_FileType" */
export type Wui_FileType_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "WUI_FileType" */
export enum Wui_FileType_Update_Column {
  /** column name */
  Value = 'value'
}

/** aggregated selection of "WUI_File" */
export type Wui_File_Aggregate = {
  __typename?: 'WUI_File_aggregate';
  aggregate?: Maybe<Wui_File_Aggregate_Fields>;
  nodes: Array<Wui_File>;
};

/** aggregate fields of "WUI_File" */
export type Wui_File_Aggregate_Fields = {
  __typename?: 'WUI_File_aggregate_fields';
  avg?: Maybe<Wui_File_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_File_Max_Fields>;
  min?: Maybe<Wui_File_Min_Fields>;
  stddev?: Maybe<Wui_File_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_File_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_File_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_File_Sum_Fields>;
  var_pop?: Maybe<Wui_File_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_File_Var_Samp_Fields>;
  variance?: Maybe<Wui_File_Variance_Fields>;
};


/** aggregate fields of "WUI_File" */
export type Wui_File_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wui_File_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Wui_File_Avg_Fields = {
  __typename?: 'WUI_File_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "WUI_File". All fields are combined with a logical 'AND'. */
export type Wui_File_Bool_Exp = {
  _and?: Maybe<Array<Wui_File_Bool_Exp>>;
  _not?: Maybe<Wui_File_Bool_Exp>;
  _or?: Maybe<Array<Wui_File_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  file_type?: Maybe<Wui_FileType_Enum_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  url?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_File" */
export enum Wui_File_Constraint {
  /** unique or primary key constraint */
  WuiFilePkey = 'WUI_File_pkey',
  /** unique or primary key constraint */
  WuiFileUrlKey = 'WUI_File_url_key'
}

/** input type for incrementing numeric columns in table "WUI_File" */
export type Wui_File_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_File" */
export type Wui_File_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  file_type?: Maybe<Wui_FileType_Enum>;
  id?: Maybe<Scalars['bigint']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Wui_File_Max_Fields = {
  __typename?: 'WUI_File_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Wui_File_Min_Fields = {
  __typename?: 'WUI_File_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  url?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "WUI_File" */
export type Wui_File_Mutation_Response = {
  __typename?: 'WUI_File_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_File>;
};

/** input type for inserting object relation for remote table "WUI_File" */
export type Wui_File_Obj_Rel_Insert_Input = {
  data: Wui_File_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Wui_File_On_Conflict>;
};

/** on conflict condition type for table "WUI_File" */
export type Wui_File_On_Conflict = {
  constraint: Wui_File_Constraint;
  update_columns?: Array<Wui_File_Update_Column>;
  where?: Maybe<Wui_File_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_File". */
export type Wui_File_Order_By = {
  created_at?: Maybe<Order_By>;
  file_type?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** primary key columns input for table: WUI_File */
export type Wui_File_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "WUI_File" */
export enum Wui_File_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileType = 'file_type',
  /** column name */
  Id = 'id',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "WUI_File" */
export type Wui_File_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  file_type?: Maybe<Wui_FileType_Enum>;
  id?: Maybe<Scalars['bigint']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Wui_File_Stddev_Fields = {
  __typename?: 'WUI_File_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Wui_File_Stddev_Pop_Fields = {
  __typename?: 'WUI_File_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Wui_File_Stddev_Samp_Fields = {
  __typename?: 'WUI_File_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Wui_File_Sum_Fields = {
  __typename?: 'WUI_File_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "WUI_File" */
export enum Wui_File_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileType = 'file_type',
  /** column name */
  Id = 'id',
  /** column name */
  Url = 'url'
}

/** aggregate var_pop on columns */
export type Wui_File_Var_Pop_Fields = {
  __typename?: 'WUI_File_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Wui_File_Var_Samp_Fields = {
  __typename?: 'WUI_File_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Wui_File_Variance_Fields = {
  __typename?: 'WUI_File_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "WUI_Notification" */
export type Wui_Notification = {
  __typename?: 'WUI_Notification';
  /** An object relationship */
  WUI_User: Wui_User;
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  message: Scalars['String'];
  metadata: Scalars['jsonb'];
  seen?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  user_id: Scalars['bigint'];
};


/** columns and relationships of "WUI_Notification" */
export type Wui_NotificationMetadataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "WUI_Notification" */
export type Wui_Notification_Aggregate = {
  __typename?: 'WUI_Notification_aggregate';
  aggregate?: Maybe<Wui_Notification_Aggregate_Fields>;
  nodes: Array<Wui_Notification>;
};

/** aggregate fields of "WUI_Notification" */
export type Wui_Notification_Aggregate_Fields = {
  __typename?: 'WUI_Notification_aggregate_fields';
  avg?: Maybe<Wui_Notification_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_Notification_Max_Fields>;
  min?: Maybe<Wui_Notification_Min_Fields>;
  stddev?: Maybe<Wui_Notification_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_Notification_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_Notification_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_Notification_Sum_Fields>;
  var_pop?: Maybe<Wui_Notification_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_Notification_Var_Samp_Fields>;
  variance?: Maybe<Wui_Notification_Variance_Fields>;
};


/** aggregate fields of "WUI_Notification" */
export type Wui_Notification_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wui_Notification_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Wui_Notification_Append_Input = {
  metadata?: Maybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Wui_Notification_Avg_Fields = {
  __typename?: 'WUI_Notification_avg_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "WUI_Notification". All fields are combined with a logical 'AND'. */
export type Wui_Notification_Bool_Exp = {
  WUI_User?: Maybe<Wui_User_Bool_Exp>;
  _and?: Maybe<Array<Wui_Notification_Bool_Exp>>;
  _not?: Maybe<Wui_Notification_Bool_Exp>;
  _or?: Maybe<Array<Wui_Notification_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  message?: Maybe<String_Comparison_Exp>;
  metadata?: Maybe<Jsonb_Comparison_Exp>;
  seen?: Maybe<Timestamptz_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  user_id?: Maybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_Notification" */
export enum Wui_Notification_Constraint {
  /** unique or primary key constraint */
  WuiNotificationPkey = 'WUI_Notification_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Wui_Notification_Delete_At_Path_Input = {
  metadata?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Wui_Notification_Delete_Elem_Input = {
  metadata?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Wui_Notification_Delete_Key_Input = {
  metadata?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "WUI_Notification" */
export type Wui_Notification_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_Notification" */
export type Wui_Notification_Insert_Input = {
  WUI_User?: Maybe<Wui_User_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['jsonb']>;
  seen?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Wui_Notification_Max_Fields = {
  __typename?: 'WUI_Notification_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['String']>;
  seen?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Wui_Notification_Min_Fields = {
  __typename?: 'WUI_Notification_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['String']>;
  seen?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "WUI_Notification" */
export type Wui_Notification_Mutation_Response = {
  __typename?: 'WUI_Notification_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_Notification>;
};

/** on conflict condition type for table "WUI_Notification" */
export type Wui_Notification_On_Conflict = {
  constraint: Wui_Notification_Constraint;
  update_columns?: Array<Wui_Notification_Update_Column>;
  where?: Maybe<Wui_Notification_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_Notification". */
export type Wui_Notification_Order_By = {
  WUI_User?: Maybe<Wui_User_Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  metadata?: Maybe<Order_By>;
  seen?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: WUI_Notification */
export type Wui_Notification_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Wui_Notification_Prepend_Input = {
  metadata?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "WUI_Notification" */
export enum Wui_Notification_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Seen = 'seen',
  /** column name */
  Title = 'title',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "WUI_Notification" */
export type Wui_Notification_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['jsonb']>;
  seen?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Wui_Notification_Stddev_Fields = {
  __typename?: 'WUI_Notification_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Wui_Notification_Stddev_Pop_Fields = {
  __typename?: 'WUI_Notification_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Wui_Notification_Stddev_Samp_Fields = {
  __typename?: 'WUI_Notification_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Wui_Notification_Sum_Fields = {
  __typename?: 'WUI_Notification_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "WUI_Notification" */
export enum Wui_Notification_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Seen = 'seen',
  /** column name */
  Title = 'title',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Wui_Notification_Var_Pop_Fields = {
  __typename?: 'WUI_Notification_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Wui_Notification_Var_Samp_Fields = {
  __typename?: 'WUI_Notification_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Wui_Notification_Variance_Fields = {
  __typename?: 'WUI_Notification_variance_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "WUI_Order" */
export type Wui_Order = {
  __typename?: 'WUI_Order';
  /** An object relationship */
  WUI_User_Buyer: Wui_User;
  /** An object relationship */
  WUI_User_Seller: Wui_User;
  buyer_id: Scalars['bigint'];
  closed_at?: Maybe<Scalars['timestamptz']>;
  created_at: Scalars['timestamptz'];
  created_by: Scalars['bigint'];
  description: Scalars['String'];
  id: Scalars['bigint'];
  items: Scalars['jsonb'];
  metadata?: Maybe<Scalars['jsonb']>;
  package_type: Wui_SellerPackageType_Enum;
  price: Scalars['numeric'];
  seller_id: Scalars['bigint'];
  status: Wui_OrderStatus_Enum;
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "WUI_Order" */
export type Wui_OrderItemsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "WUI_Order" */
export type Wui_OrderMetadataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** columns and relationships of "WUI_OrderReview" */
export type Wui_OrderReview = {
  __typename?: 'WUI_OrderReview';
  /** An object relationship */
  WUI_Order: Wui_Order;
  /** An object relationship */
  WUI_UserReview: Wui_UserReview;
  id: Scalars['bigint'];
  order_id: Scalars['bigint'];
  review_id: Scalars['bigint'];
};

/** aggregated selection of "WUI_OrderReview" */
export type Wui_OrderReview_Aggregate = {
  __typename?: 'WUI_OrderReview_aggregate';
  aggregate?: Maybe<Wui_OrderReview_Aggregate_Fields>;
  nodes: Array<Wui_OrderReview>;
};

/** aggregate fields of "WUI_OrderReview" */
export type Wui_OrderReview_Aggregate_Fields = {
  __typename?: 'WUI_OrderReview_aggregate_fields';
  avg?: Maybe<Wui_OrderReview_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_OrderReview_Max_Fields>;
  min?: Maybe<Wui_OrderReview_Min_Fields>;
  stddev?: Maybe<Wui_OrderReview_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_OrderReview_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_OrderReview_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_OrderReview_Sum_Fields>;
  var_pop?: Maybe<Wui_OrderReview_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_OrderReview_Var_Samp_Fields>;
  variance?: Maybe<Wui_OrderReview_Variance_Fields>;
};


/** aggregate fields of "WUI_OrderReview" */
export type Wui_OrderReview_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wui_OrderReview_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Wui_OrderReview_Avg_Fields = {
  __typename?: 'WUI_OrderReview_avg_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  review_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "WUI_OrderReview". All fields are combined with a logical 'AND'. */
export type Wui_OrderReview_Bool_Exp = {
  WUI_Order?: Maybe<Wui_Order_Bool_Exp>;
  WUI_UserReview?: Maybe<Wui_UserReview_Bool_Exp>;
  _and?: Maybe<Array<Wui_OrderReview_Bool_Exp>>;
  _not?: Maybe<Wui_OrderReview_Bool_Exp>;
  _or?: Maybe<Array<Wui_OrderReview_Bool_Exp>>;
  id?: Maybe<Bigint_Comparison_Exp>;
  order_id?: Maybe<Bigint_Comparison_Exp>;
  review_id?: Maybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_OrderReview" */
export enum Wui_OrderReview_Constraint {
  /** unique or primary key constraint */
  WuiOrderReviewPkey = 'WUI_OrderReview_pkey'
}

/** input type for incrementing numeric columns in table "WUI_OrderReview" */
export type Wui_OrderReview_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  order_id?: Maybe<Scalars['bigint']>;
  review_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_OrderReview" */
export type Wui_OrderReview_Insert_Input = {
  WUI_Order?: Maybe<Wui_Order_Obj_Rel_Insert_Input>;
  WUI_UserReview?: Maybe<Wui_UserReview_Obj_Rel_Insert_Input>;
  id?: Maybe<Scalars['bigint']>;
  order_id?: Maybe<Scalars['bigint']>;
  review_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Wui_OrderReview_Max_Fields = {
  __typename?: 'WUI_OrderReview_max_fields';
  id?: Maybe<Scalars['bigint']>;
  order_id?: Maybe<Scalars['bigint']>;
  review_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Wui_OrderReview_Min_Fields = {
  __typename?: 'WUI_OrderReview_min_fields';
  id?: Maybe<Scalars['bigint']>;
  order_id?: Maybe<Scalars['bigint']>;
  review_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "WUI_OrderReview" */
export type Wui_OrderReview_Mutation_Response = {
  __typename?: 'WUI_OrderReview_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_OrderReview>;
};

/** on conflict condition type for table "WUI_OrderReview" */
export type Wui_OrderReview_On_Conflict = {
  constraint: Wui_OrderReview_Constraint;
  update_columns?: Array<Wui_OrderReview_Update_Column>;
  where?: Maybe<Wui_OrderReview_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_OrderReview". */
export type Wui_OrderReview_Order_By = {
  WUI_Order?: Maybe<Wui_Order_Order_By>;
  WUI_UserReview?: Maybe<Wui_UserReview_Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  review_id?: Maybe<Order_By>;
};

/** primary key columns input for table: WUI_OrderReview */
export type Wui_OrderReview_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "WUI_OrderReview" */
export enum Wui_OrderReview_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  ReviewId = 'review_id'
}

/** input type for updating data in table "WUI_OrderReview" */
export type Wui_OrderReview_Set_Input = {
  id?: Maybe<Scalars['bigint']>;
  order_id?: Maybe<Scalars['bigint']>;
  review_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Wui_OrderReview_Stddev_Fields = {
  __typename?: 'WUI_OrderReview_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  review_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Wui_OrderReview_Stddev_Pop_Fields = {
  __typename?: 'WUI_OrderReview_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  review_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Wui_OrderReview_Stddev_Samp_Fields = {
  __typename?: 'WUI_OrderReview_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  review_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Wui_OrderReview_Sum_Fields = {
  __typename?: 'WUI_OrderReview_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  order_id?: Maybe<Scalars['bigint']>;
  review_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "WUI_OrderReview" */
export enum Wui_OrderReview_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  ReviewId = 'review_id'
}

/** aggregate var_pop on columns */
export type Wui_OrderReview_Var_Pop_Fields = {
  __typename?: 'WUI_OrderReview_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  review_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Wui_OrderReview_Var_Samp_Fields = {
  __typename?: 'WUI_OrderReview_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  review_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Wui_OrderReview_Variance_Fields = {
  __typename?: 'WUI_OrderReview_variance_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  review_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "WUI_OrderStatus" */
export type Wui_OrderStatus = {
  __typename?: 'WUI_OrderStatus';
  value: Scalars['String'];
};

/** aggregated selection of "WUI_OrderStatus" */
export type Wui_OrderStatus_Aggregate = {
  __typename?: 'WUI_OrderStatus_aggregate';
  aggregate?: Maybe<Wui_OrderStatus_Aggregate_Fields>;
  nodes: Array<Wui_OrderStatus>;
};

/** aggregate fields of "WUI_OrderStatus" */
export type Wui_OrderStatus_Aggregate_Fields = {
  __typename?: 'WUI_OrderStatus_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Wui_OrderStatus_Max_Fields>;
  min?: Maybe<Wui_OrderStatus_Min_Fields>;
};


/** aggregate fields of "WUI_OrderStatus" */
export type Wui_OrderStatus_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wui_OrderStatus_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "WUI_OrderStatus". All fields are combined with a logical 'AND'. */
export type Wui_OrderStatus_Bool_Exp = {
  _and?: Maybe<Array<Wui_OrderStatus_Bool_Exp>>;
  _not?: Maybe<Wui_OrderStatus_Bool_Exp>;
  _or?: Maybe<Array<Wui_OrderStatus_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_OrderStatus" */
export enum Wui_OrderStatus_Constraint {
  /** unique or primary key constraint */
  WuiOrderStatusPkey = 'WUI_OrderStatus_pkey'
}

export enum Wui_OrderStatus_Enum {
  Cancelled = 'cancelled',
  Completed = 'completed',
  Delivered = 'delivered',
  InProgress = 'inProgress',
  Open = 'open',
  Pending = 'pending',
  Rejected = 'rejected',
  WaitingForContent = 'waitingForContent'
}

/** Boolean expression to compare columns of type "WUI_OrderStatus_enum". All fields are combined with logical 'AND'. */
export type Wui_OrderStatus_Enum_Comparison_Exp = {
  _eq?: Maybe<Wui_OrderStatus_Enum>;
  _in?: Maybe<Array<Wui_OrderStatus_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Wui_OrderStatus_Enum>;
  _nin?: Maybe<Array<Wui_OrderStatus_Enum>>;
};

/** input type for inserting data into table "WUI_OrderStatus" */
export type Wui_OrderStatus_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Wui_OrderStatus_Max_Fields = {
  __typename?: 'WUI_OrderStatus_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Wui_OrderStatus_Min_Fields = {
  __typename?: 'WUI_OrderStatus_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "WUI_OrderStatus" */
export type Wui_OrderStatus_Mutation_Response = {
  __typename?: 'WUI_OrderStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_OrderStatus>;
};

/** on conflict condition type for table "WUI_OrderStatus" */
export type Wui_OrderStatus_On_Conflict = {
  constraint: Wui_OrderStatus_Constraint;
  update_columns?: Array<Wui_OrderStatus_Update_Column>;
  where?: Maybe<Wui_OrderStatus_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_OrderStatus". */
export type Wui_OrderStatus_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: WUI_OrderStatus */
export type Wui_OrderStatus_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "WUI_OrderStatus" */
export enum Wui_OrderStatus_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "WUI_OrderStatus" */
export type Wui_OrderStatus_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "WUI_OrderStatus" */
export enum Wui_OrderStatus_Update_Column {
  /** column name */
  Value = 'value'
}

/** aggregated selection of "WUI_Order" */
export type Wui_Order_Aggregate = {
  __typename?: 'WUI_Order_aggregate';
  aggregate?: Maybe<Wui_Order_Aggregate_Fields>;
  nodes: Array<Wui_Order>;
};

/** aggregate fields of "WUI_Order" */
export type Wui_Order_Aggregate_Fields = {
  __typename?: 'WUI_Order_aggregate_fields';
  avg?: Maybe<Wui_Order_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_Order_Max_Fields>;
  min?: Maybe<Wui_Order_Min_Fields>;
  stddev?: Maybe<Wui_Order_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_Order_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_Order_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_Order_Sum_Fields>;
  var_pop?: Maybe<Wui_Order_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_Order_Var_Samp_Fields>;
  variance?: Maybe<Wui_Order_Variance_Fields>;
};


/** aggregate fields of "WUI_Order" */
export type Wui_Order_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wui_Order_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Wui_Order_Append_Input = {
  items?: Maybe<Scalars['jsonb']>;
  metadata?: Maybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Wui_Order_Avg_Fields = {
  __typename?: 'WUI_Order_avg_fields';
  buyer_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  seller_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "WUI_Order". All fields are combined with a logical 'AND'. */
export type Wui_Order_Bool_Exp = {
  WUI_User_Buyer?: Maybe<Wui_User_Bool_Exp>;
  WUI_User_Seller?: Maybe<Wui_User_Bool_Exp>;
  _and?: Maybe<Array<Wui_Order_Bool_Exp>>;
  _not?: Maybe<Wui_Order_Bool_Exp>;
  _or?: Maybe<Array<Wui_Order_Bool_Exp>>;
  buyer_id?: Maybe<Bigint_Comparison_Exp>;
  closed_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  items?: Maybe<Jsonb_Comparison_Exp>;
  metadata?: Maybe<Jsonb_Comparison_Exp>;
  package_type?: Maybe<Wui_SellerPackageType_Enum_Comparison_Exp>;
  price?: Maybe<Numeric_Comparison_Exp>;
  seller_id?: Maybe<Bigint_Comparison_Exp>;
  status?: Maybe<Wui_OrderStatus_Enum_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_Order" */
export enum Wui_Order_Constraint {
  /** unique or primary key constraint */
  WuiOrderPkey = 'WUI_Order_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Wui_Order_Delete_At_Path_Input = {
  items?: Maybe<Array<Scalars['String']>>;
  metadata?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Wui_Order_Delete_Elem_Input = {
  items?: Maybe<Scalars['Int']>;
  metadata?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Wui_Order_Delete_Key_Input = {
  items?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "WUI_Order" */
export type Wui_Order_Inc_Input = {
  buyer_id?: Maybe<Scalars['bigint']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['numeric']>;
  seller_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_Order" */
export type Wui_Order_Insert_Input = {
  WUI_User_Buyer?: Maybe<Wui_User_Obj_Rel_Insert_Input>;
  WUI_User_Seller?: Maybe<Wui_User_Obj_Rel_Insert_Input>;
  buyer_id?: Maybe<Scalars['bigint']>;
  closed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  items?: Maybe<Scalars['jsonb']>;
  metadata?: Maybe<Scalars['jsonb']>;
  package_type?: Maybe<Wui_SellerPackageType_Enum>;
  price?: Maybe<Scalars['numeric']>;
  seller_id?: Maybe<Scalars['bigint']>;
  status?: Maybe<Wui_OrderStatus_Enum>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Wui_Order_Max_Fields = {
  __typename?: 'WUI_Order_max_fields';
  buyer_id?: Maybe<Scalars['bigint']>;
  closed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['numeric']>;
  seller_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Wui_Order_Min_Fields = {
  __typename?: 'WUI_Order_min_fields';
  buyer_id?: Maybe<Scalars['bigint']>;
  closed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['numeric']>;
  seller_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "WUI_Order" */
export type Wui_Order_Mutation_Response = {
  __typename?: 'WUI_Order_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_Order>;
};

/** input type for inserting object relation for remote table "WUI_Order" */
export type Wui_Order_Obj_Rel_Insert_Input = {
  data: Wui_Order_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Wui_Order_On_Conflict>;
};

/** on conflict condition type for table "WUI_Order" */
export type Wui_Order_On_Conflict = {
  constraint: Wui_Order_Constraint;
  update_columns?: Array<Wui_Order_Update_Column>;
  where?: Maybe<Wui_Order_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_Order". */
export type Wui_Order_Order_By = {
  WUI_User_Buyer?: Maybe<Wui_User_Order_By>;
  WUI_User_Seller?: Maybe<Wui_User_Order_By>;
  buyer_id?: Maybe<Order_By>;
  closed_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  items?: Maybe<Order_By>;
  metadata?: Maybe<Order_By>;
  package_type?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  seller_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: WUI_Order */
export type Wui_Order_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Wui_Order_Prepend_Input = {
  items?: Maybe<Scalars['jsonb']>;
  metadata?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "WUI_Order" */
export enum Wui_Order_Select_Column {
  /** column name */
  BuyerId = 'buyer_id',
  /** column name */
  ClosedAt = 'closed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Items = 'items',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  PackageType = 'package_type',
  /** column name */
  Price = 'price',
  /** column name */
  SellerId = 'seller_id',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "WUI_Order" */
export type Wui_Order_Set_Input = {
  buyer_id?: Maybe<Scalars['bigint']>;
  closed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  items?: Maybe<Scalars['jsonb']>;
  metadata?: Maybe<Scalars['jsonb']>;
  package_type?: Maybe<Wui_SellerPackageType_Enum>;
  price?: Maybe<Scalars['numeric']>;
  seller_id?: Maybe<Scalars['bigint']>;
  status?: Maybe<Wui_OrderStatus_Enum>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Wui_Order_Stddev_Fields = {
  __typename?: 'WUI_Order_stddev_fields';
  buyer_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  seller_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Wui_Order_Stddev_Pop_Fields = {
  __typename?: 'WUI_Order_stddev_pop_fields';
  buyer_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  seller_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Wui_Order_Stddev_Samp_Fields = {
  __typename?: 'WUI_Order_stddev_samp_fields';
  buyer_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  seller_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Wui_Order_Sum_Fields = {
  __typename?: 'WUI_Order_sum_fields';
  buyer_id?: Maybe<Scalars['bigint']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['numeric']>;
  seller_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "WUI_Order" */
export enum Wui_Order_Update_Column {
  /** column name */
  BuyerId = 'buyer_id',
  /** column name */
  ClosedAt = 'closed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Items = 'items',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  PackageType = 'package_type',
  /** column name */
  Price = 'price',
  /** column name */
  SellerId = 'seller_id',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Wui_Order_Var_Pop_Fields = {
  __typename?: 'WUI_Order_var_pop_fields';
  buyer_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  seller_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Wui_Order_Var_Samp_Fields = {
  __typename?: 'WUI_Order_var_samp_fields';
  buyer_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  seller_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Wui_Order_Variance_Fields = {
  __typename?: 'WUI_Order_variance_fields';
  buyer_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  seller_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "WUI_Payment" */
export type Wui_Payment = {
  __typename?: 'WUI_Payment';
  /** An object relationship */
  WUI_Order: Wui_Order;
  /** An object relationship */
  WUI_PaymentStatus: Wui_PaymentStatus;
  buyer_fees: Scalars['numeric'];
  created_at: Scalars['timestamptz'];
  currency_code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  error_code?: Maybe<Scalars['String']>;
  error_msg?: Maybe<Scalars['String']>;
  external_id: Scalars['String'];
  id: Scalars['bigint'];
  metadata?: Maybe<Scalars['jsonb']>;
  order_id: Scalars['bigint'];
  price: Scalars['numeric'];
  seller_fees: Scalars['numeric'];
  statement_description?: Maybe<Scalars['String']>;
  status: Wui_PaymentStatus_Enum;
  transaction_fees: Scalars['numeric'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "WUI_Payment" */
export type Wui_PaymentMetadataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** columns and relationships of "WUI_PaymentMethod" */
export type Wui_PaymentMethod = {
  __typename?: 'WUI_PaymentMethod';
  /** An object relationship */
  WUI_PaymentMethodSource: Wui_PaymentMethodSource;
  /** An object relationship */
  WUI_User: Wui_User;
  id: Scalars['bigint'];
  source: Wui_PaymentMethodSource_Enum;
  user_id: Scalars['bigint'];
};

/** columns and relationships of "WUI_PaymentMethodBankAccount" */
export type Wui_PaymentMethodBankAccount = {
  __typename?: 'WUI_PaymentMethodBankAccount';
  id: Scalars['Int'];
  payment_method_id: Scalars['bigint'];
};

/** aggregated selection of "WUI_PaymentMethodBankAccount" */
export type Wui_PaymentMethodBankAccount_Aggregate = {
  __typename?: 'WUI_PaymentMethodBankAccount_aggregate';
  aggregate?: Maybe<Wui_PaymentMethodBankAccount_Aggregate_Fields>;
  nodes: Array<Wui_PaymentMethodBankAccount>;
};

/** aggregate fields of "WUI_PaymentMethodBankAccount" */
export type Wui_PaymentMethodBankAccount_Aggregate_Fields = {
  __typename?: 'WUI_PaymentMethodBankAccount_aggregate_fields';
  avg?: Maybe<Wui_PaymentMethodBankAccount_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_PaymentMethodBankAccount_Max_Fields>;
  min?: Maybe<Wui_PaymentMethodBankAccount_Min_Fields>;
  stddev?: Maybe<Wui_PaymentMethodBankAccount_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_PaymentMethodBankAccount_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_PaymentMethodBankAccount_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_PaymentMethodBankAccount_Sum_Fields>;
  var_pop?: Maybe<Wui_PaymentMethodBankAccount_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_PaymentMethodBankAccount_Var_Samp_Fields>;
  variance?: Maybe<Wui_PaymentMethodBankAccount_Variance_Fields>;
};


/** aggregate fields of "WUI_PaymentMethodBankAccount" */
export type Wui_PaymentMethodBankAccount_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wui_PaymentMethodBankAccount_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Wui_PaymentMethodBankAccount_Avg_Fields = {
  __typename?: 'WUI_PaymentMethodBankAccount_avg_fields';
  id?: Maybe<Scalars['Float']>;
  payment_method_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "WUI_PaymentMethodBankAccount". All fields are combined with a logical 'AND'. */
export type Wui_PaymentMethodBankAccount_Bool_Exp = {
  _and?: Maybe<Array<Wui_PaymentMethodBankAccount_Bool_Exp>>;
  _not?: Maybe<Wui_PaymentMethodBankAccount_Bool_Exp>;
  _or?: Maybe<Array<Wui_PaymentMethodBankAccount_Bool_Exp>>;
  id?: Maybe<Int_Comparison_Exp>;
  payment_method_id?: Maybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_PaymentMethodBankAccount" */
export enum Wui_PaymentMethodBankAccount_Constraint {
  /** unique or primary key constraint */
  WuiPaymentMethodBankAccountPkey = 'WUI_PaymentMethodBankAccount_pkey'
}

/** input type for incrementing numeric columns in table "WUI_PaymentMethodBankAccount" */
export type Wui_PaymentMethodBankAccount_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  payment_method_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_PaymentMethodBankAccount" */
export type Wui_PaymentMethodBankAccount_Insert_Input = {
  id?: Maybe<Scalars['Int']>;
  payment_method_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Wui_PaymentMethodBankAccount_Max_Fields = {
  __typename?: 'WUI_PaymentMethodBankAccount_max_fields';
  id?: Maybe<Scalars['Int']>;
  payment_method_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Wui_PaymentMethodBankAccount_Min_Fields = {
  __typename?: 'WUI_PaymentMethodBankAccount_min_fields';
  id?: Maybe<Scalars['Int']>;
  payment_method_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "WUI_PaymentMethodBankAccount" */
export type Wui_PaymentMethodBankAccount_Mutation_Response = {
  __typename?: 'WUI_PaymentMethodBankAccount_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_PaymentMethodBankAccount>;
};

/** on conflict condition type for table "WUI_PaymentMethodBankAccount" */
export type Wui_PaymentMethodBankAccount_On_Conflict = {
  constraint: Wui_PaymentMethodBankAccount_Constraint;
  update_columns?: Array<Wui_PaymentMethodBankAccount_Update_Column>;
  where?: Maybe<Wui_PaymentMethodBankAccount_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_PaymentMethodBankAccount". */
export type Wui_PaymentMethodBankAccount_Order_By = {
  id?: Maybe<Order_By>;
  payment_method_id?: Maybe<Order_By>;
};

/** primary key columns input for table: WUI_PaymentMethodBankAccount */
export type Wui_PaymentMethodBankAccount_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "WUI_PaymentMethodBankAccount" */
export enum Wui_PaymentMethodBankAccount_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  PaymentMethodId = 'payment_method_id'
}

/** input type for updating data in table "WUI_PaymentMethodBankAccount" */
export type Wui_PaymentMethodBankAccount_Set_Input = {
  id?: Maybe<Scalars['Int']>;
  payment_method_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Wui_PaymentMethodBankAccount_Stddev_Fields = {
  __typename?: 'WUI_PaymentMethodBankAccount_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  payment_method_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Wui_PaymentMethodBankAccount_Stddev_Pop_Fields = {
  __typename?: 'WUI_PaymentMethodBankAccount_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  payment_method_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Wui_PaymentMethodBankAccount_Stddev_Samp_Fields = {
  __typename?: 'WUI_PaymentMethodBankAccount_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  payment_method_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Wui_PaymentMethodBankAccount_Sum_Fields = {
  __typename?: 'WUI_PaymentMethodBankAccount_sum_fields';
  id?: Maybe<Scalars['Int']>;
  payment_method_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "WUI_PaymentMethodBankAccount" */
export enum Wui_PaymentMethodBankAccount_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  PaymentMethodId = 'payment_method_id'
}

/** aggregate var_pop on columns */
export type Wui_PaymentMethodBankAccount_Var_Pop_Fields = {
  __typename?: 'WUI_PaymentMethodBankAccount_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  payment_method_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Wui_PaymentMethodBankAccount_Var_Samp_Fields = {
  __typename?: 'WUI_PaymentMethodBankAccount_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  payment_method_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Wui_PaymentMethodBankAccount_Variance_Fields = {
  __typename?: 'WUI_PaymentMethodBankAccount_variance_fields';
  id?: Maybe<Scalars['Float']>;
  payment_method_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "WUI_PaymentMethodCard" */
export type Wui_PaymentMethodCard = {
  __typename?: 'WUI_PaymentMethodCard';
  /** An object relationship */
  WUI_PaymentMethod: Wui_PaymentMethod;
  allow_3d_secure: Scalars['Boolean'];
  brand: Scalars['String'];
  country_code: Scalars['String'];
  created_at?: Maybe<Scalars['timestamptz']>;
  exp_month: Scalars['Int'];
  exp_year: Scalars['Int'];
  external_id: Scalars['String'];
  fingerprint: Scalars['String'];
  funding: Scalars['String'];
  id: Scalars['bigint'];
  last4: Scalars['String'];
  payment_method_id: Scalars['bigint'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "WUI_PaymentMethodCard" */
export type Wui_PaymentMethodCard_Aggregate = {
  __typename?: 'WUI_PaymentMethodCard_aggregate';
  aggregate?: Maybe<Wui_PaymentMethodCard_Aggregate_Fields>;
  nodes: Array<Wui_PaymentMethodCard>;
};

/** aggregate fields of "WUI_PaymentMethodCard" */
export type Wui_PaymentMethodCard_Aggregate_Fields = {
  __typename?: 'WUI_PaymentMethodCard_aggregate_fields';
  avg?: Maybe<Wui_PaymentMethodCard_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_PaymentMethodCard_Max_Fields>;
  min?: Maybe<Wui_PaymentMethodCard_Min_Fields>;
  stddev?: Maybe<Wui_PaymentMethodCard_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_PaymentMethodCard_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_PaymentMethodCard_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_PaymentMethodCard_Sum_Fields>;
  var_pop?: Maybe<Wui_PaymentMethodCard_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_PaymentMethodCard_Var_Samp_Fields>;
  variance?: Maybe<Wui_PaymentMethodCard_Variance_Fields>;
};


/** aggregate fields of "WUI_PaymentMethodCard" */
export type Wui_PaymentMethodCard_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wui_PaymentMethodCard_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Wui_PaymentMethodCard_Avg_Fields = {
  __typename?: 'WUI_PaymentMethodCard_avg_fields';
  exp_month?: Maybe<Scalars['Float']>;
  exp_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payment_method_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "WUI_PaymentMethodCard". All fields are combined with a logical 'AND'. */
export type Wui_PaymentMethodCard_Bool_Exp = {
  WUI_PaymentMethod?: Maybe<Wui_PaymentMethod_Bool_Exp>;
  _and?: Maybe<Array<Wui_PaymentMethodCard_Bool_Exp>>;
  _not?: Maybe<Wui_PaymentMethodCard_Bool_Exp>;
  _or?: Maybe<Array<Wui_PaymentMethodCard_Bool_Exp>>;
  allow_3d_secure?: Maybe<Boolean_Comparison_Exp>;
  brand?: Maybe<String_Comparison_Exp>;
  country_code?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  exp_month?: Maybe<Int_Comparison_Exp>;
  exp_year?: Maybe<Int_Comparison_Exp>;
  external_id?: Maybe<String_Comparison_Exp>;
  fingerprint?: Maybe<String_Comparison_Exp>;
  funding?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  last4?: Maybe<String_Comparison_Exp>;
  payment_method_id?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_PaymentMethodCard" */
export enum Wui_PaymentMethodCard_Constraint {
  /** unique or primary key constraint */
  WuiCardPkey = 'WUI_Card_pkey'
}

/** input type for incrementing numeric columns in table "WUI_PaymentMethodCard" */
export type Wui_PaymentMethodCard_Inc_Input = {
  exp_month?: Maybe<Scalars['Int']>;
  exp_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  payment_method_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_PaymentMethodCard" */
export type Wui_PaymentMethodCard_Insert_Input = {
  WUI_PaymentMethod?: Maybe<Wui_PaymentMethod_Obj_Rel_Insert_Input>;
  allow_3d_secure?: Maybe<Scalars['Boolean']>;
  brand?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  exp_month?: Maybe<Scalars['Int']>;
  exp_year?: Maybe<Scalars['Int']>;
  external_id?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  last4?: Maybe<Scalars['String']>;
  payment_method_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Wui_PaymentMethodCard_Max_Fields = {
  __typename?: 'WUI_PaymentMethodCard_max_fields';
  brand?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  exp_month?: Maybe<Scalars['Int']>;
  exp_year?: Maybe<Scalars['Int']>;
  external_id?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  last4?: Maybe<Scalars['String']>;
  payment_method_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Wui_PaymentMethodCard_Min_Fields = {
  __typename?: 'WUI_PaymentMethodCard_min_fields';
  brand?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  exp_month?: Maybe<Scalars['Int']>;
  exp_year?: Maybe<Scalars['Int']>;
  external_id?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  last4?: Maybe<Scalars['String']>;
  payment_method_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "WUI_PaymentMethodCard" */
export type Wui_PaymentMethodCard_Mutation_Response = {
  __typename?: 'WUI_PaymentMethodCard_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_PaymentMethodCard>;
};

/** on conflict condition type for table "WUI_PaymentMethodCard" */
export type Wui_PaymentMethodCard_On_Conflict = {
  constraint: Wui_PaymentMethodCard_Constraint;
  update_columns?: Array<Wui_PaymentMethodCard_Update_Column>;
  where?: Maybe<Wui_PaymentMethodCard_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_PaymentMethodCard". */
export type Wui_PaymentMethodCard_Order_By = {
  WUI_PaymentMethod?: Maybe<Wui_PaymentMethod_Order_By>;
  allow_3d_secure?: Maybe<Order_By>;
  brand?: Maybe<Order_By>;
  country_code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  exp_month?: Maybe<Order_By>;
  exp_year?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  fingerprint?: Maybe<Order_By>;
  funding?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last4?: Maybe<Order_By>;
  payment_method_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: WUI_PaymentMethodCard */
export type Wui_PaymentMethodCard_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "WUI_PaymentMethodCard" */
export enum Wui_PaymentMethodCard_Select_Column {
  /** column name */
  Allow_3dSecure = 'allow_3d_secure',
  /** column name */
  Brand = 'brand',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpMonth = 'exp_month',
  /** column name */
  ExpYear = 'exp_year',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Fingerprint = 'fingerprint',
  /** column name */
  Funding = 'funding',
  /** column name */
  Id = 'id',
  /** column name */
  Last4 = 'last4',
  /** column name */
  PaymentMethodId = 'payment_method_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "WUI_PaymentMethodCard" */
export type Wui_PaymentMethodCard_Set_Input = {
  allow_3d_secure?: Maybe<Scalars['Boolean']>;
  brand?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  exp_month?: Maybe<Scalars['Int']>;
  exp_year?: Maybe<Scalars['Int']>;
  external_id?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  last4?: Maybe<Scalars['String']>;
  payment_method_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Wui_PaymentMethodCard_Stddev_Fields = {
  __typename?: 'WUI_PaymentMethodCard_stddev_fields';
  exp_month?: Maybe<Scalars['Float']>;
  exp_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payment_method_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Wui_PaymentMethodCard_Stddev_Pop_Fields = {
  __typename?: 'WUI_PaymentMethodCard_stddev_pop_fields';
  exp_month?: Maybe<Scalars['Float']>;
  exp_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payment_method_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Wui_PaymentMethodCard_Stddev_Samp_Fields = {
  __typename?: 'WUI_PaymentMethodCard_stddev_samp_fields';
  exp_month?: Maybe<Scalars['Float']>;
  exp_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payment_method_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Wui_PaymentMethodCard_Sum_Fields = {
  __typename?: 'WUI_PaymentMethodCard_sum_fields';
  exp_month?: Maybe<Scalars['Int']>;
  exp_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  payment_method_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "WUI_PaymentMethodCard" */
export enum Wui_PaymentMethodCard_Update_Column {
  /** column name */
  Allow_3dSecure = 'allow_3d_secure',
  /** column name */
  Brand = 'brand',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpMonth = 'exp_month',
  /** column name */
  ExpYear = 'exp_year',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Fingerprint = 'fingerprint',
  /** column name */
  Funding = 'funding',
  /** column name */
  Id = 'id',
  /** column name */
  Last4 = 'last4',
  /** column name */
  PaymentMethodId = 'payment_method_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Wui_PaymentMethodCard_Var_Pop_Fields = {
  __typename?: 'WUI_PaymentMethodCard_var_pop_fields';
  exp_month?: Maybe<Scalars['Float']>;
  exp_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payment_method_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Wui_PaymentMethodCard_Var_Samp_Fields = {
  __typename?: 'WUI_PaymentMethodCard_var_samp_fields';
  exp_month?: Maybe<Scalars['Float']>;
  exp_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payment_method_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Wui_PaymentMethodCard_Variance_Fields = {
  __typename?: 'WUI_PaymentMethodCard_variance_fields';
  exp_month?: Maybe<Scalars['Float']>;
  exp_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payment_method_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "WUI_PaymentMethodSource" */
export type Wui_PaymentMethodSource = {
  __typename?: 'WUI_PaymentMethodSource';
  value: Scalars['String'];
};

/** aggregated selection of "WUI_PaymentMethodSource" */
export type Wui_PaymentMethodSource_Aggregate = {
  __typename?: 'WUI_PaymentMethodSource_aggregate';
  aggregate?: Maybe<Wui_PaymentMethodSource_Aggregate_Fields>;
  nodes: Array<Wui_PaymentMethodSource>;
};

/** aggregate fields of "WUI_PaymentMethodSource" */
export type Wui_PaymentMethodSource_Aggregate_Fields = {
  __typename?: 'WUI_PaymentMethodSource_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Wui_PaymentMethodSource_Max_Fields>;
  min?: Maybe<Wui_PaymentMethodSource_Min_Fields>;
};


/** aggregate fields of "WUI_PaymentMethodSource" */
export type Wui_PaymentMethodSource_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wui_PaymentMethodSource_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "WUI_PaymentMethodSource". All fields are combined with a logical 'AND'. */
export type Wui_PaymentMethodSource_Bool_Exp = {
  _and?: Maybe<Array<Wui_PaymentMethodSource_Bool_Exp>>;
  _not?: Maybe<Wui_PaymentMethodSource_Bool_Exp>;
  _or?: Maybe<Array<Wui_PaymentMethodSource_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_PaymentMethodSource" */
export enum Wui_PaymentMethodSource_Constraint {
  /** unique or primary key constraint */
  WuiPaymentMethodSourceEnumPkey = 'WUI_PaymentMethodSourceEnum_pkey'
}

export enum Wui_PaymentMethodSource_Enum {
  Stripe = 'stripe'
}

/** Boolean expression to compare columns of type "WUI_PaymentMethodSource_enum". All fields are combined with logical 'AND'. */
export type Wui_PaymentMethodSource_Enum_Comparison_Exp = {
  _eq?: Maybe<Wui_PaymentMethodSource_Enum>;
  _in?: Maybe<Array<Wui_PaymentMethodSource_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Wui_PaymentMethodSource_Enum>;
  _nin?: Maybe<Array<Wui_PaymentMethodSource_Enum>>;
};

/** input type for inserting data into table "WUI_PaymentMethodSource" */
export type Wui_PaymentMethodSource_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Wui_PaymentMethodSource_Max_Fields = {
  __typename?: 'WUI_PaymentMethodSource_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Wui_PaymentMethodSource_Min_Fields = {
  __typename?: 'WUI_PaymentMethodSource_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "WUI_PaymentMethodSource" */
export type Wui_PaymentMethodSource_Mutation_Response = {
  __typename?: 'WUI_PaymentMethodSource_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_PaymentMethodSource>;
};

/** input type for inserting object relation for remote table "WUI_PaymentMethodSource" */
export type Wui_PaymentMethodSource_Obj_Rel_Insert_Input = {
  data: Wui_PaymentMethodSource_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Wui_PaymentMethodSource_On_Conflict>;
};

/** on conflict condition type for table "WUI_PaymentMethodSource" */
export type Wui_PaymentMethodSource_On_Conflict = {
  constraint: Wui_PaymentMethodSource_Constraint;
  update_columns?: Array<Wui_PaymentMethodSource_Update_Column>;
  where?: Maybe<Wui_PaymentMethodSource_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_PaymentMethodSource". */
export type Wui_PaymentMethodSource_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: WUI_PaymentMethodSource */
export type Wui_PaymentMethodSource_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "WUI_PaymentMethodSource" */
export enum Wui_PaymentMethodSource_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "WUI_PaymentMethodSource" */
export type Wui_PaymentMethodSource_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "WUI_PaymentMethodSource" */
export enum Wui_PaymentMethodSource_Update_Column {
  /** column name */
  Value = 'value'
}

/** aggregated selection of "WUI_PaymentMethod" */
export type Wui_PaymentMethod_Aggregate = {
  __typename?: 'WUI_PaymentMethod_aggregate';
  aggregate?: Maybe<Wui_PaymentMethod_Aggregate_Fields>;
  nodes: Array<Wui_PaymentMethod>;
};

/** aggregate fields of "WUI_PaymentMethod" */
export type Wui_PaymentMethod_Aggregate_Fields = {
  __typename?: 'WUI_PaymentMethod_aggregate_fields';
  avg?: Maybe<Wui_PaymentMethod_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_PaymentMethod_Max_Fields>;
  min?: Maybe<Wui_PaymentMethod_Min_Fields>;
  stddev?: Maybe<Wui_PaymentMethod_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_PaymentMethod_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_PaymentMethod_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_PaymentMethod_Sum_Fields>;
  var_pop?: Maybe<Wui_PaymentMethod_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_PaymentMethod_Var_Samp_Fields>;
  variance?: Maybe<Wui_PaymentMethod_Variance_Fields>;
};


/** aggregate fields of "WUI_PaymentMethod" */
export type Wui_PaymentMethod_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wui_PaymentMethod_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Wui_PaymentMethod_Avg_Fields = {
  __typename?: 'WUI_PaymentMethod_avg_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "WUI_PaymentMethod". All fields are combined with a logical 'AND'. */
export type Wui_PaymentMethod_Bool_Exp = {
  WUI_PaymentMethodSource?: Maybe<Wui_PaymentMethodSource_Bool_Exp>;
  WUI_User?: Maybe<Wui_User_Bool_Exp>;
  _and?: Maybe<Array<Wui_PaymentMethod_Bool_Exp>>;
  _not?: Maybe<Wui_PaymentMethod_Bool_Exp>;
  _or?: Maybe<Array<Wui_PaymentMethod_Bool_Exp>>;
  id?: Maybe<Bigint_Comparison_Exp>;
  source?: Maybe<Wui_PaymentMethodSource_Enum_Comparison_Exp>;
  user_id?: Maybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_PaymentMethod" */
export enum Wui_PaymentMethod_Constraint {
  /** unique or primary key constraint */
  WuiPaymentMethodPkey = 'WUI_PaymentMethod_pkey'
}

/** input type for incrementing numeric columns in table "WUI_PaymentMethod" */
export type Wui_PaymentMethod_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_PaymentMethod" */
export type Wui_PaymentMethod_Insert_Input = {
  WUI_PaymentMethodSource?: Maybe<Wui_PaymentMethodSource_Obj_Rel_Insert_Input>;
  WUI_User?: Maybe<Wui_User_Obj_Rel_Insert_Input>;
  id?: Maybe<Scalars['bigint']>;
  source?: Maybe<Wui_PaymentMethodSource_Enum>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Wui_PaymentMethod_Max_Fields = {
  __typename?: 'WUI_PaymentMethod_max_fields';
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Wui_PaymentMethod_Min_Fields = {
  __typename?: 'WUI_PaymentMethod_min_fields';
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "WUI_PaymentMethod" */
export type Wui_PaymentMethod_Mutation_Response = {
  __typename?: 'WUI_PaymentMethod_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_PaymentMethod>;
};

/** input type for inserting object relation for remote table "WUI_PaymentMethod" */
export type Wui_PaymentMethod_Obj_Rel_Insert_Input = {
  data: Wui_PaymentMethod_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Wui_PaymentMethod_On_Conflict>;
};

/** on conflict condition type for table "WUI_PaymentMethod" */
export type Wui_PaymentMethod_On_Conflict = {
  constraint: Wui_PaymentMethod_Constraint;
  update_columns?: Array<Wui_PaymentMethod_Update_Column>;
  where?: Maybe<Wui_PaymentMethod_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_PaymentMethod". */
export type Wui_PaymentMethod_Order_By = {
  WUI_PaymentMethodSource?: Maybe<Wui_PaymentMethodSource_Order_By>;
  WUI_User?: Maybe<Wui_User_Order_By>;
  id?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: WUI_PaymentMethod */
export type Wui_PaymentMethod_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "WUI_PaymentMethod" */
export enum Wui_PaymentMethod_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Source = 'source',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "WUI_PaymentMethod" */
export type Wui_PaymentMethod_Set_Input = {
  id?: Maybe<Scalars['bigint']>;
  source?: Maybe<Wui_PaymentMethodSource_Enum>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Wui_PaymentMethod_Stddev_Fields = {
  __typename?: 'WUI_PaymentMethod_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Wui_PaymentMethod_Stddev_Pop_Fields = {
  __typename?: 'WUI_PaymentMethod_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Wui_PaymentMethod_Stddev_Samp_Fields = {
  __typename?: 'WUI_PaymentMethod_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Wui_PaymentMethod_Sum_Fields = {
  __typename?: 'WUI_PaymentMethod_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "WUI_PaymentMethod" */
export enum Wui_PaymentMethod_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Source = 'source',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Wui_PaymentMethod_Var_Pop_Fields = {
  __typename?: 'WUI_PaymentMethod_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Wui_PaymentMethod_Var_Samp_Fields = {
  __typename?: 'WUI_PaymentMethod_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Wui_PaymentMethod_Variance_Fields = {
  __typename?: 'WUI_PaymentMethod_variance_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "WUI_PaymentStatus" */
export type Wui_PaymentStatus = {
  __typename?: 'WUI_PaymentStatus';
  value: Scalars['String'];
};

/** aggregated selection of "WUI_PaymentStatus" */
export type Wui_PaymentStatus_Aggregate = {
  __typename?: 'WUI_PaymentStatus_aggregate';
  aggregate?: Maybe<Wui_PaymentStatus_Aggregate_Fields>;
  nodes: Array<Wui_PaymentStatus>;
};

/** aggregate fields of "WUI_PaymentStatus" */
export type Wui_PaymentStatus_Aggregate_Fields = {
  __typename?: 'WUI_PaymentStatus_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Wui_PaymentStatus_Max_Fields>;
  min?: Maybe<Wui_PaymentStatus_Min_Fields>;
};


/** aggregate fields of "WUI_PaymentStatus" */
export type Wui_PaymentStatus_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wui_PaymentStatus_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "WUI_PaymentStatus". All fields are combined with a logical 'AND'. */
export type Wui_PaymentStatus_Bool_Exp = {
  _and?: Maybe<Array<Wui_PaymentStatus_Bool_Exp>>;
  _not?: Maybe<Wui_PaymentStatus_Bool_Exp>;
  _or?: Maybe<Array<Wui_PaymentStatus_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_PaymentStatus" */
export enum Wui_PaymentStatus_Constraint {
  /** unique or primary key constraint */
  WuiPaymentStatusPkey = 'WUI_PaymentStatus_pkey'
}

export enum Wui_PaymentStatus_Enum {
  Captured = 'captured',
  Declined = 'declined',
  Pending = 'pending',
  Refunded = 'refunded',
  Uncaptured = 'uncaptured'
}

/** Boolean expression to compare columns of type "WUI_PaymentStatus_enum". All fields are combined with logical 'AND'. */
export type Wui_PaymentStatus_Enum_Comparison_Exp = {
  _eq?: Maybe<Wui_PaymentStatus_Enum>;
  _in?: Maybe<Array<Wui_PaymentStatus_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Wui_PaymentStatus_Enum>;
  _nin?: Maybe<Array<Wui_PaymentStatus_Enum>>;
};

/** input type for inserting data into table "WUI_PaymentStatus" */
export type Wui_PaymentStatus_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Wui_PaymentStatus_Max_Fields = {
  __typename?: 'WUI_PaymentStatus_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Wui_PaymentStatus_Min_Fields = {
  __typename?: 'WUI_PaymentStatus_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "WUI_PaymentStatus" */
export type Wui_PaymentStatus_Mutation_Response = {
  __typename?: 'WUI_PaymentStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_PaymentStatus>;
};

/** input type for inserting object relation for remote table "WUI_PaymentStatus" */
export type Wui_PaymentStatus_Obj_Rel_Insert_Input = {
  data: Wui_PaymentStatus_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Wui_PaymentStatus_On_Conflict>;
};

/** on conflict condition type for table "WUI_PaymentStatus" */
export type Wui_PaymentStatus_On_Conflict = {
  constraint: Wui_PaymentStatus_Constraint;
  update_columns?: Array<Wui_PaymentStatus_Update_Column>;
  where?: Maybe<Wui_PaymentStatus_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_PaymentStatus". */
export type Wui_PaymentStatus_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: WUI_PaymentStatus */
export type Wui_PaymentStatus_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "WUI_PaymentStatus" */
export enum Wui_PaymentStatus_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "WUI_PaymentStatus" */
export type Wui_PaymentStatus_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "WUI_PaymentStatus" */
export enum Wui_PaymentStatus_Update_Column {
  /** column name */
  Value = 'value'
}

/** aggregated selection of "WUI_Payment" */
export type Wui_Payment_Aggregate = {
  __typename?: 'WUI_Payment_aggregate';
  aggregate?: Maybe<Wui_Payment_Aggregate_Fields>;
  nodes: Array<Wui_Payment>;
};

/** aggregate fields of "WUI_Payment" */
export type Wui_Payment_Aggregate_Fields = {
  __typename?: 'WUI_Payment_aggregate_fields';
  avg?: Maybe<Wui_Payment_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_Payment_Max_Fields>;
  min?: Maybe<Wui_Payment_Min_Fields>;
  stddev?: Maybe<Wui_Payment_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_Payment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_Payment_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_Payment_Sum_Fields>;
  var_pop?: Maybe<Wui_Payment_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_Payment_Var_Samp_Fields>;
  variance?: Maybe<Wui_Payment_Variance_Fields>;
};


/** aggregate fields of "WUI_Payment" */
export type Wui_Payment_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wui_Payment_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Wui_Payment_Append_Input = {
  metadata?: Maybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Wui_Payment_Avg_Fields = {
  __typename?: 'WUI_Payment_avg_fields';
  buyer_fees?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  seller_fees?: Maybe<Scalars['Float']>;
  transaction_fees?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "WUI_Payment". All fields are combined with a logical 'AND'. */
export type Wui_Payment_Bool_Exp = {
  WUI_Order?: Maybe<Wui_Order_Bool_Exp>;
  WUI_PaymentStatus?: Maybe<Wui_PaymentStatus_Bool_Exp>;
  _and?: Maybe<Array<Wui_Payment_Bool_Exp>>;
  _not?: Maybe<Wui_Payment_Bool_Exp>;
  _or?: Maybe<Array<Wui_Payment_Bool_Exp>>;
  buyer_fees?: Maybe<Numeric_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  currency_code?: Maybe<String_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  error_code?: Maybe<String_Comparison_Exp>;
  error_msg?: Maybe<String_Comparison_Exp>;
  external_id?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  metadata?: Maybe<Jsonb_Comparison_Exp>;
  order_id?: Maybe<Bigint_Comparison_Exp>;
  price?: Maybe<Numeric_Comparison_Exp>;
  seller_fees?: Maybe<Numeric_Comparison_Exp>;
  statement_description?: Maybe<String_Comparison_Exp>;
  status?: Maybe<Wui_PaymentStatus_Enum_Comparison_Exp>;
  transaction_fees?: Maybe<Numeric_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_Payment" */
export enum Wui_Payment_Constraint {
  /** unique or primary key constraint */
  WuiPaymentPkey = 'WUI_Payment_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Wui_Payment_Delete_At_Path_Input = {
  metadata?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Wui_Payment_Delete_Elem_Input = {
  metadata?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Wui_Payment_Delete_Key_Input = {
  metadata?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "WUI_Payment" */
export type Wui_Payment_Inc_Input = {
  buyer_fees?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  order_id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['numeric']>;
  seller_fees?: Maybe<Scalars['numeric']>;
  transaction_fees?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "WUI_Payment" */
export type Wui_Payment_Insert_Input = {
  WUI_Order?: Maybe<Wui_Order_Obj_Rel_Insert_Input>;
  WUI_PaymentStatus?: Maybe<Wui_PaymentStatus_Obj_Rel_Insert_Input>;
  buyer_fees?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency_code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  error_code?: Maybe<Scalars['String']>;
  error_msg?: Maybe<Scalars['String']>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  metadata?: Maybe<Scalars['jsonb']>;
  order_id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['numeric']>;
  seller_fees?: Maybe<Scalars['numeric']>;
  statement_description?: Maybe<Scalars['String']>;
  status?: Maybe<Wui_PaymentStatus_Enum>;
  transaction_fees?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Wui_Payment_Max_Fields = {
  __typename?: 'WUI_Payment_max_fields';
  buyer_fees?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency_code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  error_code?: Maybe<Scalars['String']>;
  error_msg?: Maybe<Scalars['String']>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  order_id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['numeric']>;
  seller_fees?: Maybe<Scalars['numeric']>;
  statement_description?: Maybe<Scalars['String']>;
  transaction_fees?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Wui_Payment_Min_Fields = {
  __typename?: 'WUI_Payment_min_fields';
  buyer_fees?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency_code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  error_code?: Maybe<Scalars['String']>;
  error_msg?: Maybe<Scalars['String']>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  order_id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['numeric']>;
  seller_fees?: Maybe<Scalars['numeric']>;
  statement_description?: Maybe<Scalars['String']>;
  transaction_fees?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "WUI_Payment" */
export type Wui_Payment_Mutation_Response = {
  __typename?: 'WUI_Payment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_Payment>;
};

/** on conflict condition type for table "WUI_Payment" */
export type Wui_Payment_On_Conflict = {
  constraint: Wui_Payment_Constraint;
  update_columns?: Array<Wui_Payment_Update_Column>;
  where?: Maybe<Wui_Payment_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_Payment". */
export type Wui_Payment_Order_By = {
  WUI_Order?: Maybe<Wui_Order_Order_By>;
  WUI_PaymentStatus?: Maybe<Wui_PaymentStatus_Order_By>;
  buyer_fees?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  currency_code?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  error_code?: Maybe<Order_By>;
  error_msg?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  metadata?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  seller_fees?: Maybe<Order_By>;
  statement_description?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  transaction_fees?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: WUI_Payment */
export type Wui_Payment_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Wui_Payment_Prepend_Input = {
  metadata?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "WUI_Payment" */
export enum Wui_Payment_Select_Column {
  /** column name */
  BuyerFees = 'buyer_fees',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrencyCode = 'currency_code',
  /** column name */
  Description = 'description',
  /** column name */
  ErrorCode = 'error_code',
  /** column name */
  ErrorMsg = 'error_msg',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  Price = 'price',
  /** column name */
  SellerFees = 'seller_fees',
  /** column name */
  StatementDescription = 'statement_description',
  /** column name */
  Status = 'status',
  /** column name */
  TransactionFees = 'transaction_fees',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "WUI_Payment" */
export type Wui_Payment_Set_Input = {
  buyer_fees?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency_code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  error_code?: Maybe<Scalars['String']>;
  error_msg?: Maybe<Scalars['String']>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  metadata?: Maybe<Scalars['jsonb']>;
  order_id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['numeric']>;
  seller_fees?: Maybe<Scalars['numeric']>;
  statement_description?: Maybe<Scalars['String']>;
  status?: Maybe<Wui_PaymentStatus_Enum>;
  transaction_fees?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Wui_Payment_Stddev_Fields = {
  __typename?: 'WUI_Payment_stddev_fields';
  buyer_fees?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  seller_fees?: Maybe<Scalars['Float']>;
  transaction_fees?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Wui_Payment_Stddev_Pop_Fields = {
  __typename?: 'WUI_Payment_stddev_pop_fields';
  buyer_fees?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  seller_fees?: Maybe<Scalars['Float']>;
  transaction_fees?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Wui_Payment_Stddev_Samp_Fields = {
  __typename?: 'WUI_Payment_stddev_samp_fields';
  buyer_fees?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  seller_fees?: Maybe<Scalars['Float']>;
  transaction_fees?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Wui_Payment_Sum_Fields = {
  __typename?: 'WUI_Payment_sum_fields';
  buyer_fees?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  order_id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['numeric']>;
  seller_fees?: Maybe<Scalars['numeric']>;
  transaction_fees?: Maybe<Scalars['numeric']>;
};

/** update columns of table "WUI_Payment" */
export enum Wui_Payment_Update_Column {
  /** column name */
  BuyerFees = 'buyer_fees',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrencyCode = 'currency_code',
  /** column name */
  Description = 'description',
  /** column name */
  ErrorCode = 'error_code',
  /** column name */
  ErrorMsg = 'error_msg',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  Price = 'price',
  /** column name */
  SellerFees = 'seller_fees',
  /** column name */
  StatementDescription = 'statement_description',
  /** column name */
  Status = 'status',
  /** column name */
  TransactionFees = 'transaction_fees',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Wui_Payment_Var_Pop_Fields = {
  __typename?: 'WUI_Payment_var_pop_fields';
  buyer_fees?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  seller_fees?: Maybe<Scalars['Float']>;
  transaction_fees?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Wui_Payment_Var_Samp_Fields = {
  __typename?: 'WUI_Payment_var_samp_fields';
  buyer_fees?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  seller_fees?: Maybe<Scalars['Float']>;
  transaction_fees?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Wui_Payment_Variance_Fields = {
  __typename?: 'WUI_Payment_variance_fields';
  buyer_fees?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  seller_fees?: Maybe<Scalars['Float']>;
  transaction_fees?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "WUI_Role" */
export type Wui_Role = {
  __typename?: 'WUI_Role';
  id: Scalars['Int'];
  value: Scalars['String'];
};

/** aggregated selection of "WUI_Role" */
export type Wui_Role_Aggregate = {
  __typename?: 'WUI_Role_aggregate';
  aggregate?: Maybe<Wui_Role_Aggregate_Fields>;
  nodes: Array<Wui_Role>;
};

/** aggregate fields of "WUI_Role" */
export type Wui_Role_Aggregate_Fields = {
  __typename?: 'WUI_Role_aggregate_fields';
  avg?: Maybe<Wui_Role_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_Role_Max_Fields>;
  min?: Maybe<Wui_Role_Min_Fields>;
  stddev?: Maybe<Wui_Role_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_Role_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_Role_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_Role_Sum_Fields>;
  var_pop?: Maybe<Wui_Role_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_Role_Var_Samp_Fields>;
  variance?: Maybe<Wui_Role_Variance_Fields>;
};


/** aggregate fields of "WUI_Role" */
export type Wui_Role_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wui_Role_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Wui_Role_Avg_Fields = {
  __typename?: 'WUI_Role_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "WUI_Role". All fields are combined with a logical 'AND'. */
export type Wui_Role_Bool_Exp = {
  _and?: Maybe<Array<Wui_Role_Bool_Exp>>;
  _not?: Maybe<Wui_Role_Bool_Exp>;
  _or?: Maybe<Array<Wui_Role_Bool_Exp>>;
  id?: Maybe<Int_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_Role" */
export enum Wui_Role_Constraint {
  /** unique or primary key constraint */
  WuiRolePkey = 'WUI_Role_pkey',
  /** unique or primary key constraint */
  WuiRoleValueKey = 'WUI_Role_value_key'
}

/** input type for incrementing numeric columns in table "WUI_Role" */
export type Wui_Role_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "WUI_Role" */
export type Wui_Role_Insert_Input = {
  id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Wui_Role_Max_Fields = {
  __typename?: 'WUI_Role_max_fields';
  id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Wui_Role_Min_Fields = {
  __typename?: 'WUI_Role_min_fields';
  id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "WUI_Role" */
export type Wui_Role_Mutation_Response = {
  __typename?: 'WUI_Role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_Role>;
};

/** on conflict condition type for table "WUI_Role" */
export type Wui_Role_On_Conflict = {
  constraint: Wui_Role_Constraint;
  update_columns?: Array<Wui_Role_Update_Column>;
  where?: Maybe<Wui_Role_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_Role". */
export type Wui_Role_Order_By = {
  id?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: WUI_Role */
export type Wui_Role_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "WUI_Role" */
export enum Wui_Role_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "WUI_Role" */
export type Wui_Role_Set_Input = {
  id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Wui_Role_Stddev_Fields = {
  __typename?: 'WUI_Role_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Wui_Role_Stddev_Pop_Fields = {
  __typename?: 'WUI_Role_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Wui_Role_Stddev_Samp_Fields = {
  __typename?: 'WUI_Role_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Wui_Role_Sum_Fields = {
  __typename?: 'WUI_Role_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "WUI_Role" */
export enum Wui_Role_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Value = 'value'
}

/** aggregate var_pop on columns */
export type Wui_Role_Var_Pop_Fields = {
  __typename?: 'WUI_Role_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Wui_Role_Var_Samp_Fields = {
  __typename?: 'WUI_Role_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Wui_Role_Variance_Fields = {
  __typename?: 'WUI_Role_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "WUI_SellerPackage" */
export type Wui_SellerPackage = {
  __typename?: 'WUI_SellerPackage';
  /** An array relationship */
  WUI_SellerPackageItems: Array<Wui_SellerPackageItem>;
  /** An aggregate relationship */
  WUI_SellerPackageItems_aggregate: Wui_SellerPackageItem_Aggregate;
  /** An object relationship */
  WUI_User: Wui_User;
  description: Scalars['String'];
  enabled: Scalars['Boolean'];
  id: Scalars['bigint'];
  metadata?: Maybe<Scalars['jsonb']>;
  package_type: Wui_SellerPackageType_Enum;
  price: Scalars['numeric'];
  title?: Maybe<Scalars['String']>;
  user_id: Scalars['bigint'];
};


/** columns and relationships of "WUI_SellerPackage" */
export type Wui_SellerPackageWui_SellerPackageItemsArgs = {
  distinct_on?: Maybe<Array<Wui_SellerPackageItem_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_SellerPackageItem_Order_By>>;
  where?: Maybe<Wui_SellerPackageItem_Bool_Exp>;
};


/** columns and relationships of "WUI_SellerPackage" */
export type Wui_SellerPackageWui_SellerPackageItems_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_SellerPackageItem_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_SellerPackageItem_Order_By>>;
  where?: Maybe<Wui_SellerPackageItem_Bool_Exp>;
};


/** columns and relationships of "WUI_SellerPackage" */
export type Wui_SellerPackageMetadataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** columns and relationships of "WUI_SellerPackageItem" */
export type Wui_SellerPackageItem = {
  __typename?: 'WUI_SellerPackageItem';
  /** An object relationship */
  WUI_SellerPackage: Wui_SellerPackage;
  comment?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  quantity?: Maybe<Scalars['Int']>;
  seller_package_id: Scalars['bigint'];
  title?: Maybe<Scalars['String']>;
};

/** aggregated selection of "WUI_SellerPackageItem" */
export type Wui_SellerPackageItem_Aggregate = {
  __typename?: 'WUI_SellerPackageItem_aggregate';
  aggregate?: Maybe<Wui_SellerPackageItem_Aggregate_Fields>;
  nodes: Array<Wui_SellerPackageItem>;
};

/** aggregate fields of "WUI_SellerPackageItem" */
export type Wui_SellerPackageItem_Aggregate_Fields = {
  __typename?: 'WUI_SellerPackageItem_aggregate_fields';
  avg?: Maybe<Wui_SellerPackageItem_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_SellerPackageItem_Max_Fields>;
  min?: Maybe<Wui_SellerPackageItem_Min_Fields>;
  stddev?: Maybe<Wui_SellerPackageItem_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_SellerPackageItem_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_SellerPackageItem_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_SellerPackageItem_Sum_Fields>;
  var_pop?: Maybe<Wui_SellerPackageItem_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_SellerPackageItem_Var_Samp_Fields>;
  variance?: Maybe<Wui_SellerPackageItem_Variance_Fields>;
};


/** aggregate fields of "WUI_SellerPackageItem" */
export type Wui_SellerPackageItem_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wui_SellerPackageItem_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "WUI_SellerPackageItem" */
export type Wui_SellerPackageItem_Aggregate_Order_By = {
  avg?: Maybe<Wui_SellerPackageItem_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Wui_SellerPackageItem_Max_Order_By>;
  min?: Maybe<Wui_SellerPackageItem_Min_Order_By>;
  stddev?: Maybe<Wui_SellerPackageItem_Stddev_Order_By>;
  stddev_pop?: Maybe<Wui_SellerPackageItem_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Wui_SellerPackageItem_Stddev_Samp_Order_By>;
  sum?: Maybe<Wui_SellerPackageItem_Sum_Order_By>;
  var_pop?: Maybe<Wui_SellerPackageItem_Var_Pop_Order_By>;
  var_samp?: Maybe<Wui_SellerPackageItem_Var_Samp_Order_By>;
  variance?: Maybe<Wui_SellerPackageItem_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "WUI_SellerPackageItem" */
export type Wui_SellerPackageItem_Arr_Rel_Insert_Input = {
  data: Array<Wui_SellerPackageItem_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Wui_SellerPackageItem_On_Conflict>;
};

/** aggregate avg on columns */
export type Wui_SellerPackageItem_Avg_Fields = {
  __typename?: 'WUI_SellerPackageItem_avg_fields';
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  seller_package_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "WUI_SellerPackageItem" */
export type Wui_SellerPackageItem_Avg_Order_By = {
  id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  seller_package_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "WUI_SellerPackageItem". All fields are combined with a logical 'AND'. */
export type Wui_SellerPackageItem_Bool_Exp = {
  WUI_SellerPackage?: Maybe<Wui_SellerPackage_Bool_Exp>;
  _and?: Maybe<Array<Wui_SellerPackageItem_Bool_Exp>>;
  _not?: Maybe<Wui_SellerPackageItem_Bool_Exp>;
  _or?: Maybe<Array<Wui_SellerPackageItem_Bool_Exp>>;
  comment?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  quantity?: Maybe<Int_Comparison_Exp>;
  seller_package_id?: Maybe<Bigint_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_SellerPackageItem" */
export enum Wui_SellerPackageItem_Constraint {
  /** unique or primary key constraint */
  WuiSellerPackageItemPkey = 'WUI_SellerPackageItem_pkey'
}

/** input type for incrementing numeric columns in table "WUI_SellerPackageItem" */
export type Wui_SellerPackageItem_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['Int']>;
  seller_package_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_SellerPackageItem" */
export type Wui_SellerPackageItem_Insert_Input = {
  WUI_SellerPackage?: Maybe<Wui_SellerPackage_Obj_Rel_Insert_Input>;
  comment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['Int']>;
  seller_package_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Wui_SellerPackageItem_Max_Fields = {
  __typename?: 'WUI_SellerPackageItem_max_fields';
  comment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['Int']>;
  seller_package_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "WUI_SellerPackageItem" */
export type Wui_SellerPackageItem_Max_Order_By = {
  comment?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  seller_package_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Wui_SellerPackageItem_Min_Fields = {
  __typename?: 'WUI_SellerPackageItem_min_fields';
  comment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['Int']>;
  seller_package_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "WUI_SellerPackageItem" */
export type Wui_SellerPackageItem_Min_Order_By = {
  comment?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  seller_package_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** response of any mutation on the table "WUI_SellerPackageItem" */
export type Wui_SellerPackageItem_Mutation_Response = {
  __typename?: 'WUI_SellerPackageItem_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_SellerPackageItem>;
};

/** on conflict condition type for table "WUI_SellerPackageItem" */
export type Wui_SellerPackageItem_On_Conflict = {
  constraint: Wui_SellerPackageItem_Constraint;
  update_columns?: Array<Wui_SellerPackageItem_Update_Column>;
  where?: Maybe<Wui_SellerPackageItem_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_SellerPackageItem". */
export type Wui_SellerPackageItem_Order_By = {
  WUI_SellerPackage?: Maybe<Wui_SellerPackage_Order_By>;
  comment?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  seller_package_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** primary key columns input for table: WUI_SellerPackageItem */
export type Wui_SellerPackageItem_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "WUI_SellerPackageItem" */
export enum Wui_SellerPackageItem_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Id = 'id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  SellerPackageId = 'seller_package_id',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "WUI_SellerPackageItem" */
export type Wui_SellerPackageItem_Set_Input = {
  comment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['Int']>;
  seller_package_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Wui_SellerPackageItem_Stddev_Fields = {
  __typename?: 'WUI_SellerPackageItem_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  seller_package_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "WUI_SellerPackageItem" */
export type Wui_SellerPackageItem_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  seller_package_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Wui_SellerPackageItem_Stddev_Pop_Fields = {
  __typename?: 'WUI_SellerPackageItem_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  seller_package_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "WUI_SellerPackageItem" */
export type Wui_SellerPackageItem_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  seller_package_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Wui_SellerPackageItem_Stddev_Samp_Fields = {
  __typename?: 'WUI_SellerPackageItem_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  seller_package_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "WUI_SellerPackageItem" */
export type Wui_SellerPackageItem_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  seller_package_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Wui_SellerPackageItem_Sum_Fields = {
  __typename?: 'WUI_SellerPackageItem_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['Int']>;
  seller_package_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "WUI_SellerPackageItem" */
export type Wui_SellerPackageItem_Sum_Order_By = {
  id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  seller_package_id?: Maybe<Order_By>;
};

/** update columns of table "WUI_SellerPackageItem" */
export enum Wui_SellerPackageItem_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Id = 'id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  SellerPackageId = 'seller_package_id',
  /** column name */
  Title = 'title'
}

/** aggregate var_pop on columns */
export type Wui_SellerPackageItem_Var_Pop_Fields = {
  __typename?: 'WUI_SellerPackageItem_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  seller_package_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "WUI_SellerPackageItem" */
export type Wui_SellerPackageItem_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  seller_package_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Wui_SellerPackageItem_Var_Samp_Fields = {
  __typename?: 'WUI_SellerPackageItem_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  seller_package_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "WUI_SellerPackageItem" */
export type Wui_SellerPackageItem_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  seller_package_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Wui_SellerPackageItem_Variance_Fields = {
  __typename?: 'WUI_SellerPackageItem_variance_fields';
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  seller_package_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "WUI_SellerPackageItem" */
export type Wui_SellerPackageItem_Variance_Order_By = {
  id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  seller_package_id?: Maybe<Order_By>;
};

/** columns and relationships of "WUI_SellerPackageType" */
export type Wui_SellerPackageType = {
  __typename?: 'WUI_SellerPackageType';
  value: Scalars['String'];
};

/** aggregated selection of "WUI_SellerPackageType" */
export type Wui_SellerPackageType_Aggregate = {
  __typename?: 'WUI_SellerPackageType_aggregate';
  aggregate?: Maybe<Wui_SellerPackageType_Aggregate_Fields>;
  nodes: Array<Wui_SellerPackageType>;
};

/** aggregate fields of "WUI_SellerPackageType" */
export type Wui_SellerPackageType_Aggregate_Fields = {
  __typename?: 'WUI_SellerPackageType_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Wui_SellerPackageType_Max_Fields>;
  min?: Maybe<Wui_SellerPackageType_Min_Fields>;
};


/** aggregate fields of "WUI_SellerPackageType" */
export type Wui_SellerPackageType_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wui_SellerPackageType_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "WUI_SellerPackageType". All fields are combined with a logical 'AND'. */
export type Wui_SellerPackageType_Bool_Exp = {
  _and?: Maybe<Array<Wui_SellerPackageType_Bool_Exp>>;
  _not?: Maybe<Wui_SellerPackageType_Bool_Exp>;
  _or?: Maybe<Array<Wui_SellerPackageType_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_SellerPackageType" */
export enum Wui_SellerPackageType_Constraint {
  /** unique or primary key constraint */
  WuiSellerPackageTypeEnumPkey = 'WUI_SellerPackageTypeEnum_pkey'
}

export enum Wui_SellerPackageType_Enum {
  Basic = 'basic',
  Custom = 'custom',
  Monthly = 'monthly',
  Standard = 'standard'
}

/** Boolean expression to compare columns of type "WUI_SellerPackageType_enum". All fields are combined with logical 'AND'. */
export type Wui_SellerPackageType_Enum_Comparison_Exp = {
  _eq?: Maybe<Wui_SellerPackageType_Enum>;
  _in?: Maybe<Array<Wui_SellerPackageType_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Wui_SellerPackageType_Enum>;
  _nin?: Maybe<Array<Wui_SellerPackageType_Enum>>;
};

/** input type for inserting data into table "WUI_SellerPackageType" */
export type Wui_SellerPackageType_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Wui_SellerPackageType_Max_Fields = {
  __typename?: 'WUI_SellerPackageType_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Wui_SellerPackageType_Min_Fields = {
  __typename?: 'WUI_SellerPackageType_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "WUI_SellerPackageType" */
export type Wui_SellerPackageType_Mutation_Response = {
  __typename?: 'WUI_SellerPackageType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_SellerPackageType>;
};

/** on conflict condition type for table "WUI_SellerPackageType" */
export type Wui_SellerPackageType_On_Conflict = {
  constraint: Wui_SellerPackageType_Constraint;
  update_columns?: Array<Wui_SellerPackageType_Update_Column>;
  where?: Maybe<Wui_SellerPackageType_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_SellerPackageType". */
export type Wui_SellerPackageType_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: WUI_SellerPackageType */
export type Wui_SellerPackageType_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "WUI_SellerPackageType" */
export enum Wui_SellerPackageType_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "WUI_SellerPackageType" */
export type Wui_SellerPackageType_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "WUI_SellerPackageType" */
export enum Wui_SellerPackageType_Update_Column {
  /** column name */
  Value = 'value'
}

/** aggregated selection of "WUI_SellerPackage" */
export type Wui_SellerPackage_Aggregate = {
  __typename?: 'WUI_SellerPackage_aggregate';
  aggregate?: Maybe<Wui_SellerPackage_Aggregate_Fields>;
  nodes: Array<Wui_SellerPackage>;
};

/** aggregate fields of "WUI_SellerPackage" */
export type Wui_SellerPackage_Aggregate_Fields = {
  __typename?: 'WUI_SellerPackage_aggregate_fields';
  avg?: Maybe<Wui_SellerPackage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_SellerPackage_Max_Fields>;
  min?: Maybe<Wui_SellerPackage_Min_Fields>;
  stddev?: Maybe<Wui_SellerPackage_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_SellerPackage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_SellerPackage_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_SellerPackage_Sum_Fields>;
  var_pop?: Maybe<Wui_SellerPackage_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_SellerPackage_Var_Samp_Fields>;
  variance?: Maybe<Wui_SellerPackage_Variance_Fields>;
};


/** aggregate fields of "WUI_SellerPackage" */
export type Wui_SellerPackage_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wui_SellerPackage_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "WUI_SellerPackage" */
export type Wui_SellerPackage_Aggregate_Order_By = {
  avg?: Maybe<Wui_SellerPackage_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Wui_SellerPackage_Max_Order_By>;
  min?: Maybe<Wui_SellerPackage_Min_Order_By>;
  stddev?: Maybe<Wui_SellerPackage_Stddev_Order_By>;
  stddev_pop?: Maybe<Wui_SellerPackage_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Wui_SellerPackage_Stddev_Samp_Order_By>;
  sum?: Maybe<Wui_SellerPackage_Sum_Order_By>;
  var_pop?: Maybe<Wui_SellerPackage_Var_Pop_Order_By>;
  var_samp?: Maybe<Wui_SellerPackage_Var_Samp_Order_By>;
  variance?: Maybe<Wui_SellerPackage_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Wui_SellerPackage_Append_Input = {
  metadata?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "WUI_SellerPackage" */
export type Wui_SellerPackage_Arr_Rel_Insert_Input = {
  data: Array<Wui_SellerPackage_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Wui_SellerPackage_On_Conflict>;
};

/** aggregate avg on columns */
export type Wui_SellerPackage_Avg_Fields = {
  __typename?: 'WUI_SellerPackage_avg_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "WUI_SellerPackage" */
export type Wui_SellerPackage_Avg_Order_By = {
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "WUI_SellerPackage". All fields are combined with a logical 'AND'. */
export type Wui_SellerPackage_Bool_Exp = {
  WUI_SellerPackageItems?: Maybe<Wui_SellerPackageItem_Bool_Exp>;
  WUI_User?: Maybe<Wui_User_Bool_Exp>;
  _and?: Maybe<Array<Wui_SellerPackage_Bool_Exp>>;
  _not?: Maybe<Wui_SellerPackage_Bool_Exp>;
  _or?: Maybe<Array<Wui_SellerPackage_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  enabled?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  metadata?: Maybe<Jsonb_Comparison_Exp>;
  package_type?: Maybe<Wui_SellerPackageType_Enum_Comparison_Exp>;
  price?: Maybe<Numeric_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  user_id?: Maybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_SellerPackage" */
export enum Wui_SellerPackage_Constraint {
  /** unique or primary key constraint */
  WuiSellerPackagePkey = 'WUI_SellerPackage_pkey',
  /** unique or primary key constraint */
  WuiSellerPackageUserIdPackageTypeKey = 'WUI_SellerPackage_user_id_package_type_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Wui_SellerPackage_Delete_At_Path_Input = {
  metadata?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Wui_SellerPackage_Delete_Elem_Input = {
  metadata?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Wui_SellerPackage_Delete_Key_Input = {
  metadata?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "WUI_SellerPackage" */
export type Wui_SellerPackage_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['numeric']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_SellerPackage" */
export type Wui_SellerPackage_Insert_Input = {
  WUI_SellerPackageItems?: Maybe<Wui_SellerPackageItem_Arr_Rel_Insert_Input>;
  WUI_User?: Maybe<Wui_User_Obj_Rel_Insert_Input>;
  description?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  metadata?: Maybe<Scalars['jsonb']>;
  package_type?: Maybe<Wui_SellerPackageType_Enum>;
  price?: Maybe<Scalars['numeric']>;
  title?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Wui_SellerPackage_Max_Fields = {
  __typename?: 'WUI_SellerPackage_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['numeric']>;
  title?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "WUI_SellerPackage" */
export type Wui_SellerPackage_Max_Order_By = {
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Wui_SellerPackage_Min_Fields = {
  __typename?: 'WUI_SellerPackage_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['numeric']>;
  title?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "WUI_SellerPackage" */
export type Wui_SellerPackage_Min_Order_By = {
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "WUI_SellerPackage" */
export type Wui_SellerPackage_Mutation_Response = {
  __typename?: 'WUI_SellerPackage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_SellerPackage>;
};

/** input type for inserting object relation for remote table "WUI_SellerPackage" */
export type Wui_SellerPackage_Obj_Rel_Insert_Input = {
  data: Wui_SellerPackage_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Wui_SellerPackage_On_Conflict>;
};

/** on conflict condition type for table "WUI_SellerPackage" */
export type Wui_SellerPackage_On_Conflict = {
  constraint: Wui_SellerPackage_Constraint;
  update_columns?: Array<Wui_SellerPackage_Update_Column>;
  where?: Maybe<Wui_SellerPackage_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_SellerPackage". */
export type Wui_SellerPackage_Order_By = {
  WUI_SellerPackageItems_aggregate?: Maybe<Wui_SellerPackageItem_Aggregate_Order_By>;
  WUI_User?: Maybe<Wui_User_Order_By>;
  description?: Maybe<Order_By>;
  enabled?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  metadata?: Maybe<Order_By>;
  package_type?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: WUI_SellerPackage */
export type Wui_SellerPackage_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Wui_SellerPackage_Prepend_Input = {
  metadata?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "WUI_SellerPackage" */
export enum Wui_SellerPackage_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  PackageType = 'package_type',
  /** column name */
  Price = 'price',
  /** column name */
  Title = 'title',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "WUI_SellerPackage" */
export type Wui_SellerPackage_Set_Input = {
  description?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  metadata?: Maybe<Scalars['jsonb']>;
  package_type?: Maybe<Wui_SellerPackageType_Enum>;
  price?: Maybe<Scalars['numeric']>;
  title?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Wui_SellerPackage_Stddev_Fields = {
  __typename?: 'WUI_SellerPackage_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "WUI_SellerPackage" */
export type Wui_SellerPackage_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Wui_SellerPackage_Stddev_Pop_Fields = {
  __typename?: 'WUI_SellerPackage_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "WUI_SellerPackage" */
export type Wui_SellerPackage_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Wui_SellerPackage_Stddev_Samp_Fields = {
  __typename?: 'WUI_SellerPackage_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "WUI_SellerPackage" */
export type Wui_SellerPackage_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Wui_SellerPackage_Sum_Fields = {
  __typename?: 'WUI_SellerPackage_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['numeric']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "WUI_SellerPackage" */
export type Wui_SellerPackage_Sum_Order_By = {
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** update columns of table "WUI_SellerPackage" */
export enum Wui_SellerPackage_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  PackageType = 'package_type',
  /** column name */
  Price = 'price',
  /** column name */
  Title = 'title',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Wui_SellerPackage_Var_Pop_Fields = {
  __typename?: 'WUI_SellerPackage_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "WUI_SellerPackage" */
export type Wui_SellerPackage_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Wui_SellerPackage_Var_Samp_Fields = {
  __typename?: 'WUI_SellerPackage_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "WUI_SellerPackage" */
export type Wui_SellerPackage_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Wui_SellerPackage_Variance_Fields = {
  __typename?: 'WUI_SellerPackage_variance_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "WUI_SellerPackage" */
export type Wui_SellerPackage_Variance_Order_By = {
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** columns and relationships of "WUI_SellerProject" */
export type Wui_SellerProject = {
  __typename?: 'WUI_SellerProject';
  /** An array relationship */
  WUI_SellerProjectFiles: Array<Wui_SellerProjectFile>;
  /** An aggregate relationship */
  WUI_SellerProjectFiles_aggregate: Wui_SellerProjectFile_Aggregate;
  /** An array relationship */
  WUI_SellerProjectItems: Array<Wui_SellerProjectItem>;
  /** An aggregate relationship */
  WUI_SellerProjectItems_aggregate: Wui_SellerProjectItem_Aggregate;
  /** An object relationship */
  WUI_User: Wui_User;
  /** An array relationship */
  demographics: Array<ProjectDemographic>;
  /** An aggregate relationship */
  demographics_aggregate: ProjectDemographic_Aggregate;
  description: Scalars['String'];
  enabled: Scalars['Boolean'];
  id: Scalars['bigint'];
  price: Scalars['numeric'];
  title: Scalars['String'];
  user_id: Scalars['bigint'];
};


/** columns and relationships of "WUI_SellerProject" */
export type Wui_SellerProjectWui_SellerProjectFilesArgs = {
  distinct_on?: Maybe<Array<Wui_SellerProjectFile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_SellerProjectFile_Order_By>>;
  where?: Maybe<Wui_SellerProjectFile_Bool_Exp>;
};


/** columns and relationships of "WUI_SellerProject" */
export type Wui_SellerProjectWui_SellerProjectFiles_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_SellerProjectFile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_SellerProjectFile_Order_By>>;
  where?: Maybe<Wui_SellerProjectFile_Bool_Exp>;
};


/** columns and relationships of "WUI_SellerProject" */
export type Wui_SellerProjectWui_SellerProjectItemsArgs = {
  distinct_on?: Maybe<Array<Wui_SellerProjectItem_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_SellerProjectItem_Order_By>>;
  where?: Maybe<Wui_SellerProjectItem_Bool_Exp>;
};


/** columns and relationships of "WUI_SellerProject" */
export type Wui_SellerProjectWui_SellerProjectItems_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_SellerProjectItem_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_SellerProjectItem_Order_By>>;
  where?: Maybe<Wui_SellerProjectItem_Bool_Exp>;
};


/** columns and relationships of "WUI_SellerProject" */
export type Wui_SellerProjectDemographicsArgs = {
  distinct_on?: Maybe<Array<ProjectDemographic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectDemographic_Order_By>>;
  where?: Maybe<ProjectDemographic_Bool_Exp>;
};


/** columns and relationships of "WUI_SellerProject" */
export type Wui_SellerProjectDemographics_AggregateArgs = {
  distinct_on?: Maybe<Array<ProjectDemographic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectDemographic_Order_By>>;
  where?: Maybe<ProjectDemographic_Bool_Exp>;
};

/** columns and relationships of "WUI_SellerProjectFile" */
export type Wui_SellerProjectFile = {
  __typename?: 'WUI_SellerProjectFile';
  /** An object relationship */
  WUI_File: Wui_File;
  created_at: Scalars['timestamptz'];
  file_id: Scalars['bigint'];
  id: Scalars['Int'];
  project_id?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "WUI_SellerProjectFile" */
export type Wui_SellerProjectFile_Aggregate = {
  __typename?: 'WUI_SellerProjectFile_aggregate';
  aggregate?: Maybe<Wui_SellerProjectFile_Aggregate_Fields>;
  nodes: Array<Wui_SellerProjectFile>;
};

/** aggregate fields of "WUI_SellerProjectFile" */
export type Wui_SellerProjectFile_Aggregate_Fields = {
  __typename?: 'WUI_SellerProjectFile_aggregate_fields';
  avg?: Maybe<Wui_SellerProjectFile_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_SellerProjectFile_Max_Fields>;
  min?: Maybe<Wui_SellerProjectFile_Min_Fields>;
  stddev?: Maybe<Wui_SellerProjectFile_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_SellerProjectFile_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_SellerProjectFile_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_SellerProjectFile_Sum_Fields>;
  var_pop?: Maybe<Wui_SellerProjectFile_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_SellerProjectFile_Var_Samp_Fields>;
  variance?: Maybe<Wui_SellerProjectFile_Variance_Fields>;
};


/** aggregate fields of "WUI_SellerProjectFile" */
export type Wui_SellerProjectFile_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wui_SellerProjectFile_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "WUI_SellerProjectFile" */
export type Wui_SellerProjectFile_Aggregate_Order_By = {
  avg?: Maybe<Wui_SellerProjectFile_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Wui_SellerProjectFile_Max_Order_By>;
  min?: Maybe<Wui_SellerProjectFile_Min_Order_By>;
  stddev?: Maybe<Wui_SellerProjectFile_Stddev_Order_By>;
  stddev_pop?: Maybe<Wui_SellerProjectFile_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Wui_SellerProjectFile_Stddev_Samp_Order_By>;
  sum?: Maybe<Wui_SellerProjectFile_Sum_Order_By>;
  var_pop?: Maybe<Wui_SellerProjectFile_Var_Pop_Order_By>;
  var_samp?: Maybe<Wui_SellerProjectFile_Var_Samp_Order_By>;
  variance?: Maybe<Wui_SellerProjectFile_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "WUI_SellerProjectFile" */
export type Wui_SellerProjectFile_Arr_Rel_Insert_Input = {
  data: Array<Wui_SellerProjectFile_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Wui_SellerProjectFile_On_Conflict>;
};

/** aggregate avg on columns */
export type Wui_SellerProjectFile_Avg_Fields = {
  __typename?: 'WUI_SellerProjectFile_avg_fields';
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "WUI_SellerProjectFile" */
export type Wui_SellerProjectFile_Avg_Order_By = {
  file_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "WUI_SellerProjectFile". All fields are combined with a logical 'AND'. */
export type Wui_SellerProjectFile_Bool_Exp = {
  WUI_File?: Maybe<Wui_File_Bool_Exp>;
  _and?: Maybe<Array<Wui_SellerProjectFile_Bool_Exp>>;
  _not?: Maybe<Wui_SellerProjectFile_Bool_Exp>;
  _or?: Maybe<Array<Wui_SellerProjectFile_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  file_id?: Maybe<Bigint_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  project_id?: Maybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_SellerProjectFile" */
export enum Wui_SellerProjectFile_Constraint {
  /** unique or primary key constraint */
  ProjectFilePkey = 'ProjectFile_pkey'
}

/** input type for incrementing numeric columns in table "WUI_SellerProjectFile" */
export type Wui_SellerProjectFile_Inc_Input = {
  file_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_SellerProjectFile" */
export type Wui_SellerProjectFile_Insert_Input = {
  WUI_File?: Maybe<Wui_File_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Wui_SellerProjectFile_Max_Fields = {
  __typename?: 'WUI_SellerProjectFile_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "WUI_SellerProjectFile" */
export type Wui_SellerProjectFile_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  file_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Wui_SellerProjectFile_Min_Fields = {
  __typename?: 'WUI_SellerProjectFile_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "WUI_SellerProjectFile" */
export type Wui_SellerProjectFile_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  file_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "WUI_SellerProjectFile" */
export type Wui_SellerProjectFile_Mutation_Response = {
  __typename?: 'WUI_SellerProjectFile_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_SellerProjectFile>;
};

/** on conflict condition type for table "WUI_SellerProjectFile" */
export type Wui_SellerProjectFile_On_Conflict = {
  constraint: Wui_SellerProjectFile_Constraint;
  update_columns?: Array<Wui_SellerProjectFile_Update_Column>;
  where?: Maybe<Wui_SellerProjectFile_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_SellerProjectFile". */
export type Wui_SellerProjectFile_Order_By = {
  WUI_File?: Maybe<Wui_File_Order_By>;
  created_at?: Maybe<Order_By>;
  file_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
};

/** primary key columns input for table: WUI_SellerProjectFile */
export type Wui_SellerProjectFile_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "WUI_SellerProjectFile" */
export enum Wui_SellerProjectFile_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id'
}

/** input type for updating data in table "WUI_SellerProjectFile" */
export type Wui_SellerProjectFile_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Wui_SellerProjectFile_Stddev_Fields = {
  __typename?: 'WUI_SellerProjectFile_stddev_fields';
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "WUI_SellerProjectFile" */
export type Wui_SellerProjectFile_Stddev_Order_By = {
  file_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Wui_SellerProjectFile_Stddev_Pop_Fields = {
  __typename?: 'WUI_SellerProjectFile_stddev_pop_fields';
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "WUI_SellerProjectFile" */
export type Wui_SellerProjectFile_Stddev_Pop_Order_By = {
  file_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Wui_SellerProjectFile_Stddev_Samp_Fields = {
  __typename?: 'WUI_SellerProjectFile_stddev_samp_fields';
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "WUI_SellerProjectFile" */
export type Wui_SellerProjectFile_Stddev_Samp_Order_By = {
  file_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Wui_SellerProjectFile_Sum_Fields = {
  __typename?: 'WUI_SellerProjectFile_sum_fields';
  file_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "WUI_SellerProjectFile" */
export type Wui_SellerProjectFile_Sum_Order_By = {
  file_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
};

/** update columns of table "WUI_SellerProjectFile" */
export enum Wui_SellerProjectFile_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id'
}

/** aggregate var_pop on columns */
export type Wui_SellerProjectFile_Var_Pop_Fields = {
  __typename?: 'WUI_SellerProjectFile_var_pop_fields';
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "WUI_SellerProjectFile" */
export type Wui_SellerProjectFile_Var_Pop_Order_By = {
  file_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Wui_SellerProjectFile_Var_Samp_Fields = {
  __typename?: 'WUI_SellerProjectFile_var_samp_fields';
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "WUI_SellerProjectFile" */
export type Wui_SellerProjectFile_Var_Samp_Order_By = {
  file_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Wui_SellerProjectFile_Variance_Fields = {
  __typename?: 'WUI_SellerProjectFile_variance_fields';
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "WUI_SellerProjectFile" */
export type Wui_SellerProjectFile_Variance_Order_By = {
  file_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
};

/** columns and relationships of "WUI_SellerProjectItem" */
export type Wui_SellerProjectItem = {
  __typename?: 'WUI_SellerProjectItem';
  /** An object relationship */
  WUI_SellerProject: Wui_SellerProject;
  details: Scalars['String'];
  id: Scalars['bigint'];
  seller_project_id: Scalars['bigint'];
};

/** aggregated selection of "WUI_SellerProjectItem" */
export type Wui_SellerProjectItem_Aggregate = {
  __typename?: 'WUI_SellerProjectItem_aggregate';
  aggregate?: Maybe<Wui_SellerProjectItem_Aggregate_Fields>;
  nodes: Array<Wui_SellerProjectItem>;
};

/** aggregate fields of "WUI_SellerProjectItem" */
export type Wui_SellerProjectItem_Aggregate_Fields = {
  __typename?: 'WUI_SellerProjectItem_aggregate_fields';
  avg?: Maybe<Wui_SellerProjectItem_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_SellerProjectItem_Max_Fields>;
  min?: Maybe<Wui_SellerProjectItem_Min_Fields>;
  stddev?: Maybe<Wui_SellerProjectItem_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_SellerProjectItem_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_SellerProjectItem_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_SellerProjectItem_Sum_Fields>;
  var_pop?: Maybe<Wui_SellerProjectItem_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_SellerProjectItem_Var_Samp_Fields>;
  variance?: Maybe<Wui_SellerProjectItem_Variance_Fields>;
};


/** aggregate fields of "WUI_SellerProjectItem" */
export type Wui_SellerProjectItem_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wui_SellerProjectItem_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "WUI_SellerProjectItem" */
export type Wui_SellerProjectItem_Aggregate_Order_By = {
  avg?: Maybe<Wui_SellerProjectItem_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Wui_SellerProjectItem_Max_Order_By>;
  min?: Maybe<Wui_SellerProjectItem_Min_Order_By>;
  stddev?: Maybe<Wui_SellerProjectItem_Stddev_Order_By>;
  stddev_pop?: Maybe<Wui_SellerProjectItem_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Wui_SellerProjectItem_Stddev_Samp_Order_By>;
  sum?: Maybe<Wui_SellerProjectItem_Sum_Order_By>;
  var_pop?: Maybe<Wui_SellerProjectItem_Var_Pop_Order_By>;
  var_samp?: Maybe<Wui_SellerProjectItem_Var_Samp_Order_By>;
  variance?: Maybe<Wui_SellerProjectItem_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "WUI_SellerProjectItem" */
export type Wui_SellerProjectItem_Arr_Rel_Insert_Input = {
  data: Array<Wui_SellerProjectItem_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Wui_SellerProjectItem_On_Conflict>;
};

/** aggregate avg on columns */
export type Wui_SellerProjectItem_Avg_Fields = {
  __typename?: 'WUI_SellerProjectItem_avg_fields';
  id?: Maybe<Scalars['Float']>;
  seller_project_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "WUI_SellerProjectItem" */
export type Wui_SellerProjectItem_Avg_Order_By = {
  id?: Maybe<Order_By>;
  seller_project_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "WUI_SellerProjectItem". All fields are combined with a logical 'AND'. */
export type Wui_SellerProjectItem_Bool_Exp = {
  WUI_SellerProject?: Maybe<Wui_SellerProject_Bool_Exp>;
  _and?: Maybe<Array<Wui_SellerProjectItem_Bool_Exp>>;
  _not?: Maybe<Wui_SellerProjectItem_Bool_Exp>;
  _or?: Maybe<Array<Wui_SellerProjectItem_Bool_Exp>>;
  details?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  seller_project_id?: Maybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_SellerProjectItem" */
export enum Wui_SellerProjectItem_Constraint {
  /** unique or primary key constraint */
  WuiSellerProjectItemPkey = 'WUI_SellerProjectItem_pkey'
}

/** input type for incrementing numeric columns in table "WUI_SellerProjectItem" */
export type Wui_SellerProjectItem_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  seller_project_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_SellerProjectItem" */
export type Wui_SellerProjectItem_Insert_Input = {
  WUI_SellerProject?: Maybe<Wui_SellerProject_Obj_Rel_Insert_Input>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  seller_project_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Wui_SellerProjectItem_Max_Fields = {
  __typename?: 'WUI_SellerProjectItem_max_fields';
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  seller_project_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "WUI_SellerProjectItem" */
export type Wui_SellerProjectItem_Max_Order_By = {
  details?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  seller_project_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Wui_SellerProjectItem_Min_Fields = {
  __typename?: 'WUI_SellerProjectItem_min_fields';
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  seller_project_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "WUI_SellerProjectItem" */
export type Wui_SellerProjectItem_Min_Order_By = {
  details?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  seller_project_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "WUI_SellerProjectItem" */
export type Wui_SellerProjectItem_Mutation_Response = {
  __typename?: 'WUI_SellerProjectItem_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_SellerProjectItem>;
};

/** on conflict condition type for table "WUI_SellerProjectItem" */
export type Wui_SellerProjectItem_On_Conflict = {
  constraint: Wui_SellerProjectItem_Constraint;
  update_columns?: Array<Wui_SellerProjectItem_Update_Column>;
  where?: Maybe<Wui_SellerProjectItem_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_SellerProjectItem". */
export type Wui_SellerProjectItem_Order_By = {
  WUI_SellerProject?: Maybe<Wui_SellerProject_Order_By>;
  details?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  seller_project_id?: Maybe<Order_By>;
};

/** primary key columns input for table: WUI_SellerProjectItem */
export type Wui_SellerProjectItem_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "WUI_SellerProjectItem" */
export enum Wui_SellerProjectItem_Select_Column {
  /** column name */
  Details = 'details',
  /** column name */
  Id = 'id',
  /** column name */
  SellerProjectId = 'seller_project_id'
}

/** input type for updating data in table "WUI_SellerProjectItem" */
export type Wui_SellerProjectItem_Set_Input = {
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  seller_project_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Wui_SellerProjectItem_Stddev_Fields = {
  __typename?: 'WUI_SellerProjectItem_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  seller_project_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "WUI_SellerProjectItem" */
export type Wui_SellerProjectItem_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  seller_project_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Wui_SellerProjectItem_Stddev_Pop_Fields = {
  __typename?: 'WUI_SellerProjectItem_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  seller_project_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "WUI_SellerProjectItem" */
export type Wui_SellerProjectItem_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  seller_project_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Wui_SellerProjectItem_Stddev_Samp_Fields = {
  __typename?: 'WUI_SellerProjectItem_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  seller_project_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "WUI_SellerProjectItem" */
export type Wui_SellerProjectItem_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  seller_project_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Wui_SellerProjectItem_Sum_Fields = {
  __typename?: 'WUI_SellerProjectItem_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  seller_project_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "WUI_SellerProjectItem" */
export type Wui_SellerProjectItem_Sum_Order_By = {
  id?: Maybe<Order_By>;
  seller_project_id?: Maybe<Order_By>;
};

/** update columns of table "WUI_SellerProjectItem" */
export enum Wui_SellerProjectItem_Update_Column {
  /** column name */
  Details = 'details',
  /** column name */
  Id = 'id',
  /** column name */
  SellerProjectId = 'seller_project_id'
}

/** aggregate var_pop on columns */
export type Wui_SellerProjectItem_Var_Pop_Fields = {
  __typename?: 'WUI_SellerProjectItem_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  seller_project_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "WUI_SellerProjectItem" */
export type Wui_SellerProjectItem_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  seller_project_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Wui_SellerProjectItem_Var_Samp_Fields = {
  __typename?: 'WUI_SellerProjectItem_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  seller_project_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "WUI_SellerProjectItem" */
export type Wui_SellerProjectItem_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  seller_project_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Wui_SellerProjectItem_Variance_Fields = {
  __typename?: 'WUI_SellerProjectItem_variance_fields';
  id?: Maybe<Scalars['Float']>;
  seller_project_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "WUI_SellerProjectItem" */
export type Wui_SellerProjectItem_Variance_Order_By = {
  id?: Maybe<Order_By>;
  seller_project_id?: Maybe<Order_By>;
};

/** aggregated selection of "WUI_SellerProject" */
export type Wui_SellerProject_Aggregate = {
  __typename?: 'WUI_SellerProject_aggregate';
  aggregate?: Maybe<Wui_SellerProject_Aggregate_Fields>;
  nodes: Array<Wui_SellerProject>;
};

/** aggregate fields of "WUI_SellerProject" */
export type Wui_SellerProject_Aggregate_Fields = {
  __typename?: 'WUI_SellerProject_aggregate_fields';
  avg?: Maybe<Wui_SellerProject_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_SellerProject_Max_Fields>;
  min?: Maybe<Wui_SellerProject_Min_Fields>;
  stddev?: Maybe<Wui_SellerProject_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_SellerProject_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_SellerProject_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_SellerProject_Sum_Fields>;
  var_pop?: Maybe<Wui_SellerProject_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_SellerProject_Var_Samp_Fields>;
  variance?: Maybe<Wui_SellerProject_Variance_Fields>;
};


/** aggregate fields of "WUI_SellerProject" */
export type Wui_SellerProject_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wui_SellerProject_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Wui_SellerProject_Avg_Fields = {
  __typename?: 'WUI_SellerProject_avg_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "WUI_SellerProject". All fields are combined with a logical 'AND'. */
export type Wui_SellerProject_Bool_Exp = {
  WUI_SellerProjectFiles?: Maybe<Wui_SellerProjectFile_Bool_Exp>;
  WUI_SellerProjectItems?: Maybe<Wui_SellerProjectItem_Bool_Exp>;
  WUI_User?: Maybe<Wui_User_Bool_Exp>;
  _and?: Maybe<Array<Wui_SellerProject_Bool_Exp>>;
  _not?: Maybe<Wui_SellerProject_Bool_Exp>;
  _or?: Maybe<Array<Wui_SellerProject_Bool_Exp>>;
  demographics?: Maybe<ProjectDemographic_Bool_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  enabled?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  price?: Maybe<Numeric_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  user_id?: Maybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_SellerProject" */
export enum Wui_SellerProject_Constraint {
  /** unique or primary key constraint */
  WuiSellerProjectPkey = 'WUI_SellerProject_pkey'
}

/** input type for incrementing numeric columns in table "WUI_SellerProject" */
export type Wui_SellerProject_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['numeric']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_SellerProject" */
export type Wui_SellerProject_Insert_Input = {
  WUI_SellerProjectFiles?: Maybe<Wui_SellerProjectFile_Arr_Rel_Insert_Input>;
  WUI_SellerProjectItems?: Maybe<Wui_SellerProjectItem_Arr_Rel_Insert_Input>;
  WUI_User?: Maybe<Wui_User_Obj_Rel_Insert_Input>;
  demographics?: Maybe<ProjectDemographic_Arr_Rel_Insert_Input>;
  description?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['numeric']>;
  title?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Wui_SellerProject_Max_Fields = {
  __typename?: 'WUI_SellerProject_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['numeric']>;
  title?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Wui_SellerProject_Min_Fields = {
  __typename?: 'WUI_SellerProject_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['numeric']>;
  title?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "WUI_SellerProject" */
export type Wui_SellerProject_Mutation_Response = {
  __typename?: 'WUI_SellerProject_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_SellerProject>;
};

/** input type for inserting object relation for remote table "WUI_SellerProject" */
export type Wui_SellerProject_Obj_Rel_Insert_Input = {
  data: Wui_SellerProject_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Wui_SellerProject_On_Conflict>;
};

/** on conflict condition type for table "WUI_SellerProject" */
export type Wui_SellerProject_On_Conflict = {
  constraint: Wui_SellerProject_Constraint;
  update_columns?: Array<Wui_SellerProject_Update_Column>;
  where?: Maybe<Wui_SellerProject_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_SellerProject". */
export type Wui_SellerProject_Order_By = {
  WUI_SellerProjectFiles_aggregate?: Maybe<Wui_SellerProjectFile_Aggregate_Order_By>;
  WUI_SellerProjectItems_aggregate?: Maybe<Wui_SellerProjectItem_Aggregate_Order_By>;
  WUI_User?: Maybe<Wui_User_Order_By>;
  demographics_aggregate?: Maybe<ProjectDemographic_Aggregate_Order_By>;
  description?: Maybe<Order_By>;
  enabled?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: WUI_SellerProject */
export type Wui_SellerProject_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "WUI_SellerProject" */
export enum Wui_SellerProject_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  Id = 'id',
  /** column name */
  Price = 'price',
  /** column name */
  Title = 'title',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "WUI_SellerProject" */
export type Wui_SellerProject_Set_Input = {
  description?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['numeric']>;
  title?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Wui_SellerProject_Stddev_Fields = {
  __typename?: 'WUI_SellerProject_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Wui_SellerProject_Stddev_Pop_Fields = {
  __typename?: 'WUI_SellerProject_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Wui_SellerProject_Stddev_Samp_Fields = {
  __typename?: 'WUI_SellerProject_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Wui_SellerProject_Sum_Fields = {
  __typename?: 'WUI_SellerProject_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['numeric']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "WUI_SellerProject" */
export enum Wui_SellerProject_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  Id = 'id',
  /** column name */
  Price = 'price',
  /** column name */
  Title = 'title',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Wui_SellerProject_Var_Pop_Fields = {
  __typename?: 'WUI_SellerProject_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Wui_SellerProject_Var_Samp_Fields = {
  __typename?: 'WUI_SellerProject_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Wui_SellerProject_Variance_Fields = {
  __typename?: 'WUI_SellerProject_variance_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "WUI_Session" */
export type Wui_Session = {
  __typename?: 'WUI_Session';
  id: Scalars['bigint'];
  session_id: Scalars['String'];
  user_id?: Maybe<Scalars['bigint']>;
};

/** columns and relationships of "WUI_SessionEvent" */
export type Wui_SessionEvent = {
  __typename?: 'WUI_SessionEvent';
  /** An object relationship */
  SocialMediaLink?: Maybe<SocialMediaLink>;
  /** An object relationship */
  WUI_Session: Wui_Session;
  /** An object relationship */
  WUI_SessionEventType: Wui_SessionEventType;
  /** An object relationship */
  WUI_User: Wui_User;
  created_at: Scalars['timestamptz'];
  event_type: Wui_SessionEventType_Enum;
  id: Scalars['bigint'];
  link_id?: Maybe<Scalars['bigint']>;
  seller_id: Scalars['bigint'];
  session_id: Scalars['bigint'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "WUI_SessionEventType" */
export type Wui_SessionEventType = {
  __typename?: 'WUI_SessionEventType';
  value: Scalars['String'];
};

/** aggregated selection of "WUI_SessionEventType" */
export type Wui_SessionEventType_Aggregate = {
  __typename?: 'WUI_SessionEventType_aggregate';
  aggregate?: Maybe<Wui_SessionEventType_Aggregate_Fields>;
  nodes: Array<Wui_SessionEventType>;
};

/** aggregate fields of "WUI_SessionEventType" */
export type Wui_SessionEventType_Aggregate_Fields = {
  __typename?: 'WUI_SessionEventType_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Wui_SessionEventType_Max_Fields>;
  min?: Maybe<Wui_SessionEventType_Min_Fields>;
};


/** aggregate fields of "WUI_SessionEventType" */
export type Wui_SessionEventType_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wui_SessionEventType_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "WUI_SessionEventType". All fields are combined with a logical 'AND'. */
export type Wui_SessionEventType_Bool_Exp = {
  _and?: Maybe<Array<Wui_SessionEventType_Bool_Exp>>;
  _not?: Maybe<Wui_SessionEventType_Bool_Exp>;
  _or?: Maybe<Array<Wui_SessionEventType_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_SessionEventType" */
export enum Wui_SessionEventType_Constraint {
  /** unique or primary key constraint */
  WuiSessionEventTypePkey = 'WUI_SessionEventType_pkey'
}

export enum Wui_SessionEventType_Enum {
  Click = 'click',
  PageView = 'page_view'
}

/** Boolean expression to compare columns of type "WUI_SessionEventType_enum". All fields are combined with logical 'AND'. */
export type Wui_SessionEventType_Enum_Comparison_Exp = {
  _eq?: Maybe<Wui_SessionEventType_Enum>;
  _in?: Maybe<Array<Wui_SessionEventType_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Wui_SessionEventType_Enum>;
  _nin?: Maybe<Array<Wui_SessionEventType_Enum>>;
};

/** input type for inserting data into table "WUI_SessionEventType" */
export type Wui_SessionEventType_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Wui_SessionEventType_Max_Fields = {
  __typename?: 'WUI_SessionEventType_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Wui_SessionEventType_Min_Fields = {
  __typename?: 'WUI_SessionEventType_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "WUI_SessionEventType" */
export type Wui_SessionEventType_Mutation_Response = {
  __typename?: 'WUI_SessionEventType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_SessionEventType>;
};

/** input type for inserting object relation for remote table "WUI_SessionEventType" */
export type Wui_SessionEventType_Obj_Rel_Insert_Input = {
  data: Wui_SessionEventType_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Wui_SessionEventType_On_Conflict>;
};

/** on conflict condition type for table "WUI_SessionEventType" */
export type Wui_SessionEventType_On_Conflict = {
  constraint: Wui_SessionEventType_Constraint;
  update_columns?: Array<Wui_SessionEventType_Update_Column>;
  where?: Maybe<Wui_SessionEventType_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_SessionEventType". */
export type Wui_SessionEventType_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: WUI_SessionEventType */
export type Wui_SessionEventType_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "WUI_SessionEventType" */
export enum Wui_SessionEventType_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "WUI_SessionEventType" */
export type Wui_SessionEventType_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "WUI_SessionEventType" */
export enum Wui_SessionEventType_Update_Column {
  /** column name */
  Value = 'value'
}

/** aggregated selection of "WUI_SessionEvent" */
export type Wui_SessionEvent_Aggregate = {
  __typename?: 'WUI_SessionEvent_aggregate';
  aggregate?: Maybe<Wui_SessionEvent_Aggregate_Fields>;
  nodes: Array<Wui_SessionEvent>;
};

/** aggregate fields of "WUI_SessionEvent" */
export type Wui_SessionEvent_Aggregate_Fields = {
  __typename?: 'WUI_SessionEvent_aggregate_fields';
  avg?: Maybe<Wui_SessionEvent_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_SessionEvent_Max_Fields>;
  min?: Maybe<Wui_SessionEvent_Min_Fields>;
  stddev?: Maybe<Wui_SessionEvent_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_SessionEvent_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_SessionEvent_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_SessionEvent_Sum_Fields>;
  var_pop?: Maybe<Wui_SessionEvent_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_SessionEvent_Var_Samp_Fields>;
  variance?: Maybe<Wui_SessionEvent_Variance_Fields>;
};


/** aggregate fields of "WUI_SessionEvent" */
export type Wui_SessionEvent_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wui_SessionEvent_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Wui_SessionEvent_Avg_Fields = {
  __typename?: 'WUI_SessionEvent_avg_fields';
  id?: Maybe<Scalars['Float']>;
  link_id?: Maybe<Scalars['Float']>;
  seller_id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "WUI_SessionEvent". All fields are combined with a logical 'AND'. */
export type Wui_SessionEvent_Bool_Exp = {
  SocialMediaLink?: Maybe<SocialMediaLink_Bool_Exp>;
  WUI_Session?: Maybe<Wui_Session_Bool_Exp>;
  WUI_SessionEventType?: Maybe<Wui_SessionEventType_Bool_Exp>;
  WUI_User?: Maybe<Wui_User_Bool_Exp>;
  _and?: Maybe<Array<Wui_SessionEvent_Bool_Exp>>;
  _not?: Maybe<Wui_SessionEvent_Bool_Exp>;
  _or?: Maybe<Array<Wui_SessionEvent_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  event_type?: Maybe<Wui_SessionEventType_Enum_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  link_id?: Maybe<Bigint_Comparison_Exp>;
  seller_id?: Maybe<Bigint_Comparison_Exp>;
  session_id?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_SessionEvent" */
export enum Wui_SessionEvent_Constraint {
  /** unique or primary key constraint */
  WuiSessionEventPkey = 'WUI_SessionEvent_pkey'
}

/** input type for incrementing numeric columns in table "WUI_SessionEvent" */
export type Wui_SessionEvent_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  link_id?: Maybe<Scalars['bigint']>;
  seller_id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_SessionEvent" */
export type Wui_SessionEvent_Insert_Input = {
  SocialMediaLink?: Maybe<SocialMediaLink_Obj_Rel_Insert_Input>;
  WUI_Session?: Maybe<Wui_Session_Obj_Rel_Insert_Input>;
  WUI_SessionEventType?: Maybe<Wui_SessionEventType_Obj_Rel_Insert_Input>;
  WUI_User?: Maybe<Wui_User_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  event_type?: Maybe<Wui_SessionEventType_Enum>;
  id?: Maybe<Scalars['bigint']>;
  link_id?: Maybe<Scalars['bigint']>;
  seller_id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Wui_SessionEvent_Max_Fields = {
  __typename?: 'WUI_SessionEvent_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  link_id?: Maybe<Scalars['bigint']>;
  seller_id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Wui_SessionEvent_Min_Fields = {
  __typename?: 'WUI_SessionEvent_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  link_id?: Maybe<Scalars['bigint']>;
  seller_id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "WUI_SessionEvent" */
export type Wui_SessionEvent_Mutation_Response = {
  __typename?: 'WUI_SessionEvent_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_SessionEvent>;
};

/** on conflict condition type for table "WUI_SessionEvent" */
export type Wui_SessionEvent_On_Conflict = {
  constraint: Wui_SessionEvent_Constraint;
  update_columns?: Array<Wui_SessionEvent_Update_Column>;
  where?: Maybe<Wui_SessionEvent_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_SessionEvent". */
export type Wui_SessionEvent_Order_By = {
  SocialMediaLink?: Maybe<SocialMediaLink_Order_By>;
  WUI_Session?: Maybe<Wui_Session_Order_By>;
  WUI_SessionEventType?: Maybe<Wui_SessionEventType_Order_By>;
  WUI_User?: Maybe<Wui_User_Order_By>;
  created_at?: Maybe<Order_By>;
  event_type?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  link_id?: Maybe<Order_By>;
  seller_id?: Maybe<Order_By>;
  session_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: WUI_SessionEvent */
export type Wui_SessionEvent_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "WUI_SessionEvent" */
export enum Wui_SessionEvent_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventType = 'event_type',
  /** column name */
  Id = 'id',
  /** column name */
  LinkId = 'link_id',
  /** column name */
  SellerId = 'seller_id',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "WUI_SessionEvent" */
export type Wui_SessionEvent_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  event_type?: Maybe<Wui_SessionEventType_Enum>;
  id?: Maybe<Scalars['bigint']>;
  link_id?: Maybe<Scalars['bigint']>;
  seller_id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Wui_SessionEvent_Stddev_Fields = {
  __typename?: 'WUI_SessionEvent_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  link_id?: Maybe<Scalars['Float']>;
  seller_id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Wui_SessionEvent_Stddev_Pop_Fields = {
  __typename?: 'WUI_SessionEvent_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  link_id?: Maybe<Scalars['Float']>;
  seller_id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Wui_SessionEvent_Stddev_Samp_Fields = {
  __typename?: 'WUI_SessionEvent_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  link_id?: Maybe<Scalars['Float']>;
  seller_id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Wui_SessionEvent_Sum_Fields = {
  __typename?: 'WUI_SessionEvent_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  link_id?: Maybe<Scalars['bigint']>;
  seller_id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "WUI_SessionEvent" */
export enum Wui_SessionEvent_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventType = 'event_type',
  /** column name */
  Id = 'id',
  /** column name */
  LinkId = 'link_id',
  /** column name */
  SellerId = 'seller_id',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Wui_SessionEvent_Var_Pop_Fields = {
  __typename?: 'WUI_SessionEvent_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  link_id?: Maybe<Scalars['Float']>;
  seller_id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Wui_SessionEvent_Var_Samp_Fields = {
  __typename?: 'WUI_SessionEvent_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  link_id?: Maybe<Scalars['Float']>;
  seller_id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Wui_SessionEvent_Variance_Fields = {
  __typename?: 'WUI_SessionEvent_variance_fields';
  id?: Maybe<Scalars['Float']>;
  link_id?: Maybe<Scalars['Float']>;
  seller_id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "WUI_Session" */
export type Wui_Session_Aggregate = {
  __typename?: 'WUI_Session_aggregate';
  aggregate?: Maybe<Wui_Session_Aggregate_Fields>;
  nodes: Array<Wui_Session>;
};

/** aggregate fields of "WUI_Session" */
export type Wui_Session_Aggregate_Fields = {
  __typename?: 'WUI_Session_aggregate_fields';
  avg?: Maybe<Wui_Session_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_Session_Max_Fields>;
  min?: Maybe<Wui_Session_Min_Fields>;
  stddev?: Maybe<Wui_Session_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_Session_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_Session_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_Session_Sum_Fields>;
  var_pop?: Maybe<Wui_Session_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_Session_Var_Samp_Fields>;
  variance?: Maybe<Wui_Session_Variance_Fields>;
};


/** aggregate fields of "WUI_Session" */
export type Wui_Session_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wui_Session_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Wui_Session_Avg_Fields = {
  __typename?: 'WUI_Session_avg_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "WUI_Session". All fields are combined with a logical 'AND'. */
export type Wui_Session_Bool_Exp = {
  _and?: Maybe<Array<Wui_Session_Bool_Exp>>;
  _not?: Maybe<Wui_Session_Bool_Exp>;
  _or?: Maybe<Array<Wui_Session_Bool_Exp>>;
  id?: Maybe<Bigint_Comparison_Exp>;
  session_id?: Maybe<String_Comparison_Exp>;
  user_id?: Maybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_Session" */
export enum Wui_Session_Constraint {
  /** unique or primary key constraint */
  WuiSessionPkey = 'WUI_Session_pkey',
  /** unique or primary key constraint */
  WuiSessionSessionIdKey = 'WUI_Session_session_id_key'
}

/** input type for incrementing numeric columns in table "WUI_Session" */
export type Wui_Session_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_Session" */
export type Wui_Session_Insert_Input = {
  id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Wui_Session_Max_Fields = {
  __typename?: 'WUI_Session_max_fields';
  id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Wui_Session_Min_Fields = {
  __typename?: 'WUI_Session_min_fields';
  id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "WUI_Session" */
export type Wui_Session_Mutation_Response = {
  __typename?: 'WUI_Session_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_Session>;
};

/** input type for inserting object relation for remote table "WUI_Session" */
export type Wui_Session_Obj_Rel_Insert_Input = {
  data: Wui_Session_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Wui_Session_On_Conflict>;
};

/** on conflict condition type for table "WUI_Session" */
export type Wui_Session_On_Conflict = {
  constraint: Wui_Session_Constraint;
  update_columns?: Array<Wui_Session_Update_Column>;
  where?: Maybe<Wui_Session_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_Session". */
export type Wui_Session_Order_By = {
  id?: Maybe<Order_By>;
  session_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: WUI_Session */
export type Wui_Session_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "WUI_Session" */
export enum Wui_Session_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "WUI_Session" */
export type Wui_Session_Set_Input = {
  id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Wui_Session_Stddev_Fields = {
  __typename?: 'WUI_Session_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Wui_Session_Stddev_Pop_Fields = {
  __typename?: 'WUI_Session_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Wui_Session_Stddev_Samp_Fields = {
  __typename?: 'WUI_Session_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Wui_Session_Sum_Fields = {
  __typename?: 'WUI_Session_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "WUI_Session" */
export enum Wui_Session_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Wui_Session_Var_Pop_Fields = {
  __typename?: 'WUI_Session_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Wui_Session_Var_Samp_Fields = {
  __typename?: 'WUI_Session_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Wui_Session_Variance_Fields = {
  __typename?: 'WUI_Session_variance_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "WUI_SocialMediaSource" */
export type Wui_SocialMediaSource = {
  __typename?: 'WUI_SocialMediaSource';
  value: Scalars['String'];
};

/** aggregated selection of "WUI_SocialMediaSource" */
export type Wui_SocialMediaSource_Aggregate = {
  __typename?: 'WUI_SocialMediaSource_aggregate';
  aggregate?: Maybe<Wui_SocialMediaSource_Aggregate_Fields>;
  nodes: Array<Wui_SocialMediaSource>;
};

/** aggregate fields of "WUI_SocialMediaSource" */
export type Wui_SocialMediaSource_Aggregate_Fields = {
  __typename?: 'WUI_SocialMediaSource_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Wui_SocialMediaSource_Max_Fields>;
  min?: Maybe<Wui_SocialMediaSource_Min_Fields>;
};


/** aggregate fields of "WUI_SocialMediaSource" */
export type Wui_SocialMediaSource_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wui_SocialMediaSource_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "WUI_SocialMediaSource". All fields are combined with a logical 'AND'. */
export type Wui_SocialMediaSource_Bool_Exp = {
  _and?: Maybe<Array<Wui_SocialMediaSource_Bool_Exp>>;
  _not?: Maybe<Wui_SocialMediaSource_Bool_Exp>;
  _or?: Maybe<Array<Wui_SocialMediaSource_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_SocialMediaSource" */
export enum Wui_SocialMediaSource_Constraint {
  /** unique or primary key constraint */
  WuiSocialMediaSourceEnumPkey = 'WUI_SocialMediaSourceEnum_pkey'
}

export enum Wui_SocialMediaSource_Enum {
  Facebook = 'facebook',
  General = 'general',
  Instagram = 'instagram',
  Tiktok = 'tiktok',
  Twitter = 'twitter',
  Youtube = 'youtube'
}

/** Boolean expression to compare columns of type "WUI_SocialMediaSource_enum". All fields are combined with logical 'AND'. */
export type Wui_SocialMediaSource_Enum_Comparison_Exp = {
  _eq?: Maybe<Wui_SocialMediaSource_Enum>;
  _in?: Maybe<Array<Wui_SocialMediaSource_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Wui_SocialMediaSource_Enum>;
  _nin?: Maybe<Array<Wui_SocialMediaSource_Enum>>;
};

/** input type for inserting data into table "WUI_SocialMediaSource" */
export type Wui_SocialMediaSource_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Wui_SocialMediaSource_Max_Fields = {
  __typename?: 'WUI_SocialMediaSource_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Wui_SocialMediaSource_Min_Fields = {
  __typename?: 'WUI_SocialMediaSource_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "WUI_SocialMediaSource" */
export type Wui_SocialMediaSource_Mutation_Response = {
  __typename?: 'WUI_SocialMediaSource_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_SocialMediaSource>;
};

/** input type for inserting object relation for remote table "WUI_SocialMediaSource" */
export type Wui_SocialMediaSource_Obj_Rel_Insert_Input = {
  data: Wui_SocialMediaSource_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Wui_SocialMediaSource_On_Conflict>;
};

/** on conflict condition type for table "WUI_SocialMediaSource" */
export type Wui_SocialMediaSource_On_Conflict = {
  constraint: Wui_SocialMediaSource_Constraint;
  update_columns?: Array<Wui_SocialMediaSource_Update_Column>;
  where?: Maybe<Wui_SocialMediaSource_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_SocialMediaSource". */
export type Wui_SocialMediaSource_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: WUI_SocialMediaSource */
export type Wui_SocialMediaSource_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "WUI_SocialMediaSource" */
export enum Wui_SocialMediaSource_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "WUI_SocialMediaSource" */
export type Wui_SocialMediaSource_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "WUI_SocialMediaSource" */
export enum Wui_SocialMediaSource_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "WUI_User" */
export type Wui_User = {
  __typename?: 'WUI_User';
  /** An array relationship */
  SocialMediaDemographics: Array<SocialMediaDemographic>;
  /** An aggregate relationship */
  SocialMediaDemographics_aggregate: SocialMediaDemographic_Aggregate;
  /** An object relationship */
  WUI_AuthProvider?: Maybe<Wui_AuthProvider>;
  /** An object relationship */
  WUI_File_Cover_Picture?: Maybe<Wui_File>;
  /** An object relationship */
  WUI_File_Profile_Picture?: Maybe<Wui_File>;
  /** An array relationship */
  WUI_SellerPackages: Array<Wui_SellerPackage>;
  /** An aggregate relationship */
  WUI_SellerPackages_aggregate: Wui_SellerPackage_Aggregate;
  /** An array relationship */
  WUI_UserReviews: Array<Wui_UserReview>;
  /** An aggregate relationship */
  WUI_UserReviews_aggregate: Wui_UserReview_Aggregate;
  about_me?: Maybe<Scalars['String']>;
  accepted_terms?: Maybe<Scalars['Boolean']>;
  allow_contact_without_order?: Maybe<Scalars['Boolean']>;
  auth_provider_id?: Maybe<Scalars['bigint']>;
  completed_legal_information: Scalars['Boolean'];
  contact_email: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  cover_picture_file_id?: Maybe<Scalars['bigint']>;
  created_at: Scalars['timestamptz'];
  first_name: Scalars['String'];
  id: Scalars['bigint'];
  is_seller: Scalars['Boolean'];
  last_access_time?: Maybe<Scalars['timestamptz']>;
  last_name: Scalars['String'];
  last_seen_date?: Maybe<Scalars['timestamptz']>;
  phone_number?: Maybe<Scalars['String']>;
  profile_picture_file_id?: Maybe<Scalars['bigint']>;
  role?: Maybe<Scalars['String']>;
  stripe_account_id?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  theme_color?: Maybe<Scalars['String']>;
  username: Scalars['String'];
  uuid: Scalars['uuid'];
  video_url?: Maybe<Scalars['String']>;
};


/** columns and relationships of "WUI_User" */
export type Wui_UserSocialMediaDemographicsArgs = {
  distinct_on?: Maybe<Array<SocialMediaDemographic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SocialMediaDemographic_Order_By>>;
  where?: Maybe<SocialMediaDemographic_Bool_Exp>;
};


/** columns and relationships of "WUI_User" */
export type Wui_UserSocialMediaDemographics_AggregateArgs = {
  distinct_on?: Maybe<Array<SocialMediaDemographic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SocialMediaDemographic_Order_By>>;
  where?: Maybe<SocialMediaDemographic_Bool_Exp>;
};


/** columns and relationships of "WUI_User" */
export type Wui_UserWui_SellerPackagesArgs = {
  distinct_on?: Maybe<Array<Wui_SellerPackage_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_SellerPackage_Order_By>>;
  where?: Maybe<Wui_SellerPackage_Bool_Exp>;
};


/** columns and relationships of "WUI_User" */
export type Wui_UserWui_SellerPackages_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_SellerPackage_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_SellerPackage_Order_By>>;
  where?: Maybe<Wui_SellerPackage_Bool_Exp>;
};


/** columns and relationships of "WUI_User" */
export type Wui_UserWui_UserReviewsArgs = {
  distinct_on?: Maybe<Array<Wui_UserReview_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_UserReview_Order_By>>;
  where?: Maybe<Wui_UserReview_Bool_Exp>;
};


/** columns and relationships of "WUI_User" */
export type Wui_UserWui_UserReviews_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_UserReview_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_UserReview_Order_By>>;
  where?: Maybe<Wui_UserReview_Bool_Exp>;
};

/** columns and relationships of "WUI_UserFilePortfolio" */
export type Wui_UserFilePortfolio = {
  __typename?: 'WUI_UserFilePortfolio';
  /** An object relationship */
  WUI_File?: Maybe<Wui_File>;
  /** An object relationship */
  WUI_User: Wui_User;
  file_id?: Maybe<Scalars['bigint']>;
  id: Scalars['bigint'];
  order?: Maybe<Scalars['Int']>;
  user_id: Scalars['bigint'];
};

/** aggregated selection of "WUI_UserFilePortfolio" */
export type Wui_UserFilePortfolio_Aggregate = {
  __typename?: 'WUI_UserFilePortfolio_aggregate';
  aggregate?: Maybe<Wui_UserFilePortfolio_Aggregate_Fields>;
  nodes: Array<Wui_UserFilePortfolio>;
};

/** aggregate fields of "WUI_UserFilePortfolio" */
export type Wui_UserFilePortfolio_Aggregate_Fields = {
  __typename?: 'WUI_UserFilePortfolio_aggregate_fields';
  avg?: Maybe<Wui_UserFilePortfolio_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_UserFilePortfolio_Max_Fields>;
  min?: Maybe<Wui_UserFilePortfolio_Min_Fields>;
  stddev?: Maybe<Wui_UserFilePortfolio_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_UserFilePortfolio_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_UserFilePortfolio_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_UserFilePortfolio_Sum_Fields>;
  var_pop?: Maybe<Wui_UserFilePortfolio_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_UserFilePortfolio_Var_Samp_Fields>;
  variance?: Maybe<Wui_UserFilePortfolio_Variance_Fields>;
};


/** aggregate fields of "WUI_UserFilePortfolio" */
export type Wui_UserFilePortfolio_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wui_UserFilePortfolio_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Wui_UserFilePortfolio_Avg_Fields = {
  __typename?: 'WUI_UserFilePortfolio_avg_fields';
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "WUI_UserFilePortfolio". All fields are combined with a logical 'AND'. */
export type Wui_UserFilePortfolio_Bool_Exp = {
  WUI_File?: Maybe<Wui_File_Bool_Exp>;
  WUI_User?: Maybe<Wui_User_Bool_Exp>;
  _and?: Maybe<Array<Wui_UserFilePortfolio_Bool_Exp>>;
  _not?: Maybe<Wui_UserFilePortfolio_Bool_Exp>;
  _or?: Maybe<Array<Wui_UserFilePortfolio_Bool_Exp>>;
  file_id?: Maybe<Bigint_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  user_id?: Maybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_UserFilePortfolio" */
export enum Wui_UserFilePortfolio_Constraint {
  /** unique or primary key constraint */
  WuiUserImagePortfolioPkey = 'WUI_UserImagePortfolio_pkey'
}

/** input type for incrementing numeric columns in table "WUI_UserFilePortfolio" */
export type Wui_UserFilePortfolio_Inc_Input = {
  file_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_UserFilePortfolio" */
export type Wui_UserFilePortfolio_Insert_Input = {
  WUI_File?: Maybe<Wui_File_Obj_Rel_Insert_Input>;
  WUI_User?: Maybe<Wui_User_Obj_Rel_Insert_Input>;
  file_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Wui_UserFilePortfolio_Max_Fields = {
  __typename?: 'WUI_UserFilePortfolio_max_fields';
  file_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Wui_UserFilePortfolio_Min_Fields = {
  __typename?: 'WUI_UserFilePortfolio_min_fields';
  file_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "WUI_UserFilePortfolio" */
export type Wui_UserFilePortfolio_Mutation_Response = {
  __typename?: 'WUI_UserFilePortfolio_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_UserFilePortfolio>;
};

/** on conflict condition type for table "WUI_UserFilePortfolio" */
export type Wui_UserFilePortfolio_On_Conflict = {
  constraint: Wui_UserFilePortfolio_Constraint;
  update_columns?: Array<Wui_UserFilePortfolio_Update_Column>;
  where?: Maybe<Wui_UserFilePortfolio_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_UserFilePortfolio". */
export type Wui_UserFilePortfolio_Order_By = {
  WUI_File?: Maybe<Wui_File_Order_By>;
  WUI_User?: Maybe<Wui_User_Order_By>;
  file_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: WUI_UserFilePortfolio */
export type Wui_UserFilePortfolio_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "WUI_UserFilePortfolio" */
export enum Wui_UserFilePortfolio_Select_Column {
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "WUI_UserFilePortfolio" */
export type Wui_UserFilePortfolio_Set_Input = {
  file_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Wui_UserFilePortfolio_Stddev_Fields = {
  __typename?: 'WUI_UserFilePortfolio_stddev_fields';
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Wui_UserFilePortfolio_Stddev_Pop_Fields = {
  __typename?: 'WUI_UserFilePortfolio_stddev_pop_fields';
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Wui_UserFilePortfolio_Stddev_Samp_Fields = {
  __typename?: 'WUI_UserFilePortfolio_stddev_samp_fields';
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Wui_UserFilePortfolio_Sum_Fields = {
  __typename?: 'WUI_UserFilePortfolio_sum_fields';
  file_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "WUI_UserFilePortfolio" */
export enum Wui_UserFilePortfolio_Update_Column {
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Wui_UserFilePortfolio_Var_Pop_Fields = {
  __typename?: 'WUI_UserFilePortfolio_var_pop_fields';
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Wui_UserFilePortfolio_Var_Samp_Fields = {
  __typename?: 'WUI_UserFilePortfolio_var_samp_fields';
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Wui_UserFilePortfolio_Variance_Fields = {
  __typename?: 'WUI_UserFilePortfolio_variance_fields';
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "WUI_UserReview" */
export type Wui_UserReview = {
  __typename?: 'WUI_UserReview';
  /** An object relationship */
  WUI_User_From: Wui_User;
  /** An object relationship */
  WUI_User_To: Wui_User;
  comment?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  from_user_id: Scalars['bigint'];
  id: Scalars['bigint'];
  stars: Scalars['Int'];
  to_user_id: Scalars['bigint'];
};

/** aggregated selection of "WUI_UserReview" */
export type Wui_UserReview_Aggregate = {
  __typename?: 'WUI_UserReview_aggregate';
  aggregate?: Maybe<Wui_UserReview_Aggregate_Fields>;
  nodes: Array<Wui_UserReview>;
};

/** aggregate fields of "WUI_UserReview" */
export type Wui_UserReview_Aggregate_Fields = {
  __typename?: 'WUI_UserReview_aggregate_fields';
  avg?: Maybe<Wui_UserReview_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_UserReview_Max_Fields>;
  min?: Maybe<Wui_UserReview_Min_Fields>;
  stddev?: Maybe<Wui_UserReview_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_UserReview_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_UserReview_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_UserReview_Sum_Fields>;
  var_pop?: Maybe<Wui_UserReview_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_UserReview_Var_Samp_Fields>;
  variance?: Maybe<Wui_UserReview_Variance_Fields>;
};


/** aggregate fields of "WUI_UserReview" */
export type Wui_UserReview_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wui_UserReview_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "WUI_UserReview" */
export type Wui_UserReview_Aggregate_Order_By = {
  avg?: Maybe<Wui_UserReview_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Wui_UserReview_Max_Order_By>;
  min?: Maybe<Wui_UserReview_Min_Order_By>;
  stddev?: Maybe<Wui_UserReview_Stddev_Order_By>;
  stddev_pop?: Maybe<Wui_UserReview_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Wui_UserReview_Stddev_Samp_Order_By>;
  sum?: Maybe<Wui_UserReview_Sum_Order_By>;
  var_pop?: Maybe<Wui_UserReview_Var_Pop_Order_By>;
  var_samp?: Maybe<Wui_UserReview_Var_Samp_Order_By>;
  variance?: Maybe<Wui_UserReview_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "WUI_UserReview" */
export type Wui_UserReview_Arr_Rel_Insert_Input = {
  data: Array<Wui_UserReview_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Wui_UserReview_On_Conflict>;
};

/** aggregate avg on columns */
export type Wui_UserReview_Avg_Fields = {
  __typename?: 'WUI_UserReview_avg_fields';
  from_user_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  stars?: Maybe<Scalars['Float']>;
  to_user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "WUI_UserReview" */
export type Wui_UserReview_Avg_Order_By = {
  from_user_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  stars?: Maybe<Order_By>;
  to_user_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "WUI_UserReview". All fields are combined with a logical 'AND'. */
export type Wui_UserReview_Bool_Exp = {
  WUI_User_From?: Maybe<Wui_User_Bool_Exp>;
  WUI_User_To?: Maybe<Wui_User_Bool_Exp>;
  _and?: Maybe<Array<Wui_UserReview_Bool_Exp>>;
  _not?: Maybe<Wui_UserReview_Bool_Exp>;
  _or?: Maybe<Array<Wui_UserReview_Bool_Exp>>;
  comment?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  from_user_id?: Maybe<Bigint_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  stars?: Maybe<Int_Comparison_Exp>;
  to_user_id?: Maybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_UserReview" */
export enum Wui_UserReview_Constraint {
  /** unique or primary key constraint */
  WuiUserReviewPkey = 'WUI_UserReview_pkey'
}

/** input type for incrementing numeric columns in table "WUI_UserReview" */
export type Wui_UserReview_Inc_Input = {
  from_user_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  stars?: Maybe<Scalars['Int']>;
  to_user_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_UserReview" */
export type Wui_UserReview_Insert_Input = {
  WUI_User_From?: Maybe<Wui_User_Obj_Rel_Insert_Input>;
  WUI_User_To?: Maybe<Wui_User_Obj_Rel_Insert_Input>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  from_user_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  stars?: Maybe<Scalars['Int']>;
  to_user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Wui_UserReview_Max_Fields = {
  __typename?: 'WUI_UserReview_max_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  from_user_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  stars?: Maybe<Scalars['Int']>;
  to_user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "WUI_UserReview" */
export type Wui_UserReview_Max_Order_By = {
  comment?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  from_user_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  stars?: Maybe<Order_By>;
  to_user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Wui_UserReview_Min_Fields = {
  __typename?: 'WUI_UserReview_min_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  from_user_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  stars?: Maybe<Scalars['Int']>;
  to_user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "WUI_UserReview" */
export type Wui_UserReview_Min_Order_By = {
  comment?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  from_user_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  stars?: Maybe<Order_By>;
  to_user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "WUI_UserReview" */
export type Wui_UserReview_Mutation_Response = {
  __typename?: 'WUI_UserReview_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_UserReview>;
};

/** input type for inserting object relation for remote table "WUI_UserReview" */
export type Wui_UserReview_Obj_Rel_Insert_Input = {
  data: Wui_UserReview_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Wui_UserReview_On_Conflict>;
};

/** on conflict condition type for table "WUI_UserReview" */
export type Wui_UserReview_On_Conflict = {
  constraint: Wui_UserReview_Constraint;
  update_columns?: Array<Wui_UserReview_Update_Column>;
  where?: Maybe<Wui_UserReview_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_UserReview". */
export type Wui_UserReview_Order_By = {
  WUI_User_From?: Maybe<Wui_User_Order_By>;
  WUI_User_To?: Maybe<Wui_User_Order_By>;
  comment?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  from_user_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  stars?: Maybe<Order_By>;
  to_user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: WUI_UserReview */
export type Wui_UserReview_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "WUI_UserReview" */
export enum Wui_UserReview_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FromUserId = 'from_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Stars = 'stars',
  /** column name */
  ToUserId = 'to_user_id'
}

/** input type for updating data in table "WUI_UserReview" */
export type Wui_UserReview_Set_Input = {
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  from_user_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  stars?: Maybe<Scalars['Int']>;
  to_user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Wui_UserReview_Stddev_Fields = {
  __typename?: 'WUI_UserReview_stddev_fields';
  from_user_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  stars?: Maybe<Scalars['Float']>;
  to_user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "WUI_UserReview" */
export type Wui_UserReview_Stddev_Order_By = {
  from_user_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  stars?: Maybe<Order_By>;
  to_user_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Wui_UserReview_Stddev_Pop_Fields = {
  __typename?: 'WUI_UserReview_stddev_pop_fields';
  from_user_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  stars?: Maybe<Scalars['Float']>;
  to_user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "WUI_UserReview" */
export type Wui_UserReview_Stddev_Pop_Order_By = {
  from_user_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  stars?: Maybe<Order_By>;
  to_user_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Wui_UserReview_Stddev_Samp_Fields = {
  __typename?: 'WUI_UserReview_stddev_samp_fields';
  from_user_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  stars?: Maybe<Scalars['Float']>;
  to_user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "WUI_UserReview" */
export type Wui_UserReview_Stddev_Samp_Order_By = {
  from_user_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  stars?: Maybe<Order_By>;
  to_user_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Wui_UserReview_Sum_Fields = {
  __typename?: 'WUI_UserReview_sum_fields';
  from_user_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  stars?: Maybe<Scalars['Int']>;
  to_user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "WUI_UserReview" */
export type Wui_UserReview_Sum_Order_By = {
  from_user_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  stars?: Maybe<Order_By>;
  to_user_id?: Maybe<Order_By>;
};

/** update columns of table "WUI_UserReview" */
export enum Wui_UserReview_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FromUserId = 'from_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Stars = 'stars',
  /** column name */
  ToUserId = 'to_user_id'
}

/** aggregate var_pop on columns */
export type Wui_UserReview_Var_Pop_Fields = {
  __typename?: 'WUI_UserReview_var_pop_fields';
  from_user_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  stars?: Maybe<Scalars['Float']>;
  to_user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "WUI_UserReview" */
export type Wui_UserReview_Var_Pop_Order_By = {
  from_user_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  stars?: Maybe<Order_By>;
  to_user_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Wui_UserReview_Var_Samp_Fields = {
  __typename?: 'WUI_UserReview_var_samp_fields';
  from_user_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  stars?: Maybe<Scalars['Float']>;
  to_user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "WUI_UserReview" */
export type Wui_UserReview_Var_Samp_Order_By = {
  from_user_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  stars?: Maybe<Order_By>;
  to_user_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Wui_UserReview_Variance_Fields = {
  __typename?: 'WUI_UserReview_variance_fields';
  from_user_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  stars?: Maybe<Scalars['Float']>;
  to_user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "WUI_UserReview" */
export type Wui_UserReview_Variance_Order_By = {
  from_user_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  stars?: Maybe<Order_By>;
  to_user_id?: Maybe<Order_By>;
};

/** columns and relationships of "WUI_UserRole" */
export type Wui_UserRole = {
  __typename?: 'WUI_UserRole';
  role_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "WUI_UserRole" */
export type Wui_UserRole_Aggregate = {
  __typename?: 'WUI_UserRole_aggregate';
  aggregate?: Maybe<Wui_UserRole_Aggregate_Fields>;
  nodes: Array<Wui_UserRole>;
};

/** aggregate fields of "WUI_UserRole" */
export type Wui_UserRole_Aggregate_Fields = {
  __typename?: 'WUI_UserRole_aggregate_fields';
  avg?: Maybe<Wui_UserRole_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_UserRole_Max_Fields>;
  min?: Maybe<Wui_UserRole_Min_Fields>;
  stddev?: Maybe<Wui_UserRole_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_UserRole_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_UserRole_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_UserRole_Sum_Fields>;
  var_pop?: Maybe<Wui_UserRole_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_UserRole_Var_Samp_Fields>;
  variance?: Maybe<Wui_UserRole_Variance_Fields>;
};


/** aggregate fields of "WUI_UserRole" */
export type Wui_UserRole_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wui_UserRole_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Wui_UserRole_Avg_Fields = {
  __typename?: 'WUI_UserRole_avg_fields';
  role_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "WUI_UserRole". All fields are combined with a logical 'AND'. */
export type Wui_UserRole_Bool_Exp = {
  _and?: Maybe<Array<Wui_UserRole_Bool_Exp>>;
  _not?: Maybe<Wui_UserRole_Bool_Exp>;
  _or?: Maybe<Array<Wui_UserRole_Bool_Exp>>;
  role_id?: Maybe<Int_Comparison_Exp>;
  user_id?: Maybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Wui_UserRole_Max_Fields = {
  __typename?: 'WUI_UserRole_max_fields';
  role_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Wui_UserRole_Min_Fields = {
  __typename?: 'WUI_UserRole_min_fields';
  role_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "WUI_UserRole". */
export type Wui_UserRole_Order_By = {
  role_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** select columns of table "WUI_UserRole" */
export enum Wui_UserRole_Select_Column {
  /** column name */
  RoleId = 'role_id',
  /** column name */
  UserId = 'user_id'
}

/** aggregate stddev on columns */
export type Wui_UserRole_Stddev_Fields = {
  __typename?: 'WUI_UserRole_stddev_fields';
  role_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Wui_UserRole_Stddev_Pop_Fields = {
  __typename?: 'WUI_UserRole_stddev_pop_fields';
  role_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Wui_UserRole_Stddev_Samp_Fields = {
  __typename?: 'WUI_UserRole_stddev_samp_fields';
  role_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Wui_UserRole_Sum_Fields = {
  __typename?: 'WUI_UserRole_sum_fields';
  role_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Wui_UserRole_Var_Pop_Fields = {
  __typename?: 'WUI_UserRole_var_pop_fields';
  role_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Wui_UserRole_Var_Samp_Fields = {
  __typename?: 'WUI_UserRole_var_samp_fields';
  role_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Wui_UserRole_Variance_Fields = {
  __typename?: 'WUI_UserRole_variance_fields';
  role_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "WUI_User" */
export type Wui_User_Aggregate = {
  __typename?: 'WUI_User_aggregate';
  aggregate?: Maybe<Wui_User_Aggregate_Fields>;
  nodes: Array<Wui_User>;
};

/** aggregate fields of "WUI_User" */
export type Wui_User_Aggregate_Fields = {
  __typename?: 'WUI_User_aggregate_fields';
  avg?: Maybe<Wui_User_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_User_Max_Fields>;
  min?: Maybe<Wui_User_Min_Fields>;
  stddev?: Maybe<Wui_User_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_User_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_User_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_User_Sum_Fields>;
  var_pop?: Maybe<Wui_User_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_User_Var_Samp_Fields>;
  variance?: Maybe<Wui_User_Variance_Fields>;
};


/** aggregate fields of "WUI_User" */
export type Wui_User_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wui_User_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Wui_User_Avg_Fields = {
  __typename?: 'WUI_User_avg_fields';
  auth_provider_id?: Maybe<Scalars['Float']>;
  cover_picture_file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_picture_file_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "WUI_User". All fields are combined with a logical 'AND'. */
export type Wui_User_Bool_Exp = {
  SocialMediaDemographics?: Maybe<SocialMediaDemographic_Bool_Exp>;
  WUI_AuthProvider?: Maybe<Wui_AuthProvider_Bool_Exp>;
  WUI_File_Cover_Picture?: Maybe<Wui_File_Bool_Exp>;
  WUI_File_Profile_Picture?: Maybe<Wui_File_Bool_Exp>;
  WUI_SellerPackages?: Maybe<Wui_SellerPackage_Bool_Exp>;
  WUI_UserReviews?: Maybe<Wui_UserReview_Bool_Exp>;
  _and?: Maybe<Array<Wui_User_Bool_Exp>>;
  _not?: Maybe<Wui_User_Bool_Exp>;
  _or?: Maybe<Array<Wui_User_Bool_Exp>>;
  about_me?: Maybe<String_Comparison_Exp>;
  accepted_terms?: Maybe<Boolean_Comparison_Exp>;
  allow_contact_without_order?: Maybe<Boolean_Comparison_Exp>;
  auth_provider_id?: Maybe<Bigint_Comparison_Exp>;
  completed_legal_information?: Maybe<Boolean_Comparison_Exp>;
  contact_email?: Maybe<String_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  cover_picture_file_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  is_seller?: Maybe<Boolean_Comparison_Exp>;
  last_access_time?: Maybe<Timestamptz_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  last_seen_date?: Maybe<Timestamptz_Comparison_Exp>;
  phone_number?: Maybe<String_Comparison_Exp>;
  profile_picture_file_id?: Maybe<Bigint_Comparison_Exp>;
  role?: Maybe<String_Comparison_Exp>;
  stripe_account_id?: Maybe<String_Comparison_Exp>;
  stripe_customer_id?: Maybe<String_Comparison_Exp>;
  theme_color?: Maybe<String_Comparison_Exp>;
  username?: Maybe<String_Comparison_Exp>;
  uuid?: Maybe<Uuid_Comparison_Exp>;
  video_url?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "WUI_User" */
export enum Wui_User_Constraint {
  /** unique or primary key constraint */
  WuiUserStripeCustomerIdKey = 'WUI_User_stripe_customer_id_key',
  /** unique or primary key constraint */
  UserEmailKey = 'user_email_key',
  /** unique or primary key constraint */
  UserPkey = 'user_pkey',
  /** unique or primary key constraint */
  UserUsernameKey = 'user_username_key'
}

/** input type for incrementing numeric columns in table "WUI_User" */
export type Wui_User_Inc_Input = {
  auth_provider_id?: Maybe<Scalars['bigint']>;
  cover_picture_file_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  profile_picture_file_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WUI_User" */
export type Wui_User_Insert_Input = {
  SocialMediaDemographics?: Maybe<SocialMediaDemographic_Arr_Rel_Insert_Input>;
  WUI_AuthProvider?: Maybe<Wui_AuthProvider_Obj_Rel_Insert_Input>;
  WUI_File_Cover_Picture?: Maybe<Wui_File_Obj_Rel_Insert_Input>;
  WUI_File_Profile_Picture?: Maybe<Wui_File_Obj_Rel_Insert_Input>;
  WUI_SellerPackages?: Maybe<Wui_SellerPackage_Arr_Rel_Insert_Input>;
  WUI_UserReviews?: Maybe<Wui_UserReview_Arr_Rel_Insert_Input>;
  about_me?: Maybe<Scalars['String']>;
  accepted_terms?: Maybe<Scalars['Boolean']>;
  allow_contact_without_order?: Maybe<Scalars['Boolean']>;
  auth_provider_id?: Maybe<Scalars['bigint']>;
  completed_legal_information?: Maybe<Scalars['Boolean']>;
  contact_email?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  cover_picture_file_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  is_seller?: Maybe<Scalars['Boolean']>;
  last_access_time?: Maybe<Scalars['timestamptz']>;
  last_name?: Maybe<Scalars['String']>;
  last_seen_date?: Maybe<Scalars['timestamptz']>;
  phone_number?: Maybe<Scalars['String']>;
  profile_picture_file_id?: Maybe<Scalars['bigint']>;
  role?: Maybe<Scalars['String']>;
  stripe_account_id?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  theme_color?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['uuid']>;
  video_url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Wui_User_Max_Fields = {
  __typename?: 'WUI_User_max_fields';
  about_me?: Maybe<Scalars['String']>;
  auth_provider_id?: Maybe<Scalars['bigint']>;
  contact_email?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  cover_picture_file_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  last_access_time?: Maybe<Scalars['timestamptz']>;
  last_name?: Maybe<Scalars['String']>;
  last_seen_date?: Maybe<Scalars['timestamptz']>;
  phone_number?: Maybe<Scalars['String']>;
  profile_picture_file_id?: Maybe<Scalars['bigint']>;
  role?: Maybe<Scalars['String']>;
  stripe_account_id?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  theme_color?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['uuid']>;
  video_url?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Wui_User_Min_Fields = {
  __typename?: 'WUI_User_min_fields';
  about_me?: Maybe<Scalars['String']>;
  auth_provider_id?: Maybe<Scalars['bigint']>;
  contact_email?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  cover_picture_file_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  last_access_time?: Maybe<Scalars['timestamptz']>;
  last_name?: Maybe<Scalars['String']>;
  last_seen_date?: Maybe<Scalars['timestamptz']>;
  phone_number?: Maybe<Scalars['String']>;
  profile_picture_file_id?: Maybe<Scalars['bigint']>;
  role?: Maybe<Scalars['String']>;
  stripe_account_id?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  theme_color?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['uuid']>;
  video_url?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "WUI_User" */
export type Wui_User_Mutation_Response = {
  __typename?: 'WUI_User_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_User>;
};

/** input type for inserting object relation for remote table "WUI_User" */
export type Wui_User_Obj_Rel_Insert_Input = {
  data: Wui_User_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Wui_User_On_Conflict>;
};

/** on conflict condition type for table "WUI_User" */
export type Wui_User_On_Conflict = {
  constraint: Wui_User_Constraint;
  update_columns?: Array<Wui_User_Update_Column>;
  where?: Maybe<Wui_User_Bool_Exp>;
};

/** Ordering options when selecting data from "WUI_User". */
export type Wui_User_Order_By = {
  SocialMediaDemographics_aggregate?: Maybe<SocialMediaDemographic_Aggregate_Order_By>;
  WUI_AuthProvider?: Maybe<Wui_AuthProvider_Order_By>;
  WUI_File_Cover_Picture?: Maybe<Wui_File_Order_By>;
  WUI_File_Profile_Picture?: Maybe<Wui_File_Order_By>;
  WUI_SellerPackages_aggregate?: Maybe<Wui_SellerPackage_Aggregate_Order_By>;
  WUI_UserReviews_aggregate?: Maybe<Wui_UserReview_Aggregate_Order_By>;
  about_me?: Maybe<Order_By>;
  accepted_terms?: Maybe<Order_By>;
  allow_contact_without_order?: Maybe<Order_By>;
  auth_provider_id?: Maybe<Order_By>;
  completed_legal_information?: Maybe<Order_By>;
  contact_email?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  cover_picture_file_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_seller?: Maybe<Order_By>;
  last_access_time?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  last_seen_date?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
  profile_picture_file_id?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  stripe_account_id?: Maybe<Order_By>;
  stripe_customer_id?: Maybe<Order_By>;
  theme_color?: Maybe<Order_By>;
  username?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
  video_url?: Maybe<Order_By>;
};

/** primary key columns input for table: WUI_User */
export type Wui_User_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "WUI_User" */
export enum Wui_User_Select_Column {
  /** column name */
  AboutMe = 'about_me',
  /** column name */
  AcceptedTerms = 'accepted_terms',
  /** column name */
  AllowContactWithoutOrder = 'allow_contact_without_order',
  /** column name */
  AuthProviderId = 'auth_provider_id',
  /** column name */
  CompletedLegalInformation = 'completed_legal_information',
  /** column name */
  ContactEmail = 'contact_email',
  /** column name */
  Country = 'country',
  /** column name */
  CoverPictureFileId = 'cover_picture_file_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsSeller = 'is_seller',
  /** column name */
  LastAccessTime = 'last_access_time',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LastSeenDate = 'last_seen_date',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  ProfilePictureFileId = 'profile_picture_file_id',
  /** column name */
  Role = 'role',
  /** column name */
  StripeAccountId = 'stripe_account_id',
  /** column name */
  StripeCustomerId = 'stripe_customer_id',
  /** column name */
  ThemeColor = 'theme_color',
  /** column name */
  Username = 'username',
  /** column name */
  Uuid = 'uuid',
  /** column name */
  VideoUrl = 'video_url'
}

/** input type for updating data in table "WUI_User" */
export type Wui_User_Set_Input = {
  about_me?: Maybe<Scalars['String']>;
  accepted_terms?: Maybe<Scalars['Boolean']>;
  allow_contact_without_order?: Maybe<Scalars['Boolean']>;
  auth_provider_id?: Maybe<Scalars['bigint']>;
  completed_legal_information?: Maybe<Scalars['Boolean']>;
  contact_email?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  cover_picture_file_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  is_seller?: Maybe<Scalars['Boolean']>;
  last_access_time?: Maybe<Scalars['timestamptz']>;
  last_name?: Maybe<Scalars['String']>;
  last_seen_date?: Maybe<Scalars['timestamptz']>;
  phone_number?: Maybe<Scalars['String']>;
  profile_picture_file_id?: Maybe<Scalars['bigint']>;
  role?: Maybe<Scalars['String']>;
  stripe_account_id?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  theme_color?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['uuid']>;
  video_url?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Wui_User_Stddev_Fields = {
  __typename?: 'WUI_User_stddev_fields';
  auth_provider_id?: Maybe<Scalars['Float']>;
  cover_picture_file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_picture_file_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Wui_User_Stddev_Pop_Fields = {
  __typename?: 'WUI_User_stddev_pop_fields';
  auth_provider_id?: Maybe<Scalars['Float']>;
  cover_picture_file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_picture_file_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Wui_User_Stddev_Samp_Fields = {
  __typename?: 'WUI_User_stddev_samp_fields';
  auth_provider_id?: Maybe<Scalars['Float']>;
  cover_picture_file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_picture_file_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Wui_User_Sum_Fields = {
  __typename?: 'WUI_User_sum_fields';
  auth_provider_id?: Maybe<Scalars['bigint']>;
  cover_picture_file_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  profile_picture_file_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "WUI_User" */
export enum Wui_User_Update_Column {
  /** column name */
  AboutMe = 'about_me',
  /** column name */
  AcceptedTerms = 'accepted_terms',
  /** column name */
  AllowContactWithoutOrder = 'allow_contact_without_order',
  /** column name */
  AuthProviderId = 'auth_provider_id',
  /** column name */
  CompletedLegalInformation = 'completed_legal_information',
  /** column name */
  ContactEmail = 'contact_email',
  /** column name */
  Country = 'country',
  /** column name */
  CoverPictureFileId = 'cover_picture_file_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsSeller = 'is_seller',
  /** column name */
  LastAccessTime = 'last_access_time',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LastSeenDate = 'last_seen_date',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  ProfilePictureFileId = 'profile_picture_file_id',
  /** column name */
  Role = 'role',
  /** column name */
  StripeAccountId = 'stripe_account_id',
  /** column name */
  StripeCustomerId = 'stripe_customer_id',
  /** column name */
  ThemeColor = 'theme_color',
  /** column name */
  Username = 'username',
  /** column name */
  Uuid = 'uuid',
  /** column name */
  VideoUrl = 'video_url'
}

/** aggregate var_pop on columns */
export type Wui_User_Var_Pop_Fields = {
  __typename?: 'WUI_User_var_pop_fields';
  auth_provider_id?: Maybe<Scalars['Float']>;
  cover_picture_file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_picture_file_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Wui_User_Var_Samp_Fields = {
  __typename?: 'WUI_User_var_samp_fields';
  auth_provider_id?: Maybe<Scalars['Float']>;
  cover_picture_file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_picture_file_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Wui_User_Variance_Fields = {
  __typename?: 'WUI_User_variance_fields';
  auth_provider_id?: Maybe<Scalars['Float']>;
  cover_picture_file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_picture_file_id?: Maybe<Scalars['Float']>;
};


/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};



/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  createBankAccount?: Maybe<BankAccountOutput>;
  createCompanyAccount?: Maybe<CreateCompanyInfoOutput>;
  createIndividualAccount?: Maybe<CreateIndividualAccountOutput>;
  create_WUI_Order?: Maybe<OrderOutput>;
  create_WUI_OrderDetails?: Maybe<OrderDetailsOutput>;
  create_WUI_PaymentIntent?: Maybe<PaymentIntentOutput>;
  create_WUI_PaymentMethodCard?: Maybe<PaymentMethodCardOutput>;
  create_WUI_SessionEvent?: Maybe<SessionEventOutput>;
  defaultBankAccount?: Maybe<DefaultBankAccountOutput>;
  /** delete data from the table: "Demographic" */
  delete_Demographic?: Maybe<Demographic_Mutation_Response>;
  /** delete single row from the table: "Demographic" */
  delete_Demographic_by_pk?: Maybe<Demographic>;
  /** delete data from the table: "HeroBanner" */
  delete_HeroBanner?: Maybe<HeroBanner_Mutation_Response>;
  /** delete single row from the table: "HeroBanner" */
  delete_HeroBanner_by_pk?: Maybe<HeroBanner>;
  /** delete data from the table: "ProjectDemographic" */
  delete_ProjectDemographic?: Maybe<ProjectDemographic_Mutation_Response>;
  /** delete single row from the table: "ProjectDemographic" */
  delete_ProjectDemographic_by_pk?: Maybe<ProjectDemographic>;
  /** delete data from the table: "SocialMediaDemographic" */
  delete_SocialMediaDemographic?: Maybe<SocialMediaDemographic_Mutation_Response>;
  /** delete single row from the table: "SocialMediaDemographic" */
  delete_SocialMediaDemographic_by_pk?: Maybe<SocialMediaDemographic>;
  /** delete data from the table: "SocialMediaLink" */
  delete_SocialMediaLink?: Maybe<SocialMediaLink_Mutation_Response>;
  /** delete single row from the table: "SocialMediaLink" */
  delete_SocialMediaLink_by_pk?: Maybe<SocialMediaLink>;
  /** delete data from the table: "WUI_AuthProvider" */
  delete_WUI_AuthProvider?: Maybe<Wui_AuthProvider_Mutation_Response>;
  /** delete single row from the table: "WUI_AuthProvider" */
  delete_WUI_AuthProvider_by_pk?: Maybe<Wui_AuthProvider>;
  /** delete data from the table: "WUI_ChatRoom" */
  delete_WUI_ChatRoom?: Maybe<Wui_ChatRoom_Mutation_Response>;
  /** delete data from the table: "WUI_ChatRoomMessage" */
  delete_WUI_ChatRoomMessage?: Maybe<Wui_ChatRoomMessage_Mutation_Response>;
  /** delete single row from the table: "WUI_ChatRoomMessage" */
  delete_WUI_ChatRoomMessage_by_pk?: Maybe<Wui_ChatRoomMessage>;
  /** delete data from the table: "WUI_ChatRoomOrder" */
  delete_WUI_ChatRoomOrder?: Maybe<Wui_ChatRoomOrder_Mutation_Response>;
  /** delete single row from the table: "WUI_ChatRoomOrder" */
  delete_WUI_ChatRoomOrder_by_pk?: Maybe<Wui_ChatRoomOrder>;
  /** delete data from the table: "WUI_ChatRoomType" */
  delete_WUI_ChatRoomType?: Maybe<Wui_ChatRoomType_Mutation_Response>;
  /** delete single row from the table: "WUI_ChatRoomType" */
  delete_WUI_ChatRoomType_by_pk?: Maybe<Wui_ChatRoomType>;
  /** delete data from the table: "WUI_ChatRoomUser" */
  delete_WUI_ChatRoomUser?: Maybe<Wui_ChatRoomUser_Mutation_Response>;
  /** delete single row from the table: "WUI_ChatRoomUser" */
  delete_WUI_ChatRoomUser_by_pk?: Maybe<Wui_ChatRoomUser>;
  /** delete single row from the table: "WUI_ChatRoom" */
  delete_WUI_ChatRoom_by_pk?: Maybe<Wui_ChatRoom>;
  /** delete data from the table: "WUI_File" */
  delete_WUI_File?: Maybe<Wui_File_Mutation_Response>;
  /** delete data from the table: "WUI_FileType" */
  delete_WUI_FileType?: Maybe<Wui_FileType_Mutation_Response>;
  /** delete single row from the table: "WUI_FileType" */
  delete_WUI_FileType_by_pk?: Maybe<Wui_FileType>;
  /** delete single row from the table: "WUI_File" */
  delete_WUI_File_by_pk?: Maybe<Wui_File>;
  /** delete data from the table: "WUI_Notification" */
  delete_WUI_Notification?: Maybe<Wui_Notification_Mutation_Response>;
  /** delete single row from the table: "WUI_Notification" */
  delete_WUI_Notification_by_pk?: Maybe<Wui_Notification>;
  /** delete data from the table: "WUI_Order" */
  delete_WUI_Order?: Maybe<Wui_Order_Mutation_Response>;
  /** delete data from the table: "WUI_OrderReview" */
  delete_WUI_OrderReview?: Maybe<Wui_OrderReview_Mutation_Response>;
  /** delete single row from the table: "WUI_OrderReview" */
  delete_WUI_OrderReview_by_pk?: Maybe<Wui_OrderReview>;
  /** delete data from the table: "WUI_OrderStatus" */
  delete_WUI_OrderStatus?: Maybe<Wui_OrderStatus_Mutation_Response>;
  /** delete single row from the table: "WUI_OrderStatus" */
  delete_WUI_OrderStatus_by_pk?: Maybe<Wui_OrderStatus>;
  /** delete single row from the table: "WUI_Order" */
  delete_WUI_Order_by_pk?: Maybe<Wui_Order>;
  /** delete data from the table: "WUI_Payment" */
  delete_WUI_Payment?: Maybe<Wui_Payment_Mutation_Response>;
  /** delete data from the table: "WUI_PaymentMethod" */
  delete_WUI_PaymentMethod?: Maybe<Wui_PaymentMethod_Mutation_Response>;
  /** delete data from the table: "WUI_PaymentMethodBankAccount" */
  delete_WUI_PaymentMethodBankAccount?: Maybe<Wui_PaymentMethodBankAccount_Mutation_Response>;
  /** delete single row from the table: "WUI_PaymentMethodBankAccount" */
  delete_WUI_PaymentMethodBankAccount_by_pk?: Maybe<Wui_PaymentMethodBankAccount>;
  /** delete data from the table: "WUI_PaymentMethodCard" */
  delete_WUI_PaymentMethodCard?: Maybe<Wui_PaymentMethodCard_Mutation_Response>;
  /** delete single row from the table: "WUI_PaymentMethodCard" */
  delete_WUI_PaymentMethodCard_by_pk?: Maybe<Wui_PaymentMethodCard>;
  /** delete data from the table: "WUI_PaymentMethodSource" */
  delete_WUI_PaymentMethodSource?: Maybe<Wui_PaymentMethodSource_Mutation_Response>;
  /** delete single row from the table: "WUI_PaymentMethodSource" */
  delete_WUI_PaymentMethodSource_by_pk?: Maybe<Wui_PaymentMethodSource>;
  /** delete single row from the table: "WUI_PaymentMethod" */
  delete_WUI_PaymentMethod_by_pk?: Maybe<Wui_PaymentMethod>;
  /** delete data from the table: "WUI_PaymentStatus" */
  delete_WUI_PaymentStatus?: Maybe<Wui_PaymentStatus_Mutation_Response>;
  /** delete single row from the table: "WUI_PaymentStatus" */
  delete_WUI_PaymentStatus_by_pk?: Maybe<Wui_PaymentStatus>;
  /** delete single row from the table: "WUI_Payment" */
  delete_WUI_Payment_by_pk?: Maybe<Wui_Payment>;
  /** delete data from the table: "WUI_Role" */
  delete_WUI_Role?: Maybe<Wui_Role_Mutation_Response>;
  /** delete single row from the table: "WUI_Role" */
  delete_WUI_Role_by_pk?: Maybe<Wui_Role>;
  /** delete data from the table: "WUI_SellerPackage" */
  delete_WUI_SellerPackage?: Maybe<Wui_SellerPackage_Mutation_Response>;
  /** delete data from the table: "WUI_SellerPackageItem" */
  delete_WUI_SellerPackageItem?: Maybe<Wui_SellerPackageItem_Mutation_Response>;
  /** delete single row from the table: "WUI_SellerPackageItem" */
  delete_WUI_SellerPackageItem_by_pk?: Maybe<Wui_SellerPackageItem>;
  /** delete data from the table: "WUI_SellerPackageType" */
  delete_WUI_SellerPackageType?: Maybe<Wui_SellerPackageType_Mutation_Response>;
  /** delete single row from the table: "WUI_SellerPackageType" */
  delete_WUI_SellerPackageType_by_pk?: Maybe<Wui_SellerPackageType>;
  /** delete single row from the table: "WUI_SellerPackage" */
  delete_WUI_SellerPackage_by_pk?: Maybe<Wui_SellerPackage>;
  /** delete data from the table: "WUI_SellerProject" */
  delete_WUI_SellerProject?: Maybe<Wui_SellerProject_Mutation_Response>;
  /** delete data from the table: "WUI_SellerProjectFile" */
  delete_WUI_SellerProjectFile?: Maybe<Wui_SellerProjectFile_Mutation_Response>;
  /** delete single row from the table: "WUI_SellerProjectFile" */
  delete_WUI_SellerProjectFile_by_pk?: Maybe<Wui_SellerProjectFile>;
  /** delete data from the table: "WUI_SellerProjectItem" */
  delete_WUI_SellerProjectItem?: Maybe<Wui_SellerProjectItem_Mutation_Response>;
  /** delete single row from the table: "WUI_SellerProjectItem" */
  delete_WUI_SellerProjectItem_by_pk?: Maybe<Wui_SellerProjectItem>;
  /** delete single row from the table: "WUI_SellerProject" */
  delete_WUI_SellerProject_by_pk?: Maybe<Wui_SellerProject>;
  /** delete data from the table: "WUI_Session" */
  delete_WUI_Session?: Maybe<Wui_Session_Mutation_Response>;
  /** delete data from the table: "WUI_SessionEvent" */
  delete_WUI_SessionEvent?: Maybe<Wui_SessionEvent_Mutation_Response>;
  /** delete data from the table: "WUI_SessionEventType" */
  delete_WUI_SessionEventType?: Maybe<Wui_SessionEventType_Mutation_Response>;
  /** delete single row from the table: "WUI_SessionEventType" */
  delete_WUI_SessionEventType_by_pk?: Maybe<Wui_SessionEventType>;
  /** delete single row from the table: "WUI_SessionEvent" */
  delete_WUI_SessionEvent_by_pk?: Maybe<Wui_SessionEvent>;
  /** delete single row from the table: "WUI_Session" */
  delete_WUI_Session_by_pk?: Maybe<Wui_Session>;
  /** delete data from the table: "WUI_SocialMediaSource" */
  delete_WUI_SocialMediaSource?: Maybe<Wui_SocialMediaSource_Mutation_Response>;
  /** delete single row from the table: "WUI_SocialMediaSource" */
  delete_WUI_SocialMediaSource_by_pk?: Maybe<Wui_SocialMediaSource>;
  /** delete data from the table: "WUI_User" */
  delete_WUI_User?: Maybe<Wui_User_Mutation_Response>;
  /** delete data from the table: "WUI_UserFilePortfolio" */
  delete_WUI_UserFilePortfolio?: Maybe<Wui_UserFilePortfolio_Mutation_Response>;
  /** delete single row from the table: "WUI_UserFilePortfolio" */
  delete_WUI_UserFilePortfolio_by_pk?: Maybe<Wui_UserFilePortfolio>;
  /** delete data from the table: "WUI_UserReview" */
  delete_WUI_UserReview?: Maybe<Wui_UserReview_Mutation_Response>;
  /** delete single row from the table: "WUI_UserReview" */
  delete_WUI_UserReview_by_pk?: Maybe<Wui_UserReview>;
  /** delete single row from the table: "WUI_User" */
  delete_WUI_User_by_pk?: Maybe<Wui_User>;
  /** delete data from the table: "wui_userlastseen" */
  delete_wui_userlastseen?: Maybe<Wui_Userlastseen_Mutation_Response>;
  /** insert data into the table: "Demographic" */
  insert_Demographic?: Maybe<Demographic_Mutation_Response>;
  /** insert a single row into the table: "Demographic" */
  insert_Demographic_one?: Maybe<Demographic>;
  /** insert data into the table: "HeroBanner" */
  insert_HeroBanner?: Maybe<HeroBanner_Mutation_Response>;
  /** insert a single row into the table: "HeroBanner" */
  insert_HeroBanner_one?: Maybe<HeroBanner>;
  /** insert data into the table: "ProjectDemographic" */
  insert_ProjectDemographic?: Maybe<ProjectDemographic_Mutation_Response>;
  /** insert a single row into the table: "ProjectDemographic" */
  insert_ProjectDemographic_one?: Maybe<ProjectDemographic>;
  /** insert data into the table: "SocialMediaDemographic" */
  insert_SocialMediaDemographic?: Maybe<SocialMediaDemographic_Mutation_Response>;
  /** insert a single row into the table: "SocialMediaDemographic" */
  insert_SocialMediaDemographic_one?: Maybe<SocialMediaDemographic>;
  /** insert data into the table: "SocialMediaLink" */
  insert_SocialMediaLink?: Maybe<SocialMediaLink_Mutation_Response>;
  /** insert a single row into the table: "SocialMediaLink" */
  insert_SocialMediaLink_one?: Maybe<SocialMediaLink>;
  /** insert data into the table: "WUI_AuthProvider" */
  insert_WUI_AuthProvider?: Maybe<Wui_AuthProvider_Mutation_Response>;
  /** insert a single row into the table: "WUI_AuthProvider" */
  insert_WUI_AuthProvider_one?: Maybe<Wui_AuthProvider>;
  /** insert data into the table: "WUI_ChatRoom" */
  insert_WUI_ChatRoom?: Maybe<Wui_ChatRoom_Mutation_Response>;
  /** insert data into the table: "WUI_ChatRoomMessage" */
  insert_WUI_ChatRoomMessage?: Maybe<Wui_ChatRoomMessage_Mutation_Response>;
  /** insert a single row into the table: "WUI_ChatRoomMessage" */
  insert_WUI_ChatRoomMessage_one?: Maybe<Wui_ChatRoomMessage>;
  /** insert data into the table: "WUI_ChatRoomOrder" */
  insert_WUI_ChatRoomOrder?: Maybe<Wui_ChatRoomOrder_Mutation_Response>;
  /** insert a single row into the table: "WUI_ChatRoomOrder" */
  insert_WUI_ChatRoomOrder_one?: Maybe<Wui_ChatRoomOrder>;
  /** insert data into the table: "WUI_ChatRoomType" */
  insert_WUI_ChatRoomType?: Maybe<Wui_ChatRoomType_Mutation_Response>;
  /** insert a single row into the table: "WUI_ChatRoomType" */
  insert_WUI_ChatRoomType_one?: Maybe<Wui_ChatRoomType>;
  /** insert data into the table: "WUI_ChatRoomUser" */
  insert_WUI_ChatRoomUser?: Maybe<Wui_ChatRoomUser_Mutation_Response>;
  /** insert a single row into the table: "WUI_ChatRoomUser" */
  insert_WUI_ChatRoomUser_one?: Maybe<Wui_ChatRoomUser>;
  /** insert a single row into the table: "WUI_ChatRoom" */
  insert_WUI_ChatRoom_one?: Maybe<Wui_ChatRoom>;
  /** insert data into the table: "WUI_File" */
  insert_WUI_File?: Maybe<Wui_File_Mutation_Response>;
  /** insert data into the table: "WUI_FileType" */
  insert_WUI_FileType?: Maybe<Wui_FileType_Mutation_Response>;
  /** insert a single row into the table: "WUI_FileType" */
  insert_WUI_FileType_one?: Maybe<Wui_FileType>;
  /** insert a single row into the table: "WUI_File" */
  insert_WUI_File_one?: Maybe<Wui_File>;
  /** insert data into the table: "WUI_Notification" */
  insert_WUI_Notification?: Maybe<Wui_Notification_Mutation_Response>;
  /** insert a single row into the table: "WUI_Notification" */
  insert_WUI_Notification_one?: Maybe<Wui_Notification>;
  /** insert data into the table: "WUI_Order" */
  insert_WUI_Order?: Maybe<Wui_Order_Mutation_Response>;
  /** insert data into the table: "WUI_OrderReview" */
  insert_WUI_OrderReview?: Maybe<Wui_OrderReview_Mutation_Response>;
  /** insert a single row into the table: "WUI_OrderReview" */
  insert_WUI_OrderReview_one?: Maybe<Wui_OrderReview>;
  /** insert data into the table: "WUI_OrderStatus" */
  insert_WUI_OrderStatus?: Maybe<Wui_OrderStatus_Mutation_Response>;
  /** insert a single row into the table: "WUI_OrderStatus" */
  insert_WUI_OrderStatus_one?: Maybe<Wui_OrderStatus>;
  /** insert a single row into the table: "WUI_Order" */
  insert_WUI_Order_one?: Maybe<Wui_Order>;
  /** insert data into the table: "WUI_Payment" */
  insert_WUI_Payment?: Maybe<Wui_Payment_Mutation_Response>;
  /** insert data into the table: "WUI_PaymentMethod" */
  insert_WUI_PaymentMethod?: Maybe<Wui_PaymentMethod_Mutation_Response>;
  /** insert data into the table: "WUI_PaymentMethodBankAccount" */
  insert_WUI_PaymentMethodBankAccount?: Maybe<Wui_PaymentMethodBankAccount_Mutation_Response>;
  /** insert a single row into the table: "WUI_PaymentMethodBankAccount" */
  insert_WUI_PaymentMethodBankAccount_one?: Maybe<Wui_PaymentMethodBankAccount>;
  /** insert data into the table: "WUI_PaymentMethodCard" */
  insert_WUI_PaymentMethodCard?: Maybe<Wui_PaymentMethodCard_Mutation_Response>;
  /** insert a single row into the table: "WUI_PaymentMethodCard" */
  insert_WUI_PaymentMethodCard_one?: Maybe<Wui_PaymentMethodCard>;
  /** insert data into the table: "WUI_PaymentMethodSource" */
  insert_WUI_PaymentMethodSource?: Maybe<Wui_PaymentMethodSource_Mutation_Response>;
  /** insert a single row into the table: "WUI_PaymentMethodSource" */
  insert_WUI_PaymentMethodSource_one?: Maybe<Wui_PaymentMethodSource>;
  /** insert a single row into the table: "WUI_PaymentMethod" */
  insert_WUI_PaymentMethod_one?: Maybe<Wui_PaymentMethod>;
  /** insert data into the table: "WUI_PaymentStatus" */
  insert_WUI_PaymentStatus?: Maybe<Wui_PaymentStatus_Mutation_Response>;
  /** insert a single row into the table: "WUI_PaymentStatus" */
  insert_WUI_PaymentStatus_one?: Maybe<Wui_PaymentStatus>;
  /** insert a single row into the table: "WUI_Payment" */
  insert_WUI_Payment_one?: Maybe<Wui_Payment>;
  /** insert data into the table: "WUI_Role" */
  insert_WUI_Role?: Maybe<Wui_Role_Mutation_Response>;
  /** insert a single row into the table: "WUI_Role" */
  insert_WUI_Role_one?: Maybe<Wui_Role>;
  /** insert data into the table: "WUI_SellerPackage" */
  insert_WUI_SellerPackage?: Maybe<Wui_SellerPackage_Mutation_Response>;
  /** insert data into the table: "WUI_SellerPackageItem" */
  insert_WUI_SellerPackageItem?: Maybe<Wui_SellerPackageItem_Mutation_Response>;
  /** insert a single row into the table: "WUI_SellerPackageItem" */
  insert_WUI_SellerPackageItem_one?: Maybe<Wui_SellerPackageItem>;
  /** insert data into the table: "WUI_SellerPackageType" */
  insert_WUI_SellerPackageType?: Maybe<Wui_SellerPackageType_Mutation_Response>;
  /** insert a single row into the table: "WUI_SellerPackageType" */
  insert_WUI_SellerPackageType_one?: Maybe<Wui_SellerPackageType>;
  /** insert a single row into the table: "WUI_SellerPackage" */
  insert_WUI_SellerPackage_one?: Maybe<Wui_SellerPackage>;
  /** insert data into the table: "WUI_SellerProject" */
  insert_WUI_SellerProject?: Maybe<Wui_SellerProject_Mutation_Response>;
  /** insert data into the table: "WUI_SellerProjectFile" */
  insert_WUI_SellerProjectFile?: Maybe<Wui_SellerProjectFile_Mutation_Response>;
  /** insert a single row into the table: "WUI_SellerProjectFile" */
  insert_WUI_SellerProjectFile_one?: Maybe<Wui_SellerProjectFile>;
  /** insert data into the table: "WUI_SellerProjectItem" */
  insert_WUI_SellerProjectItem?: Maybe<Wui_SellerProjectItem_Mutation_Response>;
  /** insert a single row into the table: "WUI_SellerProjectItem" */
  insert_WUI_SellerProjectItem_one?: Maybe<Wui_SellerProjectItem>;
  /** insert a single row into the table: "WUI_SellerProject" */
  insert_WUI_SellerProject_one?: Maybe<Wui_SellerProject>;
  /** insert data into the table: "WUI_Session" */
  insert_WUI_Session?: Maybe<Wui_Session_Mutation_Response>;
  /** insert data into the table: "WUI_SessionEvent" */
  insert_WUI_SessionEvent?: Maybe<Wui_SessionEvent_Mutation_Response>;
  /** insert data into the table: "WUI_SessionEventType" */
  insert_WUI_SessionEventType?: Maybe<Wui_SessionEventType_Mutation_Response>;
  /** insert a single row into the table: "WUI_SessionEventType" */
  insert_WUI_SessionEventType_one?: Maybe<Wui_SessionEventType>;
  /** insert a single row into the table: "WUI_SessionEvent" */
  insert_WUI_SessionEvent_one?: Maybe<Wui_SessionEvent>;
  /** insert a single row into the table: "WUI_Session" */
  insert_WUI_Session_one?: Maybe<Wui_Session>;
  /** insert data into the table: "WUI_SocialMediaSource" */
  insert_WUI_SocialMediaSource?: Maybe<Wui_SocialMediaSource_Mutation_Response>;
  /** insert a single row into the table: "WUI_SocialMediaSource" */
  insert_WUI_SocialMediaSource_one?: Maybe<Wui_SocialMediaSource>;
  /** insert data into the table: "WUI_User" */
  insert_WUI_User?: Maybe<Wui_User_Mutation_Response>;
  /** insert data into the table: "WUI_UserFilePortfolio" */
  insert_WUI_UserFilePortfolio?: Maybe<Wui_UserFilePortfolio_Mutation_Response>;
  /** insert a single row into the table: "WUI_UserFilePortfolio" */
  insert_WUI_UserFilePortfolio_one?: Maybe<Wui_UserFilePortfolio>;
  /** insert data into the table: "WUI_UserReview" */
  insert_WUI_UserReview?: Maybe<Wui_UserReview_Mutation_Response>;
  /** insert a single row into the table: "WUI_UserReview" */
  insert_WUI_UserReview_one?: Maybe<Wui_UserReview>;
  /** insert a single row into the table: "WUI_User" */
  insert_WUI_User_one?: Maybe<Wui_User>;
  /** insert data into the table: "wui_userlastseen" */
  insert_wui_userlastseen?: Maybe<Wui_Userlastseen_Mutation_Response>;
  /** insert a single row into the table: "wui_userlastseen" */
  insert_wui_userlastseen_one?: Maybe<Wui_Userlastseen>;
  login?: Maybe<LoginOutput>;
  loginAdmin?: Maybe<LoginAdminOutput>;
  removeBankAccount?: Maybe<RemoveBankAccountOutput>;
  remove_WUI_PaymentMethodCard?: Maybe<RemovePaymentMethodCardOutput>;
  signup?: Maybe<SignupOutput>;
  updateStatus_WUI_Order?: Maybe<UpdateOrderOutput>;
  /** update data of the table: "Demographic" */
  update_Demographic?: Maybe<Demographic_Mutation_Response>;
  /** update single row of the table: "Demographic" */
  update_Demographic_by_pk?: Maybe<Demographic>;
  /** update data of the table: "HeroBanner" */
  update_HeroBanner?: Maybe<HeroBanner_Mutation_Response>;
  /** update single row of the table: "HeroBanner" */
  update_HeroBanner_by_pk?: Maybe<HeroBanner>;
  /** update data of the table: "ProjectDemographic" */
  update_ProjectDemographic?: Maybe<ProjectDemographic_Mutation_Response>;
  /** update single row of the table: "ProjectDemographic" */
  update_ProjectDemographic_by_pk?: Maybe<ProjectDemographic>;
  /** update data of the table: "SocialMediaDemographic" */
  update_SocialMediaDemographic?: Maybe<SocialMediaDemographic_Mutation_Response>;
  /** update single row of the table: "SocialMediaDemographic" */
  update_SocialMediaDemographic_by_pk?: Maybe<SocialMediaDemographic>;
  /** update data of the table: "SocialMediaLink" */
  update_SocialMediaLink?: Maybe<SocialMediaLink_Mutation_Response>;
  /** update single row of the table: "SocialMediaLink" */
  update_SocialMediaLink_by_pk?: Maybe<SocialMediaLink>;
  /** update data of the table: "WUI_AuthProvider" */
  update_WUI_AuthProvider?: Maybe<Wui_AuthProvider_Mutation_Response>;
  /** update single row of the table: "WUI_AuthProvider" */
  update_WUI_AuthProvider_by_pk?: Maybe<Wui_AuthProvider>;
  /** update data of the table: "WUI_ChatRoom" */
  update_WUI_ChatRoom?: Maybe<Wui_ChatRoom_Mutation_Response>;
  /** update data of the table: "WUI_ChatRoomMessage" */
  update_WUI_ChatRoomMessage?: Maybe<Wui_ChatRoomMessage_Mutation_Response>;
  /** update single row of the table: "WUI_ChatRoomMessage" */
  update_WUI_ChatRoomMessage_by_pk?: Maybe<Wui_ChatRoomMessage>;
  /** update data of the table: "WUI_ChatRoomOrder" */
  update_WUI_ChatRoomOrder?: Maybe<Wui_ChatRoomOrder_Mutation_Response>;
  /** update single row of the table: "WUI_ChatRoomOrder" */
  update_WUI_ChatRoomOrder_by_pk?: Maybe<Wui_ChatRoomOrder>;
  /** update data of the table: "WUI_ChatRoomType" */
  update_WUI_ChatRoomType?: Maybe<Wui_ChatRoomType_Mutation_Response>;
  /** update single row of the table: "WUI_ChatRoomType" */
  update_WUI_ChatRoomType_by_pk?: Maybe<Wui_ChatRoomType>;
  /** update data of the table: "WUI_ChatRoomUser" */
  update_WUI_ChatRoomUser?: Maybe<Wui_ChatRoomUser_Mutation_Response>;
  /** update single row of the table: "WUI_ChatRoomUser" */
  update_WUI_ChatRoomUser_by_pk?: Maybe<Wui_ChatRoomUser>;
  /** update single row of the table: "WUI_ChatRoom" */
  update_WUI_ChatRoom_by_pk?: Maybe<Wui_ChatRoom>;
  /** update data of the table: "WUI_File" */
  update_WUI_File?: Maybe<Wui_File_Mutation_Response>;
  /** update data of the table: "WUI_FileType" */
  update_WUI_FileType?: Maybe<Wui_FileType_Mutation_Response>;
  /** update single row of the table: "WUI_FileType" */
  update_WUI_FileType_by_pk?: Maybe<Wui_FileType>;
  /** update single row of the table: "WUI_File" */
  update_WUI_File_by_pk?: Maybe<Wui_File>;
  /** update data of the table: "WUI_Notification" */
  update_WUI_Notification?: Maybe<Wui_Notification_Mutation_Response>;
  /** update single row of the table: "WUI_Notification" */
  update_WUI_Notification_by_pk?: Maybe<Wui_Notification>;
  /** update data of the table: "WUI_Order" */
  update_WUI_Order?: Maybe<Wui_Order_Mutation_Response>;
  /** update data of the table: "WUI_OrderReview" */
  update_WUI_OrderReview?: Maybe<Wui_OrderReview_Mutation_Response>;
  /** update single row of the table: "WUI_OrderReview" */
  update_WUI_OrderReview_by_pk?: Maybe<Wui_OrderReview>;
  /** update data of the table: "WUI_OrderStatus" */
  update_WUI_OrderStatus?: Maybe<Wui_OrderStatus_Mutation_Response>;
  /** update single row of the table: "WUI_OrderStatus" */
  update_WUI_OrderStatus_by_pk?: Maybe<Wui_OrderStatus>;
  /** update single row of the table: "WUI_Order" */
  update_WUI_Order_by_pk?: Maybe<Wui_Order>;
  /** update data of the table: "WUI_Payment" */
  update_WUI_Payment?: Maybe<Wui_Payment_Mutation_Response>;
  /** update data of the table: "WUI_PaymentMethod" */
  update_WUI_PaymentMethod?: Maybe<Wui_PaymentMethod_Mutation_Response>;
  /** update data of the table: "WUI_PaymentMethodBankAccount" */
  update_WUI_PaymentMethodBankAccount?: Maybe<Wui_PaymentMethodBankAccount_Mutation_Response>;
  /** update single row of the table: "WUI_PaymentMethodBankAccount" */
  update_WUI_PaymentMethodBankAccount_by_pk?: Maybe<Wui_PaymentMethodBankAccount>;
  /** update data of the table: "WUI_PaymentMethodCard" */
  update_WUI_PaymentMethodCard?: Maybe<Wui_PaymentMethodCard_Mutation_Response>;
  /** update single row of the table: "WUI_PaymentMethodCard" */
  update_WUI_PaymentMethodCard_by_pk?: Maybe<Wui_PaymentMethodCard>;
  /** update data of the table: "WUI_PaymentMethodSource" */
  update_WUI_PaymentMethodSource?: Maybe<Wui_PaymentMethodSource_Mutation_Response>;
  /** update single row of the table: "WUI_PaymentMethodSource" */
  update_WUI_PaymentMethodSource_by_pk?: Maybe<Wui_PaymentMethodSource>;
  /** update single row of the table: "WUI_PaymentMethod" */
  update_WUI_PaymentMethod_by_pk?: Maybe<Wui_PaymentMethod>;
  /** update data of the table: "WUI_PaymentStatus" */
  update_WUI_PaymentStatus?: Maybe<Wui_PaymentStatus_Mutation_Response>;
  /** update single row of the table: "WUI_PaymentStatus" */
  update_WUI_PaymentStatus_by_pk?: Maybe<Wui_PaymentStatus>;
  /** update single row of the table: "WUI_Payment" */
  update_WUI_Payment_by_pk?: Maybe<Wui_Payment>;
  /** update data of the table: "WUI_Role" */
  update_WUI_Role?: Maybe<Wui_Role_Mutation_Response>;
  /** update single row of the table: "WUI_Role" */
  update_WUI_Role_by_pk?: Maybe<Wui_Role>;
  /** update data of the table: "WUI_SellerPackage" */
  update_WUI_SellerPackage?: Maybe<Wui_SellerPackage_Mutation_Response>;
  /** update data of the table: "WUI_SellerPackageItem" */
  update_WUI_SellerPackageItem?: Maybe<Wui_SellerPackageItem_Mutation_Response>;
  /** update single row of the table: "WUI_SellerPackageItem" */
  update_WUI_SellerPackageItem_by_pk?: Maybe<Wui_SellerPackageItem>;
  /** update data of the table: "WUI_SellerPackageType" */
  update_WUI_SellerPackageType?: Maybe<Wui_SellerPackageType_Mutation_Response>;
  /** update single row of the table: "WUI_SellerPackageType" */
  update_WUI_SellerPackageType_by_pk?: Maybe<Wui_SellerPackageType>;
  /** update single row of the table: "WUI_SellerPackage" */
  update_WUI_SellerPackage_by_pk?: Maybe<Wui_SellerPackage>;
  /** update data of the table: "WUI_SellerProject" */
  update_WUI_SellerProject?: Maybe<Wui_SellerProject_Mutation_Response>;
  /** update data of the table: "WUI_SellerProjectFile" */
  update_WUI_SellerProjectFile?: Maybe<Wui_SellerProjectFile_Mutation_Response>;
  /** update single row of the table: "WUI_SellerProjectFile" */
  update_WUI_SellerProjectFile_by_pk?: Maybe<Wui_SellerProjectFile>;
  /** update data of the table: "WUI_SellerProjectItem" */
  update_WUI_SellerProjectItem?: Maybe<Wui_SellerProjectItem_Mutation_Response>;
  /** update single row of the table: "WUI_SellerProjectItem" */
  update_WUI_SellerProjectItem_by_pk?: Maybe<Wui_SellerProjectItem>;
  /** update single row of the table: "WUI_SellerProject" */
  update_WUI_SellerProject_by_pk?: Maybe<Wui_SellerProject>;
  /** update data of the table: "WUI_Session" */
  update_WUI_Session?: Maybe<Wui_Session_Mutation_Response>;
  /** update data of the table: "WUI_SessionEvent" */
  update_WUI_SessionEvent?: Maybe<Wui_SessionEvent_Mutation_Response>;
  /** update data of the table: "WUI_SessionEventType" */
  update_WUI_SessionEventType?: Maybe<Wui_SessionEventType_Mutation_Response>;
  /** update single row of the table: "WUI_SessionEventType" */
  update_WUI_SessionEventType_by_pk?: Maybe<Wui_SessionEventType>;
  /** update single row of the table: "WUI_SessionEvent" */
  update_WUI_SessionEvent_by_pk?: Maybe<Wui_SessionEvent>;
  /** update single row of the table: "WUI_Session" */
  update_WUI_Session_by_pk?: Maybe<Wui_Session>;
  /** update data of the table: "WUI_SocialMediaSource" */
  update_WUI_SocialMediaSource?: Maybe<Wui_SocialMediaSource_Mutation_Response>;
  /** update single row of the table: "WUI_SocialMediaSource" */
  update_WUI_SocialMediaSource_by_pk?: Maybe<Wui_SocialMediaSource>;
  /** update data of the table: "WUI_User" */
  update_WUI_User?: Maybe<Wui_User_Mutation_Response>;
  /** update data of the table: "WUI_UserFilePortfolio" */
  update_WUI_UserFilePortfolio?: Maybe<Wui_UserFilePortfolio_Mutation_Response>;
  /** update single row of the table: "WUI_UserFilePortfolio" */
  update_WUI_UserFilePortfolio_by_pk?: Maybe<Wui_UserFilePortfolio>;
  /** update data of the table: "WUI_UserReview" */
  update_WUI_UserReview?: Maybe<Wui_UserReview_Mutation_Response>;
  /** update single row of the table: "WUI_UserReview" */
  update_WUI_UserReview_by_pk?: Maybe<Wui_UserReview>;
  /** update single row of the table: "WUI_User" */
  update_WUI_User_by_pk?: Maybe<Wui_User>;
  /** update data of the table: "wui_userlastseen" */
  update_wui_userlastseen?: Maybe<Wui_Userlastseen_Mutation_Response>;
  uploadCoverPicture_WUI_user?: Maybe<FileOutput>;
  uploadPicture_HeroBanner?: Maybe<FileOutput>;
  uploadPicture_WUI_ChatRoomMessage?: Maybe<FileOutput>;
  uploadPicture_WUI_ProjectFile?: Maybe<ProjectFileOutput>;
  uploadPicture_WUI_UseFilePortfolio?: Maybe<PortfolioFileOutput>;
  uploadProfilePicture_WUI_User?: Maybe<FileOutput>;
};


/** mutation root */
export type Mutation_RootCreateBankAccountArgs = {
  token: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCreateCompanyAccountArgs = {
  address: CompanyAddress;
  name: Scalars['String'];
  taxID: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCreateIndividualAccountArgs = {
  address: IndividualAddress;
  birthday: Scalars['String'];
  firstName: Scalars['String'];
  last4ssn: Scalars['String'];
  lastName: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCreate_Wui_OrderArgs = {
  packageFees: Array<Maybe<Scalars['String']>>;
  packageId: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootCreate_Wui_OrderDetailsArgs = {
  data: Scalars['jsonb'];
  roomID: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootCreate_Wui_PaymentIntentArgs = {
  orderId: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootCreate_Wui_PaymentMethodCardArgs = {
  paymentMethodId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCreate_Wui_SessionEventArgs = {
  eventType: Scalars['String'];
  linkId?: Maybe<Scalars['bigint']>;
  sellerId: Scalars['bigint'];
  sessionId: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDefaultBankAccountArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_DemographicArgs = {
  where: Demographic_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Demographic_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_HeroBannerArgs = {
  where: HeroBanner_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_HeroBanner_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_ProjectDemographicArgs = {
  where: ProjectDemographic_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ProjectDemographic_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_SocialMediaDemographicArgs = {
  where: SocialMediaDemographic_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SocialMediaDemographic_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_SocialMediaLinkArgs = {
  where: SocialMediaLink_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SocialMediaLink_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_AuthProviderArgs = {
  where: Wui_AuthProvider_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_AuthProvider_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_ChatRoomArgs = {
  where: Wui_ChatRoom_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_ChatRoomMessageArgs = {
  where: Wui_ChatRoomMessage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_ChatRoomMessage_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_ChatRoomOrderArgs = {
  where: Wui_ChatRoomOrder_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_ChatRoomOrder_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_ChatRoomTypeArgs = {
  where: Wui_ChatRoomType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_ChatRoomType_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_ChatRoomUserArgs = {
  where: Wui_ChatRoomUser_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_ChatRoomUser_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_ChatRoom_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_FileArgs = {
  where: Wui_File_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_FileTypeArgs = {
  where: Wui_FileType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_FileType_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_File_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_NotificationArgs = {
  where: Wui_Notification_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_Notification_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_OrderArgs = {
  where: Wui_Order_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_OrderReviewArgs = {
  where: Wui_OrderReview_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_OrderReview_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_OrderStatusArgs = {
  where: Wui_OrderStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_OrderStatus_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_Order_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_PaymentArgs = {
  where: Wui_Payment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_PaymentMethodArgs = {
  where: Wui_PaymentMethod_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_PaymentMethodBankAccountArgs = {
  where: Wui_PaymentMethodBankAccount_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_PaymentMethodBankAccount_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_PaymentMethodCardArgs = {
  where: Wui_PaymentMethodCard_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_PaymentMethodCard_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_PaymentMethodSourceArgs = {
  where: Wui_PaymentMethodSource_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_PaymentMethodSource_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_PaymentMethod_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_PaymentStatusArgs = {
  where: Wui_PaymentStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_PaymentStatus_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_Payment_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_RoleArgs = {
  where: Wui_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_Role_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_SellerPackageArgs = {
  where: Wui_SellerPackage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_SellerPackageItemArgs = {
  where: Wui_SellerPackageItem_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_SellerPackageItem_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_SellerPackageTypeArgs = {
  where: Wui_SellerPackageType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_SellerPackageType_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_SellerPackage_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_SellerProjectArgs = {
  where: Wui_SellerProject_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_SellerProjectFileArgs = {
  where: Wui_SellerProjectFile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_SellerProjectFile_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_SellerProjectItemArgs = {
  where: Wui_SellerProjectItem_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_SellerProjectItem_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_SellerProject_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_SessionArgs = {
  where: Wui_Session_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_SessionEventArgs = {
  where: Wui_SessionEvent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_SessionEventTypeArgs = {
  where: Wui_SessionEventType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_SessionEventType_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_SessionEvent_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_Session_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_SocialMediaSourceArgs = {
  where: Wui_SocialMediaSource_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_SocialMediaSource_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_UserArgs = {
  where: Wui_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_UserFilePortfolioArgs = {
  where: Wui_UserFilePortfolio_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_UserFilePortfolio_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_UserReviewArgs = {
  where: Wui_UserReview_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wui_UserReview_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_User_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Wui_UserlastseenArgs = {
  where: Wui_Userlastseen_Bool_Exp;
};


/** mutation root */
export type Mutation_RootInsert_DemographicArgs = {
  objects: Array<Demographic_Insert_Input>;
  on_conflict?: Maybe<Demographic_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Demographic_OneArgs = {
  object: Demographic_Insert_Input;
  on_conflict?: Maybe<Demographic_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_HeroBannerArgs = {
  objects: Array<HeroBanner_Insert_Input>;
  on_conflict?: Maybe<HeroBanner_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_HeroBanner_OneArgs = {
  object: HeroBanner_Insert_Input;
  on_conflict?: Maybe<HeroBanner_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProjectDemographicArgs = {
  objects: Array<ProjectDemographic_Insert_Input>;
  on_conflict?: Maybe<ProjectDemographic_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProjectDemographic_OneArgs = {
  object: ProjectDemographic_Insert_Input;
  on_conflict?: Maybe<ProjectDemographic_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SocialMediaDemographicArgs = {
  objects: Array<SocialMediaDemographic_Insert_Input>;
  on_conflict?: Maybe<SocialMediaDemographic_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SocialMediaDemographic_OneArgs = {
  object: SocialMediaDemographic_Insert_Input;
  on_conflict?: Maybe<SocialMediaDemographic_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SocialMediaLinkArgs = {
  objects: Array<SocialMediaLink_Insert_Input>;
  on_conflict?: Maybe<SocialMediaLink_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SocialMediaLink_OneArgs = {
  object: SocialMediaLink_Insert_Input;
  on_conflict?: Maybe<SocialMediaLink_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_AuthProviderArgs = {
  objects: Array<Wui_AuthProvider_Insert_Input>;
  on_conflict?: Maybe<Wui_AuthProvider_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_AuthProvider_OneArgs = {
  object: Wui_AuthProvider_Insert_Input;
  on_conflict?: Maybe<Wui_AuthProvider_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_ChatRoomArgs = {
  objects: Array<Wui_ChatRoom_Insert_Input>;
  on_conflict?: Maybe<Wui_ChatRoom_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_ChatRoomMessageArgs = {
  objects: Array<Wui_ChatRoomMessage_Insert_Input>;
  on_conflict?: Maybe<Wui_ChatRoomMessage_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_ChatRoomMessage_OneArgs = {
  object: Wui_ChatRoomMessage_Insert_Input;
  on_conflict?: Maybe<Wui_ChatRoomMessage_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_ChatRoomOrderArgs = {
  objects: Array<Wui_ChatRoomOrder_Insert_Input>;
  on_conflict?: Maybe<Wui_ChatRoomOrder_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_ChatRoomOrder_OneArgs = {
  object: Wui_ChatRoomOrder_Insert_Input;
  on_conflict?: Maybe<Wui_ChatRoomOrder_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_ChatRoomTypeArgs = {
  objects: Array<Wui_ChatRoomType_Insert_Input>;
  on_conflict?: Maybe<Wui_ChatRoomType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_ChatRoomType_OneArgs = {
  object: Wui_ChatRoomType_Insert_Input;
  on_conflict?: Maybe<Wui_ChatRoomType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_ChatRoomUserArgs = {
  objects: Array<Wui_ChatRoomUser_Insert_Input>;
  on_conflict?: Maybe<Wui_ChatRoomUser_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_ChatRoomUser_OneArgs = {
  object: Wui_ChatRoomUser_Insert_Input;
  on_conflict?: Maybe<Wui_ChatRoomUser_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_ChatRoom_OneArgs = {
  object: Wui_ChatRoom_Insert_Input;
  on_conflict?: Maybe<Wui_ChatRoom_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_FileArgs = {
  objects: Array<Wui_File_Insert_Input>;
  on_conflict?: Maybe<Wui_File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_FileTypeArgs = {
  objects: Array<Wui_FileType_Insert_Input>;
  on_conflict?: Maybe<Wui_FileType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_FileType_OneArgs = {
  object: Wui_FileType_Insert_Input;
  on_conflict?: Maybe<Wui_FileType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_File_OneArgs = {
  object: Wui_File_Insert_Input;
  on_conflict?: Maybe<Wui_File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_NotificationArgs = {
  objects: Array<Wui_Notification_Insert_Input>;
  on_conflict?: Maybe<Wui_Notification_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_Notification_OneArgs = {
  object: Wui_Notification_Insert_Input;
  on_conflict?: Maybe<Wui_Notification_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_OrderArgs = {
  objects: Array<Wui_Order_Insert_Input>;
  on_conflict?: Maybe<Wui_Order_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_OrderReviewArgs = {
  objects: Array<Wui_OrderReview_Insert_Input>;
  on_conflict?: Maybe<Wui_OrderReview_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_OrderReview_OneArgs = {
  object: Wui_OrderReview_Insert_Input;
  on_conflict?: Maybe<Wui_OrderReview_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_OrderStatusArgs = {
  objects: Array<Wui_OrderStatus_Insert_Input>;
  on_conflict?: Maybe<Wui_OrderStatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_OrderStatus_OneArgs = {
  object: Wui_OrderStatus_Insert_Input;
  on_conflict?: Maybe<Wui_OrderStatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_Order_OneArgs = {
  object: Wui_Order_Insert_Input;
  on_conflict?: Maybe<Wui_Order_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_PaymentArgs = {
  objects: Array<Wui_Payment_Insert_Input>;
  on_conflict?: Maybe<Wui_Payment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_PaymentMethodArgs = {
  objects: Array<Wui_PaymentMethod_Insert_Input>;
  on_conflict?: Maybe<Wui_PaymentMethod_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_PaymentMethodBankAccountArgs = {
  objects: Array<Wui_PaymentMethodBankAccount_Insert_Input>;
  on_conflict?: Maybe<Wui_PaymentMethodBankAccount_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_PaymentMethodBankAccount_OneArgs = {
  object: Wui_PaymentMethodBankAccount_Insert_Input;
  on_conflict?: Maybe<Wui_PaymentMethodBankAccount_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_PaymentMethodCardArgs = {
  objects: Array<Wui_PaymentMethodCard_Insert_Input>;
  on_conflict?: Maybe<Wui_PaymentMethodCard_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_PaymentMethodCard_OneArgs = {
  object: Wui_PaymentMethodCard_Insert_Input;
  on_conflict?: Maybe<Wui_PaymentMethodCard_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_PaymentMethodSourceArgs = {
  objects: Array<Wui_PaymentMethodSource_Insert_Input>;
  on_conflict?: Maybe<Wui_PaymentMethodSource_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_PaymentMethodSource_OneArgs = {
  object: Wui_PaymentMethodSource_Insert_Input;
  on_conflict?: Maybe<Wui_PaymentMethodSource_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_PaymentMethod_OneArgs = {
  object: Wui_PaymentMethod_Insert_Input;
  on_conflict?: Maybe<Wui_PaymentMethod_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_PaymentStatusArgs = {
  objects: Array<Wui_PaymentStatus_Insert_Input>;
  on_conflict?: Maybe<Wui_PaymentStatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_PaymentStatus_OneArgs = {
  object: Wui_PaymentStatus_Insert_Input;
  on_conflict?: Maybe<Wui_PaymentStatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_Payment_OneArgs = {
  object: Wui_Payment_Insert_Input;
  on_conflict?: Maybe<Wui_Payment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_RoleArgs = {
  objects: Array<Wui_Role_Insert_Input>;
  on_conflict?: Maybe<Wui_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_Role_OneArgs = {
  object: Wui_Role_Insert_Input;
  on_conflict?: Maybe<Wui_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_SellerPackageArgs = {
  objects: Array<Wui_SellerPackage_Insert_Input>;
  on_conflict?: Maybe<Wui_SellerPackage_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_SellerPackageItemArgs = {
  objects: Array<Wui_SellerPackageItem_Insert_Input>;
  on_conflict?: Maybe<Wui_SellerPackageItem_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_SellerPackageItem_OneArgs = {
  object: Wui_SellerPackageItem_Insert_Input;
  on_conflict?: Maybe<Wui_SellerPackageItem_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_SellerPackageTypeArgs = {
  objects: Array<Wui_SellerPackageType_Insert_Input>;
  on_conflict?: Maybe<Wui_SellerPackageType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_SellerPackageType_OneArgs = {
  object: Wui_SellerPackageType_Insert_Input;
  on_conflict?: Maybe<Wui_SellerPackageType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_SellerPackage_OneArgs = {
  object: Wui_SellerPackage_Insert_Input;
  on_conflict?: Maybe<Wui_SellerPackage_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_SellerProjectArgs = {
  objects: Array<Wui_SellerProject_Insert_Input>;
  on_conflict?: Maybe<Wui_SellerProject_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_SellerProjectFileArgs = {
  objects: Array<Wui_SellerProjectFile_Insert_Input>;
  on_conflict?: Maybe<Wui_SellerProjectFile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_SellerProjectFile_OneArgs = {
  object: Wui_SellerProjectFile_Insert_Input;
  on_conflict?: Maybe<Wui_SellerProjectFile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_SellerProjectItemArgs = {
  objects: Array<Wui_SellerProjectItem_Insert_Input>;
  on_conflict?: Maybe<Wui_SellerProjectItem_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_SellerProjectItem_OneArgs = {
  object: Wui_SellerProjectItem_Insert_Input;
  on_conflict?: Maybe<Wui_SellerProjectItem_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_SellerProject_OneArgs = {
  object: Wui_SellerProject_Insert_Input;
  on_conflict?: Maybe<Wui_SellerProject_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_SessionArgs = {
  objects: Array<Wui_Session_Insert_Input>;
  on_conflict?: Maybe<Wui_Session_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_SessionEventArgs = {
  objects: Array<Wui_SessionEvent_Insert_Input>;
  on_conflict?: Maybe<Wui_SessionEvent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_SessionEventTypeArgs = {
  objects: Array<Wui_SessionEventType_Insert_Input>;
  on_conflict?: Maybe<Wui_SessionEventType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_SessionEventType_OneArgs = {
  object: Wui_SessionEventType_Insert_Input;
  on_conflict?: Maybe<Wui_SessionEventType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_SessionEvent_OneArgs = {
  object: Wui_SessionEvent_Insert_Input;
  on_conflict?: Maybe<Wui_SessionEvent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_Session_OneArgs = {
  object: Wui_Session_Insert_Input;
  on_conflict?: Maybe<Wui_Session_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_SocialMediaSourceArgs = {
  objects: Array<Wui_SocialMediaSource_Insert_Input>;
  on_conflict?: Maybe<Wui_SocialMediaSource_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_SocialMediaSource_OneArgs = {
  object: Wui_SocialMediaSource_Insert_Input;
  on_conflict?: Maybe<Wui_SocialMediaSource_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_UserArgs = {
  objects: Array<Wui_User_Insert_Input>;
  on_conflict?: Maybe<Wui_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_UserFilePortfolioArgs = {
  objects: Array<Wui_UserFilePortfolio_Insert_Input>;
  on_conflict?: Maybe<Wui_UserFilePortfolio_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_UserFilePortfolio_OneArgs = {
  object: Wui_UserFilePortfolio_Insert_Input;
  on_conflict?: Maybe<Wui_UserFilePortfolio_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_UserReviewArgs = {
  objects: Array<Wui_UserReview_Insert_Input>;
  on_conflict?: Maybe<Wui_UserReview_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_UserReview_OneArgs = {
  object: Wui_UserReview_Insert_Input;
  on_conflict?: Maybe<Wui_UserReview_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_User_OneArgs = {
  object: Wui_User_Insert_Input;
  on_conflict?: Maybe<Wui_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_UserlastseenArgs = {
  objects: Array<Wui_Userlastseen_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Wui_Userlastseen_OneArgs = {
  object: Wui_Userlastseen_Insert_Input;
};


/** mutation root */
export type Mutation_RootLoginArgs = {
  externalID: Scalars['String'];
  token: Scalars['String'];
};


/** mutation root */
export type Mutation_RootLoginAdminArgs = {
  externalID: Scalars['String'];
  token: Scalars['String'];
};


/** mutation root */
export type Mutation_RootRemoveBankAccountArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootRemove_Wui_PaymentMethodCardArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootSignupArgs = {
  country?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  externalID: Scalars['String'];
  firstName: Scalars['String'];
  isSeller?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  token: Scalars['String'];
  username: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUpdateStatus_Wui_OrderArgs = {
  orderId: Scalars['bigint'];
  status: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUpdate_DemographicArgs = {
  _set?: Maybe<Demographic_Set_Input>;
  where: Demographic_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Demographic_By_PkArgs = {
  _set?: Maybe<Demographic_Set_Input>;
  pk_columns: Demographic_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_HeroBannerArgs = {
  _inc?: Maybe<HeroBanner_Inc_Input>;
  _set?: Maybe<HeroBanner_Set_Input>;
  where: HeroBanner_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_HeroBanner_By_PkArgs = {
  _inc?: Maybe<HeroBanner_Inc_Input>;
  _set?: Maybe<HeroBanner_Set_Input>;
  pk_columns: HeroBanner_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ProjectDemographicArgs = {
  _inc?: Maybe<ProjectDemographic_Inc_Input>;
  _set?: Maybe<ProjectDemographic_Set_Input>;
  where: ProjectDemographic_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ProjectDemographic_By_PkArgs = {
  _inc?: Maybe<ProjectDemographic_Inc_Input>;
  _set?: Maybe<ProjectDemographic_Set_Input>;
  pk_columns: ProjectDemographic_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SocialMediaDemographicArgs = {
  _inc?: Maybe<SocialMediaDemographic_Inc_Input>;
  _set?: Maybe<SocialMediaDemographic_Set_Input>;
  where: SocialMediaDemographic_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SocialMediaDemographic_By_PkArgs = {
  _inc?: Maybe<SocialMediaDemographic_Inc_Input>;
  _set?: Maybe<SocialMediaDemographic_Set_Input>;
  pk_columns: SocialMediaDemographic_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SocialMediaLinkArgs = {
  _inc?: Maybe<SocialMediaLink_Inc_Input>;
  _set?: Maybe<SocialMediaLink_Set_Input>;
  where: SocialMediaLink_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SocialMediaLink_By_PkArgs = {
  _inc?: Maybe<SocialMediaLink_Inc_Input>;
  _set?: Maybe<SocialMediaLink_Set_Input>;
  pk_columns: SocialMediaLink_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_AuthProviderArgs = {
  _inc?: Maybe<Wui_AuthProvider_Inc_Input>;
  _set?: Maybe<Wui_AuthProvider_Set_Input>;
  where: Wui_AuthProvider_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_AuthProvider_By_PkArgs = {
  _inc?: Maybe<Wui_AuthProvider_Inc_Input>;
  _set?: Maybe<Wui_AuthProvider_Set_Input>;
  pk_columns: Wui_AuthProvider_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_ChatRoomArgs = {
  _inc?: Maybe<Wui_ChatRoom_Inc_Input>;
  _set?: Maybe<Wui_ChatRoom_Set_Input>;
  where: Wui_ChatRoom_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_ChatRoomMessageArgs = {
  _append?: Maybe<Wui_ChatRoomMessage_Append_Input>;
  _delete_at_path?: Maybe<Wui_ChatRoomMessage_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Wui_ChatRoomMessage_Delete_Elem_Input>;
  _delete_key?: Maybe<Wui_ChatRoomMessage_Delete_Key_Input>;
  _inc?: Maybe<Wui_ChatRoomMessage_Inc_Input>;
  _prepend?: Maybe<Wui_ChatRoomMessage_Prepend_Input>;
  _set?: Maybe<Wui_ChatRoomMessage_Set_Input>;
  where: Wui_ChatRoomMessage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_ChatRoomMessage_By_PkArgs = {
  _append?: Maybe<Wui_ChatRoomMessage_Append_Input>;
  _delete_at_path?: Maybe<Wui_ChatRoomMessage_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Wui_ChatRoomMessage_Delete_Elem_Input>;
  _delete_key?: Maybe<Wui_ChatRoomMessage_Delete_Key_Input>;
  _inc?: Maybe<Wui_ChatRoomMessage_Inc_Input>;
  _prepend?: Maybe<Wui_ChatRoomMessage_Prepend_Input>;
  _set?: Maybe<Wui_ChatRoomMessage_Set_Input>;
  pk_columns: Wui_ChatRoomMessage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_ChatRoomOrderArgs = {
  _inc?: Maybe<Wui_ChatRoomOrder_Inc_Input>;
  _set?: Maybe<Wui_ChatRoomOrder_Set_Input>;
  where: Wui_ChatRoomOrder_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_ChatRoomOrder_By_PkArgs = {
  _inc?: Maybe<Wui_ChatRoomOrder_Inc_Input>;
  _set?: Maybe<Wui_ChatRoomOrder_Set_Input>;
  pk_columns: Wui_ChatRoomOrder_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_ChatRoomTypeArgs = {
  _set?: Maybe<Wui_ChatRoomType_Set_Input>;
  where: Wui_ChatRoomType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_ChatRoomType_By_PkArgs = {
  _set?: Maybe<Wui_ChatRoomType_Set_Input>;
  pk_columns: Wui_ChatRoomType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_ChatRoomUserArgs = {
  _inc?: Maybe<Wui_ChatRoomUser_Inc_Input>;
  _set?: Maybe<Wui_ChatRoomUser_Set_Input>;
  where: Wui_ChatRoomUser_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_ChatRoomUser_By_PkArgs = {
  _inc?: Maybe<Wui_ChatRoomUser_Inc_Input>;
  _set?: Maybe<Wui_ChatRoomUser_Set_Input>;
  pk_columns: Wui_ChatRoomUser_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_ChatRoom_By_PkArgs = {
  _inc?: Maybe<Wui_ChatRoom_Inc_Input>;
  _set?: Maybe<Wui_ChatRoom_Set_Input>;
  pk_columns: Wui_ChatRoom_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_FileArgs = {
  _inc?: Maybe<Wui_File_Inc_Input>;
  _set?: Maybe<Wui_File_Set_Input>;
  where: Wui_File_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_FileTypeArgs = {
  _set?: Maybe<Wui_FileType_Set_Input>;
  where: Wui_FileType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_FileType_By_PkArgs = {
  _set?: Maybe<Wui_FileType_Set_Input>;
  pk_columns: Wui_FileType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_File_By_PkArgs = {
  _inc?: Maybe<Wui_File_Inc_Input>;
  _set?: Maybe<Wui_File_Set_Input>;
  pk_columns: Wui_File_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_NotificationArgs = {
  _append?: Maybe<Wui_Notification_Append_Input>;
  _delete_at_path?: Maybe<Wui_Notification_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Wui_Notification_Delete_Elem_Input>;
  _delete_key?: Maybe<Wui_Notification_Delete_Key_Input>;
  _inc?: Maybe<Wui_Notification_Inc_Input>;
  _prepend?: Maybe<Wui_Notification_Prepend_Input>;
  _set?: Maybe<Wui_Notification_Set_Input>;
  where: Wui_Notification_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_Notification_By_PkArgs = {
  _append?: Maybe<Wui_Notification_Append_Input>;
  _delete_at_path?: Maybe<Wui_Notification_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Wui_Notification_Delete_Elem_Input>;
  _delete_key?: Maybe<Wui_Notification_Delete_Key_Input>;
  _inc?: Maybe<Wui_Notification_Inc_Input>;
  _prepend?: Maybe<Wui_Notification_Prepend_Input>;
  _set?: Maybe<Wui_Notification_Set_Input>;
  pk_columns: Wui_Notification_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_OrderArgs = {
  _append?: Maybe<Wui_Order_Append_Input>;
  _delete_at_path?: Maybe<Wui_Order_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Wui_Order_Delete_Elem_Input>;
  _delete_key?: Maybe<Wui_Order_Delete_Key_Input>;
  _inc?: Maybe<Wui_Order_Inc_Input>;
  _prepend?: Maybe<Wui_Order_Prepend_Input>;
  _set?: Maybe<Wui_Order_Set_Input>;
  where: Wui_Order_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_OrderReviewArgs = {
  _inc?: Maybe<Wui_OrderReview_Inc_Input>;
  _set?: Maybe<Wui_OrderReview_Set_Input>;
  where: Wui_OrderReview_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_OrderReview_By_PkArgs = {
  _inc?: Maybe<Wui_OrderReview_Inc_Input>;
  _set?: Maybe<Wui_OrderReview_Set_Input>;
  pk_columns: Wui_OrderReview_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_OrderStatusArgs = {
  _set?: Maybe<Wui_OrderStatus_Set_Input>;
  where: Wui_OrderStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_OrderStatus_By_PkArgs = {
  _set?: Maybe<Wui_OrderStatus_Set_Input>;
  pk_columns: Wui_OrderStatus_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_Order_By_PkArgs = {
  _append?: Maybe<Wui_Order_Append_Input>;
  _delete_at_path?: Maybe<Wui_Order_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Wui_Order_Delete_Elem_Input>;
  _delete_key?: Maybe<Wui_Order_Delete_Key_Input>;
  _inc?: Maybe<Wui_Order_Inc_Input>;
  _prepend?: Maybe<Wui_Order_Prepend_Input>;
  _set?: Maybe<Wui_Order_Set_Input>;
  pk_columns: Wui_Order_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_PaymentArgs = {
  _append?: Maybe<Wui_Payment_Append_Input>;
  _delete_at_path?: Maybe<Wui_Payment_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Wui_Payment_Delete_Elem_Input>;
  _delete_key?: Maybe<Wui_Payment_Delete_Key_Input>;
  _inc?: Maybe<Wui_Payment_Inc_Input>;
  _prepend?: Maybe<Wui_Payment_Prepend_Input>;
  _set?: Maybe<Wui_Payment_Set_Input>;
  where: Wui_Payment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_PaymentMethodArgs = {
  _inc?: Maybe<Wui_PaymentMethod_Inc_Input>;
  _set?: Maybe<Wui_PaymentMethod_Set_Input>;
  where: Wui_PaymentMethod_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_PaymentMethodBankAccountArgs = {
  _inc?: Maybe<Wui_PaymentMethodBankAccount_Inc_Input>;
  _set?: Maybe<Wui_PaymentMethodBankAccount_Set_Input>;
  where: Wui_PaymentMethodBankAccount_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_PaymentMethodBankAccount_By_PkArgs = {
  _inc?: Maybe<Wui_PaymentMethodBankAccount_Inc_Input>;
  _set?: Maybe<Wui_PaymentMethodBankAccount_Set_Input>;
  pk_columns: Wui_PaymentMethodBankAccount_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_PaymentMethodCardArgs = {
  _inc?: Maybe<Wui_PaymentMethodCard_Inc_Input>;
  _set?: Maybe<Wui_PaymentMethodCard_Set_Input>;
  where: Wui_PaymentMethodCard_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_PaymentMethodCard_By_PkArgs = {
  _inc?: Maybe<Wui_PaymentMethodCard_Inc_Input>;
  _set?: Maybe<Wui_PaymentMethodCard_Set_Input>;
  pk_columns: Wui_PaymentMethodCard_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_PaymentMethodSourceArgs = {
  _set?: Maybe<Wui_PaymentMethodSource_Set_Input>;
  where: Wui_PaymentMethodSource_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_PaymentMethodSource_By_PkArgs = {
  _set?: Maybe<Wui_PaymentMethodSource_Set_Input>;
  pk_columns: Wui_PaymentMethodSource_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_PaymentMethod_By_PkArgs = {
  _inc?: Maybe<Wui_PaymentMethod_Inc_Input>;
  _set?: Maybe<Wui_PaymentMethod_Set_Input>;
  pk_columns: Wui_PaymentMethod_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_PaymentStatusArgs = {
  _set?: Maybe<Wui_PaymentStatus_Set_Input>;
  where: Wui_PaymentStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_PaymentStatus_By_PkArgs = {
  _set?: Maybe<Wui_PaymentStatus_Set_Input>;
  pk_columns: Wui_PaymentStatus_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_Payment_By_PkArgs = {
  _append?: Maybe<Wui_Payment_Append_Input>;
  _delete_at_path?: Maybe<Wui_Payment_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Wui_Payment_Delete_Elem_Input>;
  _delete_key?: Maybe<Wui_Payment_Delete_Key_Input>;
  _inc?: Maybe<Wui_Payment_Inc_Input>;
  _prepend?: Maybe<Wui_Payment_Prepend_Input>;
  _set?: Maybe<Wui_Payment_Set_Input>;
  pk_columns: Wui_Payment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_RoleArgs = {
  _inc?: Maybe<Wui_Role_Inc_Input>;
  _set?: Maybe<Wui_Role_Set_Input>;
  where: Wui_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_Role_By_PkArgs = {
  _inc?: Maybe<Wui_Role_Inc_Input>;
  _set?: Maybe<Wui_Role_Set_Input>;
  pk_columns: Wui_Role_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SellerPackageArgs = {
  _append?: Maybe<Wui_SellerPackage_Append_Input>;
  _delete_at_path?: Maybe<Wui_SellerPackage_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Wui_SellerPackage_Delete_Elem_Input>;
  _delete_key?: Maybe<Wui_SellerPackage_Delete_Key_Input>;
  _inc?: Maybe<Wui_SellerPackage_Inc_Input>;
  _prepend?: Maybe<Wui_SellerPackage_Prepend_Input>;
  _set?: Maybe<Wui_SellerPackage_Set_Input>;
  where: Wui_SellerPackage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SellerPackageItemArgs = {
  _inc?: Maybe<Wui_SellerPackageItem_Inc_Input>;
  _set?: Maybe<Wui_SellerPackageItem_Set_Input>;
  where: Wui_SellerPackageItem_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SellerPackageItem_By_PkArgs = {
  _inc?: Maybe<Wui_SellerPackageItem_Inc_Input>;
  _set?: Maybe<Wui_SellerPackageItem_Set_Input>;
  pk_columns: Wui_SellerPackageItem_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SellerPackageTypeArgs = {
  _set?: Maybe<Wui_SellerPackageType_Set_Input>;
  where: Wui_SellerPackageType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SellerPackageType_By_PkArgs = {
  _set?: Maybe<Wui_SellerPackageType_Set_Input>;
  pk_columns: Wui_SellerPackageType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SellerPackage_By_PkArgs = {
  _append?: Maybe<Wui_SellerPackage_Append_Input>;
  _delete_at_path?: Maybe<Wui_SellerPackage_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Wui_SellerPackage_Delete_Elem_Input>;
  _delete_key?: Maybe<Wui_SellerPackage_Delete_Key_Input>;
  _inc?: Maybe<Wui_SellerPackage_Inc_Input>;
  _prepend?: Maybe<Wui_SellerPackage_Prepend_Input>;
  _set?: Maybe<Wui_SellerPackage_Set_Input>;
  pk_columns: Wui_SellerPackage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SellerProjectArgs = {
  _inc?: Maybe<Wui_SellerProject_Inc_Input>;
  _set?: Maybe<Wui_SellerProject_Set_Input>;
  where: Wui_SellerProject_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SellerProjectFileArgs = {
  _inc?: Maybe<Wui_SellerProjectFile_Inc_Input>;
  _set?: Maybe<Wui_SellerProjectFile_Set_Input>;
  where: Wui_SellerProjectFile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SellerProjectFile_By_PkArgs = {
  _inc?: Maybe<Wui_SellerProjectFile_Inc_Input>;
  _set?: Maybe<Wui_SellerProjectFile_Set_Input>;
  pk_columns: Wui_SellerProjectFile_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SellerProjectItemArgs = {
  _inc?: Maybe<Wui_SellerProjectItem_Inc_Input>;
  _set?: Maybe<Wui_SellerProjectItem_Set_Input>;
  where: Wui_SellerProjectItem_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SellerProjectItem_By_PkArgs = {
  _inc?: Maybe<Wui_SellerProjectItem_Inc_Input>;
  _set?: Maybe<Wui_SellerProjectItem_Set_Input>;
  pk_columns: Wui_SellerProjectItem_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SellerProject_By_PkArgs = {
  _inc?: Maybe<Wui_SellerProject_Inc_Input>;
  _set?: Maybe<Wui_SellerProject_Set_Input>;
  pk_columns: Wui_SellerProject_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SessionArgs = {
  _inc?: Maybe<Wui_Session_Inc_Input>;
  _set?: Maybe<Wui_Session_Set_Input>;
  where: Wui_Session_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SessionEventArgs = {
  _inc?: Maybe<Wui_SessionEvent_Inc_Input>;
  _set?: Maybe<Wui_SessionEvent_Set_Input>;
  where: Wui_SessionEvent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SessionEventTypeArgs = {
  _set?: Maybe<Wui_SessionEventType_Set_Input>;
  where: Wui_SessionEventType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SessionEventType_By_PkArgs = {
  _set?: Maybe<Wui_SessionEventType_Set_Input>;
  pk_columns: Wui_SessionEventType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SessionEvent_By_PkArgs = {
  _inc?: Maybe<Wui_SessionEvent_Inc_Input>;
  _set?: Maybe<Wui_SessionEvent_Set_Input>;
  pk_columns: Wui_SessionEvent_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_Session_By_PkArgs = {
  _inc?: Maybe<Wui_Session_Inc_Input>;
  _set?: Maybe<Wui_Session_Set_Input>;
  pk_columns: Wui_Session_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SocialMediaSourceArgs = {
  _set?: Maybe<Wui_SocialMediaSource_Set_Input>;
  where: Wui_SocialMediaSource_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_SocialMediaSource_By_PkArgs = {
  _set?: Maybe<Wui_SocialMediaSource_Set_Input>;
  pk_columns: Wui_SocialMediaSource_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_UserArgs = {
  _inc?: Maybe<Wui_User_Inc_Input>;
  _set?: Maybe<Wui_User_Set_Input>;
  where: Wui_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_UserFilePortfolioArgs = {
  _inc?: Maybe<Wui_UserFilePortfolio_Inc_Input>;
  _set?: Maybe<Wui_UserFilePortfolio_Set_Input>;
  where: Wui_UserFilePortfolio_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_UserFilePortfolio_By_PkArgs = {
  _inc?: Maybe<Wui_UserFilePortfolio_Inc_Input>;
  _set?: Maybe<Wui_UserFilePortfolio_Set_Input>;
  pk_columns: Wui_UserFilePortfolio_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_UserReviewArgs = {
  _inc?: Maybe<Wui_UserReview_Inc_Input>;
  _set?: Maybe<Wui_UserReview_Set_Input>;
  where: Wui_UserReview_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_UserReview_By_PkArgs = {
  _inc?: Maybe<Wui_UserReview_Inc_Input>;
  _set?: Maybe<Wui_UserReview_Set_Input>;
  pk_columns: Wui_UserReview_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_User_By_PkArgs = {
  _inc?: Maybe<Wui_User_Inc_Input>;
  _set?: Maybe<Wui_User_Set_Input>;
  pk_columns: Wui_User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wui_UserlastseenArgs = {
  _inc?: Maybe<Wui_Userlastseen_Inc_Input>;
  _set?: Maybe<Wui_Userlastseen_Set_Input>;
  where: Wui_Userlastseen_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUploadCoverPicture_Wui_UserArgs = {
  imageBase64: Scalars['String'];
  imageType: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUploadPicture_HeroBannerArgs = {
  heroID: Scalars['bigint'];
  imageBase64: Scalars['String'];
  imageType: Scalars['String'];
  key: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUploadPicture_Wui_ChatRoomMessageArgs = {
  imageBase64: Scalars['String'];
  imageType: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUploadPicture_Wui_ProjectFileArgs = {
  imageBase64: Scalars['String'];
  imageType: Scalars['String'];
  projectId: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootUploadPicture_Wui_UseFilePortfolioArgs = {
  imageBase64: Scalars['String'];
  imageType: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUploadProfilePicture_Wui_UserArgs = {
  imageBase64: Scalars['String'];
  imageType: Scalars['String'];
};


/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "Demographic" */
  Demographic: Array<Demographic>;
  /** fetch aggregated fields from the table: "Demographic" */
  Demographic_aggregate: Demographic_Aggregate;
  /** fetch data from the table: "Demographic" using primary key columns */
  Demographic_by_pk?: Maybe<Demographic>;
  /** fetch data from the table: "HeroBanner" */
  HeroBanner: Array<HeroBanner>;
  /** fetch aggregated fields from the table: "HeroBanner" */
  HeroBanner_aggregate: HeroBanner_Aggregate;
  /** fetch data from the table: "HeroBanner" using primary key columns */
  HeroBanner_by_pk?: Maybe<HeroBanner>;
  /** fetch data from the table: "ProjectDemographic" */
  ProjectDemographic: Array<ProjectDemographic>;
  /** fetch aggregated fields from the table: "ProjectDemographic" */
  ProjectDemographic_aggregate: ProjectDemographic_Aggregate;
  /** fetch data from the table: "ProjectDemographic" using primary key columns */
  ProjectDemographic_by_pk?: Maybe<ProjectDemographic>;
  /** fetch data from the table: "SocialMediaDemographic" */
  SocialMediaDemographic: Array<SocialMediaDemographic>;
  /** fetch aggregated fields from the table: "SocialMediaDemographic" */
  SocialMediaDemographic_aggregate: SocialMediaDemographic_Aggregate;
  /** fetch data from the table: "SocialMediaDemographic" using primary key columns */
  SocialMediaDemographic_by_pk?: Maybe<SocialMediaDemographic>;
  /** fetch data from the table: "SocialMediaLink" */
  SocialMediaLink: Array<SocialMediaLink>;
  /** fetch aggregated fields from the table: "SocialMediaLink" */
  SocialMediaLink_aggregate: SocialMediaLink_Aggregate;
  /** fetch data from the table: "SocialMediaLink" using primary key columns */
  SocialMediaLink_by_pk?: Maybe<SocialMediaLink>;
  /** fetch data from the table: "WUI_AuthProvider" */
  WUI_AuthProvider: Array<Wui_AuthProvider>;
  /** fetch aggregated fields from the table: "WUI_AuthProvider" */
  WUI_AuthProvider_aggregate: Wui_AuthProvider_Aggregate;
  /** fetch data from the table: "WUI_AuthProvider" using primary key columns */
  WUI_AuthProvider_by_pk?: Maybe<Wui_AuthProvider>;
  /** fetch data from the table: "WUI_ChatRoom" */
  WUI_ChatRoom: Array<Wui_ChatRoom>;
  /** fetch data from the table: "WUI_ChatRoomMessage" */
  WUI_ChatRoomMessage: Array<Wui_ChatRoomMessage>;
  /** fetch aggregated fields from the table: "WUI_ChatRoomMessage" */
  WUI_ChatRoomMessage_aggregate: Wui_ChatRoomMessage_Aggregate;
  /** fetch data from the table: "WUI_ChatRoomMessage" using primary key columns */
  WUI_ChatRoomMessage_by_pk?: Maybe<Wui_ChatRoomMessage>;
  /** fetch data from the table: "WUI_ChatRoomOrder" */
  WUI_ChatRoomOrder: Array<Wui_ChatRoomOrder>;
  /** fetch aggregated fields from the table: "WUI_ChatRoomOrder" */
  WUI_ChatRoomOrder_aggregate: Wui_ChatRoomOrder_Aggregate;
  /** fetch data from the table: "WUI_ChatRoomOrder" using primary key columns */
  WUI_ChatRoomOrder_by_pk?: Maybe<Wui_ChatRoomOrder>;
  /** fetch data from the table: "WUI_ChatRoomType" */
  WUI_ChatRoomType: Array<Wui_ChatRoomType>;
  /** fetch aggregated fields from the table: "WUI_ChatRoomType" */
  WUI_ChatRoomType_aggregate: Wui_ChatRoomType_Aggregate;
  /** fetch data from the table: "WUI_ChatRoomType" using primary key columns */
  WUI_ChatRoomType_by_pk?: Maybe<Wui_ChatRoomType>;
  /** fetch data from the table: "WUI_ChatRoomUser" */
  WUI_ChatRoomUser: Array<Wui_ChatRoomUser>;
  /** fetch aggregated fields from the table: "WUI_ChatRoomUser" */
  WUI_ChatRoomUser_aggregate: Wui_ChatRoomUser_Aggregate;
  /** fetch data from the table: "WUI_ChatRoomUser" using primary key columns */
  WUI_ChatRoomUser_by_pk?: Maybe<Wui_ChatRoomUser>;
  /** fetch aggregated fields from the table: "WUI_ChatRoom" */
  WUI_ChatRoom_aggregate: Wui_ChatRoom_Aggregate;
  /** fetch data from the table: "WUI_ChatRoom" using primary key columns */
  WUI_ChatRoom_by_pk?: Maybe<Wui_ChatRoom>;
  /** fetch data from the table: "WUI_File" */
  WUI_File: Array<Wui_File>;
  /** fetch data from the table: "WUI_FileType" */
  WUI_FileType: Array<Wui_FileType>;
  /** fetch aggregated fields from the table: "WUI_FileType" */
  WUI_FileType_aggregate: Wui_FileType_Aggregate;
  /** fetch data from the table: "WUI_FileType" using primary key columns */
  WUI_FileType_by_pk?: Maybe<Wui_FileType>;
  /** fetch aggregated fields from the table: "WUI_File" */
  WUI_File_aggregate: Wui_File_Aggregate;
  /** fetch data from the table: "WUI_File" using primary key columns */
  WUI_File_by_pk?: Maybe<Wui_File>;
  /** fetch data from the table: "WUI_Notification" */
  WUI_Notification: Array<Wui_Notification>;
  /** fetch aggregated fields from the table: "WUI_Notification" */
  WUI_Notification_aggregate: Wui_Notification_Aggregate;
  /** fetch data from the table: "WUI_Notification" using primary key columns */
  WUI_Notification_by_pk?: Maybe<Wui_Notification>;
  /** fetch data from the table: "WUI_Order" */
  WUI_Order: Array<Wui_Order>;
  /** fetch data from the table: "WUI_OrderReview" */
  WUI_OrderReview: Array<Wui_OrderReview>;
  /** fetch aggregated fields from the table: "WUI_OrderReview" */
  WUI_OrderReview_aggregate: Wui_OrderReview_Aggregate;
  /** fetch data from the table: "WUI_OrderReview" using primary key columns */
  WUI_OrderReview_by_pk?: Maybe<Wui_OrderReview>;
  /** fetch data from the table: "WUI_OrderStatus" */
  WUI_OrderStatus: Array<Wui_OrderStatus>;
  /** fetch aggregated fields from the table: "WUI_OrderStatus" */
  WUI_OrderStatus_aggregate: Wui_OrderStatus_Aggregate;
  /** fetch data from the table: "WUI_OrderStatus" using primary key columns */
  WUI_OrderStatus_by_pk?: Maybe<Wui_OrderStatus>;
  /** fetch aggregated fields from the table: "WUI_Order" */
  WUI_Order_aggregate: Wui_Order_Aggregate;
  /** fetch data from the table: "WUI_Order" using primary key columns */
  WUI_Order_by_pk?: Maybe<Wui_Order>;
  /** fetch data from the table: "WUI_Payment" */
  WUI_Payment: Array<Wui_Payment>;
  /** fetch data from the table: "WUI_PaymentMethod" */
  WUI_PaymentMethod: Array<Wui_PaymentMethod>;
  /** fetch data from the table: "WUI_PaymentMethodBankAccount" */
  WUI_PaymentMethodBankAccount: Array<Wui_PaymentMethodBankAccount>;
  /** fetch aggregated fields from the table: "WUI_PaymentMethodBankAccount" */
  WUI_PaymentMethodBankAccount_aggregate: Wui_PaymentMethodBankAccount_Aggregate;
  /** fetch data from the table: "WUI_PaymentMethodBankAccount" using primary key columns */
  WUI_PaymentMethodBankAccount_by_pk?: Maybe<Wui_PaymentMethodBankAccount>;
  /** fetch data from the table: "WUI_PaymentMethodCard" */
  WUI_PaymentMethodCard: Array<Wui_PaymentMethodCard>;
  /** fetch aggregated fields from the table: "WUI_PaymentMethodCard" */
  WUI_PaymentMethodCard_aggregate: Wui_PaymentMethodCard_Aggregate;
  /** fetch data from the table: "WUI_PaymentMethodCard" using primary key columns */
  WUI_PaymentMethodCard_by_pk?: Maybe<Wui_PaymentMethodCard>;
  /** fetch data from the table: "WUI_PaymentMethodSource" */
  WUI_PaymentMethodSource: Array<Wui_PaymentMethodSource>;
  /** fetch aggregated fields from the table: "WUI_PaymentMethodSource" */
  WUI_PaymentMethodSource_aggregate: Wui_PaymentMethodSource_Aggregate;
  /** fetch data from the table: "WUI_PaymentMethodSource" using primary key columns */
  WUI_PaymentMethodSource_by_pk?: Maybe<Wui_PaymentMethodSource>;
  /** fetch aggregated fields from the table: "WUI_PaymentMethod" */
  WUI_PaymentMethod_aggregate: Wui_PaymentMethod_Aggregate;
  /** fetch data from the table: "WUI_PaymentMethod" using primary key columns */
  WUI_PaymentMethod_by_pk?: Maybe<Wui_PaymentMethod>;
  /** fetch data from the table: "WUI_PaymentStatus" */
  WUI_PaymentStatus: Array<Wui_PaymentStatus>;
  /** fetch aggregated fields from the table: "WUI_PaymentStatus" */
  WUI_PaymentStatus_aggregate: Wui_PaymentStatus_Aggregate;
  /** fetch data from the table: "WUI_PaymentStatus" using primary key columns */
  WUI_PaymentStatus_by_pk?: Maybe<Wui_PaymentStatus>;
  /** fetch aggregated fields from the table: "WUI_Payment" */
  WUI_Payment_aggregate: Wui_Payment_Aggregate;
  /** fetch data from the table: "WUI_Payment" using primary key columns */
  WUI_Payment_by_pk?: Maybe<Wui_Payment>;
  /** fetch data from the table: "WUI_Role" */
  WUI_Role: Array<Wui_Role>;
  /** fetch aggregated fields from the table: "WUI_Role" */
  WUI_Role_aggregate: Wui_Role_Aggregate;
  /** fetch data from the table: "WUI_Role" using primary key columns */
  WUI_Role_by_pk?: Maybe<Wui_Role>;
  /** fetch data from the table: "WUI_SellerPackage" */
  WUI_SellerPackage: Array<Wui_SellerPackage>;
  /** fetch data from the table: "WUI_SellerPackageItem" */
  WUI_SellerPackageItem: Array<Wui_SellerPackageItem>;
  /** fetch aggregated fields from the table: "WUI_SellerPackageItem" */
  WUI_SellerPackageItem_aggregate: Wui_SellerPackageItem_Aggregate;
  /** fetch data from the table: "WUI_SellerPackageItem" using primary key columns */
  WUI_SellerPackageItem_by_pk?: Maybe<Wui_SellerPackageItem>;
  /** fetch data from the table: "WUI_SellerPackageType" */
  WUI_SellerPackageType: Array<Wui_SellerPackageType>;
  /** fetch aggregated fields from the table: "WUI_SellerPackageType" */
  WUI_SellerPackageType_aggregate: Wui_SellerPackageType_Aggregate;
  /** fetch data from the table: "WUI_SellerPackageType" using primary key columns */
  WUI_SellerPackageType_by_pk?: Maybe<Wui_SellerPackageType>;
  /** fetch aggregated fields from the table: "WUI_SellerPackage" */
  WUI_SellerPackage_aggregate: Wui_SellerPackage_Aggregate;
  /** fetch data from the table: "WUI_SellerPackage" using primary key columns */
  WUI_SellerPackage_by_pk?: Maybe<Wui_SellerPackage>;
  /** fetch data from the table: "WUI_SellerProject" */
  WUI_SellerProject: Array<Wui_SellerProject>;
  /** fetch data from the table: "WUI_SellerProjectFile" */
  WUI_SellerProjectFile: Array<Wui_SellerProjectFile>;
  /** fetch aggregated fields from the table: "WUI_SellerProjectFile" */
  WUI_SellerProjectFile_aggregate: Wui_SellerProjectFile_Aggregate;
  /** fetch data from the table: "WUI_SellerProjectFile" using primary key columns */
  WUI_SellerProjectFile_by_pk?: Maybe<Wui_SellerProjectFile>;
  /** fetch data from the table: "WUI_SellerProjectItem" */
  WUI_SellerProjectItem: Array<Wui_SellerProjectItem>;
  /** fetch aggregated fields from the table: "WUI_SellerProjectItem" */
  WUI_SellerProjectItem_aggregate: Wui_SellerProjectItem_Aggregate;
  /** fetch data from the table: "WUI_SellerProjectItem" using primary key columns */
  WUI_SellerProjectItem_by_pk?: Maybe<Wui_SellerProjectItem>;
  /** fetch aggregated fields from the table: "WUI_SellerProject" */
  WUI_SellerProject_aggregate: Wui_SellerProject_Aggregate;
  /** fetch data from the table: "WUI_SellerProject" using primary key columns */
  WUI_SellerProject_by_pk?: Maybe<Wui_SellerProject>;
  /** fetch data from the table: "WUI_Session" */
  WUI_Session: Array<Wui_Session>;
  /** fetch data from the table: "WUI_SessionEvent" */
  WUI_SessionEvent: Array<Wui_SessionEvent>;
  /** fetch data from the table: "WUI_SessionEventType" */
  WUI_SessionEventType: Array<Wui_SessionEventType>;
  /** fetch aggregated fields from the table: "WUI_SessionEventType" */
  WUI_SessionEventType_aggregate: Wui_SessionEventType_Aggregate;
  /** fetch data from the table: "WUI_SessionEventType" using primary key columns */
  WUI_SessionEventType_by_pk?: Maybe<Wui_SessionEventType>;
  /** fetch aggregated fields from the table: "WUI_SessionEvent" */
  WUI_SessionEvent_aggregate: Wui_SessionEvent_Aggregate;
  /** fetch data from the table: "WUI_SessionEvent" using primary key columns */
  WUI_SessionEvent_by_pk?: Maybe<Wui_SessionEvent>;
  /** fetch aggregated fields from the table: "WUI_Session" */
  WUI_Session_aggregate: Wui_Session_Aggregate;
  /** fetch data from the table: "WUI_Session" using primary key columns */
  WUI_Session_by_pk?: Maybe<Wui_Session>;
  /** fetch data from the table: "WUI_SocialMediaSource" */
  WUI_SocialMediaSource: Array<Wui_SocialMediaSource>;
  /** fetch aggregated fields from the table: "WUI_SocialMediaSource" */
  WUI_SocialMediaSource_aggregate: Wui_SocialMediaSource_Aggregate;
  /** fetch data from the table: "WUI_SocialMediaSource" using primary key columns */
  WUI_SocialMediaSource_by_pk?: Maybe<Wui_SocialMediaSource>;
  /** fetch data from the table: "WUI_User" */
  WUI_User: Array<Wui_User>;
  /** fetch data from the table: "WUI_UserFilePortfolio" */
  WUI_UserFilePortfolio: Array<Wui_UserFilePortfolio>;
  /** fetch aggregated fields from the table: "WUI_UserFilePortfolio" */
  WUI_UserFilePortfolio_aggregate: Wui_UserFilePortfolio_Aggregate;
  /** fetch data from the table: "WUI_UserFilePortfolio" using primary key columns */
  WUI_UserFilePortfolio_by_pk?: Maybe<Wui_UserFilePortfolio>;
  /** fetch data from the table: "WUI_UserReview" */
  WUI_UserReview: Array<Wui_UserReview>;
  /** fetch aggregated fields from the table: "WUI_UserReview" */
  WUI_UserReview_aggregate: Wui_UserReview_Aggregate;
  /** fetch data from the table: "WUI_UserReview" using primary key columns */
  WUI_UserReview_by_pk?: Maybe<Wui_UserReview>;
  /** fetch data from the table: "WUI_UserRole" */
  WUI_UserRole: Array<Wui_UserRole>;
  /** fetch aggregated fields from the table: "WUI_UserRole" */
  WUI_UserRole_aggregate: Wui_UserRole_Aggregate;
  /** fetch aggregated fields from the table: "WUI_User" */
  WUI_User_aggregate: Wui_User_Aggregate;
  /** fetch data from the table: "WUI_User" using primary key columns */
  WUI_User_by_pk?: Maybe<Wui_User>;
  getBankAccounts: Array<BankAccountOutput>;
  getCountrySpecs: CountrySpecsOutput;
  getCountrySupported: CountrySupportedOutput;
  legalInformation: LegalInformationOutput;
  searchInfluencers: Array<Maybe<SearchInfluencersOutput>>;
  testUser?: Maybe<DataOutput>;
  /** fetch data from the table: "wui_userlastseen" */
  wui_userlastseen: Array<Wui_Userlastseen>;
  /** fetch aggregated fields from the table: "wui_userlastseen" */
  wui_userlastseen_aggregate: Wui_Userlastseen_Aggregate;
};


export type Query_RootDemographicArgs = {
  distinct_on?: Maybe<Array<Demographic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Demographic_Order_By>>;
  where?: Maybe<Demographic_Bool_Exp>;
};


export type Query_RootDemographic_AggregateArgs = {
  distinct_on?: Maybe<Array<Demographic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Demographic_Order_By>>;
  where?: Maybe<Demographic_Bool_Exp>;
};


export type Query_RootDemographic_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootHeroBannerArgs = {
  distinct_on?: Maybe<Array<HeroBanner_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HeroBanner_Order_By>>;
  where?: Maybe<HeroBanner_Bool_Exp>;
};


export type Query_RootHeroBanner_AggregateArgs = {
  distinct_on?: Maybe<Array<HeroBanner_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HeroBanner_Order_By>>;
  where?: Maybe<HeroBanner_Bool_Exp>;
};


export type Query_RootHeroBanner_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootProjectDemographicArgs = {
  distinct_on?: Maybe<Array<ProjectDemographic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectDemographic_Order_By>>;
  where?: Maybe<ProjectDemographic_Bool_Exp>;
};


export type Query_RootProjectDemographic_AggregateArgs = {
  distinct_on?: Maybe<Array<ProjectDemographic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectDemographic_Order_By>>;
  where?: Maybe<ProjectDemographic_Bool_Exp>;
};


export type Query_RootProjectDemographic_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootSocialMediaDemographicArgs = {
  distinct_on?: Maybe<Array<SocialMediaDemographic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SocialMediaDemographic_Order_By>>;
  where?: Maybe<SocialMediaDemographic_Bool_Exp>;
};


export type Query_RootSocialMediaDemographic_AggregateArgs = {
  distinct_on?: Maybe<Array<SocialMediaDemographic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SocialMediaDemographic_Order_By>>;
  where?: Maybe<SocialMediaDemographic_Bool_Exp>;
};


export type Query_RootSocialMediaDemographic_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootSocialMediaLinkArgs = {
  distinct_on?: Maybe<Array<SocialMediaLink_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SocialMediaLink_Order_By>>;
  where?: Maybe<SocialMediaLink_Bool_Exp>;
};


export type Query_RootSocialMediaLink_AggregateArgs = {
  distinct_on?: Maybe<Array<SocialMediaLink_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SocialMediaLink_Order_By>>;
  where?: Maybe<SocialMediaLink_Bool_Exp>;
};


export type Query_RootSocialMediaLink_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWui_AuthProviderArgs = {
  distinct_on?: Maybe<Array<Wui_AuthProvider_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_AuthProvider_Order_By>>;
  where?: Maybe<Wui_AuthProvider_Bool_Exp>;
};


export type Query_RootWui_AuthProvider_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_AuthProvider_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_AuthProvider_Order_By>>;
  where?: Maybe<Wui_AuthProvider_Bool_Exp>;
};


export type Query_RootWui_AuthProvider_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWui_ChatRoomArgs = {
  distinct_on?: Maybe<Array<Wui_ChatRoom_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_ChatRoom_Order_By>>;
  where?: Maybe<Wui_ChatRoom_Bool_Exp>;
};


export type Query_RootWui_ChatRoomMessageArgs = {
  distinct_on?: Maybe<Array<Wui_ChatRoomMessage_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_ChatRoomMessage_Order_By>>;
  where?: Maybe<Wui_ChatRoomMessage_Bool_Exp>;
};


export type Query_RootWui_ChatRoomMessage_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_ChatRoomMessage_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_ChatRoomMessage_Order_By>>;
  where?: Maybe<Wui_ChatRoomMessage_Bool_Exp>;
};


export type Query_RootWui_ChatRoomMessage_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWui_ChatRoomOrderArgs = {
  distinct_on?: Maybe<Array<Wui_ChatRoomOrder_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_ChatRoomOrder_Order_By>>;
  where?: Maybe<Wui_ChatRoomOrder_Bool_Exp>;
};


export type Query_RootWui_ChatRoomOrder_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_ChatRoomOrder_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_ChatRoomOrder_Order_By>>;
  where?: Maybe<Wui_ChatRoomOrder_Bool_Exp>;
};


export type Query_RootWui_ChatRoomOrder_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWui_ChatRoomTypeArgs = {
  distinct_on?: Maybe<Array<Wui_ChatRoomType_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_ChatRoomType_Order_By>>;
  where?: Maybe<Wui_ChatRoomType_Bool_Exp>;
};


export type Query_RootWui_ChatRoomType_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_ChatRoomType_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_ChatRoomType_Order_By>>;
  where?: Maybe<Wui_ChatRoomType_Bool_Exp>;
};


export type Query_RootWui_ChatRoomType_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootWui_ChatRoomUserArgs = {
  distinct_on?: Maybe<Array<Wui_ChatRoomUser_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_ChatRoomUser_Order_By>>;
  where?: Maybe<Wui_ChatRoomUser_Bool_Exp>;
};


export type Query_RootWui_ChatRoomUser_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_ChatRoomUser_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_ChatRoomUser_Order_By>>;
  where?: Maybe<Wui_ChatRoomUser_Bool_Exp>;
};


export type Query_RootWui_ChatRoomUser_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWui_ChatRoom_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_ChatRoom_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_ChatRoom_Order_By>>;
  where?: Maybe<Wui_ChatRoom_Bool_Exp>;
};


export type Query_RootWui_ChatRoom_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWui_FileArgs = {
  distinct_on?: Maybe<Array<Wui_File_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_File_Order_By>>;
  where?: Maybe<Wui_File_Bool_Exp>;
};


export type Query_RootWui_FileTypeArgs = {
  distinct_on?: Maybe<Array<Wui_FileType_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_FileType_Order_By>>;
  where?: Maybe<Wui_FileType_Bool_Exp>;
};


export type Query_RootWui_FileType_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_FileType_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_FileType_Order_By>>;
  where?: Maybe<Wui_FileType_Bool_Exp>;
};


export type Query_RootWui_FileType_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootWui_File_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_File_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_File_Order_By>>;
  where?: Maybe<Wui_File_Bool_Exp>;
};


export type Query_RootWui_File_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWui_NotificationArgs = {
  distinct_on?: Maybe<Array<Wui_Notification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_Notification_Order_By>>;
  where?: Maybe<Wui_Notification_Bool_Exp>;
};


export type Query_RootWui_Notification_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_Notification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_Notification_Order_By>>;
  where?: Maybe<Wui_Notification_Bool_Exp>;
};


export type Query_RootWui_Notification_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWui_OrderArgs = {
  distinct_on?: Maybe<Array<Wui_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_Order_Order_By>>;
  where?: Maybe<Wui_Order_Bool_Exp>;
};


export type Query_RootWui_OrderReviewArgs = {
  distinct_on?: Maybe<Array<Wui_OrderReview_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_OrderReview_Order_By>>;
  where?: Maybe<Wui_OrderReview_Bool_Exp>;
};


export type Query_RootWui_OrderReview_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_OrderReview_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_OrderReview_Order_By>>;
  where?: Maybe<Wui_OrderReview_Bool_Exp>;
};


export type Query_RootWui_OrderReview_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWui_OrderStatusArgs = {
  distinct_on?: Maybe<Array<Wui_OrderStatus_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_OrderStatus_Order_By>>;
  where?: Maybe<Wui_OrderStatus_Bool_Exp>;
};


export type Query_RootWui_OrderStatus_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_OrderStatus_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_OrderStatus_Order_By>>;
  where?: Maybe<Wui_OrderStatus_Bool_Exp>;
};


export type Query_RootWui_OrderStatus_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootWui_Order_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_Order_Order_By>>;
  where?: Maybe<Wui_Order_Bool_Exp>;
};


export type Query_RootWui_Order_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWui_PaymentArgs = {
  distinct_on?: Maybe<Array<Wui_Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_Payment_Order_By>>;
  where?: Maybe<Wui_Payment_Bool_Exp>;
};


export type Query_RootWui_PaymentMethodArgs = {
  distinct_on?: Maybe<Array<Wui_PaymentMethod_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_PaymentMethod_Order_By>>;
  where?: Maybe<Wui_PaymentMethod_Bool_Exp>;
};


export type Query_RootWui_PaymentMethodBankAccountArgs = {
  distinct_on?: Maybe<Array<Wui_PaymentMethodBankAccount_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_PaymentMethodBankAccount_Order_By>>;
  where?: Maybe<Wui_PaymentMethodBankAccount_Bool_Exp>;
};


export type Query_RootWui_PaymentMethodBankAccount_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_PaymentMethodBankAccount_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_PaymentMethodBankAccount_Order_By>>;
  where?: Maybe<Wui_PaymentMethodBankAccount_Bool_Exp>;
};


export type Query_RootWui_PaymentMethodBankAccount_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootWui_PaymentMethodCardArgs = {
  distinct_on?: Maybe<Array<Wui_PaymentMethodCard_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_PaymentMethodCard_Order_By>>;
  where?: Maybe<Wui_PaymentMethodCard_Bool_Exp>;
};


export type Query_RootWui_PaymentMethodCard_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_PaymentMethodCard_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_PaymentMethodCard_Order_By>>;
  where?: Maybe<Wui_PaymentMethodCard_Bool_Exp>;
};


export type Query_RootWui_PaymentMethodCard_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWui_PaymentMethodSourceArgs = {
  distinct_on?: Maybe<Array<Wui_PaymentMethodSource_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_PaymentMethodSource_Order_By>>;
  where?: Maybe<Wui_PaymentMethodSource_Bool_Exp>;
};


export type Query_RootWui_PaymentMethodSource_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_PaymentMethodSource_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_PaymentMethodSource_Order_By>>;
  where?: Maybe<Wui_PaymentMethodSource_Bool_Exp>;
};


export type Query_RootWui_PaymentMethodSource_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootWui_PaymentMethod_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_PaymentMethod_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_PaymentMethod_Order_By>>;
  where?: Maybe<Wui_PaymentMethod_Bool_Exp>;
};


export type Query_RootWui_PaymentMethod_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWui_PaymentStatusArgs = {
  distinct_on?: Maybe<Array<Wui_PaymentStatus_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_PaymentStatus_Order_By>>;
  where?: Maybe<Wui_PaymentStatus_Bool_Exp>;
};


export type Query_RootWui_PaymentStatus_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_PaymentStatus_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_PaymentStatus_Order_By>>;
  where?: Maybe<Wui_PaymentStatus_Bool_Exp>;
};


export type Query_RootWui_PaymentStatus_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootWui_Payment_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_Payment_Order_By>>;
  where?: Maybe<Wui_Payment_Bool_Exp>;
};


export type Query_RootWui_Payment_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWui_RoleArgs = {
  distinct_on?: Maybe<Array<Wui_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_Role_Order_By>>;
  where?: Maybe<Wui_Role_Bool_Exp>;
};


export type Query_RootWui_Role_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_Role_Order_By>>;
  where?: Maybe<Wui_Role_Bool_Exp>;
};


export type Query_RootWui_Role_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootWui_SellerPackageArgs = {
  distinct_on?: Maybe<Array<Wui_SellerPackage_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_SellerPackage_Order_By>>;
  where?: Maybe<Wui_SellerPackage_Bool_Exp>;
};


export type Query_RootWui_SellerPackageItemArgs = {
  distinct_on?: Maybe<Array<Wui_SellerPackageItem_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_SellerPackageItem_Order_By>>;
  where?: Maybe<Wui_SellerPackageItem_Bool_Exp>;
};


export type Query_RootWui_SellerPackageItem_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_SellerPackageItem_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_SellerPackageItem_Order_By>>;
  where?: Maybe<Wui_SellerPackageItem_Bool_Exp>;
};


export type Query_RootWui_SellerPackageItem_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWui_SellerPackageTypeArgs = {
  distinct_on?: Maybe<Array<Wui_SellerPackageType_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_SellerPackageType_Order_By>>;
  where?: Maybe<Wui_SellerPackageType_Bool_Exp>;
};


export type Query_RootWui_SellerPackageType_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_SellerPackageType_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_SellerPackageType_Order_By>>;
  where?: Maybe<Wui_SellerPackageType_Bool_Exp>;
};


export type Query_RootWui_SellerPackageType_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootWui_SellerPackage_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_SellerPackage_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_SellerPackage_Order_By>>;
  where?: Maybe<Wui_SellerPackage_Bool_Exp>;
};


export type Query_RootWui_SellerPackage_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWui_SellerProjectArgs = {
  distinct_on?: Maybe<Array<Wui_SellerProject_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_SellerProject_Order_By>>;
  where?: Maybe<Wui_SellerProject_Bool_Exp>;
};


export type Query_RootWui_SellerProjectFileArgs = {
  distinct_on?: Maybe<Array<Wui_SellerProjectFile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_SellerProjectFile_Order_By>>;
  where?: Maybe<Wui_SellerProjectFile_Bool_Exp>;
};


export type Query_RootWui_SellerProjectFile_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_SellerProjectFile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_SellerProjectFile_Order_By>>;
  where?: Maybe<Wui_SellerProjectFile_Bool_Exp>;
};


export type Query_RootWui_SellerProjectFile_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootWui_SellerProjectItemArgs = {
  distinct_on?: Maybe<Array<Wui_SellerProjectItem_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_SellerProjectItem_Order_By>>;
  where?: Maybe<Wui_SellerProjectItem_Bool_Exp>;
};


export type Query_RootWui_SellerProjectItem_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_SellerProjectItem_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_SellerProjectItem_Order_By>>;
  where?: Maybe<Wui_SellerProjectItem_Bool_Exp>;
};


export type Query_RootWui_SellerProjectItem_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWui_SellerProject_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_SellerProject_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_SellerProject_Order_By>>;
  where?: Maybe<Wui_SellerProject_Bool_Exp>;
};


export type Query_RootWui_SellerProject_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWui_SessionArgs = {
  distinct_on?: Maybe<Array<Wui_Session_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_Session_Order_By>>;
  where?: Maybe<Wui_Session_Bool_Exp>;
};


export type Query_RootWui_SessionEventArgs = {
  distinct_on?: Maybe<Array<Wui_SessionEvent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_SessionEvent_Order_By>>;
  where?: Maybe<Wui_SessionEvent_Bool_Exp>;
};


export type Query_RootWui_SessionEventTypeArgs = {
  distinct_on?: Maybe<Array<Wui_SessionEventType_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_SessionEventType_Order_By>>;
  where?: Maybe<Wui_SessionEventType_Bool_Exp>;
};


export type Query_RootWui_SessionEventType_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_SessionEventType_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_SessionEventType_Order_By>>;
  where?: Maybe<Wui_SessionEventType_Bool_Exp>;
};


export type Query_RootWui_SessionEventType_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootWui_SessionEvent_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_SessionEvent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_SessionEvent_Order_By>>;
  where?: Maybe<Wui_SessionEvent_Bool_Exp>;
};


export type Query_RootWui_SessionEvent_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWui_Session_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_Session_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_Session_Order_By>>;
  where?: Maybe<Wui_Session_Bool_Exp>;
};


export type Query_RootWui_Session_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWui_SocialMediaSourceArgs = {
  distinct_on?: Maybe<Array<Wui_SocialMediaSource_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_SocialMediaSource_Order_By>>;
  where?: Maybe<Wui_SocialMediaSource_Bool_Exp>;
};


export type Query_RootWui_SocialMediaSource_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_SocialMediaSource_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_SocialMediaSource_Order_By>>;
  where?: Maybe<Wui_SocialMediaSource_Bool_Exp>;
};


export type Query_RootWui_SocialMediaSource_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootWui_UserArgs = {
  distinct_on?: Maybe<Array<Wui_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_User_Order_By>>;
  where?: Maybe<Wui_User_Bool_Exp>;
};


export type Query_RootWui_UserFilePortfolioArgs = {
  distinct_on?: Maybe<Array<Wui_UserFilePortfolio_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_UserFilePortfolio_Order_By>>;
  where?: Maybe<Wui_UserFilePortfolio_Bool_Exp>;
};


export type Query_RootWui_UserFilePortfolio_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_UserFilePortfolio_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_UserFilePortfolio_Order_By>>;
  where?: Maybe<Wui_UserFilePortfolio_Bool_Exp>;
};


export type Query_RootWui_UserFilePortfolio_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWui_UserReviewArgs = {
  distinct_on?: Maybe<Array<Wui_UserReview_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_UserReview_Order_By>>;
  where?: Maybe<Wui_UserReview_Bool_Exp>;
};


export type Query_RootWui_UserReview_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_UserReview_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_UserReview_Order_By>>;
  where?: Maybe<Wui_UserReview_Bool_Exp>;
};


export type Query_RootWui_UserReview_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWui_UserRoleArgs = {
  distinct_on?: Maybe<Array<Wui_UserRole_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_UserRole_Order_By>>;
  where?: Maybe<Wui_UserRole_Bool_Exp>;
};


export type Query_RootWui_UserRole_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_UserRole_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_UserRole_Order_By>>;
  where?: Maybe<Wui_UserRole_Bool_Exp>;
};


export type Query_RootWui_User_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_User_Order_By>>;
  where?: Maybe<Wui_User_Bool_Exp>;
};


export type Query_RootWui_User_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootGetCountrySpecsArgs = {
  countryCode: Scalars['String'];
};


export type Query_RootSearchInfluencersArgs = {
  filters: SearchInfluencersFilters;
};


export type Query_RootTestUserArgs = {
  data: Scalars['String'];
};


export type Query_RootWui_UserlastseenArgs = {
  distinct_on?: Maybe<Array<Wui_Userlastseen_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_Userlastseen_Order_By>>;
  where?: Maybe<Wui_Userlastseen_Bool_Exp>;
};


export type Query_RootWui_Userlastseen_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_Userlastseen_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_Userlastseen_Order_By>>;
  where?: Maybe<Wui_Userlastseen_Bool_Exp>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "Demographic" */
  Demographic: Array<Demographic>;
  /** fetch aggregated fields from the table: "Demographic" */
  Demographic_aggregate: Demographic_Aggregate;
  /** fetch data from the table: "Demographic" using primary key columns */
  Demographic_by_pk?: Maybe<Demographic>;
  /** fetch data from the table: "HeroBanner" */
  HeroBanner: Array<HeroBanner>;
  /** fetch aggregated fields from the table: "HeroBanner" */
  HeroBanner_aggregate: HeroBanner_Aggregate;
  /** fetch data from the table: "HeroBanner" using primary key columns */
  HeroBanner_by_pk?: Maybe<HeroBanner>;
  /** fetch data from the table: "ProjectDemographic" */
  ProjectDemographic: Array<ProjectDemographic>;
  /** fetch aggregated fields from the table: "ProjectDemographic" */
  ProjectDemographic_aggregate: ProjectDemographic_Aggregate;
  /** fetch data from the table: "ProjectDemographic" using primary key columns */
  ProjectDemographic_by_pk?: Maybe<ProjectDemographic>;
  /** fetch data from the table: "SocialMediaDemographic" */
  SocialMediaDemographic: Array<SocialMediaDemographic>;
  /** fetch aggregated fields from the table: "SocialMediaDemographic" */
  SocialMediaDemographic_aggregate: SocialMediaDemographic_Aggregate;
  /** fetch data from the table: "SocialMediaDemographic" using primary key columns */
  SocialMediaDemographic_by_pk?: Maybe<SocialMediaDemographic>;
  /** fetch data from the table: "SocialMediaLink" */
  SocialMediaLink: Array<SocialMediaLink>;
  /** fetch aggregated fields from the table: "SocialMediaLink" */
  SocialMediaLink_aggregate: SocialMediaLink_Aggregate;
  /** fetch data from the table: "SocialMediaLink" using primary key columns */
  SocialMediaLink_by_pk?: Maybe<SocialMediaLink>;
  /** fetch data from the table: "WUI_AuthProvider" */
  WUI_AuthProvider: Array<Wui_AuthProvider>;
  /** fetch aggregated fields from the table: "WUI_AuthProvider" */
  WUI_AuthProvider_aggregate: Wui_AuthProvider_Aggregate;
  /** fetch data from the table: "WUI_AuthProvider" using primary key columns */
  WUI_AuthProvider_by_pk?: Maybe<Wui_AuthProvider>;
  /** fetch data from the table: "WUI_ChatRoom" */
  WUI_ChatRoom: Array<Wui_ChatRoom>;
  /** fetch data from the table: "WUI_ChatRoomMessage" */
  WUI_ChatRoomMessage: Array<Wui_ChatRoomMessage>;
  /** fetch aggregated fields from the table: "WUI_ChatRoomMessage" */
  WUI_ChatRoomMessage_aggregate: Wui_ChatRoomMessage_Aggregate;
  /** fetch data from the table: "WUI_ChatRoomMessage" using primary key columns */
  WUI_ChatRoomMessage_by_pk?: Maybe<Wui_ChatRoomMessage>;
  /** fetch data from the table: "WUI_ChatRoomOrder" */
  WUI_ChatRoomOrder: Array<Wui_ChatRoomOrder>;
  /** fetch aggregated fields from the table: "WUI_ChatRoomOrder" */
  WUI_ChatRoomOrder_aggregate: Wui_ChatRoomOrder_Aggregate;
  /** fetch data from the table: "WUI_ChatRoomOrder" using primary key columns */
  WUI_ChatRoomOrder_by_pk?: Maybe<Wui_ChatRoomOrder>;
  /** fetch data from the table: "WUI_ChatRoomType" */
  WUI_ChatRoomType: Array<Wui_ChatRoomType>;
  /** fetch aggregated fields from the table: "WUI_ChatRoomType" */
  WUI_ChatRoomType_aggregate: Wui_ChatRoomType_Aggregate;
  /** fetch data from the table: "WUI_ChatRoomType" using primary key columns */
  WUI_ChatRoomType_by_pk?: Maybe<Wui_ChatRoomType>;
  /** fetch data from the table: "WUI_ChatRoomUser" */
  WUI_ChatRoomUser: Array<Wui_ChatRoomUser>;
  /** fetch aggregated fields from the table: "WUI_ChatRoomUser" */
  WUI_ChatRoomUser_aggregate: Wui_ChatRoomUser_Aggregate;
  /** fetch data from the table: "WUI_ChatRoomUser" using primary key columns */
  WUI_ChatRoomUser_by_pk?: Maybe<Wui_ChatRoomUser>;
  /** fetch aggregated fields from the table: "WUI_ChatRoom" */
  WUI_ChatRoom_aggregate: Wui_ChatRoom_Aggregate;
  /** fetch data from the table: "WUI_ChatRoom" using primary key columns */
  WUI_ChatRoom_by_pk?: Maybe<Wui_ChatRoom>;
  /** fetch data from the table: "WUI_File" */
  WUI_File: Array<Wui_File>;
  /** fetch data from the table: "WUI_FileType" */
  WUI_FileType: Array<Wui_FileType>;
  /** fetch aggregated fields from the table: "WUI_FileType" */
  WUI_FileType_aggregate: Wui_FileType_Aggregate;
  /** fetch data from the table: "WUI_FileType" using primary key columns */
  WUI_FileType_by_pk?: Maybe<Wui_FileType>;
  /** fetch aggregated fields from the table: "WUI_File" */
  WUI_File_aggregate: Wui_File_Aggregate;
  /** fetch data from the table: "WUI_File" using primary key columns */
  WUI_File_by_pk?: Maybe<Wui_File>;
  /** fetch data from the table: "WUI_Notification" */
  WUI_Notification: Array<Wui_Notification>;
  /** fetch aggregated fields from the table: "WUI_Notification" */
  WUI_Notification_aggregate: Wui_Notification_Aggregate;
  /** fetch data from the table: "WUI_Notification" using primary key columns */
  WUI_Notification_by_pk?: Maybe<Wui_Notification>;
  /** fetch data from the table: "WUI_Order" */
  WUI_Order: Array<Wui_Order>;
  /** fetch data from the table: "WUI_OrderReview" */
  WUI_OrderReview: Array<Wui_OrderReview>;
  /** fetch aggregated fields from the table: "WUI_OrderReview" */
  WUI_OrderReview_aggregate: Wui_OrderReview_Aggregate;
  /** fetch data from the table: "WUI_OrderReview" using primary key columns */
  WUI_OrderReview_by_pk?: Maybe<Wui_OrderReview>;
  /** fetch data from the table: "WUI_OrderStatus" */
  WUI_OrderStatus: Array<Wui_OrderStatus>;
  /** fetch aggregated fields from the table: "WUI_OrderStatus" */
  WUI_OrderStatus_aggregate: Wui_OrderStatus_Aggregate;
  /** fetch data from the table: "WUI_OrderStatus" using primary key columns */
  WUI_OrderStatus_by_pk?: Maybe<Wui_OrderStatus>;
  /** fetch aggregated fields from the table: "WUI_Order" */
  WUI_Order_aggregate: Wui_Order_Aggregate;
  /** fetch data from the table: "WUI_Order" using primary key columns */
  WUI_Order_by_pk?: Maybe<Wui_Order>;
  /** fetch data from the table: "WUI_Payment" */
  WUI_Payment: Array<Wui_Payment>;
  /** fetch data from the table: "WUI_PaymentMethod" */
  WUI_PaymentMethod: Array<Wui_PaymentMethod>;
  /** fetch data from the table: "WUI_PaymentMethodBankAccount" */
  WUI_PaymentMethodBankAccount: Array<Wui_PaymentMethodBankAccount>;
  /** fetch aggregated fields from the table: "WUI_PaymentMethodBankAccount" */
  WUI_PaymentMethodBankAccount_aggregate: Wui_PaymentMethodBankAccount_Aggregate;
  /** fetch data from the table: "WUI_PaymentMethodBankAccount" using primary key columns */
  WUI_PaymentMethodBankAccount_by_pk?: Maybe<Wui_PaymentMethodBankAccount>;
  /** fetch data from the table: "WUI_PaymentMethodCard" */
  WUI_PaymentMethodCard: Array<Wui_PaymentMethodCard>;
  /** fetch aggregated fields from the table: "WUI_PaymentMethodCard" */
  WUI_PaymentMethodCard_aggregate: Wui_PaymentMethodCard_Aggregate;
  /** fetch data from the table: "WUI_PaymentMethodCard" using primary key columns */
  WUI_PaymentMethodCard_by_pk?: Maybe<Wui_PaymentMethodCard>;
  /** fetch data from the table: "WUI_PaymentMethodSource" */
  WUI_PaymentMethodSource: Array<Wui_PaymentMethodSource>;
  /** fetch aggregated fields from the table: "WUI_PaymentMethodSource" */
  WUI_PaymentMethodSource_aggregate: Wui_PaymentMethodSource_Aggregate;
  /** fetch data from the table: "WUI_PaymentMethodSource" using primary key columns */
  WUI_PaymentMethodSource_by_pk?: Maybe<Wui_PaymentMethodSource>;
  /** fetch aggregated fields from the table: "WUI_PaymentMethod" */
  WUI_PaymentMethod_aggregate: Wui_PaymentMethod_Aggregate;
  /** fetch data from the table: "WUI_PaymentMethod" using primary key columns */
  WUI_PaymentMethod_by_pk?: Maybe<Wui_PaymentMethod>;
  /** fetch data from the table: "WUI_PaymentStatus" */
  WUI_PaymentStatus: Array<Wui_PaymentStatus>;
  /** fetch aggregated fields from the table: "WUI_PaymentStatus" */
  WUI_PaymentStatus_aggregate: Wui_PaymentStatus_Aggregate;
  /** fetch data from the table: "WUI_PaymentStatus" using primary key columns */
  WUI_PaymentStatus_by_pk?: Maybe<Wui_PaymentStatus>;
  /** fetch aggregated fields from the table: "WUI_Payment" */
  WUI_Payment_aggregate: Wui_Payment_Aggregate;
  /** fetch data from the table: "WUI_Payment" using primary key columns */
  WUI_Payment_by_pk?: Maybe<Wui_Payment>;
  /** fetch data from the table: "WUI_Role" */
  WUI_Role: Array<Wui_Role>;
  /** fetch aggregated fields from the table: "WUI_Role" */
  WUI_Role_aggregate: Wui_Role_Aggregate;
  /** fetch data from the table: "WUI_Role" using primary key columns */
  WUI_Role_by_pk?: Maybe<Wui_Role>;
  /** fetch data from the table: "WUI_SellerPackage" */
  WUI_SellerPackage: Array<Wui_SellerPackage>;
  /** fetch data from the table: "WUI_SellerPackageItem" */
  WUI_SellerPackageItem: Array<Wui_SellerPackageItem>;
  /** fetch aggregated fields from the table: "WUI_SellerPackageItem" */
  WUI_SellerPackageItem_aggregate: Wui_SellerPackageItem_Aggregate;
  /** fetch data from the table: "WUI_SellerPackageItem" using primary key columns */
  WUI_SellerPackageItem_by_pk?: Maybe<Wui_SellerPackageItem>;
  /** fetch data from the table: "WUI_SellerPackageType" */
  WUI_SellerPackageType: Array<Wui_SellerPackageType>;
  /** fetch aggregated fields from the table: "WUI_SellerPackageType" */
  WUI_SellerPackageType_aggregate: Wui_SellerPackageType_Aggregate;
  /** fetch data from the table: "WUI_SellerPackageType" using primary key columns */
  WUI_SellerPackageType_by_pk?: Maybe<Wui_SellerPackageType>;
  /** fetch aggregated fields from the table: "WUI_SellerPackage" */
  WUI_SellerPackage_aggregate: Wui_SellerPackage_Aggregate;
  /** fetch data from the table: "WUI_SellerPackage" using primary key columns */
  WUI_SellerPackage_by_pk?: Maybe<Wui_SellerPackage>;
  /** fetch data from the table: "WUI_SellerProject" */
  WUI_SellerProject: Array<Wui_SellerProject>;
  /** fetch data from the table: "WUI_SellerProjectFile" */
  WUI_SellerProjectFile: Array<Wui_SellerProjectFile>;
  /** fetch aggregated fields from the table: "WUI_SellerProjectFile" */
  WUI_SellerProjectFile_aggregate: Wui_SellerProjectFile_Aggregate;
  /** fetch data from the table: "WUI_SellerProjectFile" using primary key columns */
  WUI_SellerProjectFile_by_pk?: Maybe<Wui_SellerProjectFile>;
  /** fetch data from the table: "WUI_SellerProjectItem" */
  WUI_SellerProjectItem: Array<Wui_SellerProjectItem>;
  /** fetch aggregated fields from the table: "WUI_SellerProjectItem" */
  WUI_SellerProjectItem_aggregate: Wui_SellerProjectItem_Aggregate;
  /** fetch data from the table: "WUI_SellerProjectItem" using primary key columns */
  WUI_SellerProjectItem_by_pk?: Maybe<Wui_SellerProjectItem>;
  /** fetch aggregated fields from the table: "WUI_SellerProject" */
  WUI_SellerProject_aggregate: Wui_SellerProject_Aggregate;
  /** fetch data from the table: "WUI_SellerProject" using primary key columns */
  WUI_SellerProject_by_pk?: Maybe<Wui_SellerProject>;
  /** fetch data from the table: "WUI_Session" */
  WUI_Session: Array<Wui_Session>;
  /** fetch data from the table: "WUI_SessionEvent" */
  WUI_SessionEvent: Array<Wui_SessionEvent>;
  /** fetch data from the table: "WUI_SessionEventType" */
  WUI_SessionEventType: Array<Wui_SessionEventType>;
  /** fetch aggregated fields from the table: "WUI_SessionEventType" */
  WUI_SessionEventType_aggregate: Wui_SessionEventType_Aggregate;
  /** fetch data from the table: "WUI_SessionEventType" using primary key columns */
  WUI_SessionEventType_by_pk?: Maybe<Wui_SessionEventType>;
  /** fetch aggregated fields from the table: "WUI_SessionEvent" */
  WUI_SessionEvent_aggregate: Wui_SessionEvent_Aggregate;
  /** fetch data from the table: "WUI_SessionEvent" using primary key columns */
  WUI_SessionEvent_by_pk?: Maybe<Wui_SessionEvent>;
  /** fetch aggregated fields from the table: "WUI_Session" */
  WUI_Session_aggregate: Wui_Session_Aggregate;
  /** fetch data from the table: "WUI_Session" using primary key columns */
  WUI_Session_by_pk?: Maybe<Wui_Session>;
  /** fetch data from the table: "WUI_SocialMediaSource" */
  WUI_SocialMediaSource: Array<Wui_SocialMediaSource>;
  /** fetch aggregated fields from the table: "WUI_SocialMediaSource" */
  WUI_SocialMediaSource_aggregate: Wui_SocialMediaSource_Aggregate;
  /** fetch data from the table: "WUI_SocialMediaSource" using primary key columns */
  WUI_SocialMediaSource_by_pk?: Maybe<Wui_SocialMediaSource>;
  /** fetch data from the table: "WUI_User" */
  WUI_User: Array<Wui_User>;
  /** fetch data from the table: "WUI_UserFilePortfolio" */
  WUI_UserFilePortfolio: Array<Wui_UserFilePortfolio>;
  /** fetch aggregated fields from the table: "WUI_UserFilePortfolio" */
  WUI_UserFilePortfolio_aggregate: Wui_UserFilePortfolio_Aggregate;
  /** fetch data from the table: "WUI_UserFilePortfolio" using primary key columns */
  WUI_UserFilePortfolio_by_pk?: Maybe<Wui_UserFilePortfolio>;
  /** fetch data from the table: "WUI_UserReview" */
  WUI_UserReview: Array<Wui_UserReview>;
  /** fetch aggregated fields from the table: "WUI_UserReview" */
  WUI_UserReview_aggregate: Wui_UserReview_Aggregate;
  /** fetch data from the table: "WUI_UserReview" using primary key columns */
  WUI_UserReview_by_pk?: Maybe<Wui_UserReview>;
  /** fetch data from the table: "WUI_UserRole" */
  WUI_UserRole: Array<Wui_UserRole>;
  /** fetch aggregated fields from the table: "WUI_UserRole" */
  WUI_UserRole_aggregate: Wui_UserRole_Aggregate;
  /** fetch aggregated fields from the table: "WUI_User" */
  WUI_User_aggregate: Wui_User_Aggregate;
  /** fetch data from the table: "WUI_User" using primary key columns */
  WUI_User_by_pk?: Maybe<Wui_User>;
  /** fetch data from the table: "wui_userlastseen" */
  wui_userlastseen: Array<Wui_Userlastseen>;
  /** fetch aggregated fields from the table: "wui_userlastseen" */
  wui_userlastseen_aggregate: Wui_Userlastseen_Aggregate;
};


export type Subscription_RootDemographicArgs = {
  distinct_on?: Maybe<Array<Demographic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Demographic_Order_By>>;
  where?: Maybe<Demographic_Bool_Exp>;
};


export type Subscription_RootDemographic_AggregateArgs = {
  distinct_on?: Maybe<Array<Demographic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Demographic_Order_By>>;
  where?: Maybe<Demographic_Bool_Exp>;
};


export type Subscription_RootDemographic_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootHeroBannerArgs = {
  distinct_on?: Maybe<Array<HeroBanner_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HeroBanner_Order_By>>;
  where?: Maybe<HeroBanner_Bool_Exp>;
};


export type Subscription_RootHeroBanner_AggregateArgs = {
  distinct_on?: Maybe<Array<HeroBanner_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HeroBanner_Order_By>>;
  where?: Maybe<HeroBanner_Bool_Exp>;
};


export type Subscription_RootHeroBanner_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootProjectDemographicArgs = {
  distinct_on?: Maybe<Array<ProjectDemographic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectDemographic_Order_By>>;
  where?: Maybe<ProjectDemographic_Bool_Exp>;
};


export type Subscription_RootProjectDemographic_AggregateArgs = {
  distinct_on?: Maybe<Array<ProjectDemographic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectDemographic_Order_By>>;
  where?: Maybe<ProjectDemographic_Bool_Exp>;
};


export type Subscription_RootProjectDemographic_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootSocialMediaDemographicArgs = {
  distinct_on?: Maybe<Array<SocialMediaDemographic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SocialMediaDemographic_Order_By>>;
  where?: Maybe<SocialMediaDemographic_Bool_Exp>;
};


export type Subscription_RootSocialMediaDemographic_AggregateArgs = {
  distinct_on?: Maybe<Array<SocialMediaDemographic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SocialMediaDemographic_Order_By>>;
  where?: Maybe<SocialMediaDemographic_Bool_Exp>;
};


export type Subscription_RootSocialMediaDemographic_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootSocialMediaLinkArgs = {
  distinct_on?: Maybe<Array<SocialMediaLink_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SocialMediaLink_Order_By>>;
  where?: Maybe<SocialMediaLink_Bool_Exp>;
};


export type Subscription_RootSocialMediaLink_AggregateArgs = {
  distinct_on?: Maybe<Array<SocialMediaLink_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SocialMediaLink_Order_By>>;
  where?: Maybe<SocialMediaLink_Bool_Exp>;
};


export type Subscription_RootSocialMediaLink_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_AuthProviderArgs = {
  distinct_on?: Maybe<Array<Wui_AuthProvider_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_AuthProvider_Order_By>>;
  where?: Maybe<Wui_AuthProvider_Bool_Exp>;
};


export type Subscription_RootWui_AuthProvider_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_AuthProvider_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_AuthProvider_Order_By>>;
  where?: Maybe<Wui_AuthProvider_Bool_Exp>;
};


export type Subscription_RootWui_AuthProvider_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_ChatRoomArgs = {
  distinct_on?: Maybe<Array<Wui_ChatRoom_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_ChatRoom_Order_By>>;
  where?: Maybe<Wui_ChatRoom_Bool_Exp>;
};


export type Subscription_RootWui_ChatRoomMessageArgs = {
  distinct_on?: Maybe<Array<Wui_ChatRoomMessage_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_ChatRoomMessage_Order_By>>;
  where?: Maybe<Wui_ChatRoomMessage_Bool_Exp>;
};


export type Subscription_RootWui_ChatRoomMessage_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_ChatRoomMessage_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_ChatRoomMessage_Order_By>>;
  where?: Maybe<Wui_ChatRoomMessage_Bool_Exp>;
};


export type Subscription_RootWui_ChatRoomMessage_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_ChatRoomOrderArgs = {
  distinct_on?: Maybe<Array<Wui_ChatRoomOrder_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_ChatRoomOrder_Order_By>>;
  where?: Maybe<Wui_ChatRoomOrder_Bool_Exp>;
};


export type Subscription_RootWui_ChatRoomOrder_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_ChatRoomOrder_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_ChatRoomOrder_Order_By>>;
  where?: Maybe<Wui_ChatRoomOrder_Bool_Exp>;
};


export type Subscription_RootWui_ChatRoomOrder_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_ChatRoomTypeArgs = {
  distinct_on?: Maybe<Array<Wui_ChatRoomType_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_ChatRoomType_Order_By>>;
  where?: Maybe<Wui_ChatRoomType_Bool_Exp>;
};


export type Subscription_RootWui_ChatRoomType_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_ChatRoomType_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_ChatRoomType_Order_By>>;
  where?: Maybe<Wui_ChatRoomType_Bool_Exp>;
};


export type Subscription_RootWui_ChatRoomType_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootWui_ChatRoomUserArgs = {
  distinct_on?: Maybe<Array<Wui_ChatRoomUser_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_ChatRoomUser_Order_By>>;
  where?: Maybe<Wui_ChatRoomUser_Bool_Exp>;
};


export type Subscription_RootWui_ChatRoomUser_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_ChatRoomUser_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_ChatRoomUser_Order_By>>;
  where?: Maybe<Wui_ChatRoomUser_Bool_Exp>;
};


export type Subscription_RootWui_ChatRoomUser_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_ChatRoom_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_ChatRoom_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_ChatRoom_Order_By>>;
  where?: Maybe<Wui_ChatRoom_Bool_Exp>;
};


export type Subscription_RootWui_ChatRoom_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_FileArgs = {
  distinct_on?: Maybe<Array<Wui_File_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_File_Order_By>>;
  where?: Maybe<Wui_File_Bool_Exp>;
};


export type Subscription_RootWui_FileTypeArgs = {
  distinct_on?: Maybe<Array<Wui_FileType_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_FileType_Order_By>>;
  where?: Maybe<Wui_FileType_Bool_Exp>;
};


export type Subscription_RootWui_FileType_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_FileType_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_FileType_Order_By>>;
  where?: Maybe<Wui_FileType_Bool_Exp>;
};


export type Subscription_RootWui_FileType_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootWui_File_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_File_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_File_Order_By>>;
  where?: Maybe<Wui_File_Bool_Exp>;
};


export type Subscription_RootWui_File_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_NotificationArgs = {
  distinct_on?: Maybe<Array<Wui_Notification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_Notification_Order_By>>;
  where?: Maybe<Wui_Notification_Bool_Exp>;
};


export type Subscription_RootWui_Notification_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_Notification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_Notification_Order_By>>;
  where?: Maybe<Wui_Notification_Bool_Exp>;
};


export type Subscription_RootWui_Notification_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_OrderArgs = {
  distinct_on?: Maybe<Array<Wui_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_Order_Order_By>>;
  where?: Maybe<Wui_Order_Bool_Exp>;
};


export type Subscription_RootWui_OrderReviewArgs = {
  distinct_on?: Maybe<Array<Wui_OrderReview_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_OrderReview_Order_By>>;
  where?: Maybe<Wui_OrderReview_Bool_Exp>;
};


export type Subscription_RootWui_OrderReview_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_OrderReview_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_OrderReview_Order_By>>;
  where?: Maybe<Wui_OrderReview_Bool_Exp>;
};


export type Subscription_RootWui_OrderReview_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_OrderStatusArgs = {
  distinct_on?: Maybe<Array<Wui_OrderStatus_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_OrderStatus_Order_By>>;
  where?: Maybe<Wui_OrderStatus_Bool_Exp>;
};


export type Subscription_RootWui_OrderStatus_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_OrderStatus_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_OrderStatus_Order_By>>;
  where?: Maybe<Wui_OrderStatus_Bool_Exp>;
};


export type Subscription_RootWui_OrderStatus_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootWui_Order_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_Order_Order_By>>;
  where?: Maybe<Wui_Order_Bool_Exp>;
};


export type Subscription_RootWui_Order_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_PaymentArgs = {
  distinct_on?: Maybe<Array<Wui_Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_Payment_Order_By>>;
  where?: Maybe<Wui_Payment_Bool_Exp>;
};


export type Subscription_RootWui_PaymentMethodArgs = {
  distinct_on?: Maybe<Array<Wui_PaymentMethod_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_PaymentMethod_Order_By>>;
  where?: Maybe<Wui_PaymentMethod_Bool_Exp>;
};


export type Subscription_RootWui_PaymentMethodBankAccountArgs = {
  distinct_on?: Maybe<Array<Wui_PaymentMethodBankAccount_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_PaymentMethodBankAccount_Order_By>>;
  where?: Maybe<Wui_PaymentMethodBankAccount_Bool_Exp>;
};


export type Subscription_RootWui_PaymentMethodBankAccount_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_PaymentMethodBankAccount_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_PaymentMethodBankAccount_Order_By>>;
  where?: Maybe<Wui_PaymentMethodBankAccount_Bool_Exp>;
};


export type Subscription_RootWui_PaymentMethodBankAccount_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootWui_PaymentMethodCardArgs = {
  distinct_on?: Maybe<Array<Wui_PaymentMethodCard_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_PaymentMethodCard_Order_By>>;
  where?: Maybe<Wui_PaymentMethodCard_Bool_Exp>;
};


export type Subscription_RootWui_PaymentMethodCard_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_PaymentMethodCard_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_PaymentMethodCard_Order_By>>;
  where?: Maybe<Wui_PaymentMethodCard_Bool_Exp>;
};


export type Subscription_RootWui_PaymentMethodCard_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_PaymentMethodSourceArgs = {
  distinct_on?: Maybe<Array<Wui_PaymentMethodSource_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_PaymentMethodSource_Order_By>>;
  where?: Maybe<Wui_PaymentMethodSource_Bool_Exp>;
};


export type Subscription_RootWui_PaymentMethodSource_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_PaymentMethodSource_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_PaymentMethodSource_Order_By>>;
  where?: Maybe<Wui_PaymentMethodSource_Bool_Exp>;
};


export type Subscription_RootWui_PaymentMethodSource_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootWui_PaymentMethod_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_PaymentMethod_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_PaymentMethod_Order_By>>;
  where?: Maybe<Wui_PaymentMethod_Bool_Exp>;
};


export type Subscription_RootWui_PaymentMethod_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_PaymentStatusArgs = {
  distinct_on?: Maybe<Array<Wui_PaymentStatus_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_PaymentStatus_Order_By>>;
  where?: Maybe<Wui_PaymentStatus_Bool_Exp>;
};


export type Subscription_RootWui_PaymentStatus_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_PaymentStatus_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_PaymentStatus_Order_By>>;
  where?: Maybe<Wui_PaymentStatus_Bool_Exp>;
};


export type Subscription_RootWui_PaymentStatus_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootWui_Payment_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_Payment_Order_By>>;
  where?: Maybe<Wui_Payment_Bool_Exp>;
};


export type Subscription_RootWui_Payment_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_RoleArgs = {
  distinct_on?: Maybe<Array<Wui_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_Role_Order_By>>;
  where?: Maybe<Wui_Role_Bool_Exp>;
};


export type Subscription_RootWui_Role_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_Role_Order_By>>;
  where?: Maybe<Wui_Role_Bool_Exp>;
};


export type Subscription_RootWui_Role_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootWui_SellerPackageArgs = {
  distinct_on?: Maybe<Array<Wui_SellerPackage_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_SellerPackage_Order_By>>;
  where?: Maybe<Wui_SellerPackage_Bool_Exp>;
};


export type Subscription_RootWui_SellerPackageItemArgs = {
  distinct_on?: Maybe<Array<Wui_SellerPackageItem_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_SellerPackageItem_Order_By>>;
  where?: Maybe<Wui_SellerPackageItem_Bool_Exp>;
};


export type Subscription_RootWui_SellerPackageItem_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_SellerPackageItem_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_SellerPackageItem_Order_By>>;
  where?: Maybe<Wui_SellerPackageItem_Bool_Exp>;
};


export type Subscription_RootWui_SellerPackageItem_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_SellerPackageTypeArgs = {
  distinct_on?: Maybe<Array<Wui_SellerPackageType_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_SellerPackageType_Order_By>>;
  where?: Maybe<Wui_SellerPackageType_Bool_Exp>;
};


export type Subscription_RootWui_SellerPackageType_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_SellerPackageType_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_SellerPackageType_Order_By>>;
  where?: Maybe<Wui_SellerPackageType_Bool_Exp>;
};


export type Subscription_RootWui_SellerPackageType_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootWui_SellerPackage_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_SellerPackage_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_SellerPackage_Order_By>>;
  where?: Maybe<Wui_SellerPackage_Bool_Exp>;
};


export type Subscription_RootWui_SellerPackage_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_SellerProjectArgs = {
  distinct_on?: Maybe<Array<Wui_SellerProject_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_SellerProject_Order_By>>;
  where?: Maybe<Wui_SellerProject_Bool_Exp>;
};


export type Subscription_RootWui_SellerProjectFileArgs = {
  distinct_on?: Maybe<Array<Wui_SellerProjectFile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_SellerProjectFile_Order_By>>;
  where?: Maybe<Wui_SellerProjectFile_Bool_Exp>;
};


export type Subscription_RootWui_SellerProjectFile_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_SellerProjectFile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_SellerProjectFile_Order_By>>;
  where?: Maybe<Wui_SellerProjectFile_Bool_Exp>;
};


export type Subscription_RootWui_SellerProjectFile_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootWui_SellerProjectItemArgs = {
  distinct_on?: Maybe<Array<Wui_SellerProjectItem_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_SellerProjectItem_Order_By>>;
  where?: Maybe<Wui_SellerProjectItem_Bool_Exp>;
};


export type Subscription_RootWui_SellerProjectItem_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_SellerProjectItem_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_SellerProjectItem_Order_By>>;
  where?: Maybe<Wui_SellerProjectItem_Bool_Exp>;
};


export type Subscription_RootWui_SellerProjectItem_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_SellerProject_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_SellerProject_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_SellerProject_Order_By>>;
  where?: Maybe<Wui_SellerProject_Bool_Exp>;
};


export type Subscription_RootWui_SellerProject_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_SessionArgs = {
  distinct_on?: Maybe<Array<Wui_Session_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_Session_Order_By>>;
  where?: Maybe<Wui_Session_Bool_Exp>;
};


export type Subscription_RootWui_SessionEventArgs = {
  distinct_on?: Maybe<Array<Wui_SessionEvent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_SessionEvent_Order_By>>;
  where?: Maybe<Wui_SessionEvent_Bool_Exp>;
};


export type Subscription_RootWui_SessionEventTypeArgs = {
  distinct_on?: Maybe<Array<Wui_SessionEventType_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_SessionEventType_Order_By>>;
  where?: Maybe<Wui_SessionEventType_Bool_Exp>;
};


export type Subscription_RootWui_SessionEventType_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_SessionEventType_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_SessionEventType_Order_By>>;
  where?: Maybe<Wui_SessionEventType_Bool_Exp>;
};


export type Subscription_RootWui_SessionEventType_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootWui_SessionEvent_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_SessionEvent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_SessionEvent_Order_By>>;
  where?: Maybe<Wui_SessionEvent_Bool_Exp>;
};


export type Subscription_RootWui_SessionEvent_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_Session_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_Session_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_Session_Order_By>>;
  where?: Maybe<Wui_Session_Bool_Exp>;
};


export type Subscription_RootWui_Session_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_SocialMediaSourceArgs = {
  distinct_on?: Maybe<Array<Wui_SocialMediaSource_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_SocialMediaSource_Order_By>>;
  where?: Maybe<Wui_SocialMediaSource_Bool_Exp>;
};


export type Subscription_RootWui_SocialMediaSource_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_SocialMediaSource_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_SocialMediaSource_Order_By>>;
  where?: Maybe<Wui_SocialMediaSource_Bool_Exp>;
};


export type Subscription_RootWui_SocialMediaSource_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootWui_UserArgs = {
  distinct_on?: Maybe<Array<Wui_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_User_Order_By>>;
  where?: Maybe<Wui_User_Bool_Exp>;
};


export type Subscription_RootWui_UserFilePortfolioArgs = {
  distinct_on?: Maybe<Array<Wui_UserFilePortfolio_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_UserFilePortfolio_Order_By>>;
  where?: Maybe<Wui_UserFilePortfolio_Bool_Exp>;
};


export type Subscription_RootWui_UserFilePortfolio_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_UserFilePortfolio_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_UserFilePortfolio_Order_By>>;
  where?: Maybe<Wui_UserFilePortfolio_Bool_Exp>;
};


export type Subscription_RootWui_UserFilePortfolio_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_UserReviewArgs = {
  distinct_on?: Maybe<Array<Wui_UserReview_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_UserReview_Order_By>>;
  where?: Maybe<Wui_UserReview_Bool_Exp>;
};


export type Subscription_RootWui_UserReview_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_UserReview_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_UserReview_Order_By>>;
  where?: Maybe<Wui_UserReview_Bool_Exp>;
};


export type Subscription_RootWui_UserReview_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_UserRoleArgs = {
  distinct_on?: Maybe<Array<Wui_UserRole_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_UserRole_Order_By>>;
  where?: Maybe<Wui_UserRole_Bool_Exp>;
};


export type Subscription_RootWui_UserRole_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_UserRole_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_UserRole_Order_By>>;
  where?: Maybe<Wui_UserRole_Bool_Exp>;
};


export type Subscription_RootWui_User_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_User_Order_By>>;
  where?: Maybe<Wui_User_Bool_Exp>;
};


export type Subscription_RootWui_User_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWui_UserlastseenArgs = {
  distinct_on?: Maybe<Array<Wui_Userlastseen_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_Userlastseen_Order_By>>;
  where?: Maybe<Wui_Userlastseen_Bool_Exp>;
};


export type Subscription_RootWui_Userlastseen_AggregateArgs = {
  distinct_on?: Maybe<Array<Wui_Userlastseen_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wui_Userlastseen_Order_By>>;
  where?: Maybe<Wui_Userlastseen_Bool_Exp>;
};


/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamp']>;
  _gt?: Maybe<Scalars['timestamp']>;
  _gte?: Maybe<Scalars['timestamp']>;
  _in?: Maybe<Array<Scalars['timestamp']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamp']>;
  _lte?: Maybe<Scalars['timestamp']>;
  _neq?: Maybe<Scalars['timestamp']>;
  _nin?: Maybe<Array<Scalars['timestamp']>>;
};


/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};


/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "wui_userlastseen" */
export type Wui_Userlastseen = {
  __typename?: 'wui_userlastseen';
  id?: Maybe<Scalars['bigint']>;
  last_seen_date?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "wui_userlastseen" */
export type Wui_Userlastseen_Aggregate = {
  __typename?: 'wui_userlastseen_aggregate';
  aggregate?: Maybe<Wui_Userlastseen_Aggregate_Fields>;
  nodes: Array<Wui_Userlastseen>;
};

/** aggregate fields of "wui_userlastseen" */
export type Wui_Userlastseen_Aggregate_Fields = {
  __typename?: 'wui_userlastseen_aggregate_fields';
  avg?: Maybe<Wui_Userlastseen_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wui_Userlastseen_Max_Fields>;
  min?: Maybe<Wui_Userlastseen_Min_Fields>;
  stddev?: Maybe<Wui_Userlastseen_Stddev_Fields>;
  stddev_pop?: Maybe<Wui_Userlastseen_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wui_Userlastseen_Stddev_Samp_Fields>;
  sum?: Maybe<Wui_Userlastseen_Sum_Fields>;
  var_pop?: Maybe<Wui_Userlastseen_Var_Pop_Fields>;
  var_samp?: Maybe<Wui_Userlastseen_Var_Samp_Fields>;
  variance?: Maybe<Wui_Userlastseen_Variance_Fields>;
};


/** aggregate fields of "wui_userlastseen" */
export type Wui_Userlastseen_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wui_Userlastseen_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Wui_Userlastseen_Avg_Fields = {
  __typename?: 'wui_userlastseen_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "wui_userlastseen". All fields are combined with a logical 'AND'. */
export type Wui_Userlastseen_Bool_Exp = {
  _and?: Maybe<Array<Wui_Userlastseen_Bool_Exp>>;
  _not?: Maybe<Wui_Userlastseen_Bool_Exp>;
  _or?: Maybe<Array<Wui_Userlastseen_Bool_Exp>>;
  id?: Maybe<Bigint_Comparison_Exp>;
  last_seen_date?: Maybe<Timestamptz_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "wui_userlastseen" */
export type Wui_Userlastseen_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "wui_userlastseen" */
export type Wui_Userlastseen_Insert_Input = {
  id?: Maybe<Scalars['bigint']>;
  last_seen_date?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Wui_Userlastseen_Max_Fields = {
  __typename?: 'wui_userlastseen_max_fields';
  id?: Maybe<Scalars['bigint']>;
  last_seen_date?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Wui_Userlastseen_Min_Fields = {
  __typename?: 'wui_userlastseen_min_fields';
  id?: Maybe<Scalars['bigint']>;
  last_seen_date?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "wui_userlastseen" */
export type Wui_Userlastseen_Mutation_Response = {
  __typename?: 'wui_userlastseen_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wui_Userlastseen>;
};

/** Ordering options when selecting data from "wui_userlastseen". */
export type Wui_Userlastseen_Order_By = {
  id?: Maybe<Order_By>;
  last_seen_date?: Maybe<Order_By>;
};

/** select columns of table "wui_userlastseen" */
export enum Wui_Userlastseen_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LastSeenDate = 'last_seen_date'
}

/** input type for updating data in table "wui_userlastseen" */
export type Wui_Userlastseen_Set_Input = {
  id?: Maybe<Scalars['bigint']>;
  last_seen_date?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Wui_Userlastseen_Stddev_Fields = {
  __typename?: 'wui_userlastseen_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Wui_Userlastseen_Stddev_Pop_Fields = {
  __typename?: 'wui_userlastseen_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Wui_Userlastseen_Stddev_Samp_Fields = {
  __typename?: 'wui_userlastseen_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Wui_Userlastseen_Sum_Fields = {
  __typename?: 'wui_userlastseen_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Wui_Userlastseen_Var_Pop_Fields = {
  __typename?: 'wui_userlastseen_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Wui_Userlastseen_Var_Samp_Fields = {
  __typename?: 'wui_userlastseen_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Wui_Userlastseen_Variance_Fields = {
  __typename?: 'wui_userlastseen_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type CreateHeroBannerMutationVariables = Exact<{
  order: Scalars['Int'];
  subtitle: Scalars['String'];
  title: Scalars['String'];
  active: Scalars['Boolean'];
}>;


export type CreateHeroBannerMutation = (
  { __typename?: 'mutation_root' }
  & { insert_HeroBanner_one?: Maybe<(
    { __typename?: 'HeroBanner' }
    & Pick<HeroBanner, 'id'>
  )> }
);

export type DeleteHeroBannerMutationVariables = Exact<{
  where: HeroBanner_Bool_Exp;
}>;


export type DeleteHeroBannerMutation = (
  { __typename?: 'mutation_root' }
  & { delete_HeroBanner?: Maybe<(
    { __typename?: 'HeroBanner_mutation_response' }
    & Pick<HeroBanner_Mutation_Response, 'affected_rows'>
  )> }
);

export type DeleteOptionalBannerPicMutationVariables = Exact<{
  set: HeroBanner_Set_Input;
  id: Scalars['bigint'];
  imageID: Scalars['bigint'];
}>;


export type DeleteOptionalBannerPicMutation = (
  { __typename?: 'mutation_root' }
  & { update_HeroBanner_by_pk?: Maybe<(
    { __typename?: 'HeroBanner' }
    & Pick<HeroBanner, 'id'>
  )>, delete_WUI_File_by_pk?: Maybe<(
    { __typename?: 'WUI_File' }
    & Pick<Wui_File, 'id'>
  )> }
);

export type UpdateHeroBannerMutationVariables = Exact<{
  id: Scalars['bigint'];
  set: HeroBanner_Set_Input;
}>;


export type UpdateHeroBannerMutation = (
  { __typename?: 'mutation_root' }
  & { update_HeroBanner?: Maybe<(
    { __typename?: 'HeroBanner_mutation_response' }
    & Pick<HeroBanner_Mutation_Response, 'affected_rows'>
  )> }
);

export type UpdateMultipleHeroesBannersMutationVariables = Exact<{
  updateBanners: Array<HeroBanner_Insert_Input> | HeroBanner_Insert_Input;
}>;


export type UpdateMultipleHeroesBannersMutation = (
  { __typename?: 'mutation_root' }
  & { insert_HeroBanner?: Maybe<(
    { __typename?: 'HeroBanner_mutation_response' }
    & { returning: Array<(
      { __typename?: 'HeroBanner' }
      & Pick<HeroBanner, 'id'>
    )> }
  )> }
);

export type UploadHeroBannerPictureMutationVariables = Exact<{
  key: Scalars['String'];
  heroID: Scalars['bigint'];
  imageType: Scalars['String'];
  imageBase64: Scalars['String'];
  oldImageID: Scalars['bigint'];
}>;


export type UploadHeroBannerPictureMutation = (
  { __typename?: 'mutation_root' }
  & { uploadPicture_HeroBanner?: Maybe<(
    { __typename?: 'FileOutput' }
    & Pick<FileOutput, 'fileId' | 'imageUrl' | 'fileType'>
  )>, delete_WUI_File_by_pk?: Maybe<(
    { __typename?: 'WUI_File' }
    & Pick<Wui_File, 'id'>
  )> }
);

export type LoginAdminMutationVariables = Exact<{
  externalID: Scalars['String'];
  token: Scalars['String'];
}>;


export type LoginAdminMutation = (
  { __typename?: 'mutation_root' }
  & { loginAdmin?: Maybe<(
    { __typename?: 'LoginAdminOutput' }
    & Pick<LoginAdminOutput, 'id' | 'email' | 'firstName' | 'lastName' | 'username' | 'phoneNumber' | 'picture' | 'country'>
  )> }
);

export type CountTotalUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type CountTotalUsersQuery = (
  { __typename?: 'query_root' }
  & { WUI_User_aggregate: (
    { __typename?: 'WUI_User_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'WUI_User_aggregate_fields' }
      & Pick<Wui_User_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type GetAllUsersQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type GetAllUsersQuery = (
  { __typename?: 'query_root' }
  & { WUI_User: Array<(
    { __typename?: 'WUI_User' }
    & Pick<Wui_User, 'id' | 'created_at' | 'first_name' | 'last_name' | 'phone_number' | 'username' | 'country' | 'contact_email'>
  )> }
);

export type GetHeroBannerListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHeroBannerListQuery = (
  { __typename?: 'query_root' }
  & { HeroBanner: Array<(
    { __typename?: 'HeroBanner' }
    & Pick<HeroBanner, 'order' | 'subtitle' | 'title' | 'created_at' | 'id'>
    & { WUI_File_Lg?: Maybe<(
      { __typename?: 'WUI_File' }
      & Pick<Wui_File, 'id' | 'url'>
    )> }
  )> }
);

export type GetHeroBannerByIdQueryVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type GetHeroBannerByIdQuery = (
  { __typename?: 'query_root' }
  & { HeroBanner_by_pk?: Maybe<(
    { __typename?: 'HeroBanner' }
    & Pick<HeroBanner, 'active' | 'id' | 'order' | 'subtitle' | 'title'>
    & { WUI_File_Lg?: Maybe<(
      { __typename?: 'WUI_File' }
      & Pick<Wui_File, 'url' | 'id'>
    )>, WUI_File_Md?: Maybe<(
      { __typename?: 'WUI_File' }
      & Pick<Wui_File, 'url' | 'id'>
    )>, WUI_File_Sm?: Maybe<(
      { __typename?: 'WUI_File' }
      & Pick<Wui_File, 'id' | 'url'>
    )> }
  )> }
);

export type GetLastHeroOrderQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLastHeroOrderQuery = (
  { __typename?: 'query_root' }
  & { HeroBanner_aggregate: (
    { __typename?: 'HeroBanner_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'HeroBanner_aggregate_fields' }
      & { max?: Maybe<(
        { __typename?: 'HeroBanner_max_fields' }
        & Pick<HeroBanner_Max_Fields, 'order'>
      )> }
    )> }
  ) }
);


export const CreateHeroBannerDocument = gql`
    mutation CreateHeroBanner($order: Int!, $subtitle: String!, $title: String!, $active: Boolean!) {
  insert_HeroBanner_one(
    object: {subtitle: $subtitle, title: $title, order: $order, active: $active}
  ) {
    id
  }
}
    `;

export function useCreateHeroBannerMutation() {
  return Urql.useMutation<CreateHeroBannerMutation, CreateHeroBannerMutationVariables>(CreateHeroBannerDocument);
};
export const DeleteHeroBannerDocument = gql`
    mutation DeleteHeroBanner($where: HeroBanner_bool_exp!) {
  delete_HeroBanner(where: $where) {
    affected_rows
  }
}
    `;

export function useDeleteHeroBannerMutation() {
  return Urql.useMutation<DeleteHeroBannerMutation, DeleteHeroBannerMutationVariables>(DeleteHeroBannerDocument);
};
export const DeleteOptionalBannerPicDocument = gql`
    mutation DeleteOptionalBannerPic($set: HeroBanner_set_input!, $id: bigint!, $imageID: bigint!) {
  update_HeroBanner_by_pk(pk_columns: {id: $id}, _set: $set) {
    id
  }
  delete_WUI_File_by_pk(id: $imageID) {
    id
  }
}
    `;

export function useDeleteOptionalBannerPicMutation() {
  return Urql.useMutation<DeleteOptionalBannerPicMutation, DeleteOptionalBannerPicMutationVariables>(DeleteOptionalBannerPicDocument);
};
export const UpdateHeroBannerDocument = gql`
    mutation UpdateHeroBanner($id: bigint!, $set: HeroBanner_set_input!) {
  update_HeroBanner(where: {id: {_eq: $id}}, _set: $set) {
    affected_rows
  }
}
    `;

export function useUpdateHeroBannerMutation() {
  return Urql.useMutation<UpdateHeroBannerMutation, UpdateHeroBannerMutationVariables>(UpdateHeroBannerDocument);
};
export const UpdateMultipleHeroesBannersDocument = gql`
    mutation UpdateMultipleHeroesBanners($updateBanners: [HeroBanner_insert_input!]!) {
  insert_HeroBanner(
    objects: $updateBanners
    on_conflict: {constraint: HeroBanner_pkey, update_columns: order}
  ) {
    returning {
      id
    }
  }
}
    `;

export function useUpdateMultipleHeroesBannersMutation() {
  return Urql.useMutation<UpdateMultipleHeroesBannersMutation, UpdateMultipleHeroesBannersMutationVariables>(UpdateMultipleHeroesBannersDocument);
};
export const UploadHeroBannerPictureDocument = gql`
    mutation UploadHeroBannerPicture($key: String!, $heroID: bigint!, $imageType: String!, $imageBase64: String!, $oldImageID: bigint!) {
  uploadPicture_HeroBanner(
    key: $key
    heroID: $heroID
    imageBase64: $imageBase64
    imageType: $imageType
  ) {
    fileId
    imageUrl
    fileType
  }
  delete_WUI_File_by_pk(id: $oldImageID) {
    id
  }
}
    `;

export function useUploadHeroBannerPictureMutation() {
  return Urql.useMutation<UploadHeroBannerPictureMutation, UploadHeroBannerPictureMutationVariables>(UploadHeroBannerPictureDocument);
};
export const LoginAdminDocument = gql`
    mutation LoginAdmin($externalID: String!, $token: String!) {
  loginAdmin(externalID: $externalID, token: $token) {
    id
    email
    firstName
    lastName
    username
    phoneNumber
    picture
    country
  }
}
    `;

export function useLoginAdminMutation() {
  return Urql.useMutation<LoginAdminMutation, LoginAdminMutationVariables>(LoginAdminDocument);
};
export const CountTotalUsersDocument = gql`
    query CountTotalUsers {
  WUI_User_aggregate {
    aggregate {
      count(columns: id)
    }
  }
}
    `;

export function useCountTotalUsersQuery(options: Omit<Urql.UseQueryArgs<CountTotalUsersQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<CountTotalUsersQuery>({ query: CountTotalUsersDocument, ...options });
};
export const GetAllUsersDocument = gql`
    query GetAllUsers($offset: Int!, $limit: Int!) {
  WUI_User(order_by: {created_at: desc}, limit: $limit, offset: $offset) {
    id
    created_at
    first_name
    last_name
    phone_number
    username
    country
    contact_email
  }
}
    `;

export function useGetAllUsersQuery(options: Omit<Urql.UseQueryArgs<GetAllUsersQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetAllUsersQuery>({ query: GetAllUsersDocument, ...options });
};
export const GetHeroBannerListDocument = gql`
    query GetHeroBannerList {
  HeroBanner(order_by: {order: asc}) {
    WUI_File_Lg {
      id
      url
    }
    order
    subtitle
    title
    created_at
    id
  }
}
    `;

export function useGetHeroBannerListQuery(options: Omit<Urql.UseQueryArgs<GetHeroBannerListQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetHeroBannerListQuery>({ query: GetHeroBannerListDocument, ...options });
};
export const GetHeroBannerByIdDocument = gql`
    query GetHeroBannerByID($id: bigint!) {
  HeroBanner_by_pk(id: $id) {
    active
    id
    order
    subtitle
    title
    WUI_File_Lg {
      url
      id
    }
    WUI_File_Md {
      url
      id
    }
    WUI_File_Sm {
      id
      url
    }
  }
}
    `;

export function useGetHeroBannerByIdQuery(options: Omit<Urql.UseQueryArgs<GetHeroBannerByIdQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetHeroBannerByIdQuery>({ query: GetHeroBannerByIdDocument, ...options });
};
export const GetLastHeroOrderDocument = gql`
    query GetLastHeroOrder {
  HeroBanner_aggregate {
    aggregate {
      max {
        order
      }
    }
  }
}
    `;

export function useGetLastHeroOrderQuery(options: Omit<Urql.UseQueryArgs<GetLastHeroOrderQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetLastHeroOrderQuery>({ query: GetLastHeroOrderDocument, ...options });
};