import { useAuth } from 'clients/auth.client'
import Loading from 'components/Loading'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

function Logout() {
  const history = useHistory()
  const { logout } = useAuth()

  useEffect(() => {
    setTimeout(() => {
      logout()
      history.push('/')
    }, 15000)
  }, [])

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-25">
      <Loading $size="lg" $type="logo" />
    </div>
  )
}

export default Logout
