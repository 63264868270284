import React from 'react'
import Switch from 'react-ios-switch'

export enum SwitchStateEnum {
  True = 'true',
  False = 'false',
}

type Props = {
  defaultValue?: boolean
  onChange?: (state: boolean) => void
  disabled?: boolean
  showStateMap: Record<SwitchStateEnum, string>
  $showState?: boolean
}

const FormSwitch = ({
  defaultValue,
  onChange,
  disabled,
  showStateMap,
  $showState,
}: Props) => {
  const [state, setState] = React.useState(defaultValue || false)

  return (
    <>
      <Switch
        checked={state}
        onChange={() => {
          setState((current) => !current)
          onChange?.(!state)
        }}
        onColor={'#4e8afd'}
        disabled={disabled}
      />
      {$showState && (
        <div className="text-xs text-center">
          {showStateMap[state ? SwitchStateEnum.True : SwitchStateEnum.False]}
        </div>
      )}
    </>
  )
}

FormSwitch.defaultProps = {
  showStateMap: {
    [SwitchStateEnum.True]: 'Enable',
    [SwitchStateEnum.False]: 'Disable',
  },
}

export default FormSwitch
