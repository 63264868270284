export const AUTH = {
  storageKey: process.env.REACT_APP_AUTH_STORAGE_KEY || 'auth',
}

type Account = any

export const ACCOUNT: {
  storageKey: string
  defaultData: Account
} = {
  storageKey: 'loggedInAccount',
  defaultData: {
    id: '',
    type: null,
  },
}
