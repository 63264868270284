import Dashboard from 'pages/Dashboard'
import React from 'react'

function HomePage() {
  return (
    <div>
      <div>
        <Dashboard />
      </div>
    </div>
  )
}

export default HomePage
