import React from 'react'
import ReactDOM from 'react-dom'
import 'tailwindcss/tailwind.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter as Router } from 'react-router-dom'
import GraphqlClientProvider from 'clients/graphql.client'
import { GlobalStoreProvider } from 'stores/global.store'
import { AuthProvider } from 'clients/auth.client'
import { ToastProvider } from 'components/ToastMessage'
import 'styles/index'

const composeProviders = (
  providers: React.FC<any>[],
  children: React.ReactElement,
): React.ReactElement =>
  providers
    .reverse()
    .reduce((acc, Provider) => <Provider>{acc}</Provider>, children)
const RouterProvider = ({ children }: any) => <Router>{children}</Router>
ReactDOM.render(
  composeProviders(
    [
      RouterProvider,
      GlobalStoreProvider,
      GraphqlClientProvider,
      AuthProvider,
      ToastProvider,
    ],
    <App />,
  ),
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
