import React from 'react'
import makeStorage from 'utils/makeStorage'
import { ACCOUNT, AUTH } from 'config'
import { Maybe } from 'generated/graphql'

export type Auth = {
  token: string
}

export type Account = {
  id: number
  firstName: string
  lastName: string
  username: string
  email: string
  phoneNumber?: Maybe<string>
  picture?: string
  country: string
}

export type Profile = Pick<
  Account,
  'id' | 'firstName' | 'lastName' | 'username' | 'picture'
>

export type Store = {
  account: Account | null
}

type Set = (key: 'auth' | 'account', value: Auth | Account) => void

type Clear = () => void

export const AuthStorage = makeStorage<Auth>(AUTH.storageKey)
export const AccountStorage = makeStorage<Account>(
  ACCOUNT.storageKey,
  ACCOUNT.defaultData,
)

const Context = React.createContext<Store & { set: Set; clear: Clear }>({
  account: null,
  set: () => undefined,
  clear: () => undefined,
})

export const useGlobalStore = () => React.useContext(Context)

export const GlobalStoreProvider = ({ children }: any) => {
  const [store, setStore] = React.useState<Store>({
    account: AccountStorage.get(),
  })

  console.debug('global store', store)

  const set: Set = (key, value) => {
    switch (key) {
      case 'auth': {
        AuthStorage.set(value as Auth)
        break
      }
      case 'account': {
        AccountStorage.set(value as Account)

        setStore((currentStore) => ({
          ...currentStore,
          account: value as Account,
        }))
        break
      }
    }
  }

  const clear = () => {
    setStore({
      account: null,
    })

    AuthStorage.remove()
    AccountStorage.remove()
  }

  return (
    <Context.Provider value={{ ...store, set, clear }}>
      {children}
    </Context.Provider>
  )
}
