import cc from 'classcat'
import {
  DetailedHTMLProps,
  forwardRef,
  InputHTMLAttributes,
  TextareaHTMLAttributes,
  useEffect,
  useState,
} from 'react'
import autosize from 'autosize'
import NumberFormat from 'react-number-format'

const STYLES_COMMON = `w-full rounded-md border py-2 px-3 text-md`

export const STYLES_ENABLE = [
  STYLES_COMMON,
  'bg-white placeholder-gray-600 border-gray-600',
].join(' ')
export const STYLES_DISABLE = [
  STYLES_COMMON,
  'bg-gray-400 text-gray-700 border-gray-500',
].join(' ')

type CommonProps = {
  subtext?: string
  subtextAlign?: 'left' | 'center' | 'right'
  error?: string
  label?: string
  $disableLabel?: boolean
  $maskType?: 'localNumber' | 'money'
}

type Props =
  | ({
      $type: 'text'
    } & CommonProps &
      DetailedHTMLProps<
        InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
      >)
  | ({
      $type: 'longtext'
    } & CommonProps &
      DetailedHTMLProps<
        TextareaHTMLAttributes<HTMLTextAreaElement>,
        HTMLTextAreaElement
      >)

const FormField = forwardRef<any, any>(
  (
    {
      subtext,
      subtextAlign,
      error,
      label,
      $disableLabel,
      $type,
      $maskType,
      ...nativeAttrs
    }: Props,
    ref,
  ) => {
    // TODO: This is experimental, let's see how it works
    const [element, setElement] = useState<any>(null)
    const [hasValue, setHasValue] = useState(false)
    useEffect(() => {
      setHasValue(!!element?.value)

      if ($type === 'longtext') {
        autosize(element)
      }

      return () => {
        if ($type === 'longtext') {
          autosize.destroy(element)
        }
      }
    }, [element])

    return (
      <FormFieldWrapper>
        {!$disableLabel && hasValue && (
          <FormFieldLabel htmlFor={nativeAttrs.name!}>
            {label || nativeAttrs.placeholder}
          </FormFieldLabel>
        )}

        {$type === 'text' ? (
          $maskType ? (
            <NumberFormat
              {...(nativeAttrs as any)}
              className={cc([
                {
                  [STYLES_ENABLE]: !nativeAttrs.disabled,
                  [STYLES_DISABLE]: nativeAttrs.disabled,
                },
              ])}
              thousandSeparator={true}
              onChange={(e) => {
                setHasValue(!!e.target.value)
                nativeAttrs.onChange?.(e as any)
              }}
              getInputRef={(el: any) => {
                setElement(el)
                return (ref as any)?.(el)
              }}
              prefix={$maskType === 'money' ? '$' : undefined}
            />
          ) : (
            <input
              data-testid={`input-${nativeAttrs.name || nativeAttrs.id}`}
              {...(nativeAttrs as any)}
              className={cc([
                {
                  [STYLES_ENABLE]: !nativeAttrs.disabled,
                  [STYLES_DISABLE]: nativeAttrs.disabled,
                },
              ])}
              onChange={(e) => {
                setHasValue(!!e.target.value)
                nativeAttrs.onChange?.(e as any)
              }}
              ref={(el) => {
                setElement(el)
                return (ref as any)?.(el)
              }}
            />
          )
        ) : (
          <textarea
            data-testid={`input-${nativeAttrs.name || nativeAttrs.id}`}
            {...(nativeAttrs as any)}
            className={cc([
              'resize-none min-h-8',
              {
                [STYLES_ENABLE]: !nativeAttrs.disabled,
                [STYLES_DISABLE]: nativeAttrs.disabled,
              },
            ])}
            onChange={(e) => {
              setHasValue(!!e.target.value)
              nativeAttrs.onChange?.(e as any)
            }}
            ref={(el) => {
              setElement(el)
              return (ref as any)?.(el)
            }}
            rows={1}
          />
        )}
        {subtext && (
          <div
            className={cc([
              'text-gray-500 text-xs',
              {
                'text-left': subtextAlign === 'left',
                'text-center': subtextAlign === 'center',
                'text-right': subtextAlign === 'right',
              },
            ])}
          >
            {subtext}
          </div>
        )}
        {error && <div className="text-action-fail text-sm pt-1">{error}</div>}
      </FormFieldWrapper>
    )
  },
)

FormField.defaultProps = {
  type: 'text',
  $type: 'text',
}

export default FormField

export const FormFieldWrapper = ({ children }: { children: any }) => (
  <div className="relative">{children}</div>
)

export const FormFieldLabel = ({
  htmlFor,
  children,
}: {
  htmlFor: string
  children: any
}) => {
  return (
    <label
      htmlFor={htmlFor}
      className="text-gray-700 text-xs absolute bottom-full pb-1"
    >
      {children}
    </label>
  )
}
