import {
  createClient,
  Provider,
  dedupExchange,
  fetchExchange,
  errorExchange,
  subscriptionExchange,
} from 'urql'
import { cacheExchange } from '@urql/exchange-graphcache'
import {
  AccountStorage,
  Auth,
  AuthStorage,
  useGlobalStore,
} from 'stores/global.store'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import firebaseApp from './firebase.client'

const GraphqlClientProvider = ({ children }: any) => {
  const globalStore = useGlobalStore()
  const cache = cacheExchange({
    updates: {
      Mutation: {},
    },
  })

  let jwtExpiredCounter = 0

  const errorHandler = errorExchange({
    onError: async (error) => {
      if (error.message.includes('JWTExpired')) {
        jwtExpiredCounter++

        if (jwtExpiredCounter >= 3) {
          globalStore.clear()
          jwtExpiredCounter = 0
        } else {
          const token = await firebaseApp.auth().currentUser?.getIdToken(true)

          if (token) {
            const auth: Auth = {
              token,
            }
            globalStore.set('auth', auth)
          } else {
            globalStore.clear()
            firebaseApp.auth().signOut()
          }
        }
      }
    },
  })
  const subscriptionClient = new SubscriptionClient(
    process.env.REACT_APP_GRAPHQL_WS_URL as string,
    {
      reconnect: true,
      connectionParams: () => {
        const auth = AuthStorage.get()
        const account = AccountStorage.get()
        if (auth && account) {
          return {
            headers: {
              authorization: `Bearer ${auth.token}`,
            },
          }
        }
        return {}
      },
    },
  )
  const client = createClient({
    url: process.env.REACT_APP_GRAPHQL_URL as string,
    requestPolicy: 'cache-and-network', // TODO: Temporary until we work on manual cache updates
    fetchOptions: () => {
      const auth = AuthStorage.get()
      const account = AccountStorage.get()
      if (auth && account) {
        return {
          headers: {
            authorization: `Bearer ${auth.token}`,
          },
        }
      }

      return {}
    },
    exchanges: [
      dedupExchange,
      errorHandler,
      cache as any,
      fetchExchange,
      subscriptionExchange({
        forwardSubscription: (operation) =>
          subscriptionClient.request(operation) as any,
      }),
    ],
  })

  // TODO: Handle errors + jwt expiration
  return <Provider value={client}>{children}</Provider>
}

export default GraphqlClientProvider
