import { FC } from 'react'
import { useAuth } from '../../clients/auth.client'
import LoginSignup from 'components/LoginSignup'

type Props = {
  Component: FC
}

const RequiresLogin = ({ Component }: Props) => {
  const { isAuthenticated } = useAuth()

  if (!isAuthenticated) {
    return (
      <div>
        <div className="md:w-1/2 lg:w-5/12 xl:w-2/4 mx-auto py-10 ">
          <LoginSignup />
        </div>
      </div>
    )
  }

  return <Component />
}

export default RequiresLogin
