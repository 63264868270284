import React from 'react'
import firebase from 'firebase/app'
import { useGlobalStore, Auth, Account } from 'stores/global.store'
import { useLoginAdminMutation } from 'generated/graphql'
import { useHistory } from 'react-router'
import ms from 'ms'
import firebaseApp, { analytics } from './firebase.client'

// This is the data from the provider (e.g. Firebase)
type ProviderAuth = {
  user: firebase.User
  token: string
}

type ContextType = {
  providerAuth: ProviderAuth | null
  account: Account | null
  authenticating: boolean
  isAuthenticated: boolean
  login: (account: Account) => void
  logout: () => void
}

const Context = React.createContext<ContextType>({
  providerAuth: null,
  account: null,
  authenticating: false,
  isAuthenticated: false,
  login: () => undefined,
  logout: () => undefined,
})

export const useAuth = (): ContextType => React.useContext(Context)

export const AuthProvider = ({ children }: any) => {
  // TODO: Investigate if we can add useCallback to the arrow fns in this component
  const history = useHistory()
  const globalStore = useGlobalStore()
  const [, loginMutation] = useLoginAdminMutation()
  const { currentUser } = firebaseApp.auth()
  const [
    providerAuthState,
    setProviderAuthState,
  ] = React.useState<ProviderAuth | null>(
    currentUser
      ? {
          user: currentUser,
          token: '', // This will populated in the useEffect, always
        }
      : null,
  )
  const isAuthenticated = !!globalStore.account
  const [authenticating, setAuthenticating] = React.useState<boolean>(
    isAuthenticated,
  )
  const logout = () => {
    firebaseApp.auth().signOut()
    globalStore.clear()
  }
  const login = async (account: Account) => {
    const token = await firebaseApp.auth().currentUser?.getIdToken(true)

    if (token) {
      const auth: Auth = {
        token,
      }
      globalStore.set('auth', auth)
      globalStore.set('account', account)

      analytics.logEvent('login')
      analytics.setUserProperties({
        ...account,
      })
      analytics.setUserId(account.id.toString())
    } else {
      alert('Cannot login')
      logout()
    }
  }

  const getAccountAndLogin = async (token: string, user: firebase.User) => {
    let error: any
    try {
      const result = await loginMutation(
        {
          token,
          externalID: user.uid,
        },
        {
          requestPolicy: 'network-only',
        },
      )

      if (result.data && result.data.loginAdmin) {
        await login({
          id: result.data.loginAdmin.id,
          username: result.data.loginAdmin.username,
          email: result.data.loginAdmin.email,
          firstName: result.data.loginAdmin.firstName,
          lastName: result.data.loginAdmin.lastName,
          phoneNumber: result.data.loginAdmin.phoneNumber,
          picture: result.data.loginAdmin.picture || undefined,
          country: result.data.loginAdmin.country || '',
        })
      } else {
        error = result.error
      }
    } catch (err) {
      error = err
    }

    if (error) {
      if (error.message?.endsWith('User not found')) {
        setProviderAuthState({
          token,
          user,
        })
      } else {
        alert(error.message)
        logout()
        history.push('/')
      }
    }
  }

  const authenticate = async (user: firebase.User) => {
    setAuthenticating(true)

    if (user) {
      const token = await user.getIdToken()
      await getAccountAndLogin(token, user)
      setAuthenticating(false)
    } else {
      firebaseApp.auth().signOut()
      setAuthenticating(false)
    }
  }

  const shouldRenewToken = async (): Promise<boolean> => {
    const { currentUser } = firebaseApp.auth()

    if (currentUser) {
      const { expirationTime } = await currentUser.getIdTokenResult()
      const diff = new Date(expirationTime).valueOf() - new Date().valueOf()

      if (diff < ms('10m')) {
        return true
      }
    }

    return false
  }

  React.useEffect(() => {
    const timer = setInterval(async () => {
      if (await shouldRenewToken()) {
        const { currentUser } = firebaseApp.auth()

        if (currentUser) {
          await currentUser.getIdTokenResult(true)
        }
      }
    }, ms('5m'))

    const tokenChangeSub = firebaseApp.auth().onIdTokenChanged(async (user) => {
      if (user) {
        const newToken = await user.getIdToken()
        globalStore.set('auth', {
          token: newToken,
        })
      } else {
        logout()
      }
    })

    const authChangeSub = firebaseApp
      .auth()
      .onAuthStateChanged(async (user) => {
        if (user) {
          await authenticate(user)
        } else {
          logout()
        }
      })

    return () => {
      authChangeSub() // will unsubscribe
      tokenChangeSub()
      clearTimeout(timer)
    }
  }, [])

  React.useEffect(() => {
    if (authenticating) {
      window.localStorage.setItem('isAuthenticating', 'yes')
    } else {
      window.localStorage.removeItem('isAuthenticating')
    }
  }, [authenticating])

  return (
    <Context.Provider
      value={{
        providerAuth: providerAuthState,
        account: globalStore.account,
        authenticating,
        isAuthenticated,
        login,
        logout,
      }}
    >
      {children}
    </Context.Provider>
  )
}
