import Routes from 'Routes'
// import { Link } from 'react-router-dom'
import Header from 'components/Header/indext'
import { useAuth } from 'clients/auth.client'
import Loading from 'components/Loading'

const App = () => {
  const { authenticating } = useAuth()
  if (authenticating) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-25">
        <Loading $size="lg" $type="logo" />
      </div>
    )
  }

  return (
    <div className="theme-default">
      <Header />
      <main>
        <Routes />
      </main>
    </div>
  )
}

export default App
