import Container from 'components/Container'
import withContainer from 'hocs/withContainer'
import withSettingsMenu from 'hocs/withSettingsMenu'
import React from 'react'

function DummyPage() {
  return (
    <Container topAndBottom>
      <div>I'm just a tiny little dummy page</div>
    </Container>
  )
}

export default withContainer(withSettingsMenu(DummyPage))
