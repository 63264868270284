import React from 'react'
import FormField from '../FormField'
import FormGroup from '../FormsGroup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from '../../components/Button'
// import SocialMediaLoginButton from '../../../components/SocialMediaLoginButton'
import { useAuth } from '../../clients/auth.client'
import firebaseApp from 'clients/firebase.client'

const validationSchema = yup.object({
  email: yup.string().required().email(),
  password: yup.string().required().min(5),
})

const FormLogin = () => {
  const { register, handleSubmit, formState } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(validationSchema),
  })
  const { errors } = formState
  const [serverError, setServerError] = React.useState<string | null>(null)
  const { authenticating } = useAuth()

  return (
    <div>
      {/* <SocialMediaLoginButton type="facebook" action="login" /> */}

      {/* OR */}
      <div className="text-center">
        <div className="inline-flex items-center">
          <hr className="w-8 border-gray-500" />
          <span className="text-gray-500 px-3">or</span>
          <hr className="w-8 border-gray-500" />
        </div>
      </div>

      <div>
        {/* Email & Password */}
        <form
          onSubmit={handleSubmit(async (values) => {
            try {
              await firebaseApp
                .auth()
                .signInWithEmailAndPassword(values.email, values.password)
            } catch (error) {
              setServerError(error.message || 'Something went wrong')
            }
          })}
        >
          <FormGroup>
            <FormField
              type="email"
              placeholder="Email"
              error={errors.email?.message}
              {...register('email')}
            />
            <FormField
              type="password"
              placeholder="Password"
              error={errors.password?.message}
              {...register('password')}
            />

            {serverError && (
              <div className="text-action-fail">{serverError}</div>
            )}

            <Button
              $fluid
              loading={formState.isSubmitting || authenticating}
              data-testid="button-login"
            >
              Login
            </Button>
          </FormGroup>
        </form>
      </div>
    </div>
  )
}

export default FormLogin
