import React from 'react'
import loadingWhite from 'assets/loading-white.gif'
import loadingPrimary from 'assets/loading-primary.gif'
import loadingLogo from 'assets/Influexer-Icon-GIF.gif'

export type Size = 'xs' | 'sm' | 'md' | 'lg'

type Type = 'dots' | 'logo'

type Color = 'primary' | 'white'
interface Props {
  $size: Size
  $color: Color
  $type: Type
  context?: string
  children?: React.ReactNode
}

const widthMap = {
  xs: 25,
  sm: 35,
  md: 60,
  lg: 100,
}

export const LoadingSpinner = ({
  $size,
  $color,
  $type,
}: {
  $size: Size
  $color: Color
  $type: Type
}) => (
  <img
    src={
      $type === 'dots'
        ? $color === 'primary'
          ? loadingPrimary
          : loadingWhite
        : loadingLogo
    }
    alt="Loading"
    className="inline-block"
    style={{
      width: widthMap[$size],
    }}
  />
)

const Loading = ({ $size, $color, $type, context, children }: Props) => {
  if (context && process.env.REACT_APP_DEBUG) {
    // eslint-disable-next-line no-console
    console.debug(`Loading Component :: ${context}`)
  }

  return (
    <div className="text-center p-2 py-24">
      <LoadingSpinner $size={$size} $color={$color} $type={$type} />
      {children && (
        <div className="body-2 text-green" style={{ marginTop: -10 }}>
          {children}
        </div>
      )}
    </div>
  )
}

Loading.defaultProps = {
  $size: 'md',
  $color: 'primary',
  $type: 'dots',
}

export default Loading
