import { useCallback } from 'react'

type SettingsMenuType = {
  to: string
  text: string
}

const useSettingsMenu = () => {
  //   const { account } = useAuth()

  const SETTINGS_LINKS = useCallback(
    () =>
      [
        {
          to: '/',
          text: 'Dashboard',
        },

        {
          to: '/settings/banner_hero',
          text: 'Dashboard Hero',
        },
        {
          to: '/settings/users',
          text: 'Users',
        },
        {
          to: '/dummy1',
          text: 'page',
        },
        {
          to: '/dummy2',
          text: 'page',
        },
        {
          to: '/dummy3',
          text: 'page',
        },
      ].filter(Boolean) as SettingsMenuType[],
    [],
  )
  return SETTINGS_LINKS
}

export default useSettingsMenu
