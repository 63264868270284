import React from 'react'
import FormLogin from '../../forms/FormLogin'

function LoginSignup() {
  return (
    <div>
      <div>Login Signup</div>
      <FormLogin />
    </div>
  )
}

export default LoginSignup
