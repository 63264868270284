import { useState, useMemo } from 'react'
import Button from 'components/Button'
import ErrorMessage from 'components/ErrorMessage'
import { LoadingSpinner } from 'components/Loading'
import { useCountTotalUsersQuery, useGetAllUsersQuery } from 'generated/graphql'
import { useTable, useSortBy } from 'react-table'
import withContainer from 'hocs/withContainer'
import Container from 'components/Container'
import Title from 'components/Title'
import { PUBLIC_PROFILE_URL } from 'globalConstants'
import getFullName from 'utils/getFullName'
import FormDropDown from 'forms/FormDropDown'
import FormSwitch from 'forms/FormSwitch'

const COLUMNS = [
  { Header: 'Id', accessor: 'id' },
  { Header: 'Name', accessor: 'name' },
  { Header: 'Email', accessor: 'contact_email' },
  { Header: 'Phone Number', accessor: 'phone_number' },
  { Header: 'UserName', accessor: 'username' },
  { Header: 'Country', accessor: 'country' },
  { Header: 'Profile URL', accessor: 'user_link' },
]

const LIMIT_PER_PAGE = 20

function SettingsUsers() {
  const [page, setPage] = useState(0)
  const [{ data, fetching, error }] = useGetAllUsersQuery({
    variables: { offset: page * LIMIT_PER_PAGE, limit: LIMIT_PER_PAGE },
  })
  const columns = useMemo(() => COLUMNS, [])
  const [CountTotalUsersState] = useCountTotalUsersQuery()
  const [sortByColumn, setSortByColumn] = useState('')
  const [sortByDesc, setSortByDesc] = useState(false)
  const totalUsers =
    CountTotalUsersState.data?.WUI_User_aggregate.aggregate?.count || 0
  const totalPages = Math.round(totalUsers / LIMIT_PER_PAGE)
  const dataColumns = useMemo(
    () =>
      data?.WUI_User.map(
        ({
          first_name,
          last_name,
          contact_email,
          id,
          username,
          phone_number,
          country,
        }) => ({
          id,
          name: getFullName(first_name, last_name),
          contact_email,
          username,
          phone_number: phone_number || 'N/A',
          country: country || 'N/A',
          user_link: `${PUBLIC_PROFILE_URL}/${username}`,
        }),
      ) || [],
    [data, sortByColumn, sortByDesc],
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data: dataColumns,
      initialState: {
        sortBy: sortByColumn ? [{ id: sortByColumn, desc: sortByDesc }] : [],
      },
    },
    useSortBy,
  )
  if (fetching) {
    return <LoadingSpinner $color="primary" $size="md" $type="dots" />
  }
  if (error) {
    return <ErrorMessage>{error.message}</ErrorMessage>
  }

  if (!data) {
    return null
  }

  return (
    <Container topAndBottom leftAndRight={false} className="w-full">
      <Title title="List of users" />
      <div className="flex gap-8 items-center my-4">
        <div className="font-bold">Sort By:</div>
        <FormDropDown
          onChange={(e: any) => {
            setSortByColumn(e.target.value)
          }}
        >
          <option value={''}>None</option>
          {COLUMNS.map((column) => (
            <option value={column.accessor}>{column.Header}</option>
          ))}
        </FormDropDown>
        <div className="flex items-center gap-3">
          <div className="font-bold">Desc:</div>
          <FormSwitch
            onChange={(state) => {
              setSortByDesc(state)
            }}
          />
        </div>
      </div>
      <div className="border-b border-gray-200 shadow my-4">
        <table {...getTableProps()} className="w-full text-center">
          <thead className="bg-gray-50">
            {headerGroups.map((headerGroup: any) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th
                    {...column.getHeaderProps(column)}
                    className="px-6 py-2 text-md text-gray-700"
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="bg-white">
            {rows.map((row: any) => {
              prepareRow(row)
              return (
                <tr className="whitespace-nowrap ">
                  {row.cells.map((cell: any) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className="px-6 py-4 text-sm text-gray-500 border"
                      >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <div className="my-4 flex mx-auto items-center justify-evenly">
        <Button
          className="rounded-full"
          onClick={() => {
            if (page - 1 >= 0) {
              setPage(page - 1)
            }
          }}
        >
          -
        </Button>
        <div className="mx-auto flew-grow">{`${page + 1}/${
          totalPages === 0 ? 1 : totalPages
        }`}</div>
        <Button
          className="rounded-full"
          onClick={() => {
            if (page + 1 < totalPages) {
              setPage(page + 1)
            }
          }}
        >
          +
        </Button>
      </div>
    </Container>
  )
}

export default withContainer(SettingsUsers, { containerClass: 'w-full' })
