import cc from 'classcat'
import Link from 'components/Link'
import { ReactComponent as IconArrow } from 'assets/icons/arrow-payment.svg'
import useCurrentPath from 'hooks/useCurrentPath'
import useSettingsMenu from 'hooks/useSettingsMenu'
import Container from 'components/Container'

const SettingsMenu = () => {
  const currentPath = useCurrentPath()
  const settingsMenu = useSettingsMenu()

  return (
    <Container topAndBottom>
      <ul className="h-full bg-white bg-opacity-50 overflow-hidden rounded-lg">
        {settingsMenu().map(({ to, text }, index) => (
          <li key={`settings-menu-${index}`}>
            {index > 0 && <hr className="border-gray-400" />}
            <Link
              to={to}
              active={currentPath === to}
              className={cc([
                'flex items-center justify-between px-6 py-4 text-lg hover:bg-white',
                {
                  'bg-white border-primary border-l-2 shadow-lg':
                    currentPath === to,
                },
              ])}
            >
              <span>{text}</span>
              <IconArrow className="w-2" />
            </Link>
          </li>
        ))}
      </ul>
    </Container>
  )
}

export default SettingsMenu
