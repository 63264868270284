import Title from 'components/Title'
import withContainer from 'hocs/withContainer'
import withSettingsMenu from 'hocs/withSettingsMenu'
import React from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { ReactComponent as IconClose } from 'assets/icons/x.svg'
import { ReactComponent as IconEdit } from 'assets/icons/edit-placeholder.svg'
import { ReactComponent as DragIcon } from 'assets/icons/drag-icon.svg'
import Button from 'components/Button'
import { useHistory } from 'react-router-dom'
import {
  DeleteHeroBannerDocument,
  UpdateMultipleHeroesBannersDocument,
  useGetHeroBannerListQuery,
} from 'generated/graphql'
import { LoadingSpinner } from 'components/Loading'
import ErrorMessage from 'components/ErrorMessage'
import { useFieldArray, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import combineQuery from 'graphql-combine-query'
import { differenceBy, intersectionBy } from 'lodash/fp'
import { useClient } from 'urql'
import { useToast } from 'components/ToastMessage'

function DashBoardHero() {
  const [{ data, fetching, error }] = useGetHeroBannerListQuery()
  if (fetching) {
    return <LoadingSpinner $color="primary" $size="md" $type="dots" />
  }
  if (error) {
    return <ErrorMessage>{error.message}</ErrorMessage>
  }

  if (!data) {
    return null
  }
  return (
    <div>
      <BannersList
        initialBanners={data.HeroBanner.map(
          ({ title, subtitle, id, WUI_File_Lg }) => ({
            id,
            title,
            subtitle,
            image: WUI_File_Lg?.url || '',
            imageID: WUI_File_Lg?.id,
          }),
        )}
      />
    </div>
  )
}
const validationSchema = yup.object({
  banners: yup
    .array(
      yup.object({
        id: yup.number(),
        title: yup.string(),
        subtitle: yup.string(),
        image: yup.string(),
      }),
    )
    .required()
    .min(1, 'at least one item is required'),
})
type BannerType = {
  id: number
  title: string
  subtitle: string
  image: string
  imageID: string
}
const BannersList = ({ initialBanners }: { initialBanners: BannerType[] }) => {
  const { control, formState, handleSubmit } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      banners: initialBanners || [],
    },
  })
  const { isDirty } = formState
  const { fields, remove, move } = useFieldArray({
    control,
    name: 'banners',
  })
  const history = useHistory()
  const client = useClient()
  const toast = useToast()

  return (
    <form
      onSubmit={handleSubmit(async (values) => {
        const findBannerChanges = (
          initialPictures: BannerType[],
          picturesState: BannerType[],
        ): {
          toUpdate: BannerType[]
          toDelete: BannerType[]
        } => ({
          toUpdate: intersectionBy('id', picturesState, initialPictures),
          toDelete: differenceBy('id', initialPictures, picturesState),
        })

        const heroesChanges = findBannerChanges(initialBanners, values.banners)
        let mutation: any = combineQuery('ExterminateAllHerosMutation')
        if (heroesChanges.toDelete.length > 0) {
          mutation = mutation.add(DeleteHeroBannerDocument, {
            where: {
              _or: heroesChanges.toDelete.map((x) => ({ id: { _eq: x.id } })),
            },
          })
        }
        if (heroesChanges.toUpdate.length > 0) {
          mutation = mutation.add(UpdateMultipleHeroesBannersDocument, {
            updateBanners: heroesChanges.toUpdate.map(
              ({ id, title, subtitle }, order) => ({
                id,
                title,
                subtitle,
                order,
              }),
            ),
          })
        }
        if ('document' in mutation) {
          const { document, variables } = mutation as any
          const response = await client
            .mutation(document, variables)
            .toPromise()
          if (response.error) {
            toast.notify({
              type: 'failure',
              message: `There was an error updating the list`,
            })
          } else {
            toast.notify({
              type: 'success',
              message: 'The list has been successfuly updated.',
            })
          }
        }
      })}
    >
      <DragDropContext
        onDragEnd={(result) => {
          result.destination &&
            move(result.source.index, result.destination.index)
        }}
      >
        <Droppable droppableId="settings_hero">
          {(provided) => (
            <div
              className="flex flex-col h-full gap-3 "
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {fields.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={`hero-item-${item.id}`}
                  index={index}
                >
                  {(provided) => (
                    <div
                      className="flex gap-3 items-stretch border border-gray-500 rounded-lg mb-6 bg-white w-full"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <div
                        className="p-2 border-r border-gray-500 rounded-l-lg hover:bg-gray-300 active:bg-gray-300 "
                        {...provided.dragHandleProps}
                      >
                        <div className="flex flex-col h-full items-center">
                          <DragIcon className="w-1.5 my-auto" />
                        </div>
                      </div>
                      <div className="flex gap-3 p-3 w-full">
                        <img
                          src={item.image}
                          alt="hero"
                          className="h-20 w-20"
                        />
                        <div>
                          <Title title={item.title} subtitle={item.subtitle} />
                        </div>
                        <div className="flex items-center ml-auto gap-3">
                          {/* NOTE: CHANGE THIS ICON  THIS IS JUST A PLACEHOLDER*/}
                          <button
                            type="button"
                            className="p-2 bg-primary rounded-full"
                            onClick={() => history.push(`/hero/${item.id}`)}
                          >
                            <IconEdit
                              height="12"
                              width="10"
                              className="fill-white"
                            />
                          </button>
                          <button
                            type="button"
                            className="p-2 bg-action-fail rounded-full"
                            onClick={() => remove(index)}
                          >
                            <IconClose width="10" className="fill-white" />
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button
        $fluid
        className="mt-4"
        onClick={() => history.push('/new/hero/new_hero')}
        type="button"
        $type="secondary"
      >
        Add More
      </Button>
      <Button $fluid className="mt-4" disabled={!isDirty}>
        Save
      </Button>
    </form>
  )
}

export default withContainer(withSettingsMenu(DashBoardHero))
