import React, { FC, useCallback } from 'react'
import HomePage from 'pages/HomePage'
import DummyPage from 'pages/DummyPage'
import { Switch, Route } from 'react-router-dom'
import RequiresLogin from 'components/RequiresLogin'
import Logout from 'pages/Logout'
import DashBoardHero from 'pages/DashboardHero'
import HeroDetails from 'pages/HeroDetails'
import withContainer from 'hocs/withContainer'
import SettingsUsers from 'pages/SettingsUsers'

export type RouteType = {
  path: string
  Component: FC
  exact?: boolean
  private?: boolean
}

function Routes() {
  const routes = useCallback(
    (): RouteType[] =>
      [
        {
          private: true,
          path: '/',
          Component: HomePage,
          exact: true,
        },
        {
          private: true,
          path: '/settings/banner_hero',
          Component: DashBoardHero,
          exact: true,
        },
        {
          private: true,
          path: '/settings/users',
          Component: SettingsUsers,
        },
        {
          private: true,
          path: '/hero/:heroID',
          Component: HeroDetails,
        },
        {
          pirvate: true,
          path: '/new/hero/new_hero',
          Component: HeroDetails,
        },
        {
          private: true,
          path: '/dummy1',
          Component: DummyPage,
        },
        {
          private: true,
          path: '/dummy2',
          Component: DummyPage,
        },
        {
          private: true,
          path: '/dummy3',
          Component: DummyPage,
        },
        { private: true, path: '/logout', Component: Logout },
      ].filter(Boolean) as RouteType[],
    [],
  )
  return (
    <Switch>
      {routes().map(({ exact, path, Component, private: isPrivate }) => (
        <Route exact={exact} path={path} key={`dynamic-route-${path}`}>
          {isPrivate ? <RequiresLogin Component={Component} /> : <Component />}
        </Route>
      ))}
    </Switch>
  )
}

export default withContainer(Routes)
