import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/performance'
import 'firebase/analytics'

const firebaseApp =
  firebase.apps[0] ||
  firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  })

export const performance = firebase.performance()

export const analytics = firebase.analytics()

export default firebaseApp
