import React from 'react'

type Props = {
  data: number
  name: string
}

const DataCard = ({ data, name }: Props) => {
  return (
    <div className="bg-white border border-gray-400 rounded-lg overflow-hidden w-full text-center">
      <div className="bg-gradient-to-r from-primary to-secondary h-1.5 sm:h-2" />
      <div className="py-4">
        <div className="text-4xl sm:text-5xl text-primary font-bold">
          {data.toLocaleString()}
        </div>
        <div className="text-gray-600 text-xs sm:text-md my-1">{name}</div>
      </div>
    </div>
  )
}

export default DataCard
