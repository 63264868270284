export const SHORT_DOMAIN =
  process.env.SHORT_DOMAIN || process.env.REACT_APP_SHORT_DOMAIN || 'infx.link'
export const MARKETING_DOMAIN = 'influexer.com'
export const PUBLIC_PROFILE_URL = [SHORT_DOMAIN].join('/')

export const DASHBOARD_CATEGORIES = [
  'Today',
  'Yesterday',
  'This Week',
  'This Month',
  'This Year',
]
