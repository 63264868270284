import React from 'react'
import { ReactComponent as Hamburger } from 'assets/icons/nav.svg'
import { Link } from 'react-router-dom'
import { ReactComponent as Logo } from 'assets/logo.svg'
import { useAuth } from 'clients/auth.client'

function Header() {
  const { isAuthenticated } = useAuth()
  return (
    <>
      <header className="sticky top-0 bg-white bg-opacity-50 z-3 h-14 backdrop-filter backdrop-blur">
        <div className="max-w-screen-2xl mx-auto w-full flex justify-between items-center">
          <div className="flex items-center">
            <button
              //   onClick={() => {
              //     setMenu((current) => !current)
              //   }}
              className="py-5 px-3 sm:p-5"
              data-testid="button-menu"
            >
              <Hamburger width="20" />
            </button>
            {/* LOGO */}
            <Link to="/" className="inline-block">
              <Logo width="153" className="mx-1" />
            </Link>
          </div>
          {/* LOGOUT */}
          <div className="ml-3 sm:ml-0 mr-4">
            {isAuthenticated && <LoggedInItems />}
          </div>
        </div>
      </header>
    </>
  )
}

const LoggedInItems = () => {
  return (
    <div>
      <Link to="/logout" className="pt-1 pb-3">
        Logut
      </Link>
    </div>
  )
}

export default Header
