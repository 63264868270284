import Container from 'components/Container'
import DataCard from 'components/DataCard'
import FormDropDown from 'forms/FormDropDown'
import { DASHBOARD_CATEGORIES } from 'globalConstants'
import withContainer from 'hocs/withContainer'
import withSettingsMenu from 'hocs/withSettingsMenu'
import React from 'react'

function Dashboard() {
  return (
    <Container topAndBottom>
      <TotalUsers />
      <OrderStats />
    </Container>
  )
}

const TotalUsers = () => {
  return (
    <div className="flex gap-5 pb-5">
      <div className="w-full">
        <DataCard data={250000} name="Influencers" />
      </div>
      <div className="w-full">
        <DataCard data={1340000} name="Clients" />
      </div>
    </div>
  )
}

const OrderStats = () => {
  return (
    <div>
      <div className="flex items-center justify-between w-full py-2">
        <div className="text-lg font-bold">Orders</div>
        <div className="w-32">
          <FormDropDown $disableLabel $type="normal">
            {DASHBOARD_CATEGORIES.map((option) => (
              <option value={option} key={`audience_ages-${option}`}>
                {option}
              </option>
            ))}
          </FormDropDown>
        </div>
      </div>
      <div className="flex gap-5 pb-5">
        <div className="w-full">
          <DataCard data={252} name="Active Orders" />
        </div>
        <div className="w-full">
          <DataCard data={4876} name="Pending Orders" />
        </div>
        <div className="w-full">
          <DataCard data={989} name="Cancelled Orders" />
        </div>
      </div>
      <div className="w-full">
        <DataCard data={23765234} name="Completed Orders" />
      </div>
    </div>
  )
}

export default withContainer(withSettingsMenu(Dashboard))
